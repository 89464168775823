import axios from "axios";
import Vue from "vue";

export default {
  GET_PROMO_CODES: async ({ commit, rootState }, { shopId, options }) => {
    commit("setLoading", true);
    try {
      const {
        data: { promoCodes },
      } = await axios({
        url: `${
          rootState.apiAddress
        }/api/shops/${shopId}/promo-codes?options=${JSON.stringify(options)}`,
        headers: {
          Authorization: `Bearer ${rootState.userToken}`,
        },
      });
      commit("setPromoCodes", promoCodes);
    } catch (err) {
      const message =
        err.response && err.response.data && err.response.data.message
          ? err.response.data.message
          : rootState.defaultError;
      Vue.toasted.global
        .showServerError({
          message,
        })
        .goAway(4000);
    }
    commit("setLoading", false);
  },
  CREATE_PROMO_CODE: async (
    { rootState, commit, dispatch },
    { codeInfo, shopId }
  ) => {
    commit("setLoading", true);
    try {
      await axios({
        method: "POST",
        url: `${rootState.apiAddress}/api/shops/${shopId}/promo-code`,
        data: codeInfo,
        headers: {
          Authorization: `Bearer ${rootState.userToken}`,
        },
      });

      dispatch("GET_PROMO_CODES", { shopId });
    } catch (err) {
      const message =
        err.response && err.response.data && err.response.data.message
          ? err.response.data.message
          : rootState.defaultError;
      Vue.toasted.global
        .showServerError({
          message,
        })
        .goAway(4000);
    }
    commit("setLoading", false);
  },
  UPDATE_PROMO_CODE: async (
    { rootState, commit, dispatch },
    { codeId, promoCodeInfo, shopId }
  ) => {
    commit("setLoading", true);
    try {
      await axios({
        method: "PUT",
        url: `${rootState.apiAddress}/api/promo-codes/${codeId}`,
        headers: {
          Authorization: `Bearer ${rootState.userToken}`,
        },
        data: {
          promoCodeInfo: JSON.stringify(promoCodeInfo),
        },
      });

      dispatch("GET_PROMO_CODES", { shopId });
    } catch (err) {
      const message =
        err.response && err.response.data && err.response.data.message
          ? err.response.data.message
          : rootState.defaultError;
      Vue.toasted.global
        .showServerError({
          message,
        })
        .goAway(4000);
    }
    commit("setLoading", false);
  },
  DELETE_PROMO_CODE: async (
    { rootState, commit, dispatch },
    { code, shopId }
  ) => {
    commit("setLoading", true);
    try {
      await axios({
        method: "DELETE",
        url: `${rootState.apiAddress}/api/promo-codes/${code._id}`,
        headers: {
          Authorization: `Bearer ${rootState.userToken}`,
        },
      });

      dispatch("GET_PROMO_CODES", { shopId });
    } catch (err) {
      const message =
        err.response && err.response.data && err.response.data.message
          ? err.response.data.message
          : rootState.defaultError;
      Vue.toasted.global
        .showServerError({
          message,
        })
        .goAway(4000);
    }
    commit("setLoading", false);
  },
};
