<template>
  <div
    id="details"
    class="shop-section col-xs-12"
    v-bind:class="{ active: isActive }"
    @click="$emit('activeSection', 'details')"
  >
    <div class="header">
      <i class="fas fa-info section-icon" />

      <h5 class="section-title dark-blue-color">
        Details
        <br />
        <small>Store's basic information and contact details</small>
      </h5>
    </div>
    <b-form
      id="details-form"
      @submit="saveDetails"
      v-if="shopInfo"
      class="option-content"
    >
      <b-col>
        <b-row>
          <b-col sm="12" md="6">
            <b-form-group>
              <label>Store Title *</label>
              <input
                class="form-control"
                required
                v-model="shopInfo.name.normal"
                placeholder="e.g. Auckland central branch"
              />
              <small>Store display name on OTG</small>
            </b-form-group>
            <b-form-group>
              <label>Contact Email *</label>
              <input
                class="form-control"
                required
                v-model="shopInfo.contact.email"
                placeholder="e.g. contact@store.co.nz"
                type="email"
              />
              <small>Store contact email, for customers and others</small>
            </b-form-group>
          </b-col>
          <b-col sm="12" md="6">
            <b-form-group>
              <label>Contact Phone *</label>
              <input
                class="form-control"
                type="tel"
                pattern="^[0-9-+s()]*$"
                required
                v-model="shopInfo.contact.phone"
                placeholder="e.g. 0800 1234567"
              />
              <small
                >Store contact phone number, for customers and others</small
              >
            </b-form-group>
            <b-form-group>
              <label>GST Number</label>
              <input
                class="form-control"
                required
                v-model="shopInfo.orderingPreferences.GSTNumber"
                placeholder="e.g. 111-111-11"
              />
              <small
                >Store's GST Number to display on receipt emails for
                customers</small
              >
            </b-form-group>
            <b-button
              variant="primary"
              type="submit"
              class="float-right mb-1"
              :disabled="loading"
              pill
            >
              <b-spinner v-if="loading" />
              <span v-else>Save</span>
            </b-button>
          </b-col>
        </b-row>
      </b-col>
    </b-form>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "details-form",
  props: ["isActive", "shop"],
  data: () => ({
    shopInfo: null,
  }),
  computed: {
    ...mapGetters("shop", ["loading"]),
  },
  methods: {
    saveDetails(e) {
      e.preventDefault();
      let shopInfo = {
        name: this.shopInfo.name,
        contact: this.shopInfo.contact,
        "orderingPreferences.GSTNumber":
          this.shopInfo.orderingPreferences.GSTNumber,
      };
      this.$emit("saveChanges", shopInfo);
    },
  },
  mounted() {
    this.shopInfo = JSON.parse(JSON.stringify(this.shop));
  },
};
</script>

<style scoped></style>
