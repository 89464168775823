<template>
  <b-row>
    <b-col>
      <b-form-group label="Discount Target">
        <b-form-radio
          v-model="promoCode.rewardConfig.discountTargetType"
          value="order"
          :disabled="promoCode.type === 'free-product'"
        >
          Full Order
        </b-form-radio>
        <b-form-radio
          v-model="promoCode.rewardConfig.discountTargetType"
          value="products"
        >
          Selected Products
        </b-form-radio>
      </b-form-group>
      <b-form-group
        v-if="promoCode.rewardConfig.discountTargetType === 'products'"
        label="which products should the promo code be enabled for? Please select below."
      >
        <b-tabs>
          <b-tab
            v-for="category of categories"
            :key="category._id"
            :title="category.name"
          >
            <b-list-group class="mt-3">
              <b-list-group-item
                v-for="product of category.products"
                :key="product._id"
                :active="
                  promoCode.rewardConfig.rewardTargetProducts.includes(
                    product._id
                  )
                "
                @click="toggleProduct(product)"
              >
                {{ product.name }}
                <span class="float-right">
                  ${{ Math.floor(product.price / 100).toFixed(2) }}
                </span>
              </b-list-group-item>
            </b-list-group>
          </b-tab>
        </b-tabs>
      </b-form-group>
    </b-col>
  </b-row>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "step1",
  props: ["promoCode"],
  computed: {
    ...mapGetters("category", ["categories"]),
  },
  methods: {
    toggleProduct(product) {
      if (
        this.promoCode.rewardConfig.rewardTargetProducts.includes(product._id)
      )
        this.promoCode.rewardConfig.rewardTargetProducts =
          this.promoCode.rewardConfig.rewardTargetProducts.filter(
            (p) => p !== product._id
          );
      else this.promoCode.rewardConfig.rewardTargetProducts.push(product._id);
    },
  },
};
</script>

<style scoped></style>
