<template>
  <b-card class="m-0 p-0" no-body>
    <b-card-body class="m-0 p-0">
      <table
          class="table b-table table-hover table-borderless table-sm"
      >
        <tbody>
        <tr>
          <th class="table-primary b-table-sticky-column position-relative">Categories</th>
          <th v-for="day of dates" :key="moment(day).toISOString()" class="table-primary b-table-sticky-column position-relative">
            {{moment(day).format('DD/MM/YYYY')}}
          </th>
        </tr>
        <tr v-for="category of categoryNames" :key="category">
          <td class="table-b-table-default b-table-sticky-column">
            {{category}}
          </td>
          <td v-for="day of dates" :key="moment(day).toISOString()">
            <categories-cell :data="getCellData(day, category)" />
          </td>
        </tr>
        </tbody>
      </table>
    </b-card-body>
  </b-card>
</template>

<script>
import * as moment from 'moment';
import CategoriesCell from "@/components/reports/categories-cell";

export default {
  name: "categories",
  components: {CategoriesCell},
  props: ['report'],
  data: () =>({
  }),
  computed: {
    categoryNames() {
      let categoryNames = [];
      for (let item of this.report.data) {
        categoryNames = [...categoryNames, ...item.lineItems.map(li => (li.product && li.product.category && li.product.category.name))]
      }
      return Array.from(new Set(categoryNames))
    },
    dates() {
      let dates = [];

      let currDate = moment(this.report.startDate, 'DD-MM-YYYY').startOf('day');
      let lastDate = moment(this.report.endDate, 'DD-MM-YYYY').startOf('day');

      while(currDate.add(1, 'days').diff(lastDate) < 1) {
        dates.push(currDate.clone().toDate());
      }

      return dates;
    }
  },
  methods: {
    moment,
    getEmail(data) {
      if (data.item.owner) {
        return data.item.owner && data.item.owner.profile && data.item.owner.profile.email
      } else {
        return data.item.guestOwner && data.item.guestOwner.profile && data.item.guestOwner.profile.email
      }
    },
    getCellData(date, category) {
      let filteredOrders = this.report.data.filter(o => {
        return moment(o.createdAt).isSame(moment(date), 'day') && o.lineItems.filter( li => li.product.category.name === category).length > 0

      })
      let filteredLineItems = filteredOrders.map(o => o.lineItems).flat(1).filter(li => li.product.category.name === category)
      let total = filteredLineItems.reduce((sum, next) => {
        return sum + this.getProductTotalCost(next)
      },0)

      return {items: filteredLineItems.length, total}
    },
    getProductTotalCost(item) {
      let total = 0;
      total += item.product.price;
      for (let option of item.config) {
        if (option.value) {
          if (option.value.constructor !== Array) {
            if (option.value.price) total += parseInt(option.value.price);
          } else {
            for (let op of option.value) {
              if (op.price) total += parseInt(op.price);
            }
          }
        }
      }
      return total * item.quantity;
    },
    getCSVData() {
      let csvContent = ',' + this.dates.map(d => (moment(d).format('DD/MM/YYYY'))).join(',') + ', Total\r\n';
      for  (let category of this.categoryNames) {
        csvContent +=  `"${category}"`
        for (let day of this.dates) {
          const cellData = this.getCellData(day, category)
          csvContent +=  `, Items: ${cellData.items} - Total: $${(cellData.total/100).toFixed(2)}`
        }
        csvContent += '\r\n'
      }
      return csvContent
    }
  }
}
</script>

<style scoped>

</style>
