<template>
  <b-col>
    <b-card>
      <h4>Category Buttons</h4>
      <b-row>
        <b-col sm="12" md="6">
          <b-form-group label="Container Background Color">
            <b-input-group>
              <b-form-select
                :options="enumerations"
                v-model="pageLayout.categoryContainerColor"
              />
              <b-input-group-append>
                <theme-helper
                  :theme="theme"
                  :value="pageLayout.categoryContainerColor"
                  type="color"
                />
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col sm="12" md="6">
          <b-form-group label="Text Color">
            <b-input-group>
              <b-form-select
                :options="enumerations"
                v-model="pageLayout.categoryButtonTextColor"
              />
              <b-input-group-append>
                <theme-helper
                  :theme="theme"
                  :value="pageLayout.categoryButtonTextColor"
                  type="color"
                />
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col sm="12" md="6">
          <b-form-group label="Font">
            <b-form-select
              :options="enumerations"
              v-model="pageLayout.categoryButtonFont"
            />
          </b-form-group>
        </b-col>
        <b-col sm="12" md="6">
          <b-form-group label="Text Size">
            <b-form-select
              :options="textSizeOptions"
              v-model="pageLayout.categoryButtonTextSize"
            />
          </b-form-group>
        </b-col>
        <b-col sm="12" md="6">
          <b-form-group label="Border Color">
            <b-input-group>
              <b-form-select
                :options="enumerations"
                v-model="pageLayout.categoryButtonBorderColor"
              />
              <b-input-group-append>
                <theme-helper
                  :theme="theme"
                  :value="pageLayout.categoryButtonBorderColor"
                  type="color"
                />
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>
      </b-row>
      <h4>Category Headers</h4>
      <b-row>
        <b-col sm="12" md="6">
          <b-form-group label="Text Color">
            <b-input-group>
              <b-form-select
                :options="enumerations"
                v-model="pageLayout.categoryHeaderTextColor"
              />
              <b-input-group-append>
                <theme-helper
                  :theme="theme"
                  :value="pageLayout.categoryHeaderTextColor"
                  type="color"
                />
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col sm="12" md="6">
          <b-form-group label="Font">
            <b-form-select
              :options="enumerations"
              v-model="pageLayout.categoryHeaderFont"
            />
          </b-form-group>
        </b-col>
        <b-col sm="12" md="6">
          <b-form-group label="Text Size">
            <b-form-select
              :options="textSizeOptions"
              v-model="pageLayout.categoryHeaderTextSize"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <hr />
      <h4>Container</h4>
      <b-row>
        <b-col sm="12" md="6">
          <b-form-group label="Background Color">
            <b-input-group>
              <b-form-select
                :options="enumerations"
                v-model="pageLayout.containerColor"
              />
              <b-input-group-append>
                <theme-helper
                  :theme="theme"
                  :value="pageLayout.containerColor"
                  type="color"
                />
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col sm="12" md="6">
          <b-form-group label="Product per row">
            <b-form-select
              :options="[2, 3, 4]"
              v-model="pageLayout.productsColumns"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <hr />
      <h4>Product Name</h4>
      <b-row>
        <b-col sm="12" md="6">
          <b-form-group label="Text Color">
            <b-input-group>
              <b-form-select
                :options="enumerations"
                v-model="pageLayout.productNameTextColor"
              />
              <b-input-group-append>
                <theme-helper
                  :theme="theme"
                  :value="pageLayout.productNameTextColor"
                  type="color"
                />
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col sm="12" md="6">
          <b-form-group label="Font">
            <b-form-select
              :options="enumerations"
              v-model="pageLayout.productNameFont"
            />
          </b-form-group>
        </b-col>
        <b-col sm="12" md="6">
          <b-form-group label="Text Size">
            <b-form-select
              :options="textSizeOptions"
              v-model="pageLayout.productNameTextSize"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <h4>Product Description</h4>
      <b-row>
        <b-col sm="12" md="6">
          <b-form-group label="Text Color">
            <b-input-group>
              <b-form-select
                :options="enumerations"
                v-model="pageLayout.productDescriptionTextColor"
              />
              <b-input-group-append>
                <theme-helper
                  :theme="theme"
                  :value="pageLayout.productDescriptionTextColor"
                  type="color"
                />
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col sm="12" md="6">
          <b-form-group label="Font">
            <b-form-select
              :options="enumerations"
              v-model="pageLayout.productDescriptionFont"
            />
          </b-form-group>
        </b-col>
        <b-col sm="12" md="6">
          <b-form-group label="Text Size">
            <b-form-select
              :options="textSizeOptions"
              v-model="pageLayout.productDescriptionTextSize"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <h4>Product Price</h4>
      <b-row>
        <b-col sm="12" md="6">
          <b-form-group label="Text Color">
            <b-input-group>
              <b-form-select
                :options="enumerations"
                v-model="pageLayout.productPriceTextColor"
              />
              <b-input-group-append>
                <theme-helper
                  :theme="theme"
                  :value="pageLayout.productPriceTextColor"
                  type="color"
                />
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col sm="12" md="6">
          <b-form-group label="Font">
            <b-form-select
              :options="enumerations"
              v-model="pageLayout.productPriceFont"
            />
          </b-form-group>
        </b-col>
        <b-col sm="12" md="6">
          <b-form-group label="Text Size">
            <b-form-select
              :options="textSizeOptions"
              v-model="pageLayout.productPriceTextSize"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-button
        variant="primary"
        @click="$emit('saved', { key: 'menu', config: { ...pageLayout } })"
        >Save</b-button
      >
    </b-card>
  </b-col>
</template>

<script>
import { enumerations, textSizeOptions } from "@/utils/constants";
import ThemeHelper from "@/components/layout-editor/themeHelper";

export default {
  name: "menuTab",
  components: { ThemeHelper },
  props: ["pageLayout", "theme"],
  data: () => ({
    show: false,
    textSizeOptions,
    enumerations,
  }),
};
</script>

<style scoped></style>
