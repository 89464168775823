<template>
  <div>
    <b-card>
      <b-col>
        <h5 class="section-title orange-color">
          Shop Settings
          <br />
          <small class="dark-blue-color">
            Define your venue's customised settings
          </small>
        </h5>
      </b-col>
    </b-card>
    <div class="col-xs-12" style="padding: 32px" v-if="shop">
      <DetailsForm
        :shop="shop"
        :isActive="activeSection === 'details'"
        v-on:activeSection="toggleSection"
        v-on:saveChanges="updateShop"
      ></DetailsForm>
      <Location
        :shop="shop"
        :isActive="activeSection === 'location'"
        v-on:activeSection="toggleSection"
        v-on:saveChanges="updateShop"
      ></Location>
      <Presentation
        :shop="shop"
        :isActive="activeSection === 'presentation'"
        v-on:activeSection="toggleSection"
        v-on:saveChanges="updateShop"
      ></Presentation>
      <Verification
        :shop="shop"
        :stripeAccount="stripeAccount"
        :isActive="activeSection === 'verification'"
        v-on:activeSection="toggleSection"
        v-on:saveChanges="updateShop"
      ></Verification>
      <Notifications
        :shop="shop"
        :isActive="activeSection === 'notifications'"
        v-on:activeSection="toggleSection"
        v-on:saveChanges="updateShop"
      ></Notifications>
      <Ordering
        :shop="shop"
        :isActive="activeSection === 'ordering'"
        v-on:activeSection="toggleSection"
        v-on:saveChanges="updateShop"
      ></Ordering>
      <OpeningHours
        :shop="shop"
        :isActive="activeSection === 'opening-hours'"
        v-on:activeSection="toggleSection"
        v-on:saveChanges="updateShop"
      ></OpeningHours>
      <Delivery
        :shop="shop"
        v-if="
          shop.subscription.legacy ||
          shop.orderingPreferences.legacyUnderSubscription ||
          (!shop.subscription.id && !isTrialExpired) ||
          shop.subscription.permissions.includes('self-delivery')
        "
        :isActive="activeSection === 'delivery'"
        v-on:activeSection="toggleSection"
        v-on:saveChanges="updateShop"
      ></Delivery>
      <Pickup
        :shop="shop"
        :isActive="activeSection === 'pickup'"
        v-on:activeSection="toggleSection"
        v-on:saveChanges="updateShop"
      ></Pickup>
      <TableOrdering
        :shop="shop"
        :isActive="activeSection === 'table-ordering'"
        v-on:activeSection="toggleSection"
        v-on:saveChanges="updateShop"
      ></TableOrdering>
      <BuzzerSettings
        :shop="shop"
        :isActive="activeSection === 'buzzer'"
        v-on:activeSection="toggleSection"
        v-on:saveChanges="updateShop"
      />
      <Tab
        :shop="shop"
        :isActive="activeSection === 'tab'"
        v-on:activeSection="toggleSection"
        v-on:saveChanges="updateShop"
      ></Tab>
      <Loyalty
        :shop="shop"
        :isActive="activeSection === 'loyalty'"
        v-on:activeSection="toggleSection"
        v-on:saveChanges="updateShop"
      ></Loyalty>
      <Subscription
        :shop="shop"
        :isActive="activeSection === 'subscription'"
        v-on:activeSection="toggleSection"
        v-on:saveChanges="updateShop"
      ></Subscription>
    </div>
  </div>
</template>

<script>
import DetailsForm from "../components/shop-settings/details";
import Delivery from "../components/shop-settings/delivery";
import Location from "../components/shop-settings/location";
import Loyalty from "../components/shop-settings/loyalty";
import Notifications from "../components/shop-settings/notifications";
import OpeningHours from "../components/shop-settings/opening-hours";
import Ordering from "../components/shop-settings/ordering";
import Pickup from "../components/shop-settings/pickup";
import Presentation from "../components/shop-settings/presentation";
import Verification from "../components/shop-settings/verification";
import Subscription from "../components/shop-settings/subscription";
import Tab from "../components/shop-settings/tab";
import { mapGetters } from "vuex";
import { beforeRouteEnter } from "../router";
import TableOrdering from "../components/shop-settings/table-ordering";
import BuzzerSettings from "@/components/shop-settings/buzzer-settings.vue";

export default {
  name: "settings",
  props: ["section"],
  data: () => ({
    activeSection: "",
  }),
  components: {
    TableOrdering,
    DetailsForm,
    Delivery,
    Location,
    Loyalty,
    Notifications,
    OpeningHours,
    Ordering,
    Pickup,
    Presentation,
    Verification,
    Subscription,
    Tab,
    BuzzerSettings,
  },
  methods: {
    toggleSection(section) {
      this.activeSection = section;
    },
    async updateShop(shopInfo) {
      await this.$store.dispatch("shop/UPDATE_SHOP", {
        shopId: this.shop._id,
        shopInfo,
      });
    },
  },
  computed: {
    ...mapGetters("shop", ["shop", "stripeAccount", "isTrialExpired"]),
  },
  watch: {
    shop(newVal, oldVal) {
      console.log(newVal);
      if ((!oldVal && newVal) || newVal._id !== oldVal._id) {
        this.$store.dispatch("shop/GET_SHOP_ACCOUNT_LINK", {
          shopId: newVal._id,
        });
        if (this.$route?.query?.section) {
          this.activeSection = this.$route.query.section;
          setTimeout(() => {
            const el = document.getElementById(this.$route.query.section);
            el.scrollIntoView({ behavior: "smooth" });
          }, 1000);
        }
      }
    },
  },
  beforeRouteEnter,
};
</script>

<style>
.shop-section {
  border-top: 1px solid #e2e6e8;
  width: 100%;
  -webkit-transition: all ease 200ms;
  -moz-transition: all ease 200ms;
  -ms-transition: all ease 200ms;
  -o-transition: all ease 200ms;
  transition: all ease 200ms;
  height: 100px;
}

.shop-section .section-icon {
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  height: 50px;
  display: inline-block;
  width: 50px;
  background: white;
  text-align: center;
  justify-content: center;
  vertical-align: text-bottom;
  padding: 15px;
  margin-right: 15px;
  font-size: 20px;
  color: #2e4369;
}

.shop-section.active .section-icon,
.shop-section:hover .section-icon {
  background: #f2f5f7;
}

.shop-section .header {
  cursor: pointer;
  padding: 20px;
  -webkit-transition: all ease 200ms;
  -moz-transition: all ease 200ms;
  -ms-transition: all ease 200ms;
  -o-transition: all ease 200ms;
  transition: all ease 200ms;
}

.shop-section:hover {
  background: white;
}

.shop-section .section-title {
  width: calc(100% - 90px);
  display: inline-block;
}

.shop-section small {
  color: #5d7079;
}

.shop-section .option-content {
  display: none;
  -webkit-transition: all ease 200ms;
  -moz-transition: all ease 200ms;
  -ms-transition: all ease 200ms;
  -o-transition: all ease 200ms;
  transition: all ease 200ms;
  padding-bottom: 20px;
}

.shop-section.active {
  border: none;
  height: auto;
  background: white;
  margin: 24px 0px;
  -webkit-box-shadow: 0 1px 10px #e2e6e8;
  -moz-box-shadow: 0 1px 10px #e2e6e8;
  box-shadow: 0 1px 10px #e2e6e8;
}

.shop-section.active + .shop-section {
  border-top-width: 0;
}

.shop-section.active .option-content {
  display: block;
}
</style>
