<template>
  <div
    id="report-container"
    class="col-xs-12 npsds"
    style="padding-top: 0px; height: 100%"
  >
    <b-card>
      <b-row>
        <b-col sm="12" md="8">
          <h5 class="section-title orange-color">
            Voucher Manager
            <br />
            <small class="dark-blue-color">
              Create your own promotional codes to reward people or for campaign
              purpose
            </small>
          </h5>
        </b-col>
        <b-col sm="12" md="4">
          <b-button
            v-b-modal.promo-code-editor
            pill
            class="float-right"
            variant="primary"
            @click="openPromoCreationModal"
            >Generate Code</b-button
          >
        </b-col>
      </b-row>
    </b-card>

    <b-col class="mt-3">
      <b-table
        id="codes-data"
        head-row-variant="primary"
        foot-row-variant="success"
        ref="selectableTable"
        show-empty
        hover
        small
        borderless
        sticky-header
        style="max-height: calc(100% - 132px)"
        :busy="loading"
        :fields="fields"
        :items="promoCodes"
        sort-by="code"
      >
        <template #table-busy>
          <div class="text-center text-primary my-2">
            <b-spinner class="align-middle"></b-spinner>
          </div>
        </template>

        <template #empty>
          <h4 class="text-center">No available promo codes</h4>
        </template>

        <template #cell(expiry)="data">
          {{ moment(data.item.expiry).format("DD/MM/YYYY [at] HH:mm") }}
        </template>

        <template #cell(rewardConfig)="data">
          <label>Target: {{ data.item.rewardConfig.discountTargetType }}</label
          ><br />
          <label
            >Discount:
            {{ data.item.rewardConfig.discountType === "fixed-cost" ? "$" : ""
            }}{{ data.item.rewardConfig.discount
            }}{{
              data.item.rewardConfig.discountType !== "fixed-cost" ? "%" : ""
            }}
            off</label
          ><br />
          <div v-if="data.item.rewardConfig.discountTargetType === 'products'">
            <label>Affected Products:</label><br />
            <label
              class="label label-default"
              v-for="reward of data.item.rewardConfig.rewardTargetProducts"
              :key="reward._id"
              >{{ reward.name }} ${{
                Math.floor(reward.price / 100).toFixed(2)
              }}</label
            >
          </div>
        </template>

        <template #cell(restrictions)="data">
          <label v-if="data.item.rewardConfig.minimumSpent"
            >Minimum Spend: ${{
              data.item.rewardConfig.minimumSpent.toFixed(2)
            }}</label
          ><br />
          <label
            >Usage Limit:
            {{ data.item.rewardConfig.usageLimit || 0 }} Times</label
          >
        </template>

        <template #cell(users)="data">
          <div v-if="data.item.targetUsersConfig.isStoreWide">Everybody</div>
          <div v-else>
            <label
              class="label label-default"
              v-for="user of data.item.targetUsersConfig.users"
              :key="user._id"
              >{{ user.name.given }} {{ user.name.family }}({{
                user.profile.email
              }})</label
            ><br />
          </div>
        </template>

        <template #cell(_id)="data">
          <b-button
            pill
            variant="outline-primary"
            v-b-modal.promo-code-editor
            @click="selectedPromoCode = data.item"
          >
            <i class="fas fa-pen pointer" />
          </b-button>
        </template>

        <template #cell(createdAt)="data">
          <b-button
            pill
            variant="outline-danger"
            v-b-modal.promo-code-delete-modal
            @click="selectedPromoCode = data.item"
          >
            <i class="fas fa-trash pointer" />
          </b-button>
        </template>
      </b-table>
    </b-col>
    <promo-code-editor :promoCode="selectedPromoCode" />
    <deleteCodeModal :promoCode="selectedPromoCode" />
  </div>
</template>

<script>
import moment from "moment";
import { mapGetters } from "vuex";
import deleteCodeModal from "../components/promocodes/deleteCodeModal";
import PromoCodeEditor from "../components/promocodes/editPromoCode/index";
export default {
  name: "promo-codes",
  components: {
    PromoCodeEditor,
    deleteCodeModal,
  },
  data: () => ({
    fields: [
      {
        key: "code",
        stickyColumn: true,
        sortable: true,
        label: "Code",
      },
      {
        key: "status",
        stickyColumn: true,
        sortable: true,
        label: "Status",
      },
      {
        key: "expiry",
        stickyColumn: true,
        sortable: true,
        label: "Expiry",
      },
      {
        key: "type",
        stickyColumn: true,
        sortable: true,
        label: "Type",
      },
      {
        key: "rewardConfig",
        stickyColumn: true,
        sortable: true,
        label: "Details",
      },
      {
        key: "restrictions",
        stickyColumn: true,
        sortable: true,
        label: "Restrictions",
      },
      {
        key: "users",
        stickyColumn: true,
        sortable: true,
        label: "Users",
      },
      {
        key: "_id",
        stickyColumn: true,
        label: "",
      },
      {
        key: "createdAt",
        stickyColumn: true,
        label: "",
      },
    ],
    selectedPromoCode: null,
  }),
  methods: {
    moment,
    deletePromoCode(code) {
      console.log(code);
    },
    openPromoCreationModal() {
      this.selectedPromoCode = {
        type: "discount",
        rewardConfig: {
          discountType: "fixed-cost",
          rewardTargetProducts: [],
          discount: 1,
          minimumSpent: 10,
          discountTargetType: "order",
        },
        targetUsersConfig: {
          isStoreWide: true,
          usageLimit: 0,
          users: [],
        },
        expiry: moment().add(1, "days").toDate(),
      };
    },
  },
  computed: {
    ...mapGetters("shop", ["shop", "loading"]),
    ...mapGetters("promocode", ["promoCodes", "loading"]),
  },
  mounted() {
    if (this.shop)
      this.$store.dispatch("promocode/GET_PROMO_CODES", {
        shopId: this.shop._id,
      });
  },
  watch: {
    shop(newVal) {
      if (!newVal) return;
      this.$store.dispatch("promocode/GET_PROMO_CODES", {
        shopId: this.shop._id,
      });
      this.$store.dispatch("customer/GET_CUSTOMERS", {
        shopId: newVal._id,
        options: { limit: 1000, sort: "name.given" },
      });
    },
  },
};
</script>

<style scoped></style>
