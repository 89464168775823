<template>
  <b-row>
    <b-col>
      <b-form-group
        label="Customised Promo Code Name"
        description="If you don't enter a name, we will auto-generate a code for you."
      >
        <b-form-input placeholder="i.e. todaypromo" v-model="promoCode.code" />
      </b-form-group>
      <b-form-group
        label="Type"
        description="Select the type of reward that this promo code will provide."
      >
        <b-form-select v-model="promoCode.type">
          <option value="discount">Discount</option>
          <option value="free-product">Free Product</option>
        </b-form-select>
      </b-form-group>
    </b-col>
  </b-row>
</template>

<script>
export default {
  name: "step1",
  props: ["promoCode"],
};
</script>

<style scoped></style>
