<template>
  <b-row>
    <b-col>
      <b-row>
        <b-col v-if="!kiosk">
          <slot />
        </b-col>
        <b-col>
          <b-form-group
            label="Design Section"
            description="Select which section of the design you want to edit"
          >
            <b-form-select
              v-model="displaySection"
              :options="displaySections"
              class="text-capitalize"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <div class="row pt-2">
        <theme-tab
          v-if="displaySection === 'theme'"
          :layout-config="layoutConfig.theme"
          @saved="saveConfig"
          @reset-theme="resetTheme"
        />
        <navbar-tab
          v-if="displaySection === 'navbar'"
          :page-layout="layoutConfig.navbar"
          :theme="layoutConfig.theme"
          @image-editor-config="setImageEditorConfig"
          @saved="saveConfig"
        />
        <actionbar-tab
          v-if="displaySection === 'actionbar'"
          :page-layout="layoutConfig.actionbar"
          :theme="layoutConfig.theme"
          @saved="saveConfig"
        />
        <landing-tab
          v-if="displaySection === 'landing-page'"
          :page-layout="layoutConfig.landing"
          :theme="layoutConfig.theme"
          @image-editor-config="setImageEditorConfig"
          @saved="saveConfig"
        />
        <menu-tab
          v-if="displaySection === 'menu-page'"
          :page-layout="layoutConfig.menu"
          :theme="layoutConfig.theme"
          @saved="saveConfig"
        />
        <product-tab
          v-if="displaySection === 'product-page'"
          :page-layout="layoutConfig.product"
          :theme="layoutConfig.theme"
          @saved="saveConfig"
        />
        <cart-tab
          v-if="displaySection === 'cart-page'"
          :page-layout="layoutConfig.cart"
          :theme="layoutConfig.theme"
          @saved="saveConfig"
        />
        <checkout-tab
          v-if="displaySection === 'checkout-page'"
          :page-layout="layoutConfig.checkout"
          :theme="layoutConfig.theme"
          @saved="saveConfig"
        />
      </div>
      <image-editor
        :image-info="{
          type: 'kiosk-upload',
          aspectRatio: aspectRatio,
        }"
        @upload-success="onUploadSuccess"
      />
    </b-col>
  </b-row>
</template>

<script>
import { mapGetters } from "vuex";
import LandingTab from "@/components/layout-editor/landingTab";
import ImageEditor from "../imageEditor";
import ThemeTab from "@/components/layout-editor/themeTab";
import MenuTab from "@/components/layout-editor/menuTab";
import ProductTab from "@/components/layout-editor/productTab";
import CartTab from "@/components/layout-editor/cartTab";
import CheckoutTab from "@/components/layout-editor/checkoutTab";
import NavbarTab from "@/components/layout-editor/navbarTab";
import ActionbarTab from "@/components/layout-editor/actionbarTab";
import { twoPointOEmbed, twoPointOTheme } from "@/utils/constants";

export default {
  name: "layoutEditorTwo",
  components: {
    ActionbarTab,
    NavbarTab,
    CheckoutTab,
    ThemeTab,
    LandingTab,
    ImageEditor,
    MenuTab,
    ProductTab,
    CartTab,
  },
  props: ["kiosk", "embedKey", "embedVersion"],
  data: () => ({
    displaySection: "theme",
    aspectRatio: 1.25,
    imageEditorField: "",
    layoutConfig: {
      ...twoPointOTheme,
    },
  }),
  computed: {
    ...mapGetters("shop", ["shop"]),
    displaySections() {
      let commonSections = [
        "theme",
        "navbar",
        "actionbar",
        "menu-page",
        "product-page",
        "cart-page",
        "checkout-page",
      ];

      if (this.kiosk) commonSections.splice(3, 0, "landing-page");
      return commonSections;
    },
  },
  methods: {
    resetTheme() {
      const answer = confirm(
        `Do you which to reset your current theme to the "Host" default one? This action can't be undone`
      );
      if (!answer) return;
      this.layoutConfig = {
        ...(this.embedKey ? twoPointOEmbed : twoPointOTheme),
      };
    },
    saveConfig({ key, config }) {
      this.layoutConfig[key] = { ...this.layoutConfig[key], ...config };
      this.saveDetails({});
    },
    setImageEditorConfig(config) {
      this.imageEditorField = config.imageEditorField;
      this.aspectRatio = config.aspectRatio;
    },
    async onUploadSuccess(data) {
      console.log(data);
      const { secure_url } = data;
      switch (this.imageEditorField) {
        case "generalLogo":
          this.layoutConfig.general.logo = secure_url;
          break;
        case "navbarLogo":
          this.layoutConfig.navbar.logo = secure_url;
          this.$forceUpdate();
          break;
        case "landingLogo":
          this.layoutConfig.landing.logo = secure_url;
          this.$forceUpdate();
          break;
        case "landingBackgroundImage":
          this.layoutConfig.landing.backgroundImage = secure_url;
          this.$forceUpdate();
          break;
      }
    },
    async saveDetails(e) {
      if (e && e.preventDefault) e.preventDefault();
      if (this.kiosk) {
        this.$store.dispatch("kiosk/UPDATE_KIOSK", {
          kioskId: this.kiosk._id,
          kioskInfo: {
            layoutConfig: this.layoutConfig,
          },
          shopId: this.shop._id,
        });
      } else {
        const { layoutConfig, embedVersion } = this;
        this.$store.dispatch("shop/UPDATE_EMBED_KEY", {
          shopId: this.shop._id,
          embedKeyInfo: {
            embedVersion,
            layoutConfig,
          },
        });
      }
      this.setFieldValues();
    },
    setFieldValues() {
      if (this.kiosk) {
        this.layoutConfig = {
          ...this.layoutConfig,
          ...this.kiosk.layoutConfig,
        };
      } else if (this.embedKey) {
        this.layoutConfig = {
          ...this.layoutConfig,
          ...this.embedKey.layoutConfig,
        };
      }
    },
  },
  mounted() {
    this.setFieldValues();
  },
  watch: {
    kiosk(newVal) {
      if (!newVal) return;
      this.setFieldValues();
    },
  },
};
</script>

<style scoped></style>
