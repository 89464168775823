<template>
  <b-tab>
    <template #title>
      <i class="fas fa-address-card" /> Contact Details
    </template>
    <b-form @submit.prevent="saveDetails">
      <b-card v-if="userDetails">
        <b-row>
          <b-col lg="6" sm="12">
            <b-form-group
              label="First Name"
              description="User's first or given name"
            >
              <b-form-input
                required
                v-model="userDetails.name.given"
                placeholder="i.e.: John"
              />
            </b-form-group>
          </b-col>
          <b-col lg="6" sm="12">
            <b-form-group
              label="Last Name"
              description="User's last or family name"
            >
              <b-form-input
                required
                v-model="userDetails.name.family"
                placeholder="i.e.: Snow"
              />
            </b-form-group>
          </b-col>
          <b-col lg="6" sm="12">
            <b-form-group
              label="Email"
              description="User's email and username for access"
            >
              <b-form-input
                required
                v-model="userDetails.profile.email"
                placeholder="i.e.: john.snow@test.com"
              />
            </b-form-group>
          </b-col>
          <b-col lg="6" sm="12">
            <b-form-group
              label="Phone"
              description="User's contact phone/mobile number"
            >
              <b-input-group prepend="+64">
                <b-form-input
                  required
                  v-model="userDetails.profile.phone"
                  placeholder="i.e.: 021 111 22 33"
                />
              </b-input-group>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col lg="6" sm="12">
            <b-form-group
              v-if="userDetails.roles && myUserInfo.roles.includes('admin')"
              label="Roles"
            >
              <b-dropdown class="w-100">
                <template #button-content>
                  {{ userDetails.roles.length }} roles
                </template>
                <b-dropdown-item
                  :active="userDetails.roles.includes('consumer')"
                  @click="toggleRole('consumer')"
                >
                  consumer
                </b-dropdown-item>
                <b-dropdown-item
                  :active="userDetails.roles.includes('merchant')"
                  @click="toggleRole('merchant')"
                >
                  merchant
                </b-dropdown-item>
                <b-dropdown-item
                  :active="userDetails.roles.includes('beta-tester')"
                  @click="toggleRole('beta-tester')"
                >
                  beta-tester
                </b-dropdown-item>
                <b-dropdown-item
                  :active="userDetails.roles.includes('admin')"
                  @click="toggleRole('admin')"
                >
                  admin
                </b-dropdown-item>
              </b-dropdown>
            </b-form-group>
          </b-col>
        </b-row>
      </b-card>
      <b-button
        type="submit"
        pill
        variant="primary"
        class="float-right mt-3"
        size="lg"
        :disabled="loading"
      >
        <b-spinner v-if="loading" />
        <span v-else>Save</span>
      </b-button>
    </b-form>
  </b-tab>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "contact-details",
  props: ["userInfo"],
  data: () => ({
    userDetails: null,
  }),
  computed: {
    ...mapGetters({
      myUserInfo: "userInfo",
      loading: "loading",
    }),
  },
  methods: {
    toggleRole(role) {
      if (this.userDetails.roles.includes(role))
        this.userDetails.roles = this.userDetails.roles.filter(
          (r) => r !== role
        );
      else this.userDetails.roles.push(role);
    },
    async saveDetails() {
      await this.$store.dispatch("UPDATE_USER_INFO", {
        id: this.userInfo._id,
        userInfo: {
          name: {
            ...this.userDetails.name,
          },
          profile: {
            ...this.userDetails.profile,
          },
          roles: [...this.userDetails.roles],
        },
      });
    },
  },
  mounted() {
    if (this.userInfo)
      this.userDetails = JSON.parse(JSON.stringify(this.userInfo));
  },
  watch: {
    userInfo(newVal) {
      if (newVal) this.userDetails = newVal;
    },
  },
};
</script>

<style scoped></style>
