<template>
  <div style="height: 100%; overflow: scroll">
    <b-card>
      <h5 class="section-title orange-color">
        Embed Store
        <br />
        <small class="dark-blue-color">
          Set your embed online store settings and display
        </small>
      </h5>
    </b-card>
    <div v-if="!loading" style="height: 100%">
      <b-tabs
        v-if="embedKey"
        style="height: 100%"
        card
        fill
        pills
        @activate-tab="onTabChange"
      >
        <domain :embedKey="embedKey" :active="activeTab === 'domain'" />
        <b-tab title="Layout" :active="activeTab === 'layout'">
          <component
            v-bind:is="
              embedVersion === 1 ? 'layout-editor' : 'layout-editor-two'
            "
            :embed-key="embedKey"
            :embed-version="embedVersion"
          >
            <b-form-group
              label="Design Version"
              description="Pick which version of design you wish to display."
            >
              <b-select :options="[1, 2]" v-model="embedVersion" />
            </b-form-group>
          </component>
        </b-tab>
        <!--        <analytics :embedKey="embedKey" :active="activeTab === 'analytics'"/>-->
        <!--        <s-e-o :embedKey="embedKey" :active="activeTab === 'seo'"/>-->
        <q-r :embedKey="embedKey" :active="activeTab === 'qr'" />
      </b-tabs>
      <b-row v-if="!embedKey && !loading">
        <b-col>
          <b-alert show>
            <p>
              Embed store option is currently disabled. Would you like to enable
              it?
            </p>
            <b-button pill variant="primary" @click="enableEmbedStore" size="lg"
              >Yes, Enable Embed Store</b-button
            >
          </b-alert>
        </b-col>
      </b-row>
    </div>
    <div v-else class="text-center">
      <b-spinner></b-spinner>
    </div>
  </div>
</template>

<script>
import LayoutEditor from "../components/layoutEditor";
import Domain from "../components/embed-store/domain";
import QR from "../components/embed-store/qr";
import { mapGetters } from "vuex";
import LayoutEditorTwo from "@/components/layout-editor/layoutEditorTwo";

export default {
  name: "embed-store",
  components: { LayoutEditorTwo, Domain, LayoutEditor, QR },
  data: () => ({
    activeTab: "domain",
    embedVersion: 1,
  }),
  computed: {
    ...mapGetters("shop", ["shop", "loading", "embedKey"]),
  },
  mounted() {
    if (this.shop)
      this.$store.dispatch("shop/GET_EMBED_KEY", {
        shopId: this.shop._id,
        ignoreException: true,
      });
  },
  methods: {
    enableEmbedStore() {
      this.$store.dispatch("shop/CREATE_EMBED_KEY", { shopId: this.shop._id });
    },
    onTabChange(newIndex) {
      switch (newIndex) {
        case 0:
          this.activeTab = "domain";
          break;
        case 1:
          this.activeTab = "layout";
          break;
        case 2:
          this.activeTab = "analytics";
          break;
        case 3:
          this.activeTab = "seo";
          break;
      }
    },
  },
  watch: {
    shop() {
      this.$store.dispatch("shop/GET_EMBED_KEY", {
        shopId: this.shop._id,
        ignoreException: true,
      });
    },
    embedKey(newVal) {
      console.log(newVal);
      if (!newVal.name || newVal.name.trim() === "")
        this.embedKeyInfo.name = this.shop.name.normal;
      if (newVal) this.embedVersion = newVal.embedVersion;
    },
  },
};
</script>

<style scoped></style>
