<template>
  <b-col>
    <b-card>
      <h4>Colors</h4>
      <b-row>
        <b-col v-for="(color, key) in colors" :key="key" sm="12" md="6">
          <b-form-group :label="key">
            <b-row>
              <b-col cols="2" class="pr-0">
                <b-form-input type="color" v-model="colors[key]" />
              </b-col>
              <b-col cols="10">
                <b-form-input type="text" v-model="colors[key]" />
              </b-col>
            </b-row>
          </b-form-group>
        </b-col>
      </b-row>
      <hr />
      <h4>Fonts</h4>
      <b-row>
        <b-col v-for="(font, key) in fonts" :key="key" sm="12" md="6">
          <b-form-group :label="key">
            <b-form-input type="text" v-model="fonts[key]" />
          </b-form-group>
        </b-col>
      </b-row>
      <hr />
      <h4>Import Fonts</h4>
      <b-alert show class="pb-4"
        >Find Thousands of font styles in Google Fonts
      </b-alert>
      <b-row>
        <b-col cols="12">
          <b-button
            href="https://fonts.google.com/"
            target="_blank"
            variant="primary"
            class="float-right"
          >
            Go to Google fonts
          </b-button>
        </b-col>
      </b-row>
      <b-row class="mt-3">
        <b-col cols="12">
          <b-form-group
            description="Enter a list of font-face style import for custom fonts"
          >
            <b-form-textarea
              v-model="importFonts"
              rows="10"
              placeholder="Example: @import url('https://fonts.googleapis.com/css2?family=Inspiration&display=swap');"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <hr />
      <h4>App Container</h4>
      <b-row>
        <b-col sm="12" md="6">
          <b-form-group label="Background Color">
            <b-form-select
              :options="enumerations"
              v-model="layoutConfig.pageBackgroundColor"
            />
          </b-form-group>
        </b-col>
      </b-row>

      <b-button
        variant="primary"
        @click="
          $emit('saved', {
            key: 'theme',
            config: { colors, fonts, importFonts },
          })
        "
        >Save</b-button
      >
      <b-button class="ml-3" @click="$emit('reset-theme')">
        Reset to default
      </b-button>
    </b-card>
  </b-col>
</template>

<script>
import { enumerations } from "@/utils/constants";

export default {
  name: "themeTab",
  props: ["layoutConfig"],
  data: () => ({
    colors: {
      first: "#000000",
      second: "#000000",
      third: "#000000",
      fourth: "#000000",
      fifth: "#000000",
      sixth: "#000000",
      seventh: "#000000",
      eighth: "#000000",
      ninth: "#000000",
      tenth: "#000000",
    },
    fonts: {
      first: "matterregular",
      second: "matterregular",
      third: "matterregular",
      fourth: "matterregular",
      fifth: "matterregular",
      sixth: "matterregular",
      seventh: "matterregular",
      eighth: "matterregular",
      ninth: "matterregular",
      tenth: "matterregular",
    },
    importFonts: "",
    enumerations,
  }),
  mounted() {
    if (this.layoutConfig) {
      this.colors = {
        ...this.colors,
        ...this.layoutConfig.colors,
      };
      this.fonts = {
        ...this.fonts,
        ...this.layoutConfig.fonts,
      };
      this.importFonts = this.layoutConfig.importFonts;
    }
  },
  watch: {
    layoutConfig(newVal) {
      if (newVal) {
        console.log(newVal);
        this.colors = {
          ...this.colors,
          ...newVal.colors,
        };
        this.fonts = {
          ...this.fonts,
          ...newVal.fonts,
        };
        this.importFonts = newVal.importFonts;
      }
    },
  },
};
</script>

<style scoped></style>
