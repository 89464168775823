<template>
  <b-modal
    id="delete-product-modal"
    title="Delete Confirmation"
    okTitle="Delete"
    okVariant="danger"
    @ok="deleteRecord"
  >
    <p class="py-0" v-if="product">
      Are you sure you want to delete the record "{{ product.name }}"? This
      action can't be undone.
    </p>
  </b-modal>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "delete-category-modal",
  props: ["product", "returnTo"],
  computed: {
    ...mapGetters("shop", ["shop"]),
  },
  methods: {
    async deleteRecord() {
      await this.$store.dispatch("product/DELETE_PRODUCT", {
        productId: this.product._id,
        shopId: this.shop._id,
      });
      this.$bvModal.hide("delete-product-modal");
      if (this.returnTo) this.$router.push(this.returnTo);
    },
  },
};
</script>

<style scoped></style>
