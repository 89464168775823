<template>
  <b-tab title="Settings">
    <b-card>
      <b-row>
        <b-col>
          <div class="checkbox">
            <label>
              <input type="checkbox" v-model="dineIn.enabled" />
              Table ordering enabled
            </label>
          </div>
          <small
            >This option allows to provide dine in service for your customers
            from your embed store and or through QR code scan
          </small>
        </b-col>
      </b-row>
      <b-row class="mt-2">
        <b-col>
          <b-form-group>
            <label>Preparation time (minutes)</label>
            <input
              type="number"
              class="form-control"
              required
              v-model="dineIn.preparationTime"
              placeholder="Default time is 5 minutes"
            />
            <small
              >Minimum amount of time that the order preparation requires before
              customer can pick up. Usually food preparations take between 15 to
              30 minutes.
            </small>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form-group
            description="This option enable customers to pick in which order they want their food to be brought"
          >
            <b-form-checkbox v-model="dineIn.servingOrderEnabled"
              >Enable serving order</b-form-checkbox
            >
          </b-form-group>
        </b-col>
      </b-row>
    </b-card>
    <b-button class="mt-2 float-right" variant="primary" @click="save"
      >Save</b-button
    >
  </b-tab>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "settings",
  data: () => ({
    dineIn: {
      enabled: false,
      servingOrderEnabled: false,
      tables: [],
      preparationTime: 15,
    },
  }),
  computed: {
    ...mapGetters("shop", ["shop", "loading"]),
  },
  methods: {
    async save() {
      await this.$store.dispatch("shop/UPDATE_SHOP", {
        shopId: this.shop._id,
        shopInfo: { dineIn: this.dineIn },
      });
    },
  },
  mounted() {
    if (this.shop) this.dineIn = JSON.parse(JSON.stringify(this.shop.dineIn));
  },
  watch: {
    shop(newVal) {
      if ((!this.shop && newVal) || this.shop._id !== newVal._id)
        if (this.shop) this.dineIn = JSON.parse(JSON.stringify(newVal.dineIn));
    },
  },
};
</script>

<style scoped></style>
