import axios from "axios";
import Vue from "vue";

export default {
  GET_SHOPS: async (
    { commit, rootState },
    { search = "", type = "fixed", options }
  ) => {
    try {
      const {
        data: { shops, count },
      } = await axios({
        url: `${rootState.apiAddress}/api/shops?options=${JSON.stringify(
          options
        )}&type=${type}&search=${search}`,
        data: JSON.stringify({
          options,
        }),
        headers: {
          Authorization: `Bearer ${rootState.userToken}`,
        },
      });
      commit("setShops", shops);
      commit("setShowOnboarding", count < 1);

      if (localStorage.getItem("shopId")) {
        let shop = shops.find((s) => s._id === localStorage.getItem("shopId"));
        if (shop) commit("setShop", shop);
      }
    } catch (err) {
      const message =
        err.response && err.response.data && err.response.data.message
          ? err.response.data.message
          : rootState.defaultError;
      Vue.toasted.global
        .showServerError({
          message,
        })
        .goAway(4000);
    }
  },
  GET_SHOPS_BY_ADMIN: async (
    { commit, rootState },
    { search = "", type = "fixed", options }
  ) => {
    try {
      const {
        data: { shops, count },
      } = await axios({
        url: `${rootState.apiAddress}/api/admin-shops?options=${JSON.stringify(
          options
        )}&type=${type}&search=${search}`,
        data: JSON.stringify({
          options,
        }),
        headers: {
          Authorization: `Bearer ${rootState.userToken}`,
        },
      });
      commit("setShops", shops);
      commit("setShopsCount", count);

      if (localStorage.getItem("shopId")) {
        let shop = shops.find((s) => s._id === localStorage.getItem("shopId"));
        if (shop) commit("setShop", shop);
      }
    } catch (err) {
      const message =
        err.response && err.response.data && err.response.data.message
          ? err.response.data.message
          : rootState.defaultError;
      Vue.toasted.global
        .showServerError({
          message,
        })
        .goAway(4000);
    }
  },
  GET_SHOPS_BY_USER: async (
    { commit, rootState, dispatch },
    { search, type, options }
  ) => {
    try {
      const {
        data: { shops, count },
      } = await axios({
        url: `${rootState.apiAddress}/api/users/${
          rootState.userId
        }/shops?options=${JSON.stringify(options)}`,
        data: JSON.stringify({
          search: search,
          type: type,
          options: JSON.stringify(options),
        }),
        headers: {
          Authorization: `Bearer ${rootState.userToken}`,
        },
      });
      commit("setShops", shops);
      commit("setShowOnboarding", count < 1);
      if (localStorage.getItem("shopId")) {
        let shop =
          shops.find((s) => s._id === localStorage.getItem("shopId")) ||
          shops[0];
        if (shop) {
          dispatch("GET_SHOP", {
            shopId: shop._id,
            options: { includeStripeAccount: true },
          });
        }
      } else {
        let shop = shops[0];
        if (shop) {
          localStorage.setItem("shopId", shop._id);
          dispatch("GET_SHOP", {
            shopId: shop._id,
            options: { includeStripeAccount: true },
          });
        }
      }
    } catch (err) {
      const message =
        err.response && err.response.data && err.response.data.message
          ? err.response.data.message
          : rootState.defaultError;
      Vue.toasted.global
        .showServerError({
          message,
        })
        .goAway(4000);
    }
  },
  GET_SHOP: async ({ commit, rootState, dispatch }, { shopId, options }) => {
    try {
      const {
        data: { shop, stripeAccount },
      } = await axios({
        url: `${
          rootState.apiAddress
        }/api/shops/${shopId}?options=${JSON.stringify(options)}`,
        headers: {
          Authorization: `Bearer ${rootState.userToken}`,
        },
      });
      commit("setShop", shop);
      if (stripeAccount) commit("setStripeAccount", stripeAccount);
      dispatch("category/GET_CATEGORIES", { shopId: shop._id }, { root: true });
      dispatch("GET_ICONS");
    } catch (err) {
      const message =
        err.response && err.response.data && err.response.data.message
          ? err.response.data.message
          : rootState.defaultError;
      Vue.toasted.global
        .showServerError({
          message,
        })
        .goAway(4000);
    }
  },
  GET_SHOP_ACCOUNT_LINK: async ({ commit, rootState }, { shopId, options }) => {
    try {
      const {
        data: { accountLink },
      } = await axios({
        url: `${
          rootState.apiAddress
        }/api/shops/${shopId}/verification-link?isNewDashboard=true&options=${JSON.stringify(
          options
        )}`,
        headers: {
          Authorization: `Bearer ${rootState.userToken}`,
        },
      });
      commit("setAccountLink", accountLink);
    } catch (err) {
      const message =
        err.response && err.response.data && err.response.data.message
          ? err.response.data.message
          : rootState.defaultError;
      Vue.toasted.global
        .showServerError({
          message,
        })
        .goAway(4000);
    }
  },
  CREATE_SHOP: async (
    { rootState, dispatch, commit },
    { shopInfo, options, skipRefresh = false }
  ) => {
    commit("setLoading", true);
    try {
      const {
        data: { shop },
      } = await axios({
        url: `${rootState.apiAddress}/api/shops`,
        method: "POST",
        data: {
          shopInfo,
          options,
        },
        headers: {
          Authorization: `Bearer ${rootState.userToken}`,
        },
      });
      localStorage.setItem("shopId", shop._id);
      if (!skipRefresh) await dispatch("GET_SHOPS_BY_USER", {});
    } catch (err) {
      console.log(err);
      const message =
        err.response && err.response.data && err.response.data.message
          ? err.response.data.message
          : rootState.defaultError;
      Vue.toasted.global
        .showServerError({
          message,
        })
        .goAway(4000);
    }
    commit("setLoading", false);
  },
  UPDATE_SHOP: async (
    { rootState, dispatch, commit },
    { shopId, shopInfo, options }
  ) => {
    try {
      commit("setLoading", true);
      const {
        data: { shop },
      } = await axios({
        url: `${rootState.apiAddress}/api/shops/${shopId}`,
        method: "PUT",
        data: {
          shopInfo: JSON.stringify(shopInfo),
          options: JSON.stringify(options),
        },
        headers: {
          Authorization: `Bearer ${rootState.userToken}`,
        },
        timeout: 60 * 1000,
      });
      Vue.toasted.success("Shop update successful", {
        duration: 2000,
      });
      localStorage.setItem("shopId", shop._id);
      await dispatch("GET_SHOPS_BY_USER", {});
    } catch (err) {
      const message =
        err.response && err.response.data && err.response.data.message
          ? err.response.data.message
          : rootState.defaultError;
      Vue.toasted.global
        .showServerError({
          message,
        })
        .goAway(4000);
    }
    commit("setLoading", false);
  },
  UPDATE_SHOP_STATUS: async (
    { rootState, dispatch, commit },
    { shopId, status }
  ) => {
    try {
      commit("setLoading", true);
      const {
        data: { shop },
      } = await axios({
        url: `${rootState.apiAddress}/api/shops/${shopId}/accepting-orders/${status}`,
        method: "PUT",
        headers: {
          Authorization: `Bearer ${rootState.userToken}`,
        },
      });
      Vue.toasted.success("Shop update successful", {
        duration: 2000,
      });
      localStorage.setItem("shopId", shop._id);
      await dispatch("GET_SHOPS_BY_USER", {});
    } catch (err) {
      const message =
        err.response && err.response.data && err.response.data.message
          ? err.response.data.message
          : rootState.defaultError;
      Vue.toasted.global
        .showServerError({
          message,
        })
        .goAway(4000);
    }
    commit("setLoading", false);
  },
  UPDATE_SHOP_PAYMENT_DETAILS: async (
    { rootState, dispatch, commit },
    { shopId, shopInfo, options }
  ) => {
    try {
      commit("setLoading", true);
      const {
        data: { shop },
      } = await axios({
        url: `${rootState.apiAddress}/api/shops/${shopId}/payment`,
        method: "PUT",
        data: {
          shopInfo: JSON.stringify(shopInfo),
          options: JSON.stringify(options),
        },
        headers: {
          Authorization: `Bearer ${rootState.userToken}`,
        },
      });
      dispatch("GET_SHOP", {
        shopId: shop._id,
        options: { includeStripeAccount: true },
      });
    } catch (err) {
      const message =
        err.response && err.response.data && err.response.data.message
          ? err.response.data.message
          : rootState.defaultError;
      Vue.toasted.global
        .showServerError({
          message,
        })
        .goAway(4000);
    }
    commit("setLoading", false);
  },
  DELETE_SHOP: async ({ commit, rootState }, { shopId, options }) => {
    try {
      commit("setLoading", true);
      await axios({
        url: `${rootState.apiAddress}/api/shops/${shopId}`,
        method: "DELETE",
        data: JSON.stringify({
          options,
        }),
        headers: {
          Authorization: `Bearer ${rootState.userToken}`,
        },
      });

      Vue.toasted.success("Shop delete successful", {
        duration: 2000,
      });
    } catch (err) {
      const message =
        err.response && err.response.data && err.response.data.message
          ? err.response.data.message
          : rootState.defaultError;
      Vue.toasted.global
        .showServerError({
          message,
        })
        .goAway(4000);
    }
    commit("setLoading", false);
  },
  UPDATE_SHOP_IMAGE: async (
    { rootState, commit, dispatch },
    { shopId, imageData }
  ) => {
    try {
      commit("setLoading", true);

      let {
        data: { secure_url },
      } = await axios({
        method: "POST",
        url: "https://api.cloudinary.com/v1_1/on-the-go/image/upload",
        data: {
          file: imageData,
          upload_preset: "hr8ken0o",
        },
      });
      commit("setLoading", false);

      dispatch("UPDATE_SHOP", {
        shopId,
        shopInfo: { "images.hero": secure_url },
      });
    } catch (err) {
      const message =
        err.response && err.response.data && err.response.data.message
          ? err.response.data.message
          : rootState.defaultError;
      Vue.toasted.global
        .showServerError({
          message,
        })
        .goAway(4000);
    }
    commit("setLoading", false);
  },
  GET_ICONS: async ({ commit, rootState }) => {
    try {
      const {
        data: { icons },
      } = await axios({
        url: `${rootState.apiAddress}/api/icons`,
        headers: {
          Authorization: `Bearer ${rootState.userToken}`,
        },
      });
      commit("setIcons", icons);
    } catch (err) {
      const message =
        err.response && err.response.data && err.response.data.message
          ? err.response.data.message
          : rootState.defaultError;
      Vue.toasted.global
        .showServerError({
          message,
        })
        .goAway(4000);
    }
  },
  CREATE_EMBED_KEY: async (
    { commit, rootState, dispatch },
    { shopId, ignoreException = false }
  ) => {
    commit("setLoading", true);
    try {
      await axios({
        method: "POST",
        url: `${rootState.apiAddress}/api/shops/${shopId}/embed-key`,
        headers: {
          Authorization: `Bearer ${rootState.userToken}`,
        },
      });

      dispatch("GET_EMBED_KEY", { shopId });
    } catch (err) {
      if (!ignoreException) {
        const message =
          err.response && err.response.data && err.response.data.message
            ? err.response.data.message
            : rootState.defaultError;
        Vue.toasted.global
          .showServerError({
            message,
          })
          .goAway(4000);
      }
    }
    commit("setLoading", false);
  },
  UPDATE_EMBED_KEY: async ({ commit, rootState }, { shopId, embedKeyInfo }) => {
    commit("setLoading", true);
    try {
      const {
        data: { key },
      } = await axios({
        method: "PUT",
        url: `${rootState.apiAddress}/api/shops/${shopId}/embed-key`,
        headers: {
          Authorization: `Bearer ${rootState.userToken}`,
        },
        data: {
          keyInfo: JSON.stringify(embedKeyInfo),
        },
      });

      commit("setEmbedKey", key);
    } catch (err) {
      const message =
        err.response && err.response.data && err.response.data.message
          ? err.response.data.message
          : rootState.defaultError;
      Vue.toasted.global
        .showServerError({
          message,
        })
        .goAway(4000);
    }
    commit("setLoading", false);
  },
  GET_EMBED_KEY: async (
    { commit, rootState },
    { shopId, ignoreException = false }
  ) => {
    commit("setLoading", true);
    try {
      const {
        data: { key },
      } = await axios({
        url: `${rootState.apiAddress}/api/shops/${shopId}/embed-key`,
        headers: {
          Authorization: `Bearer ${rootState.userToken}`,
        },
      });

      commit("setEmbedKey", key);
    } catch (err) {
      if (!ignoreException) {
        const message =
          err.response && err.response.data && err.response.data.message
            ? err.response.data.message
            : rootState.defaultError;
        Vue.toasted.global
          .showServerError({
            message,
          })
          .goAway(4000);
      }
    }
    commit("setLoading", false);
  },
  GET_CUSTOMER_LOYALTY_CARD: async (
    { commit, rootState },
    { shopId, userId }
  ) => {
    commit("setLoading", true);
    try {
      const {
        data: { loyaltyCard },
      } = await axios({
        url: `${rootState.apiAddress}/api/shops/${shopId}/customers/${userId}/loyalty-card`,
        headers: {
          Authorization: `Bearer ${rootState.userToken}`,
        },
      });

      commit("setLoyaltyCard", loyaltyCard);
    } catch (err) {
      const message =
        err.response && err.response.data && err.response.data.message
          ? err.response.data.message
          : rootState.defaultError;
      Vue.toasted.global
        .showServerError({
          message,
        })
        .goAway(4000);
    }
    commit("setLoading", false);
  },
  SET_CUSTOMER_LOYALTY_CARD: async (
    { commit, rootState },
    { shopId, userId }
  ) => {
    commit("setLoading", true);
    try {
      const {
        data: { loyaltyCard },
      } = await axios({
        method: "POST",
        url: `${rootState.apiAddress}/api/shops/${shopId}/customers/${userId}/set-loyalty-card`,
        headers: {
          Authorization: `Bearer ${rootState.userToken}`,
        },
      });

      commit("setLoyaltyCard", loyaltyCard);
    } catch (err) {
      const message =
        err.response && err.response.data && err.response.data.message
          ? err.response.data.message
          : rootState.defaultError;
      Vue.toasted.global
        .showServerError({
          message,
        })
        .goAway(4000);
    }
    commit("setLoading", false);
  },
  CLAIM_CUSTOMER_LOYALTY_CARD_POINTS: async (
    { commit, rootState },
    { shopId, userId }
  ) => {
    commit("setLoading", true);
    try {
      const {
        data: { loyaltyCard },
      } = await axios({
        method: "POST",
        url: `${rootState.apiAddress}/api/shops/${shopId}/customers/${userId}/claim-loyalty-points`,
        headers: {
          Authorization: `Bearer ${rootState.userToken}`,
        },
      });

      commit("setLoyaltyCard", loyaltyCard);
    } catch (err) {
      const message =
        err.response && err.response.data && err.response.data.message
          ? err.response.data.message
          : rootState.defaultError;
      Vue.toasted.global
        .showServerError({
          message,
        })
        .goAway(4000);
    }
    commit("setLoading", false);
  },
  ASSIGN_CUSTOMER_LOYALTY_CARD_POINT: async (
    { commit, rootState },
    { shopId, userId }
  ) => {
    commit("setLoading", true);
    try {
      const {
        data: { loyaltyCard },
      } = await axios({
        method: "POST",
        url: `${rootState.apiAddress}/api/shops/${shopId}/customers/${userId}/assign-loyalty-point`,
        headers: {
          Authorization: `Bearer ${rootState.userToken}`,
        },
      });

      commit("setLoyaltyCard", loyaltyCard);
    } catch (err) {
      const message =
        err.response && err.response.data && err.response.data.message
          ? err.response.data.message
          : rootState.defaultError;
      Vue.toasted.global
        .showServerError({
          message,
        })
        .goAway(4000);
    }
    commit("setLoading", false);
  },
  COPY_SHOP_MENU: async (
    { rootState, dispatch, commit },
    { shopId, targetId }
  ) => {
    try {
      commit("setLoading", true);
      await axios({
        url: `${rootState.apiAddress}/api/shops/${shopId}/copy-menu-from/${targetId}`,
        method: "POST",
        headers: {
          Authorization: `Bearer ${rootState.userToken}`,
        },
      });
      Vue.toasted.success("Menu copy successful", {
        duration: 2000,
      });
      await dispatch("GET_SHOPS_BY_USER", {});
    } catch (err) {
      const message =
        err.response && err.response.data && err.response.data.message
          ? err.response.data.message
          : rootState.defaultError;
      Vue.toasted.global
        .showServerError({
          message,
        })
        .goAway(4000);
    }
    commit("setLoading", false);
  },
  TRANSFER_SHOP: async (
    { rootState, dispatch, commit },
    { shopId, userId }
  ) => {
    try {
      commit("setLoading", true);
      await axios({
        url: `${rootState.apiAddress}/api/shops/${shopId}/transfer-to/${userId}`,
        method: "POST",
        headers: {
          Authorization: `Bearer ${rootState.userToken}`,
        },
      });
      Vue.toasted.success("Venue transferred successfully", {
        duration: 2000,
      });
      await dispatch("GET_SHOPS_BY_USER", {});
    } catch (err) {
      const message =
        err.response && err.response.data && err.response.data.message
          ? err.response.data.message
          : rootState.defaultError;
      Vue.toasted.global
        .showServerError({
          message,
        })
        .goAway(4000);
    }
    commit("setLoading", false);
  },
  SET_STRIPE_LOCATION: async (
    { rootState, dispatch, commit },
    { shopId, location }
  ) => {
    try {
      commit("setLoading", true);
      await axios({
        url: `${rootState.apiAddress}/api/shops/${shopId}/stripe-location`,
        method: "POST",
        headers: {
          Authorization: `Bearer ${rootState.userToken}`,
        },
        data: {
          ...location,
        },
      });
      Vue.toasted.success("Venue's stripe location settled'", {
        duration: 2000,
      });
      await dispatch("GET_SHOPS_BY_USER", {});
    } catch (err) {
      const message =
        err.response && err.response.data && err.response.data.message
          ? err.response.data.message
          : rootState.defaultError;
      Vue.toasted.global
        .showServerError({
          message,
        })
        .goAway(4000);
    }
    commit("setLoading", false);
  },
};
