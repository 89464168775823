var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"shop-section col-xs-12",class:{ active: _vm.isActive },attrs:{"id":"presentation"},on:{"click":function($event){return _vm.$emit('activeSection', 'presentation')}}},[_vm._m(0),_c('b-col',{staticClass:"option-content"},[(_vm.shop)?_c('b-form',{attrs:{"id":"presentation-form"},on:{"submit":_vm.saveDetails}},[_c('b-row',[_c('b-col',{attrs:{"sm":"12","md":"6"}},[_c('b-form-group',{attrs:{"label":"Hero Image","description":"Click the image above to select the cover picture for your shop in our market place and your embed store"}},[_c('b-button',{directives:[{name:"b-modal",rawName:"v-b-modal.image-editor-modal",modifiers:{"image-editor-modal":true}}],attrs:{"variant":"light"}},[(_vm.loading)?_c('b-spinner'):_c('img',{staticStyle:{"width":"100px"},attrs:{"src":_vm.shopInfo.images.hero}}),(
                  !_vm.shopInfo.images ||
                  !_vm.shopInfo.images.hero ||
                  !_vm.shopInfo.images.hero === ''
                )?_c('span',[_vm._v(" Click to Upload ")]):_vm._e()],1)],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"sm":"12","md":"6"}},[_c('b-button',{staticClass:"float-right mb-1",attrs:{"variant":"primary","type":"submit","disabled":_vm.loading,"pill":""}},[(_vm.loading)?_c('b-spinner'):_c('span',[_vm._v("Save")])],1)],1)],1)],1):_vm._e()],1),_c('imageEditor',{attrs:{"image-info":{
      type: 'shop-hero',
      image: _vm.shopInfo.images.hero,
    }}})],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"header"},[_c('i',{staticClass:"fas fa-images section-icon"}),_c('h5',{staticClass:"section-title dark-blue-color"},[_vm._v(" Shop Profile image "),_c('br'),_c('small',[_vm._v("Store's presentation settings")])])])
}]

export { render, staticRenderFns }