<template>
  <b-tab>
    <template #title> <i class="fas fa-clock mr-1"></i> Availability </template>
    <b-card>
      <b-form class="form-horizontal col-xs-12" @submit="saveDetails">
        <div class="form-group col-xs-12 npsds">
          <div class="checkbox">
            <label>
              <input
                type="checkbox"
                v-model="categoryInfo.availability.twentyFourSeven"
              />
              Is this category available 24/7?
            </label>
          </div>
        </div>
        <b-row v-if="!categoryInfo.availability.twentyFourSeven">
          <b-col
            lg="6"
            md="12"
            class="mt-2"
            v-bind:key="day"
            v-for="day of availableDays"
          >
            <div class="checkbox">
              <label class="text-capitalize">
                <input
                  type="checkbox"
                  :checked="
                    categoryInfo.availability.availableDays.includes(day)
                  "
                  @click="toggleAvailabilityDay(day)"
                />
                available on {{ day }}?</label
              >
            </div>
            <div class="col-xs-12 npsds">
              <div style="width: 60px; display: inline-block" class="mt-2 mb-2">
                <b-form-select
                  class="form-control"
                  v-model="categoryInfo.availability[`${day}From`].hour"
                  :disabled="
                    !categoryInfo.availability.availableDays.includes(day)
                  "
                >
                  <option :key="index" v-for="index in 24" :value="index - 1">
                    <span v-if="index - 1 < 10">0</span>{{ index - 1 }}
                  </option>
                </b-form-select>
              </div>
              :
              <div style="width: 60px; display: inline-block" class="mt-2 mb-2">
                <b-form-select
                  class="form-control"
                  v-model="categoryInfo.availability[`${day}From`].min"
                  :disabled="
                    !categoryInfo.availability.availableDays.includes(day)
                  "
                >
                  <option :key="index" v-for="index in 60" :value="index - 1">
                    <span v-if="index - 1 < 10">0</span>{{ index - 1 }}
                  </option>
                </b-form-select>
              </div>
              To
              <div style="width: 60px; display: inline-block" class="mt-2 mb-2">
                <b-form-select
                  class="form-control"
                  v-model="categoryInfo.availability[`${day}To`].hour"
                  :disabled="
                    !categoryInfo.availability.availableDays.includes(day)
                  "
                >
                  <option :key="index" v-for="index in 24" :value="index - 1">
                    <span v-if="index - 1 < 10">0</span>{{ index - 1 }}
                  </option>
                </b-form-select>
              </div>
              :
              <div style="width: 60px; display: inline-block" class="mt-2 mb-2">
                <b-form-select
                  class="form-control"
                  v-model="categoryInfo.availability[`${day}To`].min"
                  :disabled="
                    !categoryInfo.availability.availableDays.includes(day)
                  "
                >
                  <option :key="index" v-for="index in 60" :value="index - 1">
                    <span v-if="index - 1 < 10">0</span>{{ index - 1 }}
                  </option>
                </b-form-select>
              </div>
            </div>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-button
              variant="primary"
              type="submit"
              class="float-right mb-1"
              :disabled="loading"
              size="lg"
              pill
            >
              <b-spinner v-if="loading" />
              <span v-else>Save</span>
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </b-card>
  </b-tab>
</template>

<script>
import { mapGetters } from "vuex";
const availableDays = [
  "monday",
  "tuesday",
  "wednesday",
  "thursday",
  "friday",
  "saturday",
  "sunday",
];
let defaultAvailability = {
  twentyFourSeven: true,
  availableDays: JSON.parse(JSON.stringify(availableDays)),
};
availableDays.forEach(
  (sd) =>
    (defaultAvailability = {
      ...defaultAvailability,
      [`${sd}From`]: { hour: 0, min: 0 },
      [`${sd}To`]: { hour: 23, min: 59 },
    })
);
export default {
  name: "availability",
  props: ["category"],
  data: () => ({
    availableDays,
    categoryInfo: {
      availability: defaultAvailability,
    },
  }),
  computed: {
    ...mapGetters("category", ["loading"]),
  },
  methods: {
    saveDetails(e) {
      e.preventDefault();
      console.log(this.categoryInfo.availability);
      this.$store.dispatch("category/UPDATE_CATEGORY", {
        categoryInfo: this.categoryInfo,
        categoryId: this.category._id,
        shopId: this.category.shop,
      });
    },
    toggleAvailabilityDay(day) {
      console.log(day);
      if (this.categoryInfo.availability.availableDays.includes(day)) {
        this.categoryInfo.availability.availableDays =
          this.categoryInfo.availability.availableDays.filter((d) => d !== day);
      } else {
        this.categoryInfo.availability.availableDays.push(day);
      }
    },
  },
  mounted() {
    this.categoryInfo.availability = this.category.availability;
  },
  watch: {
    category(newVal) {
      if (!newVal || !newVal.availability) return;
      this.categoryInfo.availability = newVal.availability;
    },
  },
};
</script>

<style scoped></style>
