<template>
  <div
    id="notifications"
    class="shop-section col-xs-12"
    v-bind:class="{ active: isActive }"
    @click="$emit('activeSection', 'notifications')"
  >
    <div class="header">
      <i class="fas fa-envelope section-icon" />

      <h5 class="section-title dark-blue-color">
        Notification Settings
        <br />
        <small>Choose how you would like order notifications</small>
      </h5>
    </div>
    <b-col
      class="option-content col-xs-12"
      v-if="shopInfo && shopInfo.notificationSettings"
    >
      <b-form
        class="form-horizontal col-xs-12"
        id="notifications-form"
        @submit="saveDetails"
      >
        <div class="col-lg-6">
          <div class="form-group col-xs-12">
            <label>New order via</label>
            <div class="checkbox">
              <label>
                <input
                  type="checkbox"
                  v-model="
                    shopInfo.notificationSettings.newOrder.pushNotification
                  "
                />
                Push notification (IOS/Android merchant app)
              </label>
            </div>
            <div class="checkbox">
              <label>
                <input
                  type="checkbox"
                  v-model="shopInfo.notificationSettings.newOrder.email"
                />
                Email
              </label>
            </div>
          </div>
        </div>

        <b-row>
          <b-col>
            <b-button
              variant="primary"
              type="submit"
              class="float-right mb-1"
              :disabled="loading"
              pill
            >
              <b-spinner v-if="loading" />
              <span v-else>Save</span>
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </b-col>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "shop-notifications",
  props: ["isActive"],
  computed: {
    ...mapGetters("shop", ["shop", "loading"]),
  },
  data: () => ({
    shopInfo: {},
  }),
  methods: {
    saveDetails(e) {
      e.preventDefault();
      let shopInfo = {
        notificationSettings: this.shopInfo.notificationSettings,
      };
      this.$emit("saveChanges", shopInfo);
    },
  },
  mounted() {
    this.shopInfo = JSON.parse(
      JSON.stringify({
        notificationSettings: this.shop.notificationSettings || {},
      })
    );
  },
  watch: {
    shop(newVal) {
      if (!newVal) return;
      this.shopInfo = {
        notificationSettings: newVal.notificationSettings || {},
      };
    },
  },
};
</script>

<style scoped></style>
