<template>
  <b-modal
    id="create-product-modal"
    title="Add Product"
    okTitle="Create"
    @ok="addProduct"
  >
    <b-form-group label="Name" description="Product's display name">
      <b-form-input
        v-model="name"
        required
        placeholder="i.e: Hot Chocolate, Bacon & Egg Pie, etc."
      />
    </b-form-group>
  </b-modal>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "create-product-modal",
  props: ["category"],
  data: () => ({
    name: "",
  }),
  computed: {
    ...mapGetters("shop", ["shop"]),
    ...mapGetters("product", ["loading"]),
  },
  methods: {
    async addProduct() {
      await this.$store.dispatch("product/CREATE_PRODUCT", {
        productInfo: {
          shop: this.shop._id,
          name: this.name,
          category: this.category._id,
          enabled: true,
        },
        shopId: this.shop._id,
      });
      this.$bvModal.hide("create-product-modal");
    },
  },
};
</script>

<style scoped></style>
