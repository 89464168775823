<template>
  <div
    id="verification"
    class="shop-section col-xs-12"
    v-bind:class="{ active: isActive }"
    @click="$emit('activeSection', 'verification')"
  >
    <div class="header">
      <i class="fas fa-user-check section-icon" />

      <h5 class="section-title dark-blue-color">
        Verification & Bank Details
        <br />
        <small
          >Verify your account and bank details to allow online trading</small
        >
      </h5>
    </div>
    <div class="option-content col-xs-12" v-if="shopInfo">
      <div class="col-lg-12 npsds">
        <form class="form-horizontal col-xs-12" @submit="savePaymentSettings">
          <h5 class="col-xs-12">Verification</h5>
          <div
            v-if="
              stripeAccount.requirements.currently_due &&
              stripeAccount.requirements.currently_due.length > 0
            "
          >
            <strong class="col-xs-12">Below information is required</strong>
            <ul class="col-xs-12 verification-requirements-list">
              <li
                v-for="message in stripeAccount.requirements.currently_due"
                :key="message"
              >
                {{ getVerificationMessage(message) }}
              </li>
            </ul>
            <div class="col-xs-12">
              <b-button
                @click="goToVerification"
                variant="success"
                pill
                class="mb-2"
              >
                Go to verification
              </b-button>
            </div>
          </div>
          <div v-else>
            <strong class="col-xs-12"
              >Your account is verified! If you need to update your legal
              details press the button below.</strong
            >
            <div class="col-xs-12">
              <b-button
                @click="goToVerification"
                variant="success"
                class="mb-2"
                pill
              >
                Update legal details
              </b-button>
            </div>
          </div>
          <h5 class="col-xs-12">Bank Account Details</h5>
          <div class="col-xs-6">
            <div class="form-group col-xs-12">
              <label>Bank Account Number</label>
              <br />
              <div style="width: 45px; display: inline-block">
                <b-form-input
                  required
                  placeholder="12"
                  v-model="shopInfo.transactional.bank_acn.bank"
                  maxlength="2"
                />
              </div>
              -
              <div style="width: 68px; display: inline-block">
                <b-form-input
                  required
                  placeholder="1234"
                  v-model="shopInfo.transactional.bank_acn.branch"
                  maxlength="4"
                />
              </div>
              -
              <div style="width: 90px; display: inline-block">
                <b-form-input
                  required
                  placeholder="1234567"
                  v-model="shopInfo.transactional.bank_acn.account"
                  maxlength="7"
                />
              </div>
              -
              <div style="width: 64px; display: inline-block">
                <b-form-input
                  required
                  placeholder="00"
                  v-model="shopInfo.transactional.bank_acn.suffix"
                  maxlength="3"
                />
              </div>
              <br />
              <small
                >Business account number where collected payments will be
                transferred</small
              >
            </div>
          </div>
          <b-row>
            <b-col>
              <b-button
                variant="primary"
                type="submit"
                class="float-right mb-1"
                :disabled="loading"
                pill
              >
                <b-spinner v-if="loading" />
                <span v-else>Save</span>
              </b-button>
            </b-col>
          </b-row>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "verification",
  props: ["isActive", "shop", "stripeAccount"],
  data: () => ({
    verificationTypes: {
      "business_profile.url": "Business website",
      business_type: "Business type (company or individual)",
      external_account: "Bank account details",
      "relationship.representative": "Business representative",
      "tos_acceptance.date": "Terms of Service date",
      "tos_acceptance.ip": "Terms of Service point of access",
    },
    shopInfo: null,
  }),
  methods: {
    savePaymentSettings(e) {
      e.preventDefault();
      this.$store.dispatch("shop/UPDATE_SHOP_PAYMENT_DETAILS", {
        shopId: this.shop._id,
        shopInfo: {
          bank_acn: this.shopInfo.transactional.bank_acn,
        },
      });
    },
    goToVerification() {
      window.location = this.accountLink.url;
    },
    getVerificationMessage(message) {
      if (this.verificationTypes[message])
        return this.verificationTypes[message];
      if (message.includes("person"))
        return `representative ${message
          .split(".")
          .slice(1)
          .join(" ")
          .replace("_", " ")}`;
      if (message.includes("company"))
        return `${message.split(".").join(" ").replace("_", " ")}`;
      if (message.includes("individual"))
        return `${message.split(".").join(" ").replace("_", " ")}`;
      if (message.includes("relationship"))
        return `${message.split(".").join(" ").replace("_", " ")}`;
    },
  },
  computed: {
    ...mapGetters("shop", ["accountLink", "loading"]),
  },
  mounted() {
    this.shopInfo = JSON.parse(JSON.stringify(this.shop));
    if (!this.shopInfo.transactional.bank_acn)
      this.shopInfo.transactional.bank_acn = {};
  },
};
</script>

<style scoped></style>
