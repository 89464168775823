<template>
  <b-card class="m-0 p-0" no-body>
    <b-card-body class="m-0 p-0">
      <b-table
        id="sales-data"
        head-row-variant="primary"
        foot-row-variant="success"
        ref="selectableTable"
        hover
        small
        borderless
        sticky-header
        style="max-height: calc(100% - 132px)"
        :fields="reportColumns"
        :items="report.data"
        sort-by="name"
      >
        <template #cell(pickupTime)="data">
          {{ moment(data.item.pickupTime).format("DD/MM/YYYY") }}
          <br />
          {{ moment(data.item.pickupTime).format("LT") }}
          <br />
          <small>
            (Placed:
            {{ moment(data.item.createdAt).format("DD/MM/YYYY HH:mm") }})
          </small>
        </template>

        <template #cell(owner)="data">
          <div>
            <span v-if="data.item.userType === 'guest' && data.item.guestOwner">
              {{ data.item.contactTracing.name }}
            </span>
            <span v-else>
              {{ data.item.owner.name.given }} {{ data.item.owner.name.family }}
            </span>
            <br />
            <a :href="`mailto:${getEmail(data)}`">
              {{ getEmail(data) }}
            </a>
            <br />
            <strong>
              {{ getPhone(data) }}
            </strong>
          </div>
        </template>

        <template #cell(orderType)="data">
          <div>
            {{ getOrderType(data.item) }}
          </div>
        </template>

        <template #cell(origin)="data">
          <div>
            {{ getOrigin(data.item) }}
          </div>
        </template>
        <template #cell(transactionResolution)="data">
          <div>
            {{ getOrderPaymentMethod(data.item) }}
          </div>
        </template>

        <template #cell(total)="data">
          ${{ (data.item.total / 100).toFixed(2) }}
        </template>

        <template #cell(totalPayment)="data">
          <span v-if="data.item.origin === 'kiosk'">
            ${{ (data.item.total / 100).toFixed(2) }}
          </span>
          <span v-else>{{
            !isNaN(data.item.totalPayment)
              ? `$${(data.item.totalPayment / 100).toFixed(2)}`
              : "Not Available"
          }}</span>
        </template>

        <template #cell(_id)="data">
          <b-button variant="primary" @click="data.toggleDetails"
            >Show Details</b-button
          >
          <b-button
            variant="info"
            class="ml-2"
            @click="triggerReceipt(data.item)"
          >
            <i class="fas fa-envelope" />
          </b-button>
        </template>

        <template #row-details="data">
          <table>
            <tr>
              <td>
                ID:
                <p>
                  {{ data.item._id }}
                </p>
              </td>
              <td class="pl-2">
                Line Items
                <ul style="text-align: left; padding-left: 0; list-style: none">
                  <li
                    v-for="lineItem of data.item.lineItems"
                    :key="lineItem._id"
                  >
                    x{{ lineItem.quantity }}{{ lineItem.product.name }}
                    <ul>
                      <li v-for="config of lineItem.config" :key="config.name">
                        <span v-if="config && config.option">
                          {{ config.option.name }}:{{ config.value.name }}
                        </span>
                      </li>
                    </ul>
                    Notes: {{ lineItem.notes }}
                  </li>
                </ul>
              </td>
            </tr>
          </table>
        </template>

        <template #foot(_id)>
          <div></div>
        </template>
      </b-table>
    </b-card-body>
  </b-card>
</template>

<script>
import * as moment from "moment";
import { mapGetters } from "vuex";
import {
  getOrderPaymentMethod,
  getOrderType,
  getOrigin,
} from "@/utils/orders/formulas";

export default {
  name: "orders",
  props: ["report"],
  computed: {
    ...mapGetters("shop", ["shop"]),
    reportColumns() {
      let columns = [
        {
          key: "pickupTime",
          sortable: true,
          label: "Date",
          stickyColumn: true,
        },
        {
          key: "owner",
          sortable: true,
          label: "Customer",
          stickyColumn: true,
        },
        {
          key: "orderType",
          sortable: true,
          label: "Type",
        },
        {
          key: "origin",
          sortable: true,
          label: "Origin",
        },
        {
          key: "transactionResolution",
          sortable: true,
          label: "Payment method",
        },
        {
          key: "total",
          sortable: true,
          label: "Total",
        },
      ];
      if (
        this.shop &&
        this.shop.customerSurcharge &&
        this.shop.customerSurcharge.enabled
      )
        columns.push({
          key: "totalPayment",
          label: "Total + Surcharge",
        });
      columns.push({
        key: "_id",
        label: "",
      });
      return columns;
    },
    reportTotalRewardNumbers() {
      if (!this.report || !this.report.data) return 0;
      return this.report.data.filter(
        (order) => order.rewardNumber && order.rewardNumber.trim() !== ""
      ).length;
    },
    reportTotal() {
      if (!this.report || !this.report.data) return 0;
      let list = this.report.data.map((d) => d.total);
      return list.length > 0
        ? list.reduce(function (accumulator, a) {
            return accumulator + a;
          })
        : 0;
    },
  },
  methods: {
    moment,
    getOrigin,
    getOrderType,
    getOrderPaymentMethod,
    getEmail(data) {
      if (data.item.owner) {
        return (
          data.item.owner &&
          data.item.owner.profile &&
          data.item.owner.profile.email
        );
      } else {
        return data.item.contactTracing && data.item.contactTracing.email;
      }
    },
    getPhone(data) {
      if (data.item.owner) {
        return (
          data.item.owner &&
          data.item.owner.profile &&
          data.item.owner.profile.phone
        );
      } else {
        return data.item.contactTracing && data.item.contactTracing.phone;
      }
    },
    getCSVData() {
      let csvContent =
        "Date, Order Time, Customer Name, Email, Type, Origin, Payment Method, Order, Details, Notes, Total, Reward Number \r\n";
      for (let order of this.report.data) {
        csvContent += moment(order.pickupTime).format("DD/MM/YYYY") + ",";
        csvContent += moment(order.pickupTime).format("LT") + ",";
        csvContent += (order.contactTracing.name || "Not Provided") + ",";
        csvContent += (order.contactTracing.email || "Not Provided") + ",";
        csvContent += this.getOrderType(order) + ",";
        csvContent += this.getOrigin(order) + ",";
        csvContent += this.getOrderPaymentMethod(order) + ",";
        csvContent += order._id + ',"';
        for (let [i, item] of order.lineItems.entries()) {
          console.log(i);
          csvContent += `x${item.quantity} ${item.product.name}\r\n`;
          for (let config of item.config) {
            csvContent += `  - ${config.option.name}:${
              config && config.value ? config.value.name : config.value
            }\r\n`;
          }
          csvContent += `Notes: ${item.notes}\r\n`;
        }
        csvContent += '",';
        csvContent += `${order.notes ? order.notes : ""},`;
        csvContent += `$${(order.total / 100).toFixed(2)},`;
        csvContent += `${order.rewardNumber ? order.rewardNumber : ""}\r\n`;
      }
      csvContent += `,,,,,,Total:,$${(this.reportTotal / 100).toFixed(2)},${
        this.reportTotalRewardNumbers
      }`;
      return csvContent;
    },
    async triggerReceipt(order) {
      const email = prompt(
        "Please enter the email address you want to send the receipt copy"
      );
      if (!email) return;
      await this.$store.dispatch("order/SEND_ORDER_RECEIPT", {
        orderId: order._id,
        email,
      });
    },
  },
};
</script>

<style scoped></style>
