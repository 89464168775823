<template>
<div>
  Items: {{data.items}}
  <br/>
  ${{(data.total/100).toFixed(2)}}
</div>
</template>

<script>
export default {
  name: "categories-cell",
  props: ['data']
}
</script>

<style scoped>

</style>
