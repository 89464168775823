<template>
  <b-card class="m-0 p-0" no-body>
    <b-card-body class="m-0 p-0">
      <b-table-simple>
        <b-thead>
          <b-tr variant="secondary">
            <b-th>Category/Product Name</b-th>
            <b-th>Units(stock units sold)</b-th>
            <b-th>Sales(formula X units X product price)</b-th>
          </b-tr>
        </b-thead>
        <b-tr
          :variant="
            entry.isCategory ? 'primary' : entry.isTotal ? 'success' : ''
          "
          v-for="entry of generateData"
          :key="entry.title"
        >
          <b-td>{{ entry.title }}</b-td>
          <b-td>{{ entry.stock }}</b-td>
          <b-td>
            {{ (entry.total / 100) | numeral("$0,0.00") }}
          </b-td>
        </b-tr>
      </b-table-simple>
    </b-card-body>
  </b-card>
</template>

<script>
import { mapGetters } from "vuex";
import { getProductTotalCost } from "@/utils/orders/formulas";

export default {
  name: "sales-by-sales",
  props: ["report"],
  computed: {
    ...mapGetters("shop", ["shop"]),
    ...mapGetters("category", ["categories"]),
    generateData() {
      const lineItems = this.report.data.map((d) => d.lineItems).flat(1);
      console.log(lineItems);
      let reportResult = [];
      let totalRow = {
        title: "Total",
        isTotal: true,
        stock: 0,
        total: 0,
      };
      for (const cat of this.categories) {
        let productsReport = [];
        for (let product of cat.products) {
          const filteredLineItems = lineItems.filter(
            (lineItem) => lineItem && lineItem.product._id === product._id
          );
          productsReport.push({
            title: product.name,
            stock: filteredLineItems.reduce(
              (prev, currentValue) => prev + currentValue.quantity,
              0
            ),
            total: filteredLineItems.reduce(
              (prev, currentValue) => prev + getProductTotalCost(currentValue),
              0
            ),
          });
        }
        productsReport = productsReport.sort((a, b) => b.stock - a.stock);
        let categoryRow = {
          isCategory: true,
          title: cat.name,
          stock: productsReport.reduce(
            (prev, currentValue) => prev + currentValue.stock,
            0
          ),
          total: productsReport.reduce(
            (prev, currentValue) => prev + currentValue.total,
            0
          ),
        };
        totalRow.stock += categoryRow.stock;
        totalRow.total += categoryRow.total;
        reportResult = [...reportResult, categoryRow, ...productsReport];
      }
      return [...reportResult, totalRow];
    },
  },
  methods: {
    getCSVData() {
      let csvContent =
        "Category/Product Name, Units(stock units sold), Sales(formula X units X product price) \r\n";
      for (let data of this.generateData) {
        csvContent += data.title + ",";
        csvContent += data.stock + ",";
        csvContent += `$${(data.total / 100).toFixed(2)}\r\n`;
      }
      return csvContent;
    },
  },
};
</script>

<style scoped></style>
