<template>
  <b-col>
    <b-card>
      <h4>Container</h4>
      <b-row>
        <b-col sm="12" md="6">
          <b-form-group label="Background Color">
            <b-form-select
              :options="enumerations"
              v-model="pageLayout.containerColor"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <hr />
      <h4>Logo</h4>
      <b-row>
        <b-col sm="12" md="6">
          <b-form-group
            label="Image"
            description="Set's the logo displayed on the navbar page top center"
          >
            <b-input-group>
              <b-form-input
                v-model="pageLayout.logo"
                placeholder="Enter here a url to your choice's logo"
              />
              <b-input-group-append>
                <b-button
                  variant="primary"
                  v-b-modal.image-editor-modal
                  @click="
                    $emit('image-editor-config', {
                      imageEditorField: 'navbarLogo',
                      aspectRatio: 4,
                    })
                  "
                >
                  Upload
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>
      </b-row>
      <hr />
      <h4>Text</h4>
      <b-row>
        <b-col sm="12" md="6">
          <b-form-group label="Color">
            <b-input-group>
              <b-form-select
                :options="enumerations"
                v-model="pageLayout.textColor"
              />
              <b-input-group-append>
                <theme-helper
                  :theme="theme"
                  :value="pageLayout.textColor"
                  type="color"
                />
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col sm="12" md="6">
          <b-form-group label="Font">
            <b-form-select :options="enumerations" v-model="pageLayout.font" />
          </b-form-group>
        </b-col>
        <b-col sm="12" md="6">
          <b-form-group label="Size">
            <b-form-select
              :options="textSizeOptions"
              v-model="pageLayout.textSize"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-button
        variant="primary"
        @click="$emit('saved', { key: 'navbar', config: { ...pageLayout } })"
        >Save</b-button
      >
    </b-card>
  </b-col>
</template>

<script>
import { enumerations, textSizeOptions } from "@/utils/constants";
import ThemeHelper from "@/components/layout-editor/themeHelper";

export default {
  name: "navbarTab",
  components: { ThemeHelper },
  props: ["pageLayout", "theme"],
  data: () => ({
    show: false,
    textSizeOptions,
    enumerations,
  }),
};
</script>

<style scoped>
.portrait-ratio {
  position: relative;
  aspect-ratio: 2/3;
  width: 60%;
  margin: auto;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.container-space {
  width: 100%;
  height: 100%;
  background-color: rgb(0 0 0 / 0.3);
  flex-direction: column;
  display: flex;
}

.head {
  width: 100%;
  height: 30%;
  flex: none;
  flex-direction: column;
  justify-items: flex-end;
  justify-content: flex-end;
  align-items: flex-end;
  display: flex;
}

.logo-image {
  width: fit-content;
  max-width: 50% !important;
  margin-left: auto;
  margin-right: auto;
  height: fit-content;
  max-height: 60%;
  display: block;
}

.head-text {
  text-transform: uppercase;
  width: 100%;
  text-align: center;
}

.body {
  justify-items: flex-end;
  justify-content: flex-end;
  align-items: flex-end;
  flex-grow: 1;
  display: flex;
}

.touch-btn {
  text-transform: uppercase;
  width: 100%;
  text-align: center;
}

.footer-space {
  padding: 2.5rem;
  justify-items: flex-end;
  justify-content: flex-end;
  align-items: flex-end;
  flex: none;
  width: 100%;
  height: 30%;
  display: flex;
}

.bg-editor {
  color: white;
  position: absolute;
  right: 10px;
  top: 10px;
  cursor: pointer;
}
</style>
