import { render, staticRenderFns } from "./product-sync-modal.vue?vue&type=template&id=298d1c35&scoped=true&"
import script from "./product-sync-modal.vue?vue&type=script&lang=js&"
export * from "./product-sync-modal.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "298d1c35",
  null
  
)

export default component.exports