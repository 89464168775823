<template>
  <b-modal
    id="create-category-modal"
    title="Add Category"
    okTitle="Create"
    @ok="addCategory"
  >
    <b-form-group label="Name" description="Category's display name">
      <b-form-input
        v-model="name"
        required
        placeholder="i.e: Hot Drinks, Pies, etc."
      />
    </b-form-group>
  </b-modal>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "create-category-modal",
  data: () => ({
    name: "",
  }),
  computed: {
    ...mapGetters("shop", ["shop"]),
    ...mapGetters("category", ["loading"]),
  },
  methods: {
    addCategory() {
      this.$store.dispatch("category/CREATE_CATEGORY", {
        categoryInfo: {
          shop: this.shop._id,
          name: this.name,
        },
        shopId: this.shop._id,
      });
    },
  },
};
</script>

<style scoped></style>
