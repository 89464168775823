var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-card',[(_vm.type === 'total-sales')?_c('b-row',{staticClass:"text-center"},[_c('b-col',[_c('h6',[_vm._v("Total Sales Today")]),_c('h2',{staticClass:"report-title"},[_vm._v("$"+_vm._s((_vm.totalAmountToday / 100).toFixed(2)))]),_c('span',{class:{
          'text-success': _vm.totalAmountToday - _vm.totalAmountYesterday >= 0.0,
          'text-danger': _vm.totalAmountToday - _vm.totalAmountYesterday <= 0.0,
        }},[_c('i',{staticClass:"fas",class:{
            'fa-arrow-up': _vm.totalAmountToday - _vm.totalAmountYesterday >= 0.0,
            'fa-arrow-down': _vm.totalAmountToday - _vm.totalAmountYesterday <= 0.0,
          }}),_vm._v(_vm._s(((_vm.totalAmountToday - _vm.totalAmountYesterday) / 100).toFixed(2))+" ")]),_c('br'),_c('small',[_vm._v("VS yesterday($"+_vm._s((_vm.totalAmountYesterday / 100).toFixed(2))+")")])])],1):_vm._e(),(_vm.type === 'total-orders')?_c('b-row',[_c('b-col',{staticClass:"text-center"},[_c('h6',[_vm._v("Total Orders Today")]),_c('h2',{staticClass:"report-title"},[_vm._v(_vm._s(_vm.totalCountToday))]),_c('span',{class:{
          'text-success': _vm.totalCountToday - _vm.totalCountYesterday >= 0.0,
          'text-danger': _vm.totalCountToday - _vm.totalCountYesterday <= 0.0,
        }},[_c('i',{staticClass:"fas",class:{
            'fa-arrow-up': _vm.totalCountToday - _vm.totalCountYesterday >= 0.0,
            'fa-arrow-down': _vm.totalCountToday - _vm.totalCountYesterday <= 0.0,
          }}),_vm._v(_vm._s(_vm.totalCountToday - _vm.totalCountYesterday)+" ")]),_c('br'),_c('small',[_vm._v("VS yesterday("+_vm._s(_vm.totalCountYesterday)+")")])])],1):_vm._e(),(_vm.type === 'average-orders')?_c('b-row',[_c('b-col',{staticClass:"text-center"},[_c('h6',[_vm._v("Average order size")]),_c('h2',{staticClass:"report-title"},[_vm._v(" $"+_vm._s((_vm.totalAverageToday / 100).toFixed(2))+" ")]),_c('span',{class:{
          'text-success': _vm.totalAverageToday - _vm.totalAverageYesterday >= 0.0,
          'text-danger': _vm.totalAverageToday - _vm.totalAverageYesterday <= 0.0,
        }},[_c('i',{staticClass:"fas",class:{
            'fa-arrow-up': _vm.totalAverageToday - _vm.totalAverageYesterday >= 0.0,
            'fa-arrow-down': _vm.totalAverageToday - _vm.totalAverageYesterday <= 0.0,
          }}),_vm._v(" "+_vm._s(((_vm.totalAverageToday - _vm.totalAverageYesterday) / 100).toFixed(2))+" ")]),_c('br'),_c('small',[_vm._v("VS yesterday($"+_vm._s((_vm.totalAverageYesterday / 100).toFixed(2))+")")])])],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }