<template>
  <b-tab>
    <template #title> <i class="fas fa-cubes mr-1"></i> Products </template>
    <b-container>
      <b-row>
        <b-col>
          <b-button
            class="float-right mb-3"
            pill
            variant="primary"
            size="lg"
            v-b-modal.create-product-modal
          >
            Add Product
          </b-button>
          <b-button
            class="float-right mb-3 mr-2"
            pill
            variant="secondary"
            size="lg"
            v-b-modal.product-sort-modal
          >
            Sort Products
          </b-button>
          <b-button
            class="float-right mb-3 mr-2"
            pill
            variant="warning"
            size="lg"
            v-b-modal.product-sync-modal
          >
            Sync Products Options
          </b-button>
        </b-col>
      </b-row>
      <b-row>
        <b-col
          md="12"
          lg="4"
          v-for="product of category.products"
          :key="product._id"
        >
          <b-card
            class="text-capitalize mt-3"
            text-variant="dark"
            style="min-height: 270px"
          >
            <template #header>
              <h5
                style="
                  overflow: hidden;
                  -ms-text-overflow: ellipsis;
                  text-overflow: ellipsis;
                  white-space: nowrap;
                "
              >
                {{ product.name }}
              </h5>
            </template>
            <b-row>
              <b-col v-if="product && product.icon">
                <img style="height: 50px" :src="product.icon.icon" />
              </b-col>
              <b-col>
                <strong>{{
                  (product.price / 100).toFixed(2) | numeral("$0,0.00")
                }}</strong>
                <br />
                <strong
                  >{{ product.stock > -1 ? product.stock : "unlimited" }} units
                  available</strong
                >
              </b-col>
            </b-row>
            <template #footer>
              <b-button
                pill
                size="lg"
                variant="primary"
                :to="`/shop/menu/${category._id}/products/${product._id}`"
              >
                <i class="fas fa-edit" />
              </b-button>
              <b-button
                size="lg"
                pill
                variant="danger"
                class="float-right"
                v-b-modal.delete-product-modal
                @click="selectedProduct = product"
              >
                <i class="fas fa-trash" />
              </b-button>
            </template>
          </b-card>
        </b-col>
      </b-row>
    </b-container>
    <add-product-modal :category="category" />
    <delete-product-modal :product="selectedProduct" />
    <product-sort-modal :category="category" />
    <product-sync-modal :category="category" />
  </b-tab>
</template>

<script>
import AddProductModal from "./add-product-modal";
import DeleteProductModal from "./delete-product-modal";
import ProductSortModal from "./product-sort-modal";
import ProductSyncModal from "@/components/menu-builder/category-details/product-sync-modal";
export default {
  name: "products",
  components: {
    ProductSyncModal,
    ProductSortModal,
    AddProductModal,
    DeleteProductModal,
  },
  props: ["category"],
  data: () => ({
    selectedProduct: null,
  }),
};
</script>

<style scoped></style>
