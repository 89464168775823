<template>
  <div>
    <b-card>
      <b-col>
        <h5 class="section-title orange-color">
          Kiosks
          <br />
          <small class="dark-blue-color">
            Manage your shop's self-checkout kiosks settings and display
          </small>
        </h5>
      </b-col>
    </b-card>
    <b-row>
      <b-col>
        <b-button
          class="float-right m-2"
          pill
          variant="primary"
          size="lg"
          @click="addKiosk"
          v-if="userInfo.roles.includes('admin')"
        >
          Add Kiosk
        </b-button>
      </b-col>
    </b-row>
    <b-row class="pl-2 pr-2">
      <b-col v-for="kiosk of kiosks" :key="kiosk._id" sm="12" md="6">
        <b-card>
          <b-form-group
            label="Unit Name"
            description="Human friendly name assigned to the kiosk unit"
          >
            <strong>{{ kiosk.name }}</strong>
          </b-form-group>
          <b-form-group
            label="Unit Key"
            description="Unique key that connects the physical unit with the settings in this dashboard"
          >
            <strong>{{ kiosk.kioskKey }}</strong>
          </b-form-group>
          <template #footer>
            <b-row>
              <b-col>
                <b-button
                  variant="info"
                  pill
                  block
                  :href="`/shop/kiosks/${kiosk._id}`"
                  >Edit</b-button
                >
              </b-col>
              <b-col>
                <b-button
                  pill
                  block
                  variant="danger"
                  @click="deleteKiosk(kiosk._id)"
                  >Delete</b-button
                >
              </b-col>
            </b-row>
          </template>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "kiosks",
  computed: {
    ...mapGetters("kiosk", ["kiosks"]),
    ...mapGetters("shop", ["shop"]),
    ...mapGetters(["userInfo"]),
  },
  methods: {
    addKiosk() {
      let name = prompt("Enter a name for the kiosk unit");
      if (!name || name.trim().length < 1)
        return alert("Please enter a valid kiosk name");
      this.$store.dispatch("kiosk/CREATE_KIOSK", {
        kioskInfo: {
          name,
          shop: this.shop._id,
        },
        shopId: this.shop._id,
      });
    },
    deleteKiosk(kioskId) {
      let answer = confirm(
        "Are you sure you want to delete the selected Kiosk Unit?"
      );
      if (!answer) return;
      this.$store.dispatch("kiosk/DELETE_KIOSK", {
        kioskId,
        shopId: this.shop._id,
      });
    },
  },
  async mounted() {
    if (this.shop)
      await this.$store.dispatch("kiosk/GET_KIOSKS", { shopId: this.shop._id });
  },
  watch: {
    async shop(newVal) {
      if (!newVal) return;
      await this.$store.dispatch("kiosk/GET_KIOSKS", { shopId: newVal._id });
    },
  },
};
</script>

<style scoped></style>
