<template>
  <b-modal
    id="delete-option-modal"
    title="Delete Confirmation"
    okTitle="Delete"
    okVariant="danger"
    @ok="deleteRecord"
  >
    <p class="py-0" v-if="option">
      Are you sure you want to delete the record "{{ option.name }}"? This
      action can't be undone.
    </p>
  </b-modal>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "delete-option-modal",
  props: ["option", "returnTo"],
  computed: {
    ...mapGetters("shop", ["shop"]),
  },
  methods: {
    async deleteRecord() {
      await this.$store.dispatch("option/DELETE_OPTION", {
        optionId: this.option._id,
        shopId: this.shop._id,
      });
      this.$bvModal.hide("delete-option-modal");
      if (this.returnTo) this.$router.push(this.returnTo);
    },
  },
};
</script>

<style scoped></style>
