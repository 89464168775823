<template>
  <b-row>
    <b-col>
      <b-row>
        <b-col><slot /></b-col>
      </b-row>
      <b-card>
        <b-form @submit.prevent="saveDetails">
          <div class="accordion">
            <!--          General-->
            <b-card no-body class="mb-2 mt-2">
              <b-card-header header-tag="header" class="p-1" role="tab">
                <b-button
                  block
                  v-b-toggle.general-section
                  variant="primary"
                  style="background-color: #ff6947; border: 1px solid #ff6947"
                  >General
                </b-button>
              </b-card-header>
              <b-collapse id="general-section" class="p-2">
                <b-row>
                  <b-col sm="12" md="6">
                    <b-form-group
                      label="Background Color"
                      description="Pick a color for the primary background color"
                    >
                      <b-row>
                        <b-col cols="2" class="pr-0">
                          <b-form-input
                            type="color"
                            v-model="layoutConfig.general.backgroundColor"
                          />
                        </b-col>
                        <b-col cols="10">
                          <b-form-input
                            type="text"
                            v-model="layoutConfig.general.backgroundColor"
                          />
                        </b-col>
                      </b-row>
                    </b-form-group>
                  </b-col>
                  <b-col sm="12" md="6">
                    <b-form-group
                      label="Secondary Background Color"
                      description="Pick a color for the secondary background color. i.e: the cart background color"
                    >
                      <b-row>
                        <b-col cols="2" class="pr-0">
                          <b-form-input
                            type="color"
                            v-model="
                              layoutConfig.general.secondaryBackgroundColor
                            "
                          />
                        </b-col>
                        <b-col cols="10">
                          <b-form-input
                            type="text"
                            v-model="
                              layoutConfig.general.secondaryBackgroundColor
                            "
                          />
                        </b-col>
                      </b-row>
                    </b-form-group>
                  </b-col>
                  <b-col sm="12" md="6">
                    <b-form-group
                      label="Text Color"
                      description="Pick a color for the text color. i.e: the whole app text color"
                    >
                      <b-row>
                        <b-col cols="2" class="pr-0">
                          <b-form-input
                            type="color"
                            v-model="layoutConfig.general.textColor"
                          />
                        </b-col>
                        <b-col cols="10">
                          <b-form-input
                            type="text"
                            v-model="layoutConfig.general.textColor"
                          />
                        </b-col>
                      </b-row>
                    </b-form-group>
                  </b-col>
                  <b-col sm="12" md="6">
                    <b-form-group
                      label="Logo"
                      description="Set's the logo displayed in all the app but the navigation bar"
                    >
                      <b-input-group>
                        <b-form-input
                          v-model="layoutConfig.general.logo"
                          placeholder="Enter here a url to your choice's logo"
                        />
                        <b-input-group-append>
                          <b-button
                            variant="primary"
                            v-b-modal.image-editor-modal
                            @click="
                              imageEditorField = 'generalLogo';
                              aspectRatio = 4;
                            "
                          >
                            Upload
                          </b-button>
                        </b-input-group-append>
                      </b-input-group>
                    </b-form-group>
                  </b-col>
                  <b-col sm="12" md="6">
                    <b-form-group
                      label="Main Font"
                      description="Font used in general text, descriptions and paragraphs of your shop"
                    >
                      <b-form-input
                        v-model="layoutConfig.general.mainFont"
                        placeholder="Helvetica, Sans Serif, etc."
                      />
                    </b-form-group>
                  </b-col>
                  <b-col sm="12" md="6">
                    <b-form-group
                      label="Titles Font"
                      description="Font used in main titles of your shop, like navigation links, product titles & prices, section titles, etc."
                    >
                      <b-form-input
                        v-model="layoutConfig.general.titlesFont"
                        placeholder="Helvetica, Sans Serif, etc."
                      />
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-collapse>
            </b-card>
            <!--          Landing-->
            <b-card no-body class="mb-2 mt-2" v-if="kiosk">
              <b-card-header header-tag="header" class="p-1" role="tab">
                <b-button
                  block
                  v-b-toggle.landing-section
                  variant="primary"
                  style="background-color: #ff6947; border: 1px solid #ff6947"
                  >Landing Page
                </b-button>
              </b-card-header>
              <b-collapse id="landing-section" class="p-2">
                <b-row>
                  <b-col sm="12" md="6">
                    <b-form-group
                      label="Text Color"
                      description="Pick a color for the text color. i.e: the whole app text color"
                    >
                      <b-row>
                        <b-col cols="2" class="pr-0">
                          <b-form-input
                            type="color"
                            v-model="layoutConfig.landing.textColor"
                          />
                        </b-col>
                        <b-col cols="10">
                          <b-form-input
                            type="text"
                            v-model="layoutConfig.general.textColor"
                          />
                        </b-col>
                      </b-row>
                    </b-form-group>
                  </b-col>
                  <b-col sm="12" md="6">
                    <b-form-group
                      label="Logo"
                      description="Set's the logo displayed on the landing page top center"
                    >
                      <b-input-group>
                        <b-form-input
                          v-model="layoutConfig.landing.logo"
                          placeholder="Enter here a url to your choice's logo"
                        />
                        <b-input-group-append>
                          <b-button
                            variant="primary"
                            v-b-modal.image-editor-modal
                            @click="
                              imageEditorField = 'landingLogo';
                              aspectRatio = 4;
                            "
                          >
                            Upload
                          </b-button>
                        </b-input-group-append>
                      </b-input-group>
                    </b-form-group>
                  </b-col>
                  <b-col sm="12" md="6">
                    <b-form-group
                      label="Background Image"
                      description="Set's the background image for the landing page"
                    >
                      <b-input-group>
                        <b-form-input
                          v-model="layoutConfig.landing.backgroundImage"
                          placeholder="Enter here a url to your choice's background"
                        />
                        <b-input-group-append>
                          <b-button
                            variant="primary"
                            v-b-modal.image-editor-modal
                            @click="
                              imageEditorField = 'landingBackgroundImage';
                              aspectRatio = 4;
                            "
                          >
                            Upload
                          </b-button>
                        </b-input-group-append>
                      </b-input-group>
                    </b-form-group>
                  </b-col>
                  <b-col sm="12" md="6">
                    <b-form-group
                      label="Font"
                      description="Font used in the landing page text messages"
                    >
                      <b-form-input
                        v-model="layoutConfig.landing.font"
                        placeholder="Helvetica, Sans Serif, etc."
                      />
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-collapse>
            </b-card>
            <!--          Navigation Bar-->
            <b-card no-body class="mb-2 mt-2">
              <b-card-header header-tag="header" class="p-1" role="tab">
                <b-button
                  block
                  v-b-toggle.navbar-section
                  variant="primary"
                  style="background-color: #ff6947; border: 1px solid #ff6947"
                  >Navigation Bar
                </b-button>
              </b-card-header>
              <b-collapse id="navbar-section" class="p-2">
                <b-row class="mt-3">
                  <b-col sm="12" md="6">
                    <b-form-group
                      label="Background Color"
                      description="Navigation Bar Background Color"
                    >
                      <b-row>
                        <b-col cols="2" class="pr-0">
                          <b-form-input
                            type="color"
                            v-model="layoutConfig.navbar.backgroundColor"
                          />
                        </b-col>
                        <b-col cols="10">
                          <b-form-input
                            type="text"
                            v-model="layoutConfig.navbar.backgroundColor"
                          />
                        </b-col>
                      </b-row>
                    </b-form-group>
                  </b-col>
                  <b-col sm="12" md="6">
                    <b-form-group
                      label="Text Color"
                      description="Pick a text color for the top navigation bar"
                    >
                      <b-row>
                        <b-col cols="2" class="pr-0">
                          <b-form-input
                            type="color"
                            v-model="layoutConfig.navbar.textColor"
                          />
                        </b-col>
                        <b-col cols="10">
                          <b-form-input
                            type="text"
                            v-model="layoutConfig.navbar.textColor"
                          />
                        </b-col>
                      </b-row>
                    </b-form-group>
                  </b-col>
                  <b-col sm="12" md="6">
                    <b-form-group
                      label="Logo"
                      description="Set's the logo displayed only in the navigation bar"
                    >
                      <b-input-group>
                        <b-form-input
                          v-model="layoutConfig.navbar.logo"
                          placeholder="Enter here a url to your choice's logo"
                        />
                        <b-input-group-append>
                          <b-button
                            variant="primary"
                            v-b-modal.image-editor-modal
                            @click="imageEditorField = 'navbarLogo'"
                          >
                            Upload
                          </b-button>
                        </b-input-group-append>
                      </b-input-group>
                    </b-form-group>
                  </b-col>
                  <b-col sm="12" md="6">
                    <b-form-group
                      label="Font"
                      description="Font used in text shown on the navigation bar. If not specified it will make use of the general main font"
                    >
                      <b-form-input
                        v-model="layoutConfig.navbar.font"
                        placeholder="Helvetica, Sans Serif, etc."
                      />
                    </b-form-group>
                  </b-col>
                  <b-col sm="12" md="6">
                    <b-form-group
                      label="Text size"
                      description="sets the navigation bar text size in pixels"
                    >
                      <b-form-input
                        v-model="layoutConfig.navbar.textSize"
                        placeholder="Example: 12px"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col sm="12" md="6">
                    <b-form-group
                      label="Font weight"
                      description="sets the navigation bar font Weight"
                    >
                      <b-form-input
                        v-model="layoutConfig.navbar.fontWeight"
                        type="number"
                        min="100"
                        value="100"
                        step="100"
                        max="900"
                        placeholder="Example: 500"
                      />
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-collapse>
            </b-card>
            <!--          Text Inputs-->
            <b-card no-body class="mb-2 mt-2">
              <b-card-header header-tag="header" class="p-1" role="tab">
                <b-button
                  block
                  v-b-toggle.text-input-section
                  variant="primary"
                  style="background-color: #ff6947; border: 1px solid #ff6947"
                  >Text Input
                </b-button>
              </b-card-header>
              <b-collapse id="text-input-section" class="p-2">
                <b-row>
                  <b-col sm="12" md="6">
                    <b-form-group
                      label="Background Color"
                      description="Pick a color for the text input background color. i.e: customer name"
                    >
                      <b-row>
                        <b-col cols="2" class="pr-0">
                          <b-form-input
                            type="color"
                            v-model="layoutConfig.inputs.backgroundColor"
                          />
                        </b-col>
                        <b-col cols="10">
                          <b-form-input
                            type="text"
                            v-model="layoutConfig.inputs.backgroundColor"
                          />
                        </b-col>
                      </b-row>
                    </b-form-group>
                  </b-col>
                  <b-col sm="12" md="6">
                    <b-form-group
                      label="Text Color"
                      description="Text input text color"
                    >
                      <b-row>
                        <b-col cols="2" class="pr-0">
                          <b-form-input
                            type="color"
                            v-model="layoutConfig.inputs.textColor"
                          />
                        </b-col>
                        <b-col cols="10">
                          <b-form-input
                            type="text"
                            v-model="layoutConfig.inputs.textColor"
                          />
                        </b-col>
                      </b-row>
                    </b-form-group>
                  </b-col>
                  <b-col sm="12" md="6">
                    <b-form-group
                      label="Display Style"
                      description="Defines the input display style"
                    >
                      <b-form-select v-model="layoutConfig.inputs.displayStyle">
                        <b-form-select-option value="rounded">
                          Rounded Corners
                        </b-form-select-option>
                        <b-form-select-option value="full-rounded">
                          Full Rounded
                        </b-form-select-option>
                        <b-form-select-option value="square">
                          Square
                        </b-form-select-option>
                      </b-form-select>
                    </b-form-group>
                  </b-col>
                  <b-col sm="12" md="6">
                    <b-form-group
                      label="Border Style"
                      description="Defines the input border style"
                    >
                      <b-form-select v-model="layoutConfig.inputs.borderStyle">
                        <b-form-select-option value="normal">
                          Normal
                        </b-form-select-option>
                        <b-form-select-option value="bottom">
                          Bottom Outline
                        </b-form-select-option>
                        <b-form-select-option value="none">
                          Borderless
                        </b-form-select-option>
                      </b-form-select>
                    </b-form-group>
                  </b-col>
                  <b-col sm="12" md="6">
                    <b-form-group
                      label="Font"
                      description="Font used in text shown on the text input"
                    >
                      <b-form-input
                        v-model="layoutConfig.inputs.font"
                        placeholder="Helvetica, Sans Serif, etc."
                      />
                    </b-form-group>
                  </b-col>
                  <b-col sm="12" md="6">
                    <b-form-group
                      label="Text size"
                      description="sets the text input text size in pixels"
                    >
                      <b-form-input
                        v-model="layoutConfig.inputs.textSize"
                        placeholder="Example: 12px"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col sm="12" md="6">
                    <b-form-group
                      label="Font weight"
                      description="sets the text input font Weight"
                    >
                      <b-form-input
                        v-model="layoutConfig.inputs.fontWeight"
                        type="number"
                        min="100"
                        value="100"
                        step="100"
                        max="900"
                        placeholder="Example: 500"
                      />
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-collapse>
            </b-card>
            <!--          Primary Button-->
            <b-card no-body class="mb-2 mt-2">
              <b-card-header header-tag="header" class="p-1" role="tab">
                <b-button
                  block
                  v-b-toggle.primary-button-section
                  variant="primary"
                  style="background-color: #ff6947; border: 1px solid #ff6947"
                  >Primary Button
                </b-button>
              </b-card-header>
              <b-collapse id="primary-button-section" class="p-2">
                <b-row>
                  <b-col sm="12" md="6">
                    <b-form-group
                      label="Background Color"
                      description="Pick a color for the primary button background color. i.e: the place order button"
                    >
                      <b-row>
                        <b-col cols="2" class="pr-0">
                          <b-form-input
                            type="color"
                            v-model="
                              layoutConfig.buttons.primary.backgroundColor
                            "
                          />
                        </b-col>
                        <b-col cols="10">
                          <b-form-input
                            type="text"
                            v-model="
                              layoutConfig.buttons.primary.backgroundColor
                            "
                          />
                        </b-col>
                      </b-row>
                    </b-form-group>
                  </b-col>
                  <b-col sm="12" md="6">
                    <b-form-group
                      label="Text Color"
                      description="Primary button inner text color"
                    >
                      <b-row>
                        <b-col cols="2" class="pr-0">
                          <b-form-input
                            type="color"
                            v-model="layoutConfig.buttons.primary.textColor"
                          />
                        </b-col>
                        <b-col cols="10">
                          <b-form-input
                            type="text"
                            v-model="layoutConfig.buttons.primary.textColor"
                          />
                        </b-col>
                      </b-row>
                    </b-form-group>
                  </b-col>
                  <b-col sm="12" md="6">
                    <b-form-group
                      label="Display Style"
                      description="Defines the button display style"
                    >
                      <b-form-select
                        v-model="layoutConfig.buttons.primary.displayStyle"
                      >
                        <b-form-select-option value="rounded">
                          Rounded Corners
                        </b-form-select-option>
                        <b-form-select-option value="full-rounded">
                          Full Rounded
                        </b-form-select-option>
                        <b-form-select-option value="square">
                          Square
                        </b-form-select-option>
                      </b-form-select>
                    </b-form-group>
                  </b-col>
                  <b-col sm="12" md="6">
                    <b-form-group
                      label="Filling Style"
                      description="Defines the button filling style"
                    >
                      <b-form-select
                        v-model="layoutConfig.buttons.primary.fillingStyle"
                      >
                        <b-form-select-option value="primary">
                          Normal
                        </b-form-select-option>
                        <b-form-select-option value="outline-primary">
                          Outline
                        </b-form-select-option>
                      </b-form-select>
                    </b-form-group>
                  </b-col>
                  <b-col sm="12" md="6">
                    <b-form-group
                      label="Font"
                      description="Font used in text shown on the primary button"
                    >
                      <b-form-input
                        v-model="layoutConfig.buttons.primary.font"
                        placeholder="Helvetica, Sans Serif, etc."
                      />
                    </b-form-group>
                  </b-col>
                  <b-col sm="12" md="6">
                    <b-form-group
                      label="Text size"
                      description="sets the primary button text size in pixels"
                    >
                      <b-form-input
                        v-model="layoutConfig.buttons.primary.textSize"
                        placeholder="Example: 12px"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col sm="12" md="6">
                    <b-form-group
                      label="Font weight"
                      description="sets the primary button font Weight"
                    >
                      <b-form-input
                        v-model="layoutConfig.buttons.primary.fontWeight"
                        type="number"
                        min="100"
                        value="100"
                        step="100"
                        max="900"
                        placeholder="Example: 500"
                      />
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-collapse>
            </b-card>
            <!--          Secondary Button-->
            <b-card no-body class="mb-2 mt-2">
              <b-card-header header-tag="header" class="p-1" role="tab">
                <b-button
                  block
                  v-b-toggle.secondary-button-section
                  variant="primary"
                  style="background-color: #ff6947; border: 1px solid #ff6947"
                  >Secondary Button
                </b-button>
              </b-card-header>
              <b-collapse id="secondary-button-section" class="p-2">
                <b-row>
                  <b-col sm="12" md="6">
                    <b-form-group
                      label="Background Color"
                      description="Pick a color for the secondary button background color. i.e: the place order button"
                    >
                      <b-row>
                        <b-col cols="2" class="pr-0">
                          <b-form-input
                            type="color"
                            v-model="
                              layoutConfig.buttons.secondary.backgroundColor
                            "
                          />
                        </b-col>
                        <b-col cols="10">
                          <b-form-input
                            type="text"
                            v-model="
                              layoutConfig.buttons.secondary.backgroundColor
                            "
                          />
                        </b-col>
                      </b-row>
                    </b-form-group>
                  </b-col>
                  <b-col sm="12" md="6">
                    <b-form-group
                      label="Text Color"
                      description="Button inner text color"
                    >
                      <b-row>
                        <b-col cols="2" class="pr-0">
                          <b-form-input
                            type="color"
                            v-model="layoutConfig.buttons.secondary.textColor"
                          />
                        </b-col>
                        <b-col cols="10">
                          <b-form-input
                            type="text"
                            v-model="layoutConfig.buttons.secondary.textColor"
                          />
                        </b-col>
                      </b-row>
                    </b-form-group>
                  </b-col>
                  <b-col sm="12" md="6">
                    <b-form-group
                      label="Display Style"
                      description="Defines the button display style"
                    >
                      <b-form-select
                        v-model="layoutConfig.buttons.secondary.displayStyle"
                      >
                        <b-form-select-option value="rounded">
                          Rounded Corners
                        </b-form-select-option>
                        <b-form-select-option value="full-rounded">
                          Full Rounded
                        </b-form-select-option>
                        <b-form-select-option value="square">
                          Square
                        </b-form-select-option>
                      </b-form-select>
                    </b-form-group>
                  </b-col>
                  <b-col sm="12" md="6">
                    <b-form-group
                      label="Filling Style"
                      description="Defines the button filling style"
                    >
                      <b-form-select
                        v-model="layoutConfig.buttons.secondary.fillingStyle"
                      >
                        <b-form-select-option value="secondary">
                          Normal
                        </b-form-select-option>
                        <b-form-select-option value="outline-secondary">
                          Outline
                        </b-form-select-option>
                      </b-form-select>
                    </b-form-group>
                  </b-col>
                  <b-col sm="12" md="6">
                    <b-form-group
                      label="Font"
                      description="Font used in text shown on the secondary button"
                    >
                      <b-form-input
                        v-model="layoutConfig.buttons.secondary.font"
                        placeholder="Helvetica, Sans Serif, etc."
                      />
                    </b-form-group>
                  </b-col>
                  <b-col sm="12" md="6">
                    <b-form-group
                      label="Text size"
                      description="sets the secondary text size in pixels"
                    >
                      <b-form-input
                        v-model="layoutConfig.buttons.secondary.textSize"
                        placeholder="Example: 12px"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col sm="12" md="6">
                    <b-form-group
                      label="Font weight"
                      description="sets the seconday button font Weight"
                    >
                      <b-form-input
                        v-model="layoutConfig.buttons.secondary.fontWeight"
                        type="number"
                        min="100"
                        value="100"
                        step="100"
                        max="900"
                        placeholder="Example: 500"
                      />
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-collapse>
            </b-card>
            <!--          Link Button-->
            <b-card no-body class="mb-2 mt-2">
              <b-card-header header-tag="header" class="p-1" role="tab">
                <b-button
                  block
                  v-b-toggle.link-button-section
                  variant="primary"
                  style="background-color: #ff6947; border: 1px solid #ff6947"
                  >Link Button
                </b-button>
              </b-card-header>
              <b-collapse id="link-button-section" class="p-2">
                <b-row>
                  <b-col sm="12" md="6">
                    <b-form-group
                      label="Text Color"
                      description="Link button text color"
                    >
                      <b-row>
                        <b-col cols="2" class="pr-0">
                          <b-form-input
                            type="color"
                            v-model="layoutConfig.buttons.link.textColor"
                          />
                        </b-col>
                        <b-col cols="10">
                          <b-form-input
                            type="text"
                            v-model="layoutConfig.buttons.link.textColor"
                          />
                        </b-col>
                      </b-row>
                    </b-form-group>
                  </b-col>
                  <b-col sm="12" md="6">
                    <b-form-group
                      label="Font"
                      description="Font used in text shown on the link button"
                    >
                      <b-form-input
                        v-model="layoutConfig.buttons.link.font"
                        placeholder="Helvetica, Sans Serif, etc."
                      />
                    </b-form-group>
                  </b-col>
                  <b-col sm="12" md="6">
                    <b-form-group
                      label="Text size"
                      description="sets the link button text size in pixels"
                    >
                      <b-form-input
                        v-model="layoutConfig.buttons.link.textSize"
                        placeholder="Example: 12px"
                      />
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-collapse>
            </b-card>
            <!--          Information Block-->
            <b-card no-body class="mb-2 mt-2">
              <b-card-header header-tag="header" class="p-1" role="tab">
                <b-button
                  block
                  v-b-toggle.info-block-section
                  variant="primary"
                  style="background-color: #ff6947; border: 1px solid #ff6947"
                  >Information Blocks
                </b-button>
              </b-card-header>
              <b-collapse id="info-block-section" class="p-2">
                <b-row>
                  <b-col sm="12" md="6">
                    <b-form-group
                      label="Background Color"
                      description="Information paragraphs background color"
                    >
                      <b-row>
                        <b-col cols="2" class="pr-0">
                          <b-form-input
                            type="color"
                            v-model="layoutConfig.infoBlock.backgroundColor"
                          />
                        </b-col>
                        <b-col cols="10">
                          <b-form-input
                            type="text"
                            v-model="layoutConfig.infoBlock.backgroundColor"
                          />
                        </b-col>
                      </b-row>
                    </b-form-group>
                  </b-col>
                  <b-col sm="12" md="6">
                    <b-form-group
                      label="Text Color"
                      description="Information paragraphs background color"
                    >
                      <b-row>
                        <b-col cols="2" class="pr-0">
                          <b-form-input
                            type="color"
                            v-model="layoutConfig.infoBlock.textColor"
                          />
                        </b-col>
                        <b-col cols="10">
                          <b-form-input
                            type="text"
                            v-model="layoutConfig.infoBlock.textColor"
                          />
                        </b-col>
                      </b-row>
                    </b-form-group>
                  </b-col>
                  <b-col sm="12" md="6">
                    <b-form-group
                      label="Font"
                      description="Font used in text shown on the information blocks"
                    >
                      <b-form-input
                        v-model="layoutConfig.infoBlock.font"
                        placeholder="Helvetica, Sans Serif, etc."
                      />
                    </b-form-group>
                  </b-col>
                  <b-col sm="12" md="6">
                    <b-form-group
                      label="Text size"
                      description="sets the information blocks text size in pixels"
                    >
                      <b-form-input
                        v-model="layoutConfig.infoBlock.textSize"
                        placeholder="Example: 12px"
                      />
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-collapse>
            </b-card>
            <!--          Import Fonts-->
            <b-card no-body class="mb-2 mt-2">
              <b-card-header header-tag="header" class="p-1" role="tab">
                <b-button
                  block
                  v-b-toggle.fonts-section
                  variant="primary"
                  style="background-color: #ff6947; border: 1px solid #ff6947"
                  >Import Fonts
                </b-button>
              </b-card-header>
              <b-collapse id="fonts-section" class="p-2">
                <b-alert show class="pb-4">
                  <span class="float-left"
                    >Find Thousands of font styles in Google Fonts</span
                  >
                  <b-button
                    href="https://fonts.google.com/"
                    target="_blank"
                    variant="primary"
                    class="float-right"
                  >
                    Go to Google fonts
                  </b-button>
                  <br />
                </b-alert>
                <b-row class="mt-3">
                  <b-col cols="12">
                    <b-form-group
                      label="Import fonts"
                      description="Enter a list of font-face style import for custom fonts"
                    >
                      <b-form-textarea
                        v-model="layoutConfig.fonts"
                        rows="10"
                        placeholder="Example: @import url('https://fonts.googleapis.com/css2?family=Inspiration&display=swap');"
                      />
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-collapse>
            </b-card>
          </div>
          <b-button
            class="float-right mt-3"
            variant="primary"
            pill
            size="lg"
            type="submit"
          >
            Save
          </b-button>
        </b-form>
      </b-card>
      <image-editor
        :image-info="{
          type: 'kiosk-upload',
          aspectRatio: aspectRatio,
        }"
        @upload-success="onUploadSuccess"
      />
    </b-col>
  </b-row>
</template>

<script>
import { mapGetters } from "vuex";
import ImageEditor from "./imageEditor";

export default {
  name: "layout",
  components: { ImageEditor },
  props: ["kiosk", "embedKey", "embedVersion"],
  data: () => ({
    aspectRatio: 1.25,
    imageEditorField: "",
    layoutConfig: {
      landing: { textColor: "white" },
      navbar: {
        backgroundColor: "",
        textColor: "",
        logo: "",
        textSize: "12px",
        font: "",
      },
      inputs: {
        backgroundColor: "",
        textColor: "",
        displayStyle: "square",
        borderStyle: "normal",
        textSize: "12px",
        font: "",
        fontWeight: "",
      },
      buttons: {
        primary: {
          fillingStyle: "primary",
          displayStyle: "rounded",
        },
        secondary: {
          fillingStyle: "secondary",
          displayStyle: "rounded",
        },
        link: {
          textColor: "black",
        },
      },
      infoBlock: {
        backgroundColor: "",
        textColor: "",
      },
      fonts: "",
      general: {
        backgroundColor: "",
        secondaryBackgroundColor: "",
        textColor: "",
        logo: "",
        mainFont: "",
        titlesFont: "",
      },
    },
  }),
  computed: {
    ...mapGetters("shop", ["shop"]),
    getNavbarStyle() {
      return `
      ${
        this.layoutConfig.navbar.fontWeight
          ? `font-weight: ${this.layoutConfig.navbar.fontWeight};`
          : ""
      }
          ${
            this.layoutConfig.navbar.backgroundColor
              ? `background-color: ${this.layoutConfig.navbar.backgroundColor};`
              : ""
          }
        `;
    },
    getPrimaryButtonStyle() {
      return `
      ${
        this.layoutConfig.buttons.primary.fontWeight
          ? `font-weight: ${this.layoutConfig.buttons.primary.fontWeight};`
          : ""
      }
        ${
          this.layoutConfig.buttons.primary.backgroundColor
            ? `border: 1px solid ${this.layoutConfig.buttons.primary.backgroundColor};`
            : ""
        }
        ${
          this.layoutConfig.buttons.primary.fillingStyle === "primary"
            ? `background-color: ${this.layoutConfig.buttons.primary.backgroundColor};`
            : ""
        }
        ${
          this.layoutConfig.buttons.primary.textColor
            ? `color: ${this.layoutConfig.buttons.primary.textColor};`
            : ""
        }
        ${
          this.layoutConfig.buttons.primary.textSize
            ? `font-size: ${this.layoutConfig.buttons.primary.textSize};`
            : ""
        }
          ${
            this.layoutConfig.buttons.primary.font
              ? `font-family: ${this.layoutConfig.buttons.primary.font};`
              : ""
          }
        `;
    },
    getSecondaryButtonStyle() {
      return `
       ${
         this.layoutConfig.buttons.secondary.fontWeight
           ? `font-weight: ${this.layoutConfig.buttons.secondary.fontWeight};`
           : ""
       }
        ${
          this.layoutConfig.buttons.secondary.backgroundColor
            ? `border: 1px solid ${this.layoutConfig.buttons.secondary.backgroundColor};`
            : ""
        }
        ${
          this.layoutConfig.buttons.secondary.fillingStyle === "secondary"
            ? `background-color: ${this.layoutConfig.buttons.secondary.backgroundColor};`
            : ""
        }
        ${
          this.layoutConfig.buttons.secondary.textColor
            ? `color: ${this.layoutConfig.buttons.secondary.textColor};`
            : ""
        }
          ${
            this.layoutConfig.buttons.secondary.textSize
              ? `font-size: ${this.layoutConfig.buttons.secondary.textSize};`
              : ""
          }
          ${
            this.layoutConfig.buttons.secondary.font
              ? `font-family: ${this.layoutConfig.buttons.secondary.font};`
              : ""
          }
        `;
    },
    getLinkButtonStyle() {
      return `${
        this.layoutConfig.buttons.link.textColor
          ? `color: ${this.layoutConfig.buttons.link.textColor};`
          : ""
      }
        ${
          this.layoutConfig.buttons.link.textSize
            ? `font-size: ${this.layoutConfig.buttons.link.textSize};`
            : ""
        }
          ${
            this.layoutConfig.buttons.link.font
              ? `font-family: ${this.layoutConfig.buttons.link.font};`
              : ""
          }
        `;
    },
    getInfoBlockStyle() {
      return `
          ${
            this.layoutConfig.infoBlock.backgroundColor
              ? `background-color: ${this.layoutConfig.infoBlock.backgroundColor};`
              : ""
          }
          ${
            this.layoutConfig.infoBlock.textColor
              ? `color: ${this.layoutConfig.infoBlock.textColor};`
              : ""
          }
          ${
            this.layoutConfig.infoBlock.font
              ? `font-family: ${this.layoutConfig.infoBlock.font};`
              : ""
          }
          ${
            this.layoutConfig.infoBlock.textSize
              ? `font-size: ${this.layoutConfig.infoBlock.textSize};`
              : ""
          }
        `;
    },
  },
  methods: {
    async onUploadSuccess(data) {
      console.log(data);
      const { secure_url } = data;
      switch (this.imageEditorField) {
        case "generalLogo":
          this.layoutConfig.general.logo = secure_url;
          break;
        case "navbarLogo":
          this.layoutConfig.navbar.logo = secure_url;
          this.$forceUpdate();
          break;
        case "landingLogo":
          this.layoutConfig.landing.logo = secure_url;
          this.$forceUpdate();
          break;
        case "landingBackgroundImage":
          this.layoutConfig.landing.backgroundImage = secure_url;
          this.$forceUpdate();
          break;
      }
    },
    async saveDetails(e) {
      e.preventDefault();
      if (this.kiosk) {
        this.$store.dispatch("kiosk/UPDATE_KIOSK", {
          kioskId: this.kiosk._id,
          kioskInfo: {
            layoutConfig: this.layoutConfig,
          },
          shopId: this.shop._id,
        });
      } else {
        const { layoutConfig, embedVersion } = this;
        this.$store.dispatch("shop/UPDATE_EMBED_KEY", {
          shopId: this.shop._id,
          embedKeyInfo: {
            embedVersion,
            layoutConfig,
          },
        });
      }
      this.setFieldValues();
    },
    setFieldValues() {
      if (this.kiosk) {
        this.layoutConfig = {
          ...this.layoutConfig,
          ...this.kiosk.layoutConfig,
        };
      } else if (this.embedKey) {
        this.layoutConfig = {
          ...this.layoutConfig,
          ...this.embedKey.layoutConfig,
        };
      }
    },
  },
  mounted() {
    this.setFieldValues();
  },
  watch: {
    kiosk(newVal) {
      if (!newVal) return;
      this.layoutConfig = {
        ...this.layoutConfig,
        ...JSON.parse(JSON.stringify(newVal.layoutConfig)),
      };
    },
  },
};
</script>

<style scoped></style>
