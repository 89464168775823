<template>
  <div class="col-xs-12 npsds">
    <h5 class="col-xs-12 report-title">Most Sold Products</h5>
    <table class="table table-sm col-xs-12">
      <tr>
        <th>#</th>
        <th>Product</th>
        <th class="text-right">QTY</th>
      </tr>
      <tr v-for="(data, index) of reportData" :key="data._id.product">
        <td>{{ index + 1 }}.</td>
        <td>{{ data._id.product }}</td>
        <td class="text-right">{{ data.count }}</td>
      </tr>
    </table>
  </div>
</template>

<script>
export default {
  name: "most-sold-products",
  props: ["reportData"],
};
</script>

<style scoped>
.report-title {
  color: #5b7fbd;
}
</style>
