import axios from "axios";
import Vue from "vue";
const defaultCategoryOptions = {
  populate: [
    {
      path: "options",
      options: {
        sort: "displayOrder",
      },
    },
    {
      path: "products",
      options: {
        sort: "displayOrder",
      },
    },
  ],
  sort: "displayOrder",
};
export default {
  GET_CATEGORIES: async (
    { commit, rootState },
    { shopId, options = defaultCategoryOptions }
  ) => {
    commit("setLoading", true);
    try {
      const {
        data: { categories },
      } = await axios({
        url: `${
          rootState.apiAddress
        }/api/shops/${shopId}/categories?options=${JSON.stringify(options)}`,
        headers: {
          Authorization: `Bearer ${rootState.userToken}`,
        },
      });
      commit("setCategories", categories);
    } catch (err) {
      const message =
        err.response && err.response.data && err.response.data.message
          ? err.response.data.message
          : rootState.defaultError;
      Vue.toasted.global
        .showServerError({
          message,
        })
        .goAway(4000);
    }
    commit("setLoading", false);
  },
  CREATE_CATEGORY: async (
    { rootState, dispatch, commit },
    { categoryInfo, shopId, options }
  ) => {
    commit("setLoading", true);
    try {
      const {
        data: { category },
      } = await axios({
        url: `${rootState.apiAddress}/api/categories/`,
        method: "POST",
        data: {
          categoryInfo: JSON.stringify(categoryInfo),
          options: JSON.stringify(options),
        },
        headers: {
          Authorization: `Bearer ${rootState.userToken}`,
        },
      });
      await dispatch("GET_CATEGORIES", { shopId });
      Vue.toasted.success("Category creation successful", {
        duration: 2000,
      });
      commit("setLoading", false);
      return category;
    } catch (err) {
      console.log(err);
      const message =
        err.response && err.response.data && err.response.data.message
          ? err.response.data.message
          : rootState.defaultError;
      Vue.toasted.global
        .showServerError({
          message,
        })
        .goAway(4000);
    }
    commit("setLoading", false);
  },
  UPDATE_CATEGORY: async (
    { rootState, dispatch, commit },
    { categoryId, categoryInfo, shopId, skipReload = false, options }
  ) => {
    try {
      commit("setLoading", true);
      const {
        data: { category },
      } = await axios({
        url: `${rootState.apiAddress}/api/categories/${categoryId}`,
        method: "PUT",
        data: {
          categoryInfo: JSON.stringify(categoryInfo),
          options: JSON.stringify(options),
        },
        headers: {
          Authorization: `Bearer ${rootState.userToken}`,
        },
      });

      if (!skipReload) {
        await dispatch("GET_CATEGORIES", { shopId });

        Vue.toasted.success("Category update successful", {
          duration: 2000,
        });
      }
      commit("setLoading", false);
      return category;
    } catch (err) {
      const message =
        err.response && err.response.data && err.response.data.message
          ? err.response.data.message
          : rootState.defaultError;
      Vue.toasted.global
        .showServerError({
          message,
        })
        .goAway(4000);
    }
    commit("setLoading", false);
  },
  DELETE_CATEGORY: async (
    { rootState, dispatch, commit },
    { categoryId, shopId }
  ) => {
    try {
      commit("setLoading", true);
      const {
        data: { category },
      } = await axios({
        url: `${rootState.apiAddress}/api/categories/${categoryId}`,
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${rootState.userToken}`,
        },
      });

      await dispatch("GET_CATEGORIES", { shopId });

      Vue.toasted.success("Category delete successful", {
        duration: 2000,
      });
      commit("setLoading", false);
      return category;
    } catch (err) {
      const message =
        err.response && err.response.data && err.response.data.message
          ? err.response.data.message
          : rootState.defaultError;
      Vue.toasted.global
        .showServerError({
          message,
        })
        .goAway(4000);
    }
    commit("setLoading", false);
  },
  SYNC_PRODUCTS: async (
    { rootState, dispatch, commit },
    { categoryId, shopId }
  ) => {
    try {
      commit("setLoading", true);
      const {
        data: { category },
      } = await axios({
        url: `${rootState.apiAddress}/api/shops/${shopId}/categories/${categoryId}/sync-products`,
        method: "POST",
        headers: {
          Authorization: `Bearer ${rootState.userToken}`,
        },
      });

      await dispatch("GET_CATEGORIES", { shopId });

      Vue.toasted.success("Category products sync successful", {
        duration: 2000,
      });
      commit("setLoading", false);
      return category;
    } catch (err) {
      const message =
        err.response && err.response.data && err.response.data.message
          ? err.response.data.message
          : rootState.defaultError;
      Vue.toasted.global
        .showServerError({
          message,
        })
        .goAway(4000);
    }
    commit("setLoading", false);
  },
};
