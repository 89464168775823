import actions from "./actions";
import getters from "./getters";
import mutations from "./mutations";

const state = {
  loading: false,
  report: null,
  todaySalesPerHour: null,
  todayBalance: null,
  tomorrowPayout: null,
  overViewSalesReport: null,
  todayVSYesterdayOverViewSalesReport: null,
  mostSoldProducts: null,
  loyaltyProducts: null,
  embedVSApp: null,
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
