<template>
  <b-modal
    id="delete-category-modal"
    title="Delete Confirmation"
    okTitle="Delete"
    okVariant="danger"
    @ok="deleteRecord"
  >
    <p class="py-0" v-if="category">
      Are you sure you want to delete the record "{{ category.name }}"? This
      action can't be undone.
    </p>
  </b-modal>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "delete-category-modal",
  props: ["category", "goToMenuBuilder"],
  computed: {
    ...mapGetters("shop", ["shop"]),
    ...mapGetters("category", ["loading"]),
  },
  methods: {
    async deleteRecord() {
      await this.$store.dispatch("category/DELETE_CATEGORY", {
        categoryId: this.category._id,
        shopId: this.shop._id,
      });
      this.$bvModal.hide("delete-category-modal");
      if (this.goToMenuBuilder) this.$router.push("/shop/menu");
    },
  },
};
</script>

<style scoped></style>
