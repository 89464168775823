<template>
  <b-modal
    title="Product Sorting"
    id="product-sort-modal"
    okTitle="Confirm"
    @ok="saveOrder"
    @show="onOpen"
    :ok-disabled="loading"
  >
    <p>Drag and drop to change the display order in the venues menu</p>
    <draggable
      v-model="productsList"
      group="people"
      @start="drag = true"
      @end="drag = false"
    >
      <b-list-group-item v-for="element in productsList" :key="element._id">
        {{ element.name }}
      </b-list-group-item>
    </draggable>
  </b-modal>
</template>

<script>
import draggable from "vuedraggable";

export default {
  name: "product-sort-modal",
  components: {
    draggable,
  },
  data: () => ({
    drag: false,
    productsList: [],
    loading: false,
  }),
  props: ["category"],
  methods: {
    async saveOrder() {
      this.loading = true;
      for (let [index, product] of this.productsList.entries()) {
        await this.$store.dispatch("product/UPDATE_PRODUCT", {
          productId: product._id,
          productInfo: { displayOrder: index },
          shopId: product.shop,
          skipReload: true,
        });
      }

      await this.$store.dispatch("category/GET_CATEGORIES", {
        shopId: this.category.shop,
      });
      this.loading = false;
    },
    onOpen() {
      console.log(this.category);
      this.productsList = this.category.products;
    },
  },
};
</script>

<style scoped></style>
