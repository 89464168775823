<template>
  <div
    id="tab"
    class="shop-section col-xs-12"
    v-bind:class="{ active: isActive }"
    @click="$emit('activeSection', 'tab')"
  >
    <div class="header">
      <i class="fas fa-file-invoice section-icon" />

      <h5 class="section-title dark-blue-color">
        Tab Settings
        <br />
        <small>Define your store tab's settings</small>
      </h5>
    </div>
    <b-col
      class="option-content col-xs-12"
      v-if="shopInfo && shopInfo.tabSettings"
    >
      <b-form class="col-xs-12" @submit="saveDetails">
        <b-form-row>
          <b-col sm="12" md="6">
            <b-form-group>
              <div class="checkbox">
                <label>
                  <input
                    type="checkbox"
                    v-model="shopInfo.tabSettings.tabOrders"
                  />
                  Allow Tab Orders
                </label>
              </div>
              <small>
                Users can keep their orders open without paying until they are
                ready with their order.
              </small>
            </b-form-group>
          </b-col>
          <b-col sm="12" md="6">
            <b-form-group>
              <div class="checkbox">
                <label>
                  <input
                    type="checkbox"
                    v-model="shopInfo.tabSettings.tabAccounts"
                  />
                  Allow Tab Accounts
                </label>
              </div>
              <small>Allow Tab accounts </small>
            </b-form-group>
          </b-col>
        </b-form-row>
        <b-form-row>
          <b-col sm="12" md="6">
            <b-form-group>
              <label>Order Life Span (minutes)</label>
              <input
                type="number"
                class="form-control"
                required
                v-model="shopInfo.tabSettings.orderLifeSpan"
                placeholder="Default time is 90 minutes"
              />
              <small
                >Minimum amount of time that the order will keep open after no
                activity
              </small>
            </b-form-group>
            <b-form-group>
              <label>Maximum ammount</label>
              <input
                type="number"
                class="form-control"
                required
                v-model="shopInfo.tabSettings.maximumAmount"
              />
              <small
                >Minimum amount for the order to accomulate without paying
              </small>
            </b-form-group>
            <!-- <b-form-group>
              <b-form-group
                v-if="user.roles.includes('admin')"
                label="Display Pickup on:"
                description="This option hide takeaway options and other UI elements for dine in bar orders"
              >
                <b-dropdown
                  block
                  variant="primary"
                  toggle-class="text-capitalize"
                  :text="
                    shopInfo.pickup.displayPlatforms &&
                    shopInfo.pickup.displayPlatforms.length > 0
                      ? shopInfo.pickup.displayPlatforms.join(' - ')
                      : 'Select Platform Options'
                  "
                >
                  <b-dropdown-item
                    :active="
                      shopInfo.pickup.displayPlatforms &&
                      shopInfo.pickup.displayPlatforms.includes('marketplace')
                    "
                    @click.stop.prevent="
                      selectPickupDisplayPlatforms('marketplace')
                    "
                    >On the Go Marketplace</b-dropdown-item
                  >
                  <b-dropdown-item
                    :active="
                      shopInfo.pickup.displayPlatforms &&
                      shopInfo.pickup.displayPlatforms.includes('embed')
                    "
                    @click.stop.prevent="selectPickupDisplayPlatforms('embed')"
                    >Embed Store</b-dropdown-item
                  >
                  <b-dropdown-item
                    :active="
                      shopInfo.pickup.displayPlatforms &&
                      shopInfo.pickup.displayPlatforms.includes('kiosk')
                    "
                    @click.stop.prevent="selectPickupDisplayPlatforms('kiosk')"
                    >Kiosk</b-dropdown-item
                  >
                </b-dropdown>
              </b-form-group>
            </b-form-group> -->
          </b-col>
          <b-col sm="12" md="6">
            <b-form-group>
              <label>Maximum ammount for user inside a period</label>
              <input
                type="number"
                class="form-control"
                required
                v-model="shopInfo.tabSettings.maximumPerUserPerPeriod"
              />
              <small>Maximum amount for user to use inside a period </small>
            </b-form-group>
            <b-form-group>
              <b-form-group label="Collection period">
                <b-dropdown
                  block
                  variant="primary"
                  toggle-class="text-capitalize"
                  :text="
                    shopInfo.tabSettings.collectionPeriod &&
                    shopInfo.tabSettings.collectionPeriod.length > 0
                      ? shopInfo.tabSettings.collectionPeriod
                      : 'Select Colelction Period'
                  "
                >
                  <b-dropdown-item
                    :active="
                      shopInfo.tabSettings.collectionPeriod &&
                      shopInfo.tabSettings.collectionPeriod === 'daily'
                    "
                    @click.stop.prevent="
                      shopInfo.tabSettings.collectionPeriod = 'daily'
                    "
                    >Daily</b-dropdown-item
                  >
                  <b-dropdown-item
                    :active="
                      shopInfo.tabSettings.collectionPeriod &&
                      shopInfo.tabSettings.collectionPeriod === 'weekly'
                    "
                    @click.stop.prevent="
                      shopInfo.tabSettings.collectionPeriod = 'weekly'
                    "
                    >Weekly</b-dropdown-item
                  >
                  <b-dropdown-item
                    :active="
                      shopInfo.tabSettings.collectionPeriod &&
                      shopInfo.tabSettings.collectionPeriod === 'monthly'
                    "
                    @click.stop.prevent="
                      shopInfo.tabSettings.collectionPeriod = 'monthly'
                    "
                    >Monthly</b-dropdown-item
                  >
                </b-dropdown>
              </b-form-group>
            </b-form-group>
          </b-col>
        </b-form-row>
        <b-form-row> </b-form-row>
        <b-form-group>
          <label>Disclaimer</label>
          <textarea
            class="form-control"
            v-model="shopInfo.tabSettings.disclaimer"
          ></textarea>
          <small
            >Specify a disclaimer message to show to the users of this feature
          </small>
        </b-form-group>
        <b-row>
          <b-col sm="12" md="6">
            <b-button
              variant="primary"
              type="submit"
              class="float-right mb-1 ml-2"
              :disabled="loading"
              pill
            >
              <b-spinner v-if="loading" />
              <span v-else>Save</span>
            </b-button>
            <b-button
              :variant="
                shopInfo.tabSettings.enabled
                  ? 'outline-danger'
                  : 'outline-primary'
              "
              @click="toggleTab"
              class="float-right mb-1"
              :disabled="loading"
              pill
            >
              <b-spinner v-if="loading" />
              <span v-else
                >{{
                  shopInfo.tabSettings.enabled ? "Disable" : "Enable"
                }}
                Tab</span
              >
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </b-col>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "shop-tab",
  props: ["isActive"],
  data: () => ({
    shopInfo: null,
  }),
  computed: {
    ...mapGetters("shop", ["shop", "loading"]),
    ...mapGetters({ user: "userInfo" }),
  },
  methods: {
    saveDetails(e) {
      e.preventDefault();
      let shopInfo = {
        tabSettings: this.shopInfo.tabSettings,
      };
      this.$emit("saveChanges", shopInfo);
    },
    toggleTab(e) {
      this.shopInfo.tabSettings.enabled = !this.shopInfo.tabSettings.enabled;
      this.saveDetails(e);
    },
    selectPickupDisplayPlatforms(platform) {
      let platforms = [];
      if (this.shopInfo.pickup.displayPlatforms)
        platforms = this.shopInfo.pickup.displayPlatforms;
      if (platforms.includes(platform))
        platforms = platforms.filter((p) => p !== platform);
      else platforms.push(platform);
      this.shopInfo.pickup.displayPlatforms = platforms;
      this.$forceUpdate();
    },
  },
  mounted() {
    this.shopInfo = JSON.parse(JSON.stringify(this.shop));
  },
  watch: {
    shop(newVal) {
      if (!newVal) return;
      this.shopInfo = {
        ...newVal,
      };
    },
  },
};
</script>

<style scoped></style>
