<template>
  <b-col>
    <b-card>
      <h4>Information Container</h4>
      <b-row>
        <b-col sm="12" md="6">
          <b-form-group label="Background Color">
            <b-input-group>
              <b-form-select
                :options="enumerations"
                v-model="pageLayout.containerColor"
              />
              <b-input-group-append>
                <theme-helper
                  :theme="theme"
                  :value="pageLayout.containerColor"
                  type="color"
                />
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>
      </b-row>
      <h4>Inputs</h4>
      <b-row>
        <b-col sm="12" md="6">
          <b-form-group label="Background Color">
            <b-input-group>
              <b-form-select
                :options="enumerations"
                v-model="pageLayout.inputsBackgroundColor"
              />
              <b-input-group-append>
                <theme-helper
                  :theme="theme"
                  :value="pageLayout.inputsBackgroundColor"
                  type="color"
                />
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col sm="12" md="6">
          <b-form-group label="Text Color">
            <b-input-group>
              <b-form-select
                :options="enumerations"
                v-model="pageLayout.inputsTextColor"
              />
              <b-input-group-append>
                <theme-helper
                  :theme="theme"
                  :value="pageLayout.inputsTextColor"
                  type="color"
                />
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col sm="12" md="6">
          <b-form-group label="Font">
            <b-form-select
              :options="enumerations"
              v-model="pageLayout.inputsFont"
            />
          </b-form-group>
        </b-col>
        <b-col sm="12" md="6">
          <b-form-group label="Text Size">
            <b-form-select
              :options="textSizeOptions"
              v-model="pageLayout.inputsTextSize"
            />
          </b-form-group>
        </b-col>
        <b-col sm="12" md="6">
          <b-form-group label="Border Color">
            <b-input-group>
              <b-form-select
                :options="enumerations"
                v-model="pageLayout.inputsBorderColor"
              />
              <b-input-group-append>
                <theme-helper
                  :theme="theme"
                  :value="pageLayout.inputsBorderColor"
                  type="color"
                />
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col sm="12" md="6">
          <b-form-group label="Border Style">
            <b-form-select
              :options="borderStyles"
              v-model="pageLayout.inputsBorderStyle"
            />
          </b-form-group>
        </b-col>
        <b-col sm="12" md="6">
          <b-form-group label="Input Shape">
            <b-form-select
              :options="shapeStyles"
              v-model="pageLayout.inputsShape"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <hr />
      <h4>Buttons</h4>
      <b-row>
        <b-col sm="12" md="6">
          <b-form-group label="Font">
            <b-form-select
              :options="enumerations"
              v-model="pageLayout.buttonsFont"
            />
          </b-form-group>
        </b-col>
        <b-col sm="12" md="6">
          <b-form-group label="Text Size">
            <b-form-select
              :options="textSizeOptions"
              v-model="pageLayout.buttonsTextSize"
            />
          </b-form-group>
        </b-col>
        <b-col sm="12" md="6">
          <b-form-group label="Selected/Border Color">
            <b-input-group>
              <b-form-select
                :options="enumerations"
                v-model="pageLayout.buttonsSelectedColor"
              />
              <b-input-group-append>
                <theme-helper
                  :theme="theme"
                  :value="pageLayout.buttonsSelectedColor"
                  type="color"
                />
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col sm="12" md="6">
          <b-form-group label="Shape">
            <b-form-select
              :options="shapeStyles"
              v-model="pageLayout.buttonsShape"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <hr />
      <h4>Costs summary</h4>
      <b-row>
        <b-col sm="12" md="6">
          <b-form-group label="Text Color">
            <b-input-group>
              <b-form-select
                :options="enumerations"
                v-model="pageLayout.summaryTextColor"
              />
              <b-input-group-append>
                <theme-helper
                  :theme="theme"
                  :value="pageLayout.summaryTextColor"
                  type="color"
                />
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col sm="12" md="6">
          <b-form-group label="Font">
            <b-form-select
              :options="enumerations"
              v-model="pageLayout.summaryFont"
            />
          </b-form-group>
        </b-col>
        <b-col sm="12" md="6">
          <b-form-group label="Text Size">
            <b-form-select
              :options="textSizeOptions"
              v-model="pageLayout.summaryTextSize"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <h4>Information blocks</h4>
      <b-row>
        <b-col sm="12" md="6">
          <b-form-group label="Background Color">
            <b-input-group>
              <b-form-select
                :options="enumerations"
                v-model="pageLayout.infoBackgroundColor"
              />
              <b-input-group-append>
                <theme-helper
                  :theme="theme"
                  :value="pageLayout.infoBackgroundColor"
                  type="color"
                />
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col sm="12" md="6">
          <b-form-group label="Text Color">
            <b-input-group>
              <b-form-select
                :options="enumerations"
                v-model="pageLayout.infoTextColor"
              />
              <b-input-group-append>
                <theme-helper
                  :theme="theme"
                  :value="pageLayout.infoTextColor"
                  type="color"
                />
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col sm="12" md="6">
          <b-form-group label="Font">
            <b-form-select
              :options="enumerations"
              v-model="pageLayout.infoFont"
            />
          </b-form-group>
        </b-col>
        <b-col sm="12" md="6">
          <b-form-group label="Text Size">
            <b-form-select
              :options="textSizeOptions"
              v-model="pageLayout.infoTextSize"
            />
          </b-form-group>
        </b-col>
        <b-col sm="12" md="6">
          <b-form-group label="Input Shape">
            <b-form-select
              :options="shapeStyles"
              v-model="pageLayout.infoShape"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <hr />
      <b-button
        variant="primary"
        @click="$emit('saved', { key: 'checkout', config: { ...pageLayout } })"
        >Save</b-button
      >
    </b-card>
  </b-col>
</template>

<script>
import {
  borderStyles,
  enumerations,
  shapeStyles,
  textSizeOptions,
} from "@/utils/constants";
import ThemeHelper from "@/components/layout-editor/themeHelper";

export default {
  name: "checkoutTab",
  components: { ThemeHelper },
  props: ["pageLayout", "theme"],
  data: () => ({
    show: false,
    textSizeOptions,
    enumerations,
    borderStyles,
    shapeStyles,
  }),
};
</script>

<style scoped></style>
