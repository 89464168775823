<template>
  <div>
    <b-card>
      <b-row>
        <b-col>
          <h5 class="section-title orange-color">
            Menu
            <br />
            <small class="dark-blue-color">
              Configure your product settings, pricing, stock and upload photos
            </small>
          </h5>
        </b-col>
      </b-row>
    </b-card>
    <b-container>
      <b-row>
        <b-col>
          <b-button
            pill
            size="lg"
            variant="primary"
            class="float-right mt-3"
            v-b-modal.create-category-modal
          >
            Add Category
          </b-button>
          <b-button
            pill
            size="lg"
            variant="secondary"
            class="float-right mt-3 mr-2"
            v-b-modal.category-sort-modal
          >
            Sort Categories
          </b-button>
          <b-button
            pill
            size="lg"
            variant="outline-primary"
            class="float-right mt-3 mr-2"
            v-b-modal.shop-assign-modal
          >
            Copy Menu
          </b-button>
        </b-col>
      </b-row>
      <b-row align-h="center">
        <b-col
          sm="12"
          md="6"
          lg="3"
          v-for="category of categories"
          :key="category._id"
          class="mt-3"
        >
          <b-card class="text-capitalize" style="height: 232px">
            <template #header>
              <h5
                style="
                  overflow: hidden;
                  -ms-text-overflow: ellipsis;
                  text-overflow: ellipsis;
                  white-space: nowrap;
                "
              >
                {{ category.name }}
              </h5>
            </template>
            <strong>{{ category.products.length }} Products</strong>
            <br />
            <strong>{{ category.options.length }} Options</strong>
            <template #footer>
              <b-button
                pill
                size="lg"
                variant="primary"
                :to="`/shop/menu/${category._id}`"
              >
                <i class="fas fa-edit" />
              </b-button>
              <b-button
                size="lg"
                pill
                variant="danger"
                class="float-right"
                v-b-modal.delete-category-modal
                @click="selectedCategory = category"
              >
                <i class="fas fa-trash" />
              </b-button>
            </template>
          </b-card>
        </b-col>
      </b-row>
    </b-container>
    <create-category-modal />
    <delete-category-modal :category="selectedCategory" />
    <shop-assign-modal v-on:shop-selected="shopSelected" />
    <category-sort-modal />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import CreateCategoryModal from "../components/menu-builder/add-category-modal";
import DeleteCategoryModal from "../components/menu-builder/delete-category-modal";
import ShopAssignModal from "../components/shop-assign-modal";
import CategorySortModal from "../components/menu-builder/category-sort-modal";

export default {
  name: "menu-builder",
  components: {
    CategorySortModal,
    ShopAssignModal,
    DeleteCategoryModal,
    CreateCategoryModal,
  },
  data: () => ({
    selectedCategory: null,
  }),
  methods: {
    shopSelected(shop) {
      console.log(shop);
      let answer = confirm(
        `Are you sure you wish to copy the menu from the venue "${shop.name.normal}" into the venue "${this.shop.name.normal}"?`
      );
      if (!answer) return;
      this.$store.dispatch("shop/COPY_SHOP_MENU", {
        shopId: this.shop._id,
        targetId: shop._id,
      });
    },
  },
  computed: {
    ...mapGetters("category", ["categories"]),
    ...mapGetters("shop", ["shop"]),
  },
};
</script>

<style scoped></style>
