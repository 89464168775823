<template>
  <b-row>
    <b-col>
      <b-form-group
        label="Discount Type"
        description="Select the discount type, if will be a % or a $ equivalent"
      >
        <b-form-select v-model="promoCode.rewardConfig.discountType">
          <option value="fixed-cost">Fixed Amount (in $)</option>
          <option value="percentage">Percentage (in %)</option>
        </b-form-select>
      </b-form-group>
      <b-form-group
        :label="`Discount Amount (${
          promoCode.rewardConfig.discountType === 'percentage' ? '%' : '$'
        })`"
        :description="`Enter the amount of ${
          promoCode.rewardConfig.discountType === 'percentage' ? '%' : '$'
        } discount`"
      >
        <b-form-input v-model="promoCode.rewardConfig.discount" type="number" />
      </b-form-group>
    </b-col>
  </b-row>
</template>

<script>
export default {
  name: "step2",
  props: ["promoCode"],
};
</script>

<style scoped></style>
