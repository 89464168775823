import axios from "axios";
import Vue from "vue";
import router from "../router";

export default {
  SIGN_IN: async ({ commit, rootState, dispatch }, { email, password }) => {
    router.app.$Progress.start();
    try {
      const {
        data: { token, userId },
      } = await axios({
        url: `${rootState.apiAddress}/login`,
        method: "post",
        data: {
          username: email,
          password,
        },
      });
      commit("setUserInfo", { token, userId });
      Vue.toasted
        .success("Login successful", { className: "app-font" })
        .goAway(4000);
      await dispatch("GET_USER_INFO");
      window.location = "/dashboard";
    } catch (err) {
      const message =
        err.response && err.response.data && err.response.data.message
          ? err.response.data.message
          : rootState.defaultError;
      Vue.toasted.global
        .showServerError({
          message,
        })
        .goAway(4000);
    }
    router.app.$Progress.finish();
  },
  SIGN_UP: async (
    { commit, rootState, dispatch },
    { name, email, password, passwordRepeat }
  ) => {
    router.app.$Progress.start();
    commit("setLoading", true);
    try {
      const {
        data: { token, userId },
      } = await axios({
        url: `${rootState.apiAddress}/signup`,
        method: "post",
        data: {
          name,
          email,
          password,
          passwordRepeat,
          isMerchant: true,
        },
      });
      commit("setUserInfo", { token, userId });
      Vue.toasted
        .success(`Sign up successful. Welcome ${name}`, {
          className: "app-font",
        })
        .goAway(4000);
      setTimeout(async () => {
        await dispatch("GET_CART");
        await dispatch("GET_USER_INFO");
        await dispatch("GET_PAYMENT_SOURCES");
      }, 2000);
      commit("setLoading", false);
      router.push(`/`);
    } catch (err) {
      const message =
        err.response && err.response.data && err.response.data.message
          ? err.response.data.message
          : rootState.defaultError;
      Vue.toasted.global
        .showServerError({
          message,
        })
        .goAway(4000);
    }
    commit("setLoading", false);
    router.app.$Progress.finish();
  },
  PASSWORD_RESET: async ({ rootState }, { email }) => {
    router.app.$Progress.start();
    try {
      await axios({
        url: `${rootState.apiAddress}/forgot-password`,
        method: "post",
        data: {
          email,
        },
      });

      Vue.toasted
        .success(
          "Request sent - If the specified email address exist in our records, you should receive a password reset email soon.",
          { className: "app-font" }
        )
        .goAway(4000);
    } catch (err) {
      const message =
        err.response && err.response.data && err.response.data.message
          ? err.response.data.message
          : rootState.defaultError;
      Vue.toasted.global
        .showServerError({
          message,
        })
        .goAway(4000);
    }
    router.app.$Progress.finish();
  },
  UPDATE_PASSWORD: async (
    { commit, rootState, dispatch },
    {
      currentPassword,
      newPassword,
      confirmPassword,
      resetToken,
      skipReload = false,
    }
  ) => {
    router.app.$Progress.start();
    commit("setLoading", true);
    try {
      const { userToken, guestToken } = rootState;
      const {
        data: { token },
      } = await axios({
        url: `${rootState.apiAddress}${
          resetToken
            ? `/forgot-password/${resetToken}`
            : "/api/my-account/change-password"
        }`,
        method: "post",
        data: {
          resetToken,
          currentPassword,
          newPassword,
          confirmPassword,
        },
        headers: {
          Authorization: `Bearer ${userToken ? userToken : guestToken}`,
        },
      });

      if (!skipReload) {
        commit("setUserInfo", { token, userId: rootState.userId });
        await dispatch("GET_USER_INFO");
      }
      Vue.toasted
        .success("Password update successful", { className: "app-font" })
        .goAway(4000);
      commit("setLoading", false);
      router.app.$Progress.finish();
      return true;
    } catch (err) {
      console.log(err);
      const message =
        err.response && err.response.data && err.response.data.message
          ? err.response.data.message
          : rootState.defaultError;
      Vue.toasted.global
        .showServerError({
          message,
        })
        .goAway(4000);
    }
    commit("setLoading", false);
    router.app.$Progress.finish();
  },
  UPDATE_USER_INFO: async (
    { commit, rootState, dispatch },
    { id, userInfo, skipReload = false }
  ) => {
    router.app.$Progress.start();
    commit("setLoading", true);
    try {
      const { userToken, userId } = rootState;
      await axios({
        url: `${rootState.apiAddress}/api/users/${id || userId}`,
        method: "put",
        data: {
          userInfo: JSON.stringify(userInfo),
        },
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      });
      if (!skipReload) {
        Vue.toasted
          .success("User details update successful", { className: "app-font" })
          .goAway(4000);
        await dispatch("GET_USER_INFO");
      }
    } catch (err) {
      const message =
        err.response && err.response.data && err.response.data.message
          ? err.response.data.message
          : rootState.defaultError;
      Vue.toasted.global
        .showServerError({
          message,
        })
        .goAway(4000);
    }
    commit("setLoading", false);
    router.app.$Progress.finish();
  },
  GET_USER_INFO: async ({ commit, rootState }) => {
    router.app.$Progress.start();
    commit("setLoading", true);
    try {
      const { userToken, userId, guestToken, guestId } = rootState;
      let options = {
        populate: { promoCode: true },
      };
      const {
        data: { user },
      } = await axios({
        url: `${rootState.apiAddress}/${userToken ? "api/users" : "guests"}/${
          userToken ? userId : guestId
        }?options=${JSON.stringify(options)}`,
        method: "get",
        headers: {
          Authorization: `Bearer ${userToken ? userToken : guestToken}`,
        },
      });
      commit("setUserDetails", user);
    } catch (err) {
      const message =
        err.response && err.response.data && err.response.data.message
          ? err.response.data.message
          : rootState.defaultError;
      Vue.toasted.global
        .showServerError({
          message,
        })
        .goAway(4000);
    }
    commit("setLoading", false);
    router.app.$Progress.finish();
  },
  GET_PLATFORM_PLANS: async ({ commit, rootState }) => {
    router.app.$Progress.start();
    commit("setLoading", true);
    try {
      const { data } = await axios({
        url: `${rootState.apiAddress}/platform-plans`,
        method: "get",
      });
      commit("setPlatformPlans", data);
    } catch (err) {
      const message =
        err.response && err.response.data && err.response.data.message
          ? err.response.data.message
          : rootState.defaultError;
      Vue.toasted.global
        .showServerError({
          message,
        })
        .goAway(4000);
    }
    commit("setLoading", false);
    router.app.$Progress.finish();
  },
};
