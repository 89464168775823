<template>
  <div class="main">
    <div class="left-panel"></div>
    <div class="right-panel">
      <div class="form-container">
        <form id="login-form" @submit="onSubmit">
          <img src="../assets/host_logo_Landscape_green.png" class="logo" />
          <h3 style="width: 280px">Welcome</h3>
          <input
            class="form-control"
            placeholder="Email"
            type="email"
            v-model="email"
          />
          <input
            class="form-control"
            placeholder="Password"
            type="password"
            v-model="password"
          />
          <button type="submit" class="login-submit">
            <span>Sign In</span>
          </button>
          <router-link class="password-reset" to="/password-reset">
            Forgot Password?
          </router-link>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { beforeRouteEnter } from "../router";

export default {
  name: "Login",
  data: () => ({
    email: null,
    password: null,
  }),
  metaInfo: {
    title: "Sign In",
  },
  computed: {
    ...mapGetters(["embedId"]),
  },
  methods: {
    async onSubmit(e) {
      e.preventDefault();
      await this.signIn({
        email: this.email,
        password: this.password,
      });
    },
    ...mapActions({
      signIn: "SIGN_IN",
    }),
  },
  beforeRouteEnter,
};
</script>

<style scoped>
.main {
  height: 100%;
  width: 100%;
  display: table;
}
.logo {
  width: 200px;
  margin: 10px auto;
  display: block;
}

.left-panel {
  display: table-cell;
  height: 100%;
  width: 40%;
  position: relative;
  background: url(../assets/withcustomer.jpg) center center/cover no-repeat;
}
.right-panel {
  display: table-cell;
  height: 100%;
  position: relative;
  width: 60%;
  background: rgb(91, 127, 189);
  background: linear-gradient(
    160deg,
    rgba(91, 127, 189, 1) 50%,
    rgba(230, 254, 116, 1) 100%
  );
}

#login-form {
  margin: auto auto;
}

.right-panel h3 {
  color: #f9f4e9;
  font-family: averta, avenir w02, avenir, Helvetica, Arial, sans-serif;
  font-style: normal;
  text-align: center;
  text-transform: uppercase;
}

.form-container {
  width: 100%;
  display: flex;
  height: 100%;
}

.form-control {
  font-family: averta, avenir w02, avenir, Helvetica, Arial, sans-serif;
  margin: 10px 0px;
}
.dont-have-password {
  color: white;
  font-size: 20px;
}

.signup {
  float: right;
  color: #5b7fbd;
  font-family: Helvetica Neue;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  text-decoration: none;
}

.password-reset {
  color: #14253e;
  font-family: Helvetica Neue;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  text-decoration: none;
}

.login-submit {
  background: #14253e;
  padding: 10px;
  display: block;
  border: none;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  width: 100%;
}

.login-submit span {
  font-family: Helvetica Neue;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  /* identical to box height */
  width: 100%;
  display: inline-block;
  align-items: center;
  text-align: center;
  letter-spacing: 2.30769px;
  text-transform: uppercase;
  color: white;
}

@media (max-width: 750px) {
  .right-panel {
    width: 100%;
  }

  .left-panel {
    display: none;
  }
}
</style>
