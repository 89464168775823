import * as moment from "moment";

export default {
  shop: (state) => state.shop,
  shops: (state) => state.shops,
  shopsCount: (state) => state.shopsCount,
  icons: (state) => state.icons,
  stripeAccount: (state) => state.stripeAccount,
  accountLink: (state) => state.accountLink,
  loading: (state) => state.loading,
  promoCodes: (state) => state.promoCodes,
  embedKey: (state) => state.embedKey,
  loyaltyCard: (state) => state.loyaltyCard,
  isTrialExpired: (state) => {
    if (!state.shop) return true;
    let value = moment().diff(moment(state.shop.createdAt), "months", true);
    return value > 1;
  },
  showOnboarding: (state) => state.showOnboarding,
};
