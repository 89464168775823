import { render, staticRenderFns } from "./opening-hours.vue?vue&type=template&id=9d9cc716&scoped=true&"
import script from "./opening-hours.vue?vue&type=script&lang=js&"
export * from "./opening-hours.vue?vue&type=script&lang=js&"
import style0 from "./opening-hours.vue?vue&type=style&index=0&id=9d9cc716&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9d9cc716",
  null
  
)

export default component.exports