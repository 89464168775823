<template>
  <b-modal
    title="Option Sorting"
    id="option-sort-modal"
    okTitle="Confirm"
    @ok="saveOrder"
    @show="onOpen"
    :ok-disabled="loading"
  >
    <p>Drag and drop to change the display order in the venues menu</p>
    <draggable
      v-model="optionsList"
      group="people"
      @start="drag = true"
      @end="drag = false"
    >
      <b-list-group-item v-for="element in optionsList" :key="element._id">
        {{ element.name }}
      </b-list-group-item>
    </draggable>
  </b-modal>
</template>

<script>
import draggable from "vuedraggable";

export default {
  name: "option-sort-modal",
  components: {
    draggable,
  },
  data: () => ({
    drag: false,
    optionsList: [],
    loading: false,
  }),
  props: ["category"],
  methods: {
    async saveOrder() {
      this.loading = true;
      for (let [index, option] of this.optionsList.entries()) {
        await this.$store.dispatch("option/UPDATE_OPTION", {
          optionId: option._id,
          optionInfo: { displayOrder: index },
          shopId: option.shop,
          skipReload: true,
        });
      }

      await this.$store.dispatch("category/GET_CATEGORIES", {
        shopId: this.category.shop,
      });
      this.loading = false;
    },
    onOpen() {
      console.log(this.category);
      this.optionsList = this.category.options;
    },
  },
};
</script>

<style scoped></style>
