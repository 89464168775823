<template>
  <b-col>
    <b-card>
      <b-row>
        <b-col sm="12" md="6">
          <b-form-group
            label="Logo"
            description="Set's the logo displayed on the landing page top center"
          >
            <b-input-group>
              <b-form-input
                v-model="pageLayout.logo"
                placeholder="Enter here a url to your choice's logo"
              />
              <b-input-group-append>
                <b-button
                  variant="primary"
                  v-b-modal.image-editor-modal
                  type="button"
                  @click="
                    $emit('image-editor-config', {
                      imageEditorField: 'landingLogo',
                      aspectRatio: 4,
                    })
                  "
                >
                  Upload
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col sm="12" md="6">
          <b-form-group
            label="Background Image"
            description="Set's the background image for the landing page"
          >
            <b-input-group>
              <b-form-input
                v-model="pageLayout.backgroundImage"
                placeholder="Enter here a url to your choice's background"
              />
              <b-input-group-append>
                <b-button
                  type="button"
                  variant="primary"
                  v-b-modal.image-editor-modal
                  @click="
                    $emit('image-editor-config', {
                      imageEditorField: 'landingBackgroundImage',
                      aspectRatio: 4,
                    })
                  "
                >
                  Upload
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col sm="12" md="6">
          <b-form-group
            label="Welcome Message"
            description="Set a custom welcome message below your shop's logo"
          >
            <b-textarea v-model="pageLayout.welcomeMessage" />
          </b-form-group>
        </b-col>
        <b-col sm="12" md="6">
          <b-form-group
            label="Start Order Button Text"
            description="Set a custom text for the start order button"
          >
            <b-textarea v-model="pageLayout.startButtonText" />
          </b-form-group>
        </b-col>
        <b-col sm="12" md="6">
          <b-form-group
            label="Text Color"
            description="Pick a color for the text color. i.e: the whole app text color"
          >
            <b-input-group>
              <b-form-select
                :options="enumerations"
                v-model="pageLayout.textColor"
              />
              <b-input-group-append>
                <theme-helper
                  :theme="theme"
                  :value="pageLayout.textColor"
                  type="color"
                />
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col sm="12" md="6">
          <b-form-group
            label="Font"
            description="Font used in the landing page text messages"
          >
            <b-form-select v-model="pageLayout.font" :options="enumerations" />
          </b-form-group>
        </b-col>
        <b-col sm="12" md="6">
          <b-form-group
            label="Subtitle text size"
            description="Font size used in the landing page subtitle text"
          >
            <b-form-select
              v-model="pageLayout.subtitleTextSize"
              :options="textSizeOptions"
            />
          </b-form-group>
        </b-col>
        <b-col sm="12" md="6">
          <b-form-group
            label="Start order text size"
            description="Font size used in the landing page start order button"
          >
            <b-form-select
              v-model="pageLayout.startOrderTextSize"
              :options="textSizeOptions"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-button
        variant="primary"
        @click="$emit('saved', { key: 'landing', config: { ...pageLayout } })"
        >Save</b-button
      >
    </b-card>
  </b-col>
</template>

<script>
import { enumerations, textSizeOptions } from "@/utils/constants";
import ThemeHelper from "@/components/layout-editor/themeHelper";

export default {
  name: "landingTab",
  components: { ThemeHelper },
  props: ["pageLayout", "theme"],
  data: () => ({
    textSizeOptions,
    enumerations,
  }),
};
</script>

<style scoped></style>
