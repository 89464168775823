<template>
  <b-form style="height: 100%" v-if="option" @submit="saveOption">
    <b-card>
      <b-row>
        <b-col cols="9">
          <h5 class="section-title orange-color">
            <span class="text-capitalize">{{ option.name }}</span>
            <br />
            <small class="dark-blue-color">
              Configure your options set, pricing and display
            </small>
          </h5>
        </b-col>
        <b-col cols="3">
          <b-dropdown
            pill
            size="lg"
            variant="link"
            class="float-right"
            no-caret
          >
            <template #button-content>
              <i class="fas fa-ellipsis-v" />
            </template>
            <b-dropdown-item v-b-modal.delete-option-modal>
              <i class="fas fa-trash mr-1" /> Delete
            </b-dropdown-item>
          </b-dropdown>
        </b-col>
      </b-row>
    </b-card>
    <b-row>
      <b-col>
        <b-col>
          <b-card class="mt-3">
            <b-row>
              <b-col cols="6">
                <b-form-group
                  label="Title"
                  description="Product option name i.e. Size"
                >
                  <b-form-input
                    v-model="optionInfo.name"
                    required
                    placeholder="Size"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="6">
                <b-form-group
                  label="Type"
                  description="Choose widget type for this option i.e. 'Scroll wheel for different size options"
                >
                  <b-form-select
                    v-model="optionInfo.type"
                    :options="optionTypes"
                    required
                    class="text-capitalize"
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row v-if="optionInfo.type === 'multi'">
              <b-col cols="6">
                <b-form-group
                  label="Minimum Selection"
                  description="The minimum number of alternatives a customer needs to select"
                >
                  <b-form-input
                    type="number"
                    v-model="optionInfo.minSelection"
                    placeholder="i.e.: 1"
                    required
                  />
                </b-form-group>
              </b-col>
              <b-col cols="6">
                <b-form-group
                  label="Maximum Selection"
                  description="The maximum number of alternatives a customer can select"
                >
                  <b-form-input
                    type="number"
                    v-model="optionInfo.maxSelection"
                    placeholder="i.e: 3"
                    required
                  />
                </b-form-group>
              </b-col>
            </b-row>
          </b-card>
        </b-col>
      </b-col>
    </b-row>
    <b-container>
      <b-row class="mt-3" align-h="end">
        <b-col>
          <b-button
            class="float-right"
            pill
            variant="primary"
            @click="optionInfo.values.push({ name: '', price: 0 })"
          >
            Add Option
          </b-button>
        </b-col>
      </b-row>
      <b-row align-h="center">
        <b-col
          md="12"
          lg="4"
          class="mt-3"
          v-for="(value, index) of optionInfo.values"
          :key="index"
        >
          <b-card>
            <b-form-group
              label="Option Title"
              description="The display title of the option"
            >
              <b-form-input
                v-model="value.name"
                required
                placeholder="i.e: Small, Mayo, etc."
              />
            </b-form-group>
            <b-form-group
              label="Option Price"
              description='The extra cost for the selected option in dollars. Use "." to specify cents '
            >
              <b-input-group prepend="$">
                <b-form-input
                  v-model="value.price"
                  placeholder="i.e.: 0.50 for 50 cents or 1.50 for 1.5 dollars"
                  required
                />
              </b-input-group>
            </b-form-group>
            <b-form-group lg="6" sm="12">
              <b-form-group
                label="SKU"
                description="SKU: Stock Keeping Unit. A unique identifier for the product"
              >
                <b-form-input v-model="value.SKU" placeholder="XYZ12345" />
              </b-form-group>
            </b-form-group>
            <template #footer>
              <b-button
                class="float-right"
                pill
                variant="danger"
                @click="deleteValue(index)"
              >
                Delete
              </b-button>
            </template>
          </b-card>
        </b-col>
      </b-row>
      <b-button
        type="submit"
        pill
        variant="primary"
        class="float-right mt-3"
        size="lg"
        :disabled="loading"
      >
        <b-spinner v-if="loading" />
        <span v-else>Save</span>
      </b-button>
      <b-button
        type="button"
        pill
        variant="secondary"
        size="lg"
        class="float-right mt-3 mr-3"
        :to="`/shop/menu/${categoryId}?section=options`"
      >
        Back
      </b-button>
    </b-container>
    <delete-option-modal
      :option="option"
      :returnTo="`/shop/menu/${categoryId}`"
    />
  </b-form>
</template>

<script>
import { mapGetters } from "vuex";
import DeleteOptionModal from "../components/menu-builder/category-details/delete-option-modal";

export default {
  name: "option-details",
  components: { DeleteOptionModal },
  props: ["optionId", "categoryId"],
  data: () => ({
    optionInfo: {},
    optionTypes: [
      {
        text: "scroll wheel",
        value: "wheel",
      },
      {
        text: "increase/decrease buttons",
        value: "increase",
      },
      {
        text: "switch button",
        value: "switch",
      },
      {
        text: "multi-selection",
        value: "multi",
      },
    ],
  }),
  computed: {
    ...mapGetters("shop", ["shop"]),
    ...mapGetters("category", ["categories"]),
    ...mapGetters("option", ["loading"]),
    category() {
      return this.categories.find((c) => c._id === this.categoryId);
    },
    option() {
      if (!this.category || !this.category.options) return null;
      return JSON.parse(
        JSON.stringify(
          this.category.options.find((o) => o._id === this.optionId)
        )
      );
    },
  },
  methods: {
    deleteValue(index) {
      this.optionInfo.values.splice(index, 1);
    },
    async saveOption(e) {
      e.preventDefault();
      let optionInfo = JSON.parse(JSON.stringify(this.optionInfo));
      optionInfo.values = optionInfo.values.map((v) => ({
        _id: v._id,
        name: v.name,
        price: v.price * 100,
        SKU: v.SKU,
      }));
      delete optionInfo._id;
      await this.$store.dispatch("option/UPDATE_OPTION", {
        optionId: this.optionId,
        optionInfo,
        shopId: this.shop._id,
      });
    },
  },
  mounted() {
    if (this.category && this.option) {
      this.optionInfo = JSON.parse(JSON.stringify(this.option));
      for (let [index] of this.optionInfo.values.entries()) {
        this.optionInfo.values[index].price =
          this.optionInfo.values[index].price / 100;
      }
    }
  },
  watch: {
    option(newVal) {
      if (newVal) {
        this.optionInfo = newVal;
        if (newVal.values) {
          for (let [index] of this.optionInfo.values.entries()) {
            this.optionInfo.values[index].price =
              this.optionInfo.values[index].price / 100;
          }
        }
      }
    },
  },
};
</script>

<style scoped></style>
