<template>
  <div style="height: 100%" v-if="product">
    <b-card>
      <b-row>
        <b-col cols="9">
          <h5 class="section-title orange-color">
            <span class="text-capitalize">{{ product.name }}</span>
            <br />
            <small class="dark-blue-color">
              Configure your product's details, options and presentation
            </small>
          </h5>
        </b-col>
        <b-col cols="3">
          <b-dropdown
            pill
            size="lg"
            variant="link"
            class="float-right"
            no-caret
          >
            <template #button-content>
              <i class="fas fa-ellipsis-v" />
            </template>
            <b-dropdown-item v-b-modal.delete-product-modal>
              <i class="fas fa-trash mr-1" /> Delete
            </b-dropdown-item>
          </b-dropdown>
        </b-col>
      </b-row>
    </b-card>
    <b-tabs style="height: 100%" card fill pills :vertica="isMobile">
      <template #tabs-end>
        <b-button
          pill
          class="float-left mt-3"
          :to="`/shop/menu/${categoryId}?section=products`"
        >
          Back
        </b-button>
      </template>
      <details-tab :product="product" />
      <options :product="product" :category="category" />
      <presentation :product="product" />
    </b-tabs>
    <delete-product-modal
      :product="product"
      :returnTo="`/shop/menu/${categoryId}`"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import DetailsTab from "../components/menu-builder/product-details/details";
import Options from "../components/menu-builder/product-details/options";
import Presentation from "../components/menu-builder/product-details/presentation";
import DeleteProductModal from "../components/menu-builder/category-details/delete-product-modal";

export default {
  name: "product-details",
  components: { Presentation, Options, DetailsTab, DeleteProductModal },
  props: ["productId", "categoryId"],
  computed: {
    ...mapGetters("shop", ["shop", "loading"]),
    ...mapGetters("category", ["categories"]),
    category() {
      return this.categories.find((c) => c._id === this.categoryId);
    },
    product() {
      if (!this.category || !this.category.products) return null;
      return JSON.parse(
        JSON.stringify(
          this.category.products.find((o) => o._id === this.productId)
        )
      );
    },
    isMobile() {
      return document.getElementById("app").offsetWidth < 600;
    },
  },
};
</script>

<style scoped></style>
