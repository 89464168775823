<template>
  <div
    id="pickup"
    class="shop-section col-xs-12"
    v-bind:class="{ active: isActive }"
    @click="$emit('activeSection', 'pickup')"
  >
    <div class="header">
      <i class="fas fa-people-carry section-icon" />

      <h5 class="section-title dark-blue-color">
        Pick Up Settings
        <br />
        <small>Define your store pick-up's settings</small>
      </h5>
    </div>
    <b-col class="option-content col-xs-12" v-if="shopInfo && shopInfo.pickup">
      <b-form class="col-xs-12" @submit="saveDetails">
        <b-form-row>
          <b-col sm="12" md="6">
            <b-form-group>
              <div class="checkbox">
                <label>
                  <input type="checkbox" v-model="shopInfo.pickup.allowASAP" />
                  Allow ASAP pickup
                </label>
              </div>
              <small
                >Users can place orders with "as soon as possible" pick up time,
                allowing orders with up to 2 minutes behind pick up time from
                current time.
              </small>
            </b-form-group>
          </b-col>
          <b-col sm="12" md="6">
            <b-form-group>
              <label>Pick Up type</label>
              <select class="form-control" v-model="shopInfo.pickup.type">
                <option value="same-day">Same Day</option>
                <option value="future-only">Future Only</option>
                <option value="hybrid">Same Day & Future Dates</option>
              </select>
              <small
                >Use this option to specify if you want your customers to pickup
                their products the same purchase day or in future dates
              </small>
            </b-form-group>
          </b-col>
          <b-col sm="12" md="6">
            <b-form-group>
              <b-form-group
                v-if="user.roles.includes('admin')"
                label="Display Pickup on:"
                description="This option hide takeaway options and other UI elements for dine in bar orders"
              >
                <b-dropdown
                  block
                  variant="primary"
                  toggle-class="text-capitalize"
                  :text="
                    shopInfo.pickup.displayPlatforms &&
                    shopInfo.pickup.displayPlatforms.length > 0
                      ? shopInfo.pickup.displayPlatforms.join(' - ')
                      : 'Select Platform Options'
                  "
                >
                  <b-dropdown-item
                    :active="
                      shopInfo.pickup.displayPlatforms &&
                      shopInfo.pickup.displayPlatforms.includes('marketplace')
                    "
                    @click.stop.prevent="
                      selectPickupDisplayPlatforms('marketplace')
                    "
                    >On the Go Marketplace</b-dropdown-item
                  >
                  <b-dropdown-item
                    :active="
                      shopInfo.pickup.displayPlatforms &&
                      shopInfo.pickup.displayPlatforms.includes('embed')
                    "
                    @click.stop.prevent="selectPickupDisplayPlatforms('embed')"
                    >Embed Store</b-dropdown-item
                  >
                  <b-dropdown-item
                    :active="
                      shopInfo.pickup.displayPlatforms &&
                      shopInfo.pickup.displayPlatforms.includes('kiosk')
                    "
                    @click.stop.prevent="selectPickupDisplayPlatforms('kiosk')"
                    >Kiosk</b-dropdown-item
                  >
                </b-dropdown>
              </b-form-group>
            </b-form-group>
          </b-col>
          <b-col sm="12" md="6">
            <b-form-group>
              <div class="checkbox">
                <label>
                  <input
                    type="checkbox"
                    v-model="shopInfo.pickup.hidePickupTime"
                  />
                  Hide online ordering pick up time
                </label>
              </div>
              <small
                >This option allow to hide the pick up time selector on online
                ordering in case that is not relevant for your business flow.
              </small>
            </b-form-group>
          </b-col>
        </b-form-row>
        <b-form-group v-if="shopInfo.pickup.type !== 'same-day'">
          <b-form-row>
            <div class="divider" />
            <b-col cols="12">
              <h5 class="orange-color">Future settings</h5>
            </b-col>
            <b-col sm="12" md="6">
              <label>Days Span</label>
              <input
                class="form-control"
                v-model="shopInfo.pickup.futureSettings.daysSpan"
              />
              <small>
                Enter the amount of days up to allow customers to request
                products on future dates. Example: Today is Monday and which to
                allow customers to book until friday, then enter 4
              </small>
            </b-col>
            <b-col sm="12" md="6">
              <label>Days Margin</label>
              <input
                class="form-control"
                v-model="shopInfo.pickup.futureSettings.daysMargin"
              />
              <small>
                Enter the amount of days apart from current date to allow next
                ordering date. Example: Today is Monday, but don't want to allow
                anyone to order before Wednesday, then enter 2
              </small>
            </b-col>
          </b-form-row>
          <b-form-row>
            <b-col sm="12" md="6">
              <div class="checkbox">
                <label>
                  <input
                    type="checkbox"
                    v-model="shopInfo.pickup.futureSettings.cutOffEnabled"
                  />
                  Enable ordering cut off time
                </label>
              </div>
              <small>
                Enable this option if you which to push user's orders to next
                working day if they place and order after a specific time of the
                day. Example: Today is Monday, you got a margin of two day, but
                you specify 13:00 as cut-off time, then if current time is
                14:00, the pick up time will be pushed one day further in the
                future.
              </small>
            </b-col>
            <b-col
              sm="12"
              md="6"
              v-if="shopInfo.pickup.futureSettings.cutOffEnabled"
            >
              <label>Cut-off time</label>
              <div class="w-100">
                <div style="width: 80px; display: inline-block" class="m-1">
                  <select
                    class="form-control hide-arrow"
                    v-model="shopInfo.pickup.futureSettings.cutOffHour"
                  >
                    <option :key="index" v-for="index in 24" :value="index - 1">
                      <span v-if="index - 1 < 10">0</span>{{ index - 1 }}
                    </option>
                  </select>
                </div>
                :
                <div style="width: 80px; display: inline-block" class="m-1">
                  <select
                    class="form-control hide-arrow"
                    v-model="shopInfo.pickup.futureSettings.cutOffMinute"
                  >
                    <option :key="index" v-for="index in 60" :value="index - 1">
                      <span v-if="index - 1 < 10">0</span>{{ index - 1 }}
                    </option>
                  </select>
                </div>
              </div>
              <small>The specific cut-off time in 24 hours time format</small>
            </b-col>
          </b-form-row>
          <b-form-row>
            <b-col sm="12" md="6">
              <div class="checkbox">
                <label>
                  <input
                    type="checkbox"
                    v-model="shopInfo.pickup.futureSettings.allowTimeSelection"
                  />
                  Enable time selection
                </label>
              </div>
              <small
                >This will allow customers to select a specific time within the
                pick up date they which to pick up their order</small
              >
            </b-col>
            <b-col
              sm="12"
              md="6"
              v-if="shopInfo.pickup.futureSettings.allowTimeSelection"
            >
              <label>Time Selection Range</label>
              <select
                v-model="shopInfo.pickup.futureSettings.timeSelectionRange"
                class="form-control"
              >
                <option value="opening-hours">Opening Hours</option>
                <option value="custom">Custom Pick Up Time Range</option>
              </select>
              <small
                >Set to whenever you which to allow customer's pick up time to
                be the same as the current venue opening hours or to enable a
                specific range</small
              >
            </b-col>
          </b-form-row>
          <b-row
            v-if="
              shopInfo.pickup.futureSettings.timeSelectionRange === 'custom' &&
              shopInfo.pickup.futureSettings.allowTimeSelection
            "
          >
            <b-col cols="12">
              <h5 class="orange-color">Custom Future Pick Up Time Range</h5>
            </b-col>
            <b-col
              md="12"
              lg="6"
              class="mt-2"
              v-bind:key="day"
              v-for="day of serviceDays"
            >
              <div class="checkbox">
                <label class="text-capitalize">
                  <input
                    type="checkbox"
                    :checked="
                      shopInfo.pickup.futureSettings.timeSelectionHours.openingDays.includes(
                        day
                      )
                    "
                    @click="toggleOpeningDay(day)"
                  />
                  available on
                  {{ day !== "holiday" ? day : "Public " + day }}?</label
                >
              </div>
              <div class="col-xs-12 npsds">
                <div style="width: 80px; display: inline-block" class="m-1">
                  <select
                    class="form-control hide-arrow"
                    v-model="
                      shopInfo.pickup.futureSettings.timeSelectionHours[
                        `${day}From`
                      ].hour
                    "
                    :disabled="
                      !shopInfo.pickup.futureSettings.timeSelectionHours.openingDays.includes(
                        day
                      )
                    "
                  >
                    <option :key="index" v-for="index in 24" :value="index - 1">
                      <span v-if="index - 1 < 10">0</span>{{ index - 1 }}
                    </option>
                  </select>
                </div>
                :
                <div style="width: 80px; display: inline-block" class="m-1">
                  <select
                    class="form-control hide-arrow"
                    v-model="
                      shopInfo.pickup.futureSettings.timeSelectionHours[
                        `${day}From`
                      ].min
                    "
                    :disabled="
                      !shopInfo.pickup.futureSettings.timeSelectionHours.openingDays.includes(
                        day
                      )
                    "
                  >
                    <option :key="index" v-for="index in 60" :value="index - 1">
                      <span v-if="index - 1 < 10">0</span>{{ index - 1 }}
                    </option>
                  </select>
                </div>
                To
                <div style="width: 80px; display: inline-block" class="m-1">
                  <select
                    class="form-control hide-arrow"
                    v-model="
                      shopInfo.pickup.futureSettings.timeSelectionHours[
                        `${day}To`
                      ].hour
                    "
                    :disabled="
                      !shopInfo.pickup.futureSettings.timeSelectionHours.openingDays.includes(
                        day
                      )
                    "
                  >
                    <option :key="index" v-for="index in 24" :value="index - 1">
                      <span v-if="index - 1 < 10">0</span>{{ index - 1 }}
                    </option>
                  </select>
                </div>
                :
                <div style="width: 80px; display: inline-block" class="m-1">
                  <select
                    class="form-control hide-arrow"
                    v-model="
                      shopInfo.pickup.futureSettings.timeSelectionHours[
                        `${day}To`
                      ].min
                    "
                    :disabled="
                      !shopInfo.pickup.futureSettings.timeSelectionHours.openingDays.includes(
                        day
                      )
                    "
                  >
                    <option :key="index" v-for="index in 60" :value="index - 1">
                      <span v-if="index - 1 < 10">0</span>{{ index - 1 }}
                    </option>
                  </select>
                </div>
                <div class="checkbox overnight">
                  <label>
                    <input
                      type="checkbox"
                      v-model="
                        shopInfo.pickup.futureSettings.timeSelectionHours[
                          `${day}To`
                        ].isOvernight
                      "
                    />
                    venue closes overnight?
                  </label>
                </div>
              </div>
            </b-col>
          </b-row>
        </b-form-group>
        <b-row>
          <b-col sm="12" md="12">
            <b-button
              variant="primary"
              type="submit"
              class="float-right mb-1 ml-2"
              :disabled="loading"
              pill
            >
              <b-spinner v-if="loading" />
              <span v-else>Save</span>
            </b-button>
            <b-button
              :variant="
                shopInfo.pickup.enabled ? 'outline-danger' : 'outline-primary'
              "
              @click="togglePickup"
              class="float-right mb-1"
              :disabled="loading"
              pill
            >
              <b-spinner v-if="loading" />
              <span v-else
                >{{ shopInfo.pickup.enabled ? "Disable" : "Enable" }} Pick
                Up</span
              >
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </b-col>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "shop-pickup",
  props: ["isActive"],
  data: () => ({
    shopInfo: null,
    serviceDays: [
      "monday",
      "tuesday",
      "wednesday",
      "thursday",
      "friday",
      "saturday",
      "sunday",
      "holiday",
    ],
  }),
  computed: {
    ...mapGetters("shop", ["shop", "loading"]),
    ...mapGetters({ user: "userInfo" }),
  },
  methods: {
    toggleOpeningDay(day) {
      if (
        this.shopInfo.pickup.futureSettings.timeSelectionHours.openingDays.includes(
          day
        )
      ) {
        this.shopInfo.pickup.futureSettings.timeSelectionHours.openingDays =
          this.shopInfo.pickup.futureSettings.timeSelectionHours.openingDays.filter(
            (d) => d !== day
          );
      } else {
        this.shopInfo.pickup.futureSettings.timeSelectionHours.openingDays.push(
          day
        );
      }
    },
    saveDetails(e) {
      e.preventDefault();
      let shopInfo = {
        pickup: this.shopInfo.pickup,
      };
      this.$emit("saveChanges", shopInfo);
    },
    togglePickup(e) {
      this.shopInfo.pickup.enabled = !this.shopInfo.pickup.enabled;
      this.saveDetails(e);
    },
    selectPickupDisplayPlatforms(platform) {
      let platforms = [];
      if (this.shopInfo.pickup.displayPlatforms)
        platforms = this.shopInfo.pickup.displayPlatforms;
      if (platforms.includes(platform))
        platforms = platforms.filter((p) => p !== platform);
      else platforms.push(platform);
      this.shopInfo.pickup.displayPlatforms = platforms;
      this.$forceUpdate();
    },
  },
  mounted() {
    this.shopInfo = {
      futureSettings: {
        timeSelectionHours: {
          openingDays: [],
        },
        // allowTimeSelection
        // timeSelectionRange
        // timeSelectionHours
      },
      ...this.shop,
    };
  },
  watch: {
    shop(newVal) {
      if (!newVal) return;
      this.shopInfo = {
        ...newVal,
      };
    },
  },
};
</script>

<style scoped>
.divider {
  border-bottom: 1px solid rgba(60, 60, 60, 0.26);
  width: 100%;
  margin: 10px 0px;
}
</style>
