<template>
  <div></div>
</template>

<script>
export default {
  name: "page-not-found",
  mounted() {
    this.$router.push("/dashboard");
  },
};
</script>

<style scoped></style>
