<template>
  <div class="col-xs-12 npsds">
    <div class="col-xs-12 npsds">
      <h5 class="col-xs-12 npsds">
        <b-col cols="12" class="p-0 report-title"
          >NZ ${{
            ((sales - (sales * percentageDeduction) / 100) / 100).toFixed(2)
          }}</b-col
        >
        <span class="report-title float-right"
          >Total: ${{
            ((total - (total * percentageDeduction) / 100) / 100).toFixed(2)
          }}</span
        >
        <b-col cols="12" class="p-0">{{
          moment(date || moment(start).unix(), "x").format(
            periodUnit === "days" ? "Do MMM" : "HH:mm Do MMM"
          )
        }}</b-col>
      </h5>
    </div>
    <b-col class="canvas-width p-0" cols="12">
      <canvas :id="chartCanvasId"></canvas>
    </b-col>
    <b-col cols="12" class="p-0">
      <div class="float-left">
        {{ moment(start).format(periodUnit === "days" ? "Do MMM" : "HH:mm") }}
      </div>
      <div class="float-right">
        {{ moment(end).format(periodUnit === "days" ? "Do MMM" : "HH:mm") }}
      </div>
    </b-col>
  </div>
</template>

<script>
import moment from "moment";

export default {
  name: "period-sales",
  data: () => ({
    chart: null,
    sales: 0,
    date: null,
    total: 0,
  }),
  props: [
    "periodUnit",
    "start",
    "end",
    "chartCanvasId",
    "reportData",
    "percentageDeduction",
  ],
  methods: {
    moment,
    fillGraph(newVal) {
      let { chart, start, end, periodUnit = "days", chartCanvasId } = this;
      if (chart) chart.destroy();
      let data = {
        labels: [],
        datasets: [
          {
            label: "sales",
            borderColor: "#1975F0",
            backgroundColor: "rgba(0,0,0,0)",
            lineTension: 0.1,
            data: [],
          },
        ],
      };

      let currentDate = moment(start);
      let dates = [moment(start)];
      let total = 0;
      while (currentDate.add(1, periodUnit).diff(end) < 0) {
        dates.push(currentDate.clone());
        data.datasets[0].data.push(
          currentDate > moment().add(1, periodUnit) ? null : 0
        );
      }
      if (periodUnit === "hours") {
        dates.push(currentDate.subtract(1, "minutes").clone());
        data.datasets[0].data.push(
          currentDate > moment().add(1, periodUnit) ? null : 0
        );
      }

      for (let [i, date] of dates.entries()) {
        data.labels.push(date);
        for (let val of newVal) {
          let { hour, day, month, year } = val._id;
          let fecha = moment.utc([year, month - 1, day, hour, 0]);
          fecha.local();

          if (periodUnit === "days" && fecha.dayOfYear() === date.dayOfYear()) {
            data.datasets[0].data[i] += val.totalAmount;
            total += val.totalAmount;
          } else if (periodUnit === "hours" && fecha.isSame(date)) {
            data.datasets[0].data[i] += val.totalAmount;
            total += val.totalAmount;
            continue;
          }
        }
        data.datasets[0].data.push(0);
      }

      this.sales = data.datasets[0].data[0].toFixed(2);
      this.date = data.labels[0];
      this.total = total;
      let context = this;

      setTimeout(() => {
        chart = new window.Chart(
          document.getElementById(chartCanvasId).getContext("2d"),
          {
            type: "LineWithLine",
            data: data,
            options: {
              elements: {
                point: {
                  radius: 1,
                },
              },
              hover: {
                mode: "nearest",
                intersect: true,
              },
              maintainAspectRatio: false,
              tooltips: {
                mode: "index",
                enabled: false,
                intersect: false,
                custom: function (tooltip) {
                  if (!tooltip) return;
                  if (!tooltip.title) return;
                  context.date = tooltip.title[0];
                  context.sales = tooltip.body[0].lines[0].replace(
                    "sales: ",
                    ""
                  );
                  // disable displaying the color box;
                  tooltip.displayColors = false;
                  tooltip.display = false;
                },
              },
              legend: {
                display: false,
              },
              scales: {
                yAxes: [
                  {
                    ticks: { display: false },
                    gridLines: {
                      color: "rgba(0, 0, 0, 0)",
                      tickMarkLength: 0,
                    },
                  },
                ],
                xAxes: [
                  {
                    ticks: { display: false },
                    gridLines: {
                      tickMarkLength: 0,
                    },
                  },
                ],
              },
            },
          }
        );

        this.chart = chart;
      }, 1000);
    },
  },
  watch: {
    reportData(newVal) {
      if (!newVal) return;
      this.fillGraph(newVal);
    },
  },
};
</script>

<style scoped>
.report-title {
  color: #5b7fbd;
}
.canvas-width {
  width: 100%;
  height: 150px;
}
</style>
