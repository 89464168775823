import axios from "axios";
import Vue from "vue";

export default {
  GET_KIOSKS: async ({ commit, rootState }, { shopId, options }) => {
    commit("setLoading", true);
    try {
      const {
        data: { kiosks },
      } = await axios({
        url: `${
          rootState.apiAddress
        }/api/shops/${shopId}/kiosks?options=${JSON.stringify(options)}`,
        headers: {
          Authorization: `Bearer ${rootState.userToken}`,
        },
      });
      commit("setKiosks", kiosks);
    } catch (err) {
      const message =
        err.response && err.response.data && err.response.data.message
          ? err.response.data.message
          : rootState.defaultError;
      Vue.toasted.global
        .showServerError({
          message,
        })
        .goAway(4000);
    }
    commit("setLoading", false);
  },
  CREATE_KIOSK: async (
    { rootState, dispatch, commit },
    { kioskInfo, shopId, options }
  ) => {
    commit("setLoading", true);
    try {
      const {
        data: { kiosk },
      } = await axios({
        url: `${rootState.apiAddress}/api/kiosks`,
        method: "POST",
        data: {
          kioskInfo: JSON.stringify(kioskInfo),
          options: JSON.stringify(options),
        },
        headers: {
          Authorization: `Bearer ${rootState.userToken}`,
        },
      });
      await dispatch("GET_KIOSKS", { shopId });
      Vue.toasted.success("Kiosk creation successful", {
        duration: 2000,
      });
      commit("setLoading", false);
      return kiosk;
    } catch (err) {
      console.log(err);
      const message =
        err.response && err.response.data && err.response.data.message
          ? err.response.data.message
          : rootState.defaultError;
      Vue.toasted.global
        .showServerError({
          message,
        })
        .goAway(4000);
    }
    commit("setLoading", false);
  },
  UPDATE_KIOSK: async (
    { rootState, dispatch, commit },
    { kioskId, kioskInfo, shopId, options }
  ) => {
    try {
      commit("setLoading", true);
      const {
        data: { kiosk },
      } = await axios({
        url: `${rootState.apiAddress}/api/kiosks/${kioskId}`,
        method: "PUT",
        data: {
          kioskInfo: JSON.stringify(kioskInfo),
          options: JSON.stringify(options),
        },
        headers: {
          Authorization: `Bearer ${rootState.userToken}`,
        },
      });

      await dispatch("GET_KIOSKS", { shopId });

      Vue.toasted.success("Kiosk update successful", {
        duration: 2000,
      });
      commit("setLoading", false);
      return kiosk;
    } catch (err) {
      const message =
        err.response && err.response.data && err.response.data.message
          ? err.response.data.message
          : rootState.defaultError;
      Vue.toasted.global
        .showServerError({
          message,
        })
        .goAway(4000);
    }
    commit("setLoading", false);
  },
  DELETE_KIOSK: async (
    { rootState, dispatch, commit },
    { kioskId, shopId }
  ) => {
    try {
      commit("setLoading", true);
      const {
        data: { kiosk },
      } = await axios({
        url: `${rootState.apiAddress}/api/kiosks/${kioskId}`,
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${rootState.userToken}`,
        },
      });

      await dispatch("GET_KIOSKS", { shopId });

      Vue.toasted.success("Kiosk delete successful", {
        duration: 2000,
      });
      commit("setLoading", false);
      return kiosk;
    } catch (err) {
      const message =
        err.response && err.response.data && err.response.data.message
          ? err.response.data.message
          : rootState.defaultError;
      Vue.toasted.global
        .showServerError({
          message,
        })
        .goAway(4000);
    }
    commit("setLoading", false);
  },
  UPLOAD_KIOSK_IMAGE: async ({ commit, rootState }, { imageData }) => {
    commit("setLoading", true, { root: true });
    try {
      let { data } = await axios({
        method: "POST",
        url: "https://api.cloudinary.com/v1_1/on-the-go/image/upload",
        data: {
          file: imageData,
          upload_preset: "hr8ken0o",
        },
      });
      commit("setLoading", false, { root: true });
      return data;
    } catch (err) {
      const message =
        err.response && err.response.data && err.response.data.message
          ? err.response.data.message
          : rootState.defaultError;
      Vue.toasted.global
        .showServerError({
          message,
        })
        .goAway(4000);
    }
    commit("setLoading", false, { root: true });
  },
};
