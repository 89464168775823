import axios from "axios";
import Vue from "vue";

export default {
  CREATE_OPTION: async (
    { rootState, commit, dispatch },
    { optionInfo, options, shopId }
  ) => {
    commit("setLoading", true);
    try {
      const {
        data: { option },
      } = await axios({
        url: `${rootState.apiAddress}/api/options/`,
        method: "POST",
        data: {
          optionInfo: JSON.stringify(optionInfo),
          options: JSON.stringify(options),
        },
        headers: {
          Authorization: `Bearer ${rootState.userToken}`,
        },
      });
      await dispatch("category/GET_CATEGORIES", { shopId }, { root: true });
      commit("setLoading", false);
      Vue.toasted.success("Option creation successful", {
        duration: 2000,
      });
      return option;
    } catch (err) {
      console.log(err);
      const message =
        err.response && err.response.data && err.response.data.message
          ? err.response.data.message
          : rootState.defaultError;
      Vue.toasted.global
        .showServerError({
          message,
        })
        .goAway(4000);
    }
    commit("setLoading", false);
  },
  UPDATE_OPTION: async (
    { rootState, commit, dispatch },
    { optionId, optionInfo, shopId, skipReload = false, options }
  ) => {
    try {
      commit("setLoading", true);
      const {
        data: { option },
      } = await axios({
        url: `${rootState.apiAddress}/api/options/${optionId}`,
        method: "PUT",
        data: {
          optionInfo: JSON.stringify(optionInfo),
          options: JSON.stringify(options),
        },
        headers: {
          Authorization: `Bearer ${rootState.userToken}`,
        },
      });

      if (!skipReload) {
        await dispatch("category/GET_CATEGORIES", { shopId }, { root: true });

        Vue.toasted.success("Option update successful", {
          duration: 2000,
        });
      }

      commit("setLoading", false);
      return option;
    } catch (err) {
      const message =
        err.response && err.response.data && err.response.data.message
          ? err.response.data.message
          : rootState.defaultError;
      Vue.toasted.global
        .showServerError({
          message,
        })
        .goAway(4000);
    }
    commit("setLoading", false);
  },
  DELETE_OPTION: async (
    { rootState, dispatch, commit },
    { optionId, shopId }
  ) => {
    try {
      commit("setLoading", true);
      const {
        data: { option },
      } = await axios({
        url: `${rootState.apiAddress}/api/options/${optionId}`,
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${rootState.userToken}`,
        },
      });

      await dispatch("category/GET_CATEGORIES", { shopId }, { root: true });
      Vue.toasted.success("Option delete successful", {
        duration: 2000,
      });
      commit("setLoading", false);
      return option;
    } catch (err) {
      const message =
        err.response && err.response.data && err.response.data.message
          ? err.response.data.message
          : rootState.defaultError;
      Vue.toasted.global
        .showServerError({
          message,
        })
        .goAway(4000);
    }
    commit("setLoading", false);
  },
};
