<template>
  <b-row>
    <b-col>
      <h4>Confirm Code Details</h4>
      <b-form-group label="Type">
        <strong>{{ promoCode.type.replace("-", " ") }}</strong>
      </b-form-group>
      <b-form-group v-if="promoCode.type === 'discount'" label="Discount">
        <strong
          >{{ promoCode.rewardConfig.discount
          }}{{
            promoCode.rewardConfig.discountType === "percentage"
              ? "% off"
              : "$ off"
          }}</strong
        >
      </b-form-group>
      <b-form-group label="Target">
        <strong class="text-capitalize">{{
          promoCode.rewardConfig.discountTargetType
        }}</strong>
      </b-form-group>
      <b-form-group
        v-if="promoCode.rewardConfig.discountTargetType === 'products'"
      >
        <label>Target Product(s):</label>
        <b-button
          class="float-right"
          pill
          variant="primary"
          @click="$emit('stepUpdate', 3)"
        >
          <i class="fas fa-edit" />
        </b-button>
        <b-row>
          <b-col>
            <b-badge
              v-for="product of selectedProducts"
              :key="product._id"
              pill
              class="mr-2"
              variant="success"
            >
              {{ product.name }}
            </b-badge>
          </b-col>
        </b-row>
      </b-form-group>
      <b-form-group label="Expiry">
        <strong>{{
          moment(promoCode.expiry).format("MMM Do YYYY [at] HH:mm")
        }}</strong>
      </b-form-group>
      <b-form-group label="Usage Limit">
        <strong
          v-if="
            !promoCode.targetUsersConfig.usageLimit ||
            promoCode.targetUsersConfig.usageLimit < 1
          "
          >Unlimited</strong
        >
        <strong v-else
          >{{ promoCode.targetUsersConfig.usageLimit }} Times</strong
        >
      </b-form-group>
      <b-form-group>
        <label>Target Customer(s)</label>
        <b-button
          class="float-right"
          pill
          variant="primary"
          @click="$emit('stepUpdate', 5)"
        >
          <i class="fas fa-edit" />
        </b-button>
        <b-row v-if="!promoCode.targetUsersConfig.isStoreWide">
          <b-col>
            <b-badge
              v-for="customer of selectedCustomers"
              :key="customer._id"
              pill
              class="mr-2"
              variant="success"
            >
              {{ customer.name.given }} {{ customer.name.family }} ({{
                customer.profile.email
              }})
            </b-badge>
          </b-col>
        </b-row>
        <b-row v-else>
          <strong> Everybody </strong>
        </b-row>
      </b-form-group>

      <!--<label>Target Customer(s): <a on-click="@this.set('step', 5)">Edit</a></label>-->
      <!--<ul style="list-style: none;">-->
      <!--{{#if !codeInfo.targetUsersConfig.isStoreWide}}-->
      <!--{{#each customers}}-->
      <!--{{#if ~/codeInfo.targetUsersConfig.users.includes(this._id)}}-->
      <!--<li>-->
      <!--<label class="label label-default" style="margin-right:10px">{{this.name.given}}-->
      <!--{{this.name.family}}({{this.profile.email}})</label>-->
      <!--</li>-->
      <!--{{/if}}-->
      <!--{{/each}}-->
      <!--{{else}}-->
      <!--<li>-->
      <!--<label class="label label-default" style="margin-right:10px"> Everybody</label>-->
      <!--</li>-->
      <!--{{/if}}-->
      <!--</ul>-->
      <!--</div>-->
    </b-col>
  </b-row>
</template>

<script>
import { mapGetters } from "vuex";
import moment from "moment";

export default {
  name: "step1",
  props: ["promoCode", "step"],
  computed: {
    ...mapGetters("category", ["categories"]),
    ...mapGetters("customer", ["customers"]),
    selectedProducts() {
      let products = this.categories.map((c) => c.products).flat(1);
      console.log(products);
      return products.filter((p) =>
        this.promoCode.rewardConfig.rewardTargetProducts.includes(p._id)
      );
    },
    selectedCustomers() {
      return this.customers.filter((c) =>
        this.promoCode.targetUsersConfig.users.includes(c._id)
      );
    },
  },
  methods: {
    moment,
  },
};
</script>

<style scoped></style>
