<template>
  <b-col>
    <b-card>
      <h4>Cart Title</h4>
      <b-row>
        <b-col sm="12" md="6">
          <b-form-group label="Text Color">
            <b-input-group>
              <b-form-select
                :options="enumerations"
                v-model="pageLayout.cartTitleTextColor"
              />
              <b-input-group-append>
                <theme-helper
                  :theme="theme"
                  :value="pageLayout.cartTitleTextColor"
                  type="color"
                />
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col sm="12" md="6">
          <b-form-group label="Font">
            <b-form-select
              :options="enumerations"
              v-model="pageLayout.cartTitleFont"
            />
          </b-form-group>
        </b-col>
        <b-col sm="12" md="6">
          <b-form-group label="Text Size">
            <b-form-select
              :options="textSizeOptions"
              v-model="pageLayout.cartTitleTextSize"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <hr />
      <h4>Products Container</h4>
      <b-row>
        <b-col sm="12" md="6">
          <b-form-group label="Background Color">
            <b-input-group>
              <b-form-select
                :options="enumerations"
                v-model="pageLayout.containerColor"
              />
              <b-input-group-append>
                <theme-helper
                  :theme="theme"
                  :value="pageLayout.containerColor"
                  type="color"
                />
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>
      </b-row>
      <hr />
      <h4>Product Name</h4>
      <b-row>
        <b-col sm="12" md="6">
          <b-form-group label="Text Color">
            <b-input-group>
              <b-form-select
                :options="enumerations"
                v-model="pageLayout.productNameTextColor"
              />
              <b-input-group-append>
                <theme-helper
                  :theme="theme"
                  :value="pageLayout.productNameTextColor"
                  type="color"
                />
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col sm="12" md="6">
          <b-form-group label="Font">
            <b-form-select
              :options="enumerations"
              v-model="pageLayout.productNameFont"
            />
          </b-form-group>
        </b-col>
        <b-col sm="12" md="6">
          <b-form-group label="Text Size">
            <b-form-select
              :options="textSizeOptions"
              v-model="pageLayout.productNameTextSize"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <h4>Product Description</h4>
      <b-row>
        <b-col sm="12" md="6">
          <b-form-group label="Text Color">
            <b-input-group>
              <b-form-select
                :options="enumerations"
                v-model="pageLayout.productDescriptionTextColor"
              />
              <b-input-group-append>
                <theme-helper
                  :theme="theme"
                  :value="pageLayout.productDescriptionTextColor"
                  type="color"
                />
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col sm="12" md="6">
          <b-form-group label="Font">
            <b-form-select
              :options="enumerations"
              v-model="pageLayout.productDescriptionFont"
            />
          </b-form-group>
        </b-col>
        <b-col sm="12" md="6">
          <b-form-group label="Text Size">
            <b-form-select
              :options="textSizeOptions"
              v-model="pageLayout.productDescriptionTextSize"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <h4>Product Price</h4>
      <b-row>
        <b-col sm="12" md="6">
          <b-form-group label="Text Color">
            <b-input-group>
              <b-form-select
                :options="enumerations"
                v-model="pageLayout.productPriceTextColor"
              />
              <b-input-group-append>
                <theme-helper
                  :theme="theme"
                  :value="pageLayout.productPriceTextColor"
                  type="color"
                />
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col sm="12" md="6">
          <b-form-group label="Font">
            <b-form-select
              :options="enumerations"
              v-model="pageLayout.productPriceFont"
            />
          </b-form-group>
        </b-col>
        <b-col sm="12" md="6">
          <b-form-group label="Text Size">
            <b-form-select
              :options="textSizeOptions"
              v-model="pageLayout.productPriceTextSize"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <hr />
      <h4>Increase Buttons</h4>
      <b-row>
        <b-col sm="12" md="6">
          <b-form-group label="Background Color">
            <b-input-group>
              <b-form-select
                :options="enumerations"
                v-model="pageLayout.increaseButtonsBackgroundColor"
              />
              <b-input-group-append>
                <theme-helper
                  :theme="theme"
                  :value="pageLayout.increaseButtonsBackgroundColor"
                  type="color"
                />
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col sm="12" md="6">
          <b-form-group label="Icons Color">
            <b-input-group>
              <b-form-select
                :options="enumerations"
                v-model="pageLayout.increaseButtonsIconsColor"
              />
              <b-input-group-append>
                <theme-helper
                  :theme="theme"
                  :value="pageLayout.increaseButtonsIconsColor"
                  type="color"
                />
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col sm="12" md="6">
          <b-form-group label="Border Color">
            <b-input-group>
              <b-form-select
                :options="enumerations"
                v-model="pageLayout.increaseButtonsBorderColor"
              />
              <b-input-group-append>
                <theme-helper
                  :theme="theme"
                  :value="pageLayout.increaseButtonsBorderColor"
                  type="color"
                />
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>
      </b-row>
      <h4>Edit Button</h4>
      <b-row>
        <b-col sm="12" md="6">
          <b-form-group label="Background Color">
            <b-input-group>
              <b-form-select
                :options="enumerations"
                v-model="pageLayout.editButtonBackgroundColor"
              />
              <b-input-group-append>
                <theme-helper
                  :theme="theme"
                  :value="pageLayout.editButtonBackgroundColor"
                  type="color"
                />
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col sm="12" md="6">
          <b-form-group label="Icon Color">
            <b-input-group>
              <b-form-select
                :options="enumerations"
                v-model="pageLayout.editButtonIconColor"
              />
              <b-input-group-append>
                <theme-helper
                  :theme="theme"
                  :value="pageLayout.editButtonIconColor"
                  type="color"
                />
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>
      </b-row>
      <hr />
      <h4>Remove Button</h4>
      <b-row>
        <b-col sm="12" md="6">
          <b-form-group label="Background Color">
            <b-input-group>
              <b-form-select
                :options="enumerations"
                v-model="pageLayout.removeButtonBackgroundColor"
              />
              <b-input-group-append>
                <theme-helper
                  :theme="theme"
                  :value="pageLayout.removeButtonBackgroundColor"
                  type="color"
                />
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col sm="12" md="6">
          <b-form-group label="Icon Color">
            <b-input-group>
              <b-form-select
                :options="enumerations"
                v-model="pageLayout.removeButtonIconColor"
              />
              <b-input-group-append>
                <theme-helper
                  :theme="theme"
                  :value="pageLayout.removeButtonIconColor"
                  type="color"
                />
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>
      </b-row>
      <hr />
      <b-button
        variant="primary"
        @click="$emit('saved', { key: 'cart', config: { ...pageLayout } })"
        >Save</b-button
      >
    </b-card>
  </b-col>
</template>

<script>
import { enumerations, textSizeOptions } from "@/utils/constants";
import ThemeHelper from "@/components/layout-editor/themeHelper";

export default {
  name: "cartTab",
  components: { ThemeHelper },
  props: ["pageLayout", "theme"],
  data: () => ({
    textSizeOptions,
    enumerations,
  }),
};
</script>

<style scoped></style>
