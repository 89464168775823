<template>
  <div style="height: 100%">
    <b-card>
      <b-col>
        <h5 class="section-title orange-color text">
          <span class="text-capitalize"
            >{{ customer.name.given }} {{ customer.name.family }}</span
          >
          <br />
          <small class="dark-blue-color">
            {{ customer.profile.email }} -
            {{ customer.profile.phone || "no contact phone available" }}
          </small>
        </h5>
      </b-col>
    </b-card>
    <b-alert :show="userInfo && userInfo.roles.includes('admin')">
      Information about "Order History" and "Loyalty" is related to the current
      shop selected.
    </b-alert>
    <b-tabs style="height: 100%" card fill pills :vertical="!isMobile">
      <template #tabs-end>
        <b-button pill class="float-left mt-3" onclick="history.back()">
          Back
        </b-button>
      </template>
      <orders :customer="customer" :shop="shop" />
      <loyalty
        v-if="shop && shop.loyalty && shop.loyalty.enabled"
        :customer="customer"
        :shop="shop"
      />
      <contact-details
        :user-info="customer"
        v-if="customer && customer.name && userInfo.roles.includes('admin')"
      />
    </b-tabs>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Orders from "../components/customers/details/orders";
import Loyalty from "../components/customers/details/loyalty";
import ContactDetails from "../components/my-account/contact-details";

export default {
  name: "customer-details",
  components: { Loyalty, Orders, ContactDetails },
  props: ["customerId"],
  computed: {
    ...mapGetters("customer", ["customers"]),
    ...mapGetters("shop", ["shop"]),
    ...mapGetters(["userInfo"]),
    isMobile() {
      return document.getElementById("app").offsetWidth < 600;
    },
    customer() {
      return this.customers.find((c) => c._id === this.customerId);
    },
  },
};
</script>

<style scoped></style>
