var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"shop-section col-xs-12",class:{ active: _vm.isActive },attrs:{"id":"notifications"},on:{"click":function($event){return _vm.$emit('activeSection', 'notifications')}}},[_vm._m(0),(_vm.shopInfo && _vm.shopInfo.notificationSettings)?_c('b-col',{staticClass:"option-content col-xs-12"},[_c('b-form',{staticClass:"form-horizontal col-xs-12",attrs:{"id":"notifications-form"},on:{"submit":_vm.saveDetails}},[_c('div',{staticClass:"col-lg-6"},[_c('div',{staticClass:"form-group col-xs-12"},[_c('label',[_vm._v("New order via")]),_c('div',{staticClass:"checkbox"},[_c('label',[_c('input',{directives:[{name:"model",rawName:"v-model",value:(
                  _vm.shopInfo.notificationSettings.newOrder.pushNotification
                ),expression:"\n                  shopInfo.notificationSettings.newOrder.pushNotification\n                "}],attrs:{"type":"checkbox"},domProps:{"checked":Array.isArray(
                  _vm.shopInfo.notificationSettings.newOrder.pushNotification
                )?_vm._i(
                  _vm.shopInfo.notificationSettings.newOrder.pushNotification
                ,null)>-1:(
                  _vm.shopInfo.notificationSettings.newOrder.pushNotification
                )},on:{"change":function($event){var $$a=
                  _vm.shopInfo.notificationSettings.newOrder.pushNotification
                ,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.shopInfo.notificationSettings.newOrder, "pushNotification", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.shopInfo.notificationSettings.newOrder, "pushNotification", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.shopInfo.notificationSettings.newOrder, "pushNotification", $$c)}}}}),_vm._v(" Push notification (IOS/Android merchant app) ")])]),_c('div',{staticClass:"checkbox"},[_c('label',[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.shopInfo.notificationSettings.newOrder.email),expression:"shopInfo.notificationSettings.newOrder.email"}],attrs:{"type":"checkbox"},domProps:{"checked":Array.isArray(_vm.shopInfo.notificationSettings.newOrder.email)?_vm._i(_vm.shopInfo.notificationSettings.newOrder.email,null)>-1:(_vm.shopInfo.notificationSettings.newOrder.email)},on:{"change":function($event){var $$a=_vm.shopInfo.notificationSettings.newOrder.email,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.shopInfo.notificationSettings.newOrder, "email", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.shopInfo.notificationSettings.newOrder, "email", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.shopInfo.notificationSettings.newOrder, "email", $$c)}}}}),_vm._v(" Email ")])])])]),_c('b-row',[_c('b-col',[_c('b-button',{staticClass:"float-right mb-1",attrs:{"variant":"primary","type":"submit","disabled":_vm.loading,"pill":""}},[(_vm.loading)?_c('b-spinner'):_c('span',[_vm._v("Save")])],1)],1)],1)],1)],1):_vm._e()],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"header"},[_c('i',{staticClass:"fas fa-envelope section-icon"}),_c('h5',{staticClass:"section-title dark-blue-color"},[_vm._v(" Notification Settings "),_c('br'),_c('small',[_vm._v("Choose how you would like order notifications")])])])
}]

export { render, staticRenderFns }