<template>
  <b-tab title="Tables">
    <b-button variant="primary" class="hover-button" @click="addNew" pill>
      <i class="fas fa-plus fa-2x"></i>
    </b-button>
    <h5 class="text-center" v-if="!dineIn.tables || dineIn.tables.length < 1">
      No tables found, click the + button to add a new table
    </h5>
    <b-form-group
      style="height: 0px; width: 0px; overflow: hidden"
      v-if="selectedTable"
    >
      <vue-html2pdf
        :show-layout="false"
        :float-layout="true"
        :enable-download="false"
        :preview-modal="true"
        :paginate-elements-by-height="500"
        filename="hee hee"
        :pdf-quality="2"
        :manual-pagination="false"
        pdf-format="a4"
        pdf-orientation="portrait"
        pdf-content-width="100%"
        :html-to-pdf-options="{
          enableLinks: true,
        }"
        ref="html2Pdf"
      >
        <section slot="pdf-content">
          <b-row>
            <b-col class="text-center">
              <h3>{{ shop.name.normal }}</h3>
              <p>Scan below QR Code to start your order</p>
              <h4>Your table number is {{ selectedTable.number }}</h4>
              <qrcode-vue :value="getQRValue(selectedTable)" size="200" />
              <small
                >Served by your <a href="https://goodhost.co.nz">Host</a></small
              >
            </b-col>
          </b-row>
        </section>
      </vue-html2pdf>
    </b-form-group>
    <b-row>
      <b-col v-for="table of dineIn.tables" :key="table._id" md="12" lg="4">
        <b-card class="mt-2">
          <b-form-group>
            <label> Table number </label>
            <i
              class="fas fa-info-circle float-right"
              v-b-tooltip.hover
              title="Enter a short number or text reference for the table number to differentiate from other tables"
            />
            <input class="form-control" v-model="table.number" />
            <!--<small>Enter a short number or text reference for the table number to differentiate from other tables</small>-->
          </b-form-group>
          <b-form-group>
            <label> Capacity </label>
            <i
              class="fas fa-info-circle float-right"
              v-b-tooltip.hover
              title="Enter the maximum amount of people allowed in this table"
            />
            <input
              class="form-control"
              type="number"
              v-model="table.capacity"
            />
            <!--<small>Enter the maximum amount of people allowed in this table</small>-->
          </b-form-group>
          <b-row>
            <b-col>
              <b-button
                block
                class="mt-1"
                variant="success"
                v-b-tooltip.hover
                title="Visit your embed store as you were scanning the QR code"
                @click="goToTableOrdering(table)"
              >
                <i class="fas fa-eye"></i> Preview
              </b-button>

              <b-button
                block
                class="mt-1"
                variant="primary"
                v-b-tooltip.hover
                title="Saves the changes on this table"
                @click="saveTable(table)"
              >
                <i class="fas fa-save"></i> Save
              </b-button>

              <b-button
                block
                class="mt-1"
                variant="danger"
                v-b-tooltip.hover
                title="Deletes this table from the venue"
                @click="deleteTable(table)"
              >
                <i class="fas fa-trash"></i> Delete
              </b-button>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
    <a id="link" style="display: none"></a>
  </b-tab>
</template>

<script>
import { mapGetters } from "vuex";
import QrcodeVue from "qrcode.vue";
import VueHtml2pdf from "vue-html2pdf";

export default {
  name: "tables",
  components: {
    QrcodeVue,
    VueHtml2pdf,
  },
  data: () => ({
    dineIn: {
      enabled: false,
      tables: [],
      preparationTime: 15,
    },
    selectedTable: {},
  }),
  computed: {
    ...mapGetters("shop", ["shop", "loading", "embedKey"]),
  },
  methods: {
    goToTableOrdering(table) {
      window
        .open(
          `http://${
            this.embedKey.origins[this.embedKey.origins.length - 1]
          }?order-type=dine-in&table=${table._id}`,
          "_blank"
        )
        .focus();
    },
    getTableQRCode(table) {
      alert();
      console.log(table);
      this.selectedTable = table;
      this.$refs.html2Pdf.generatePdf();
    },
    async saveTable() {
      await this.$store.dispatch("shop/UPDATE_SHOP", {
        shopId: this.shop._id,
        shopInfo: { dineIn: this.dineIn },
      });
    },
    async addNew() {
      const number = prompt("Enter table number");
      const capacity = prompt("Enter table maximum persons capacity");

      await this.$store.dispatch("shop/UPDATE_SHOP", {
        shopId: this.shop._id,
        shopInfo: {
          $push: {
            "dineIn.tables": {
              number,
              capacity,
            },
          },
        },
      });
    },
    async deleteTable(table) {
      let answer = confirm(
        `Are you sure you want to remove the table "${table.number}"?`
      );
      if (!answer) return;
      await this.$store.dispatch("shop/UPDATE_SHOP", {
        shopId: this.shop._id,
        shopInfo: {
          $pull: {
            "dineIn.tables": { _id: table._id },
          },
        },
      });
    },
    getQRValue(table) {
      return `https://${
        this.embedKey.origins[this.embedKey.origins.length - 1]
      }?order-type=dine-in&table=${table._id}`;
    },
  },
  mounted() {
    if (this.shop) this.dineIn = JSON.parse(JSON.stringify(this.shop.dineIn));
  },
  watch: {
    shop(newVal) {
      if (newVal) this.dineIn = JSON.parse(JSON.stringify(newVal.dineIn));
    },
  },
};
</script>

<style scoped>
.hover-button {
  position: absolute;
  right: 10px;
  bottom: 40px;
}
</style>
