<template>
  <div
    id="ordering"
    class="shop-section col-xs-12"
    v-bind:class="{ active: isActive }"
    @click="$emit('activeSection', 'ordering')"
  >
    <div class="header">
      <i class="fas fa-shopping-bag section-icon" />

      <h5 class="section-title dark-blue-color">
        Ordering Settings
        <br />
        <small>Store's ordering setting</small>
      </h5>
    </div>
    <b-col
      class="option-content col-xs-12"
      v-if="shopInfo && shopInfo.orderingPreferences"
    >
      <b-form
        class="form-horizontal col-xs-12"
        id="ordering-configuration-form"
        @submit="saveDetails"
      >
        <b-row>
          <b-col sm="12" md="6">
            <b-form-group>
              <label>Preparation time (minutes)</label>
              <input
                type="number"
                class="form-control"
                required
                v-model="shopInfo.orderingPreferences.preparationTime"
                placeholder="Default time is 5 minutes"
              />
              <small
                >Minimum amount of time that the order preparation requires
                before customer can pick up. Usually food preparations take
                between 15 to 30 minutes.
              </small>
            </b-form-group>
          </b-col>
          <b-col sm="12" md="6">
            <b-form-group>
              <div class="checkbox">
                <label>
                  <input
                    type="checkbox"
                    v-model="
                      shopInfo.orderingPreferences.displayPreparationTime
                    "
                  />
                  Display preparation time
                </label>
              </div>
              <small>
                Opt-in to tell your customers how long the preparation is in the
                embed store or the OTG marketplace app
              </small>
            </b-form-group>
          </b-col>
          <b-col sm="12" md="6">
            <b-form-group>
              <div class="checkbox">
                <label>
                  <input
                    type="checkbox"
                    v-model="shopInfo.orderingPreferences.disablePromoCodes"
                  />
                  Disable promo codes
                </label>
              </div>
              <small>
                Hides the promo codes option from all the platforms of your
                venue
              </small>
            </b-form-group>
          </b-col>
          <b-col sm="12" md="6">
            <b-form-group>
              <div class="checkbox">
                <label>
                  <input
                    type="checkbox"
                    v-model="shopInfo.orderingPreferences.autoAccept"
                  />
                  Auto-Accept Orders
                </label>
              </div>
              <small>
                New placed orders will be automatically accepted by the system.
                In case of a refund needed, this will have to be done manually
                by the venue staff
              </small>
            </b-form-group>
          </b-col>
          <b-col sm="12" md="6">
            <b-form-group>
              <div class="checkbox">
                <label>
                  <input
                    type="checkbox"
                    v-model="shopInfo.orderingPreferences.autoComplete"
                  />
                  Auto-Complete Orders
                </label>
              </div>
              <small>
                New placed orders, which are already accepted, will be
                automatically completed by the system. In case of a refund
                needed, this will have to be done manually by the venue staff
              </small>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row class="pb-3">
          <b-col sm="12" md="6">
            <label>Default Order Type</label>
            <select
              class="form-control text-capitalize"
              v-model="shopInfo.orderingPreferences.defaultOrderType"
            >
              <option value="pickup">Takeaway</option>
              <option value="dine-in">Dine In</option>
              <option value="delivery">Delivery</option>
            </select>
            <small
              >Set's the default Order type to be selected by customers</small
            >
          </b-col>
        </b-row>
        <b-row>
          <b-col sm="12" md="6">
            <b-form-group>
              <label>Customer Instructions</label>
              <textarea
                class="form-control"
                v-model="shopInfo.orderingPreferences.instructions"
                placeholder="e.g. Pick up or delivery instructions"
              ></textarea>
              <small
                >Enter here any indications or special information you want your
                customer to known when they are ordering.</small
              >
            </b-form-group>
          </b-col>
          <b-col sm="12" md="6">
            <b-form-group>
              <label>Product Notes Title</label>
              <input
                class="form-control"
                v-model="shopInfo.notesTitle"
                placeholder="e.g: Need to let us know something?"
              />
              <small
                >At the product customization screen displays a personalized
                message for the product notes</small
              >
            </b-form-group>
          </b-col>
        </b-row>
        <b-row v-if="user.roles.includes('admin')">
          <b-col sm="12" md="6">
            <b-form-group>
              <label>Order fee %(admin only)</label>
              <input
                type="number"
                class="form-control"
                required
                v-model="shopInfo.orderingPreferences.feePercent"
                placeholder="Default value is 15%"
              />
              <small
                >The order percent to deduct as success fee for OTG
                service</small
              >
            </b-form-group>
          </b-col>
          <b-col sm="12" md="6">
            <b-form-group
              label="Legacy under subscription"
              description="Define if is a legacy customer and subscription was created manually on Stripe"
              v-if="user.roles.includes('admin')"
            >
              <b-form-checkbox
                v-model="shopInfo.orderingPreferences.legacyUnderSubscription"
              >
                Is this a legacy OTG client?
              </b-form-checkbox>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row v-if="user.roles.includes('admin')">
          <b-col sm="12" md="6">
            <b-form-group>
              <div class="checkbox">
                <label>
                  <input
                    type="checkbox"
                    v-model="shopInfo.customerSurcharge.enabled"
                  />
                  Customer Surcharge % (admin only)</label
                >
              </div>
              <small> Orders will be process with a custom fee. </small>
            </b-form-group>
          </b-col>
          <b-col sm="12" md="6">
            <b-input-group prepend="% surcharge">
              <b-form-input
                type="number"
                step="0.1"
                v-model="shopInfo.customerSurcharge.percent"
                :disabled="!shopInfo.customerSurcharge.enabled"
              ></b-form-input>
              <b-col>
                <b-input-group-append
                  ><b-dropdown
                    block
                    variant="primary"
                    toggle-class="text-capitalize"
                    :text="
                      shopInfo.customerSurcharge.platforms &&
                      shopInfo.customerSurcharge.platforms.length > 0
                        ? shopInfo.customerSurcharge.platforms.join(' - ')
                        : 'Select Platform Options'
                    "
                    :disabled="!shopInfo.customerSurcharge.enabled"
                  >
                    <b-dropdown-item
                      :active="
                        shopInfo.customerSurcharge.platforms &&
                        shopInfo.customerSurcharge.platforms.includes('kiosk')
                      "
                      @click.stop.prevent="
                        selectCustomerSurchargePlatforms('kiosk')
                      "
                      >Kiosk</b-dropdown-item
                    >
                    <b-dropdown-item
                      :active="
                        shopInfo.customerSurcharge.platforms &&
                        shopInfo.customerSurcharge.platforms.includes('web')
                      "
                      @click.stop.prevent="
                        selectCustomerSurchargePlatforms('web')
                      "
                      >Web</b-dropdown-item
                    >
                    <b-dropdown-item
                      :active="
                        shopInfo.customerSurcharge.platforms &&
                        shopInfo.customerSurcharge.platforms.includes(
                          'mobile-app'
                        )
                      "
                      @click.stop.prevent="
                        selectCustomerSurchargePlatforms('mobile-app')
                      "
                      >Mobile App</b-dropdown-item
                    >
                  </b-dropdown></b-input-group-append
                ></b-col
              >
            </b-input-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col sm="12" md="6">
            <b-button
              variant="primary"
              type="submit"
              class="float-right mb-1"
              :disabled="loading"
              pill
            >
              <b-spinner v-if="loading" />
              <span v-else>Save</span>
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </b-col>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "shop-ordering",
  props: ["isActive"],
  computed: {
    ...mapGetters("shop", ["shop", "loading"]),
    ...mapGetters({ user: "userInfo" }),
  },
  data: () => ({
    shopInfo: null,
  }),
  methods: {
    saveDetails(e) {
      e.preventDefault();
      let shopInfo = {
        orderingPreferences: this.shopInfo.orderingPreferences,
        notesTitle: this.shopInfo.notesTitle,
        customerSurcharge: this.shopInfo.customerSurcharge,
      };
      this.$emit("saveChanges", shopInfo);
    },
    selectCustomerSurchargePlatforms(platform) {
      let platforms = [];
      if (this.shopInfo.customerSurcharge.platforms)
        platforms = this.shopInfo.customerSurcharge.platforms;
      if (platforms.includes(platform))
        platforms = platforms.filter((p) => p !== platform);
      else platforms.push(platform);
      this.shopInfo.customerSurcharge.platforms = platforms;
      this.$forceUpdate();
    },
  },
  mounted() {
    this.shopInfo = JSON.parse(JSON.stringify(this.shop));
  },
};
</script>

<style scoped></style>
