<template>
  <b-modal
    id="promo-code-editor"
    size="lg"
    title="Promo Code Editor"
    @ok="nextStep"
  >
    <ul class="steps-container">
      <li class="step" v-bind:class="{ active: step === 1 }"></li>
      <li class="step" v-bind:class="{ active: step === 2 }"></li>
      <li class="step" v-bind:class="{ active: step === 3 }"></li>
      <li class="step" v-bind:class="{ active: step === 4 }"></li>
      <li class="step" v-bind:class="{ active: step === 5 }"></li>
      <li class="step" v-bind:class="{ active: step === 6 }"></li>
    </ul>

    <Step1 :promoCode="promoCodeInfo" v-if="step === 1" />
    <Step2 :promoCode="promoCodeInfo" v-if="step === 2" />
    <Step3 :promoCode="promoCodeInfo" v-if="step === 3" />
    <Step4 :promoCode="promoCodeInfo" v-if="step === 4" />
    <Step5 :promoCode="promoCodeInfo" v-if="step === 5" />
    <Step6
      :promoCode="promoCodeInfo"
      v-on:stepUpdate="onStepUpdate"
      v-if="step === 6"
    />
    <template #modal-footer="scope">
      <b-button pill :disabled="step < 2" @click="step -= 1"> Back </b-button>
      <b-button pill variant="primary" @click="scope.ok">
        {{ step > 5 ? "Confirm" : "Next" }}
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import { mapGetters } from "vuex";
import Step1 from "./step1";
import Step2 from "./step2";
import Step3 from "./step3";
import Step4 from "./step4";
import Step5 from "./step5";
import Step6 from "./step6";

export default {
  name: "promoCodeEditor",
  props: ["promoCode"],
  components: { Step1, Step2, Step3, Step4, Step5, Step6 },
  data: () => ({
    step: 1,
    promoCodeInfo: null,
  }),
  computed: {
    ...mapGetters("shop", ["shop"]),
    ...mapGetters("promocode", ["loading"]),
  },
  methods: {
    onStepUpdate(step) {
      this.step = step;
    },
    async nextStep(bvModalEvt) {
      bvModalEvt.preventDefault();
      if (this.step < 6) {
        this.step += 1;
      } else {
        const { rewardConfig, targetUsersConfig, type, expiry, code } =
          this.promoCodeInfo;
        const promoCodeInfo = {
          ...rewardConfig,
          ...targetUsersConfig,
          type,
          expiry,
          code,
        };
        if (this.promoCodeInfo._id) {
          await this.$store.dispatch("promocode/UPDATE_PROMO_CODE", {
            codeId: this.promoCodeInfo._id,
            promoCodeInfo,
            shopId: this.shop._id,
          });
        } else {
          await this.$store.dispatch("promocode/CREATE_PROMO_CODE", {
            codeInfo: promoCodeInfo,
            shopId: this.shop._id,
          });
        }
        this.$bvModal.hide("promo-code-editor");
      }
    },
  },
  watch: {
    promoCode(newVal) {
      this.promoCodeInfo = JSON.parse(JSON.stringify(newVal));
    },
  },
};
</script>

<style scoped>
ul.steps-container {
  align-content: center;
  align-items: center;
  counter-reset: stepCount;
  display: flex;
  justify-content: space-around;
  margin: 20px 0px; /* for codepen */
}

li.step {
  background: dodgerblue;
  color: white;
  content: " ";
  display: flex;
  flex-grow: 1;
  height: 0.3em;
  line-height: 1em;
  margin: 0;
  position: relative;
  text-align: right;
  z-index: 2;
  transition: 0.4s;
}

li.step::before {
  color: white;
  background: dodgerblue;
  border-radius: 50%;
  counter-increment: stepCount;
  content: counter(stepCount);
  height: 2em;
  left: -2em;
  line-height: 2em;
  position: absolute;
  text-align: center;
  top: -0.85em;
  width: 2em;
  transition: 0.4s;
}

li.step.active {
  background-color: lightblue;
}

li.step.active ~ li {
  background-color: lightblue;
}

li.step.active ~ li::before {
  background-color: lightblue;
}

li.step:last-child {
  flex-basis: 0;
  flex-grow: 0;
  flex-shrink: 1;
  /* Shorthand: flex: 0 1 0; */
}
</style>
