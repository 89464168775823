<template>
  <div style="height: 100%" v-if="category">
    <b-card>
      <b-row>
        <b-col cols="9">
          <h5 class="section-title orange-color">
            <span class="text-capitalize">{{ category.name }}</span>
            <br />
            <small class="dark-blue-color">
              Configure your category's products, options and availability
            </small>
          </h5>
        </b-col>
        <b-col cols="3">
          <b-dropdown
            pill
            size="lg"
            variant="link"
            class="float-right"
            no-caret
          >
            <template #button-content>
              <i class="fas fa-ellipsis-v" />
            </template>
            <b-dropdown-item @click.stop="editCategoryName">
              <i class="fas fa-pen mr-1" /> Edit Category's Name
            </b-dropdown-item>
            <b-dropdown-item v-b-modal.delete-category-modal>
              <i class="fas fa-trash mr-1" /> Delete
            </b-dropdown-item>
          </b-dropdown>
        </b-col>
      </b-row>
    </b-card>
    <b-tabs
      style="height: 100%"
      card
      fill
      pills
      :vertical="!isMobile"
      v-model="activeTab"
    >
      <template #tabs-end>
        <b-button pill class="float-left mt-3" :to="`/shop/menu`">
          Back
        </b-button>
      </template>
      <Products :category="category" />
      <Options :category="category" />
      <availability :category="category" />
    </b-tabs>
    <delete-category-modal :category="category" :goToMenuBuilder="true" />
  </div>
</template>

<script>
import Products from "../components/menu-builder/category-details/products";
import Options from "../components/menu-builder/category-details/options";
import { mapGetters } from "vuex";
import Availability from "../components/menu-builder/category-details/availability";
import DeleteCategoryModal from "../components/menu-builder/delete-category-modal";
export default {
  name: "category-details",
  components: { DeleteCategoryModal, Availability, Options, Products },
  props: ["categoryId"],
  data: () => ({
    activeTab: 0,
  }),
  computed: {
    ...mapGetters("category", ["categories"]),
    category() {
      return this.categories.find((c) => c._id === this.categoryId);
    },
    isMobile() {
      return document.getElementById("app").offsetWidth < 600;
    },
  },
  methods: {
    editCategoryName() {
      let name = prompt(`Enter new category's name`);
      if (!name || name.trim().length < 1) return;
      this.$store.dispatch("category/UPDATE_CATEGORY", {
        categoryId: this.category._id,
        categoryInfo: {
          name,
        },
        shopId: this.category.shop,
      });
    },
  },
  mounted() {
    if (this.$route.query && this.$route.query.section) {
      switch (this.$route.query.section) {
        case "products":
          this.activeTab = 0;
          break;
        case "options":
          this.activeTab = 1;
          break;
        case "availability":
          this.activeTab = 2;
          break;
      }
    }
  },
};
</script>

<style scoped></style>
