<template>
  <div id="onboarding" v-if="showOnboarding">
    <h3 class="mt-2 text-center">
      <img src="../assets/OTG_Logo_Landscape.png" style="max-width: 400px" />
    </h3>
    <b-row align-h="center">
      <b-col sm="12" md="6" lg="6">
        <b-card v-if="step === 1" class="text-center">
          <h3>Welcome</h3>
          <p>
            To start trading in On the Go you need to create your first venue
          </p>
          <b-form-input placeholder="Enter venue's name" v-model="shopName" />
          <template #footer>
            <b-button
              pill
              variant="primary"
              class="float-right"
              @click="step += 1"
              :disabled="shopName.length < 3"
            >
              Next
            </b-button>
          </template>
        </b-card>
        <b-card v-if="step === 2" class="text-center">
          <h3>Location</h3>
          <p>
            To allow your customers find your venue you need to enter its
            location
          </p>
          <vue-google-autocomplete
            id="map"
            classname="form-control"
            placeholder="Enter venue's address"
            country="nz"
            required
            v-model="shopAddress.formatted_address"
            :types="['geocode']"
            v-on:placechanged="setAddress"
          >
          </vue-google-autocomplete>
          <template #footer>
            <b-button
              pill
              variant="primary"
              class="float-right"
              @click="createVenue"
              :disabled="
                loading ||
                !shopAddress ||
                !shopAddress.formatted_address ||
                shopAddress.formatted_address.length < 3
              "
            >
              <b-spinner v-if="loading" />
              <div v-else>Create Venue</div>
            </b-button>
            <b-button
              pill
              variant="secondary"
              class="float-right mr-2"
              @click="step -= 1"
              :disabled="loading"
            >
              Back
            </b-button>
          </template>
        </b-card>
        <b-card v-if="step === 3" class="text-center">
          <h3>Venue Created!</h3>
          <p>
            Your first venue has been created. To start trading with your first
            venue is necessary to verify your ownership or right to operate it.
          </p>

          <template #footer>
            <b-button
              pill
              variant="primary"
              class="float-right"
              href="/shop/settings?section=verification"
            >
              Verify Now
            </b-button>
            <b-button
              pill
              variant="secondary"
              class="float-right mr-2"
              href="/dashboard"
            >
              Skip
            </b-button>
          </template>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import VueGoogleAutocomplete from "vue-google-autocomplete";

export default {
  name: "onboarding",
  components: {
    VueGoogleAutocomplete,
  },
  data: () => ({
    step: 1,
    shopName: "",
    shopAddress: {},
  }),
  computed: {
    ...mapGetters(["userInfo"]),
    ...mapGetters("shop", ["shops", "loading", "showOnboarding"]),
  },
  methods: {
    setAddress(geocodedAddress, place) {
      this.shopAddress = place;
      console.log(this.shopAddress);
    },
    async createVenue() {
      await this.$store.dispatch("shop/CREATE_SHOP", {
        skipRefresh: true,
        shopInfo: {
          name: {
            normal: this.shopName,
          },
          address: this.shopAddress,
          contact: {
            email: this.userInfo.profile.email,
          },
        },
      });
      this.step += 1;
    },
    refreshScreen() {
      window.location.reload();
    },
  },
};
</script>

<style scoped>
#onboarding {
  width: 100%;
  height: 100%;
  position: absolute;
  background: #14253e;
  z-index: 100;
}
</style>
