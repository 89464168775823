<template>
  <b-tab id="presentation-tab">
    <template #title> <i class="fas fa-image" /> Presentation </template>
    <b-form @submit="saveProduct">
      <b-row>
        <b-col>
          <b-card>
            <b-row>
              <b-col lg="6" sm="12">
                <b-form-group
                  label="Icon"
                  description="Product's identification icon, used in the market place and as the default product image"
                >
                  <b-dropdown
                    boundary
                    menu-class="icons-container"
                    variant="light"
                  >
                    <template #button-content>
                      <span v-if="productInfo && productInfo.icon">
                        <img
                          :src="productInfo.icon.icon"
                          style="height: 30px; margin-right: 5px"
                        />{{ productInfo.icon.name }}
                      </span>
                      <span v-else> Select an Icon </span>
                    </template>
                    <b-dropdown-item
                      v-for="icon of icons"
                      :key="icon._id"
                      @click="productInfo.icon = icon"
                    >
                      <img :src="icon.icon" style="height: 30px" />
                      {{ icon.name }}
                    </b-dropdown-item>
                  </b-dropdown>
                </b-form-group>
              </b-col>
              <b-col lg="6" sm="12">
                <b-form-group
                  v-if="productInfo && productInfo.images"
                  label="Hero Image"
                  description="Add hero image for this product. Note maximum size is 9mb"
                >
                  <b-button variant="light" v-b-modal.image-editor-modal>
                    <b-spinner v-if="loading" />
                    <img
                      v-else
                      :src="productInfo.images.hero"
                      style="width: 100px"
                    />
                    <span
                      v-if="
                        !productInfo.images ||
                        !productInfo.images.hero ||
                        !productInfo.images.hero === ''
                      "
                    >
                      Click to Upload
                    </span>
                  </b-button>
                </b-form-group>
              </b-col>
            </b-row>
          </b-card>
          <b-button
            type="submit"
            pill
            variant="primary"
            class="float-right mt-3"
            size="lg"
            :disabled="loading"
          >
            <b-spinner v-if="loading" />
            <span v-else>Save</span>
          </b-button>
        </b-col>
      </b-row>
    </b-form>
    <imageEditor
      v-if="productInfo.images"
      :image-info="{
        productId: this.product._id,
        type: 'product-hero',
        image: productInfo.images.hero,
      }"
    />
  </b-tab>
</template>

<script>
import { mapGetters } from "vuex";
import imageEditor from "../../imageEditor";

export default {
  name: "presentation",
  components: {
    imageEditor,
  },
  props: ["product"],
  data: () => ({
    productInfo: {},
  }),
  computed: {
    ...mapGetters("product", ["loading"]),
    ...mapGetters("shop", ["shop", "icons"]),
  },
  methods: {
    saveProduct(e) {
      e.preventDefault();
      let productInfo = JSON.parse(JSON.stringify(this.productInfo));
      delete productInfo._id;
      this.$store.dispatch("product/UPDATE_PRODUCT", {
        productId: this.product._id,
        productInfo,
        shopId: this.shop._id,
      });
    },
  },
  watch: {
    product(newVal) {
      if (newVal) {
        this.productInfo = {
          icon: newVal.icon,
          images: {
            hero: "",
            ...newVal.images,
          },
        };
      }
    },
  },
  mounted() {
    if (this.product) {
      this.productInfo = {
        icon: this.product.icon,
        images: {
          hero: "",
          ...this.product.images,
        },
      };
    }
  },
};
</script>

<style scoped>
#presentation-tab::v-deep .dropdown-menu {
  max-height: 300px;
  overflow: scroll;
}
</style>
