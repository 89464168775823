<template>
  <div>
    <b-card>
      <b-col>
        <h5 class="section-title orange-color">
          Shops Database
          <br />
          <small class="dark-blue-color">
            Manage all system's shops and more
          </small>
        </h5>
      </b-col>
    </b-card>
    <b-container>
      <b-input-group class="my-3">
        <b-form-input
          v-model="textSearch"
          placeholder="Type name or email address"
          @keypress="search"
        />
        <b-input-group-append>
          <b-input-group-text>
            <i class="fas fa-search" />
          </b-input-group-text>
        </b-input-group-append>
      </b-input-group>
      <b-card>
        <b-table striped hover :items="shops" :fields="fields">
          <template #cell(_id)="data">
            <b-form-checkbox :id="data.item._id" />
          </template>
          <template #cell(name)="data">
            <strong>{{ data.item.name ? data.item.name.normal : "" }}</strong>
            <br />
            <small
              v-bind:class="{
                'text-danger': data.item.status === 'Deactive',
                'text-success': data.item.status === 'Active',
                'text-info': data.item.status === 'accepting orders',
              }"
              >{{ data.item.status }}</small
            >
          </template>
          <template #cell(createdAt)="data">
            <span>{{ moment(data.item.createdAt).format("DD MMM YYYY") }}</span>
          </template>
          <template #cell(owner)="data">
            <router-link
              :to="`/admin/users/${data.item.owner._id}`"
              tag="div"
              class="pointer"
            >
              <strong>{{ data.item.owner.name.given }}</strong>
              <strong>{{ data.item.owner.name.family }}</strong>
              <br />
              <small>{{ data.item.owner.profile.email }}</small>
              <br />
              <small
                ><strong>{{ data.item.owner.profile.phone }}</strong></small
              >
            </router-link>
          </template>
          <template #cell(transactional)="data">
            <strong>Account</strong> <br />
            <a
              target="_blank"
              :href="`https://dashboard.stripe.com/connect/accounts/${data.item.transactional.stripeId}`"
              tag="div"
              class="pointer"
            >
              <strong>{{ data.item.transactional.stripeId }}</strong>
            </a>
            <br />
            <br />
            <strong>Location</strong>
            <br />
            <a
              target="_blank"
              :href="`https://dashboard.stripe.com/terminal/locations/${data.item.transactional.stripeLocation}`"
              tag="div"
              class="pointer"
            >
              <strong>{{
                data.item.transactional.stripeLocation || "Not created"
              }}</strong>
            </a>
          </template>
          <template #cell(updatedAt)="data">
            <b-dropdown no-caret variant="light">
              <template #button-content>
                <i class="fas fa-ellipsis-v"></i>
              </template>
              <b-dropdown-item
                @click="selectedShop = data.item"
                v-b-modal.shop-delete-modal
              >
                <i class="fas fa-trash" /> Delete
              </b-dropdown-item>
              <b-dropdown-item
                @click="selectedShop = data.item"
                v-b-modal.shop-create-location-modal
              >
                <i class="fas fa-map-marker-alt" /> Set Stripe Location
              </b-dropdown-item>
            </b-dropdown>
          </template>
        </b-table>
        <b-pagination
          class="float-right"
          v-model="currentPage"
          :total-rows="shopsCount"
          :per-page="shopsLimit"
          aria-controls="my-table"
          @change="pageChange"
        ></b-pagination>
      </b-card>
    </b-container>
    <shop-delete-modal :shop="selectedShop" @shop-deleted="search(true)" />
    <shop-create-location-modal :shop="selectedShop" />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import moment from "moment";
import ShopDeleteModal from "../components/shop-delete-modal";
import ShopCreateLocationModal from "@/components/shop-create-location-modal";

const shopsLimit = 15;
export default {
  name: "admin-shops",
  components: { ShopCreateLocationModal, ShopDeleteModal },
  computed: {
    ...mapGetters("shop", ["shops", "shopsCount"]),
    shopsLimit: () => shopsLimit,
  },
  data: () => ({
    fields: [
      {
        key: "name",
        label: "Name",
      },
      {
        key: "createdAt",
        label: "Joined",
      },
      {
        key: "owner",
        label: "Owner",
      },
      {
        key: "transactional",
        label: "Stripe Info",
      },
      {
        key: "updatedAt",
        label: "",
      },
    ],
    textSearch: "",
    currentPage: 1,
    selectedShop: null,
  }),
  methods: {
    moment,
    search(immediate = false) {
      if (this.timeOut) clearTimeout(this.timeOut);
      this.timeOut = setTimeout(
        () => {
          this.$store.dispatch("shop/GET_SHOPS_BY_ADMIN", {
            options: {
              limit: shopsLimit,
              skip: shopsLimit * (this.currentPage - 1),
              sort: "-createdAt",
              populate: { owner: true },
            },
            search: this.textSearch,
          });
        },
        immediate ? 0 : 700
      );
    },
    pageChange() {
      this.search(true);
    },
    deleteShop(shop) {
      console.log(shop);
    },
  },
  mounted() {
    this.$store.dispatch("shop/GET_SHOPS_BY_ADMIN", {
      options: {
        limit: shopsLimit,
        sort: "-createdAt",
        populate: { owner: true },
      },
    });
  },
};
</script>

<style scoped></style>
