<template>
  <b-tab title="Details">
    <b-card>
      <b-form @submit="saveDetails">
        <b-row>
          <b-col sm="12" md="6">
            <b-form-group
              label="Name"
              description="Human friendly name assigned to the kiosk unit"
            >
              <b-form-input
                placeholder="i.e: Lane 1, kiosk 3, etc."
                v-model="kiosk.name"
              />
            </b-form-group>
          </b-col>
          <b-col sm="12" md="6">
            <b-form-group
              label="Unit Key"
              description="Unique key that connects the physical unit with the settings in this dashboard"
            >
              <b-form-input disabled :value="kiosk.kioskKey" />
            </b-form-group>
          </b-col>
          <b-col sm="12" md="6">
            <b-form-group
              label="Device Type"
              description="Sets the device purpose to be Kiosk or just a POS terminal"
            >
              <b-form-select v-model="kiosk.type">
                <b-form-select-option value="kiosk">
                  Self Checkout Kiosk
                </b-form-select-option>
                <b-form-select-option value="POS">
                  POS Terminal
                </b-form-select-option>
              </b-form-select>
            </b-form-group>
          </b-col>
          <b-col sm="12" md="6">
            <b-form-group
              label="EFTPOS terminal provider"
              description="Pick the provider of the EFTPOS terminal/pinpad hardware"
            >
              <b-form-select v-model="kiosk.EFTPOSProvider">
                <b-form-select-option value="smartpay">
                  Smartpay
                </b-form-select-option>
                <b-form-select-option value="verifone">
                  Verifone
                </b-form-select-option>
                <b-form-select-option value="stripe">
                  Stripe
                </b-form-select-option>
                <b-form-select-option value="none"> None </b-form-select-option>
              </b-form-select>
            </b-form-group>
          </b-col>
          <b-col sm="12" md="6">
            <b-form-group
              label="Allow Cash Payment"
              description="Displays a cash payment option on the checkout section to allow customers to pay in cash when order is completed"
            >
              <b-form-checkbox v-model="kiosk.allowPayInCash"
                >Enable</b-form-checkbox
              >
            </b-form-group>
          </b-col>
          <b-col sm="12" md="6">
            <b-form-group
              label="Allow Registered Users Sign In"
              description="Enables the QR scan fast checkout option for your current registered users, allowing them to access loyalty program(if enabled), repeat previous orders, etc."
            >
              <b-form-checkbox v-model="kiosk.allowRegisteredUsersSignIn"
                >Enable</b-form-checkbox
              >
            </b-form-group>
          </b-col>
          <b-col sm="12" md="6">
            <b-form-group
              label="Hide $0 cost prices"
              description='Hides automatically any price displayed in screen. This option can be used along EFTPOS provider "none" for ordering systems without transactions required'
            >
              <b-form-checkbox v-model="kiosk.hidePrices"
                >Enable</b-form-checkbox
              >
            </b-form-group>
          </b-col>
        </b-row>
        <b-button
          class="float-right mt-3"
          variant="primary"
          pill
          size="lg"
          type="submit"
        >
          Save
        </b-button>
      </b-form>
    </b-card>
  </b-tab>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "details",
  props: ["kiosk"],
  computed: {
    ...mapGetters("shop", ["shop"]),
  },
  methods: {
    async saveDetails(e) {
      e.preventDefault();
      this.$store.dispatch("kiosk/UPDATE_KIOSK", {
        kioskId: this.kiosk._id,
        kioskInfo: {
          name: this.kiosk.name,
          EFTPOSProvider: this.kiosk.EFTPOSProvider,
          allowPayInCash: this.kiosk.allowPayInCash,
          type: this.kiosk.type,
          allowRegisteredUsersSignIn: this.kiosk.allowRegisteredUsersSignIn,
        },
        shopId: this.shop._id,
      });
    },
  },
};
</script>

<style scoped></style>
