export default {
  setLoading: (state, loading) => (state.loading = loading),
  setShop: (state, shop) => (state.shop = shop),
  setShops: (state, shops = []) => (state.shops = shops),
  setShopsCount: (state, shopsCount = 0) => (state.shopsCount = shopsCount),
  setIcons: (state, icons = []) => (state.icons = icons),
  setStripeAccount: (state, stripeAccount = []) =>
    (state.stripeAccount = stripeAccount),
  setAccountLink: (state, accountLink) => (state.accountLink = accountLink),
  setPromoCodes: (state, promoCodes) => (state.promoCodes = promoCodes),
  setEmbedKey: (state, embedKey) => (state.embedKey = embedKey),
  setLoyaltyCard: (state, loyaltyCard) => (state.loyaltyCard = loyaltyCard),
  setShowOnboarding: (state, showOnboarding) =>
    (state.showOnboarding = showOnboarding),
};
