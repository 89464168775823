<template>
  <b-row>
    <b-col>
      <b-form-group
        label="Expiry Date"
        description="Pick the expiration date for this promo code"
      >
        <date-range-picker
          singleDatePicker
          :timePicker="true"
          :timePicker24Hour="true"
          v-model="dateRanges"
          :ranges="false"
          @update="setExpiry"
        >
          <template v-slot:input="picker" style="min-width: 100%">
            <i class="far fa-calendar-alt"></i>&nbsp;
            <strong class="report-title">{{
              picker.endDate | moment("DD/MM/YYYY HH:mm")
            }}</strong>
          </template>
        </date-range-picker>
      </b-form-group>
      <b-form-group
        label="Enter the minimum order total amount (in $) in order to allow the redemption of this"
        description="Enter the minimum order total amount (in $) in order to allow the redemption of this promo code."
      >
        <b-input-group prepend="$">
          <b-form-input
            type="number"
            v-model="promoCode.rewardConfig.minimumSpent"
            placeholder="i.e. $10"
          ></b-form-input>
        </b-input-group>
      </b-form-group>
    </b-col>
  </b-row>
</template>

<script>
import DateRangePicker from "vue2-daterange-picker/src/components/DateRangePicker";
import * as moment from "moment";
export default {
  name: "step1",
  components: { DateRangePicker },
  props: ["promoCode"],
  data: () => ({
    dateRanges: {
      startDate: new Date(),
      endDate: new Date(),
    },
  }),
  methods: {
    moment,
    setExpiry(dates) {
      this.promoCode.expiry = dates.endDate;
    },
  },
};
</script>

<style scoped></style>
