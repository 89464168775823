<template>
  <b-tab title="Domain Settings" :active="active">
    <b-form @submit="saveDomainSettings">
      <b-card>
        <b-form-group
          label="Shop Domain Name"
          description='The shop domain name that will identify your venue in our market place. Use "Shop Name + city or suburb" in case of multiple venues with the same name'
        >
          <b-form-input
            v-model="embedKeyInfo.name"
            required
            placeholder="The restaurant, city"
          />
        </b-form-group>
        <b-form-group
          label="Default Shop Domain"
          description="This is the default domain created by On the Go to host you venue embed store"
        >
          <b-form-input
            disabled
            placeholder="i.e: auckland-shop.otg.co.nz"
            :value="defaultDomain"
          />
        </b-form-group>
        <b-form-group
          label="Custom Shop Domain"
          description="Specify a custom domain name you would like to use to host you venue's embed store website"
        >
          <b-form-input
            placeholder="i.e: auckland-shop.mydomain.co.nz"
            v-model="embedKeyInfo.origins[1]"
          />
        </b-form-group>
        <b-form-group>
          <strong
            >In order to allow your custom domain to render your embed store, is
            necessary that you enter into the DNS settings of your domain
            provider a record with the below configuration:</strong
          >
        </b-form-group>
        <b-form-group>
          <b-table-simple bordered>
            <b-thead>
              <b-tr>
                <b-th variant="primary">Record</b-th>
                <b-th variant="primary">Type</b-th>
                <b-th variant="primary">Name</b-th>
                <b-th variant="primary">Value</b-th>
                <b-th variant="primary">TTL</b-th>
              </b-tr>
            </b-thead>
            <b-tbody>
              <tr>
                <td>{{ embedKeyInfo.origins[1] }}</td>
                <td>CNAME</td>
                <td>otg shop</td>
                <td>stores.onthegoapp.co.nz</td>
                <td>120 or 5 minutes</td>
              </tr>
            </b-tbody>
          </b-table-simple>
          <b-alert show>
            <strong>Important</strong>
            <p>
              Once your configuration settings are in your DNS provider, it may
              take up to 72 hours to be effective, they vary depending on your
              DNS provider and location in the world. Usually DNS provider
              within NZ or AU region come back earlier than other places in the
              world.
            </p>
            <p>
              If you need assistance to setup your DNS settings, please feel
              free to contact us at
              <a href="mailto:support@onthegoapp.co.nz"
                >support@onthegoapp.co.nz</a
              >
              to request support assistance.
            </p>
          </b-alert>
        </b-form-group>
      </b-card>
      <b-button
        class="float-right mt-3"
        variant="primary"
        pill
        size="lg"
        type="submit"
      >
        Save
      </b-button>
    </b-form>
  </b-tab>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "domain",
  props: ["embedKey", "active"],
  data: () => ({
    embedKeyInfo: {
      origins: [],
    },
  }),
  computed: {
    ...mapGetters("shop", ["shop"]),
    defaultDomain() {
      if (!this.embedKeyInfo || !this.embedKeyInfo.name) return "";
      let defaultOrigin = this.embedKeyInfo.name
        .replace(/[^a-z0-9\s-]/gi, "")
        .trim()
        .replace(/\s+/g, "-")
        .toLowerCase();
      return `${defaultOrigin}.otgshop.co.nz`;
    },
  },
  methods: {
    saveDomainSettings(event) {
      event.preventDefault();
      this.embedKeyInfo.origins[0] = this.defaultDomain;
      const { origins, name } = this.embedKeyInfo;
      this.$store.dispatch("shop/UPDATE_EMBED_KEY", {
        shopId: this.shop._id,
        embedKeyInfo: {
          origins,
          name,
          embedType: "custom",
        },
      });
    },
  },
  mounted() {
    if (this.embedKey) this.embedKeyInfo = this.embedKey;
  },
  watch: {
    embedKey(newVal) {
      if (newVal) this.embedKeyInfo = newVal;
      if (!newVal.name || newVal.name.trim() === "")
        this.embedKeyInfo.name = this.shop.name.normal;
    },
  },
};
</script>

<style scoped></style>
