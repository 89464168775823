<template>
  <b-table
      id="sales-data"
      head-row-variant="primary"
      foot-row-variant="success"
      ref="selectableTable"
      hover
      small
      borderless
      sticky-header
      style="max-height: calc(100% - 132px)"
      :fields="payoutFields"
      :items="report.data"
      sort-by="name"
  >

    <template #cell(rewardNumber)="data">
      {{ data.item.rewardNumber }}
    </template>

    <template #cell(deliveryInfo)="data">
      {{
        data.item.deliveryInfo
            ? data.item.deliveryInfo.address.formatted_address
            : ""
      }}
    </template>

    <template #cell(amount)="data">
      ${{ (data.item.amount / 100).toFixed(2) }}
    </template>

    <template #cell(status)="data">
      {{ data.item.status.replace("_", " ") }}
    </template>

    <template #cell(created)="data">
      {{ convertUnix(data.item.created).format("DD/MM/YYYY") }}(UTC)
      <br />
      {{
        convertUnix(data.item.created)
            .tz("Pacific/Auckland")
            .format("DD/MM/YYYY")
      }}(Pacific/Auckland)
    </template>

    <template #cell(arrival_date)="data">
      {{ convertUnix(data.item.arrival_date).format("DD/MM/YYYY") }}(UTC)
      <br />
      {{
        convertUnix(data.item.arrival_date)
            .tz("Pacific/Auckland")
            .format("DD/MM/YYYY")
      }}(Pacific/Auckland)
    </template>

    <template #foot(_id)>
      <div></div>
    </template>
  </b-table>
</template>

<script>
import moment from "moment-timezone";

export default {
  name: "payouts",
  props: ['report'],
  data: () => ({
    payoutFields: [
      {
        key: "amount",
        sortable: true,
        label: "Amount",
        stickyColumn: true,
      },
      {
        key: "status",
        sortable: true,
        label: "Status",
        stickyColumn: true,
      },
      {
        key: "created",
        sortable: true,
        label: "Created",
        stickyColumn: true,
      },
      {
        key: "arrival_date",
        sortable: true,
        label: "Arrival Date",
        stickyColumn: true,
      },
      {
        key: "statement_descriptor",
        sortable: true,
        label: "Bank Statement Description",
        stickyColumn: true,
      },
      {
        key: "id",
        label: "",
        stickyColumn: true,
      },
    ],
  }),
  methods: {
    convertUnix(date) {
      return moment.unix(date);
    },
    getCSVData() {
      let csvContent =
          'Amount,Status,Created,"Arrival Date","Bank Statement Description"\r\n';
      for (let r of this.report.data) {
        csvContent += `$${(r.amount / 100).toFixed(2)},"${r.status.replace(
            "_",
            " "
        )}",${moment.unix(r.created).format("DD/MM/YYYY")},${moment
            .unix(r.arrival_date)
            .format("DD/MM/YYYY")},"${r.statement_descriptor}"\r\n`;
      }
      return csvContent
    }
  }
}
</script>

<style scoped>

</style>
