var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"shop-section col-xs-12",class:{ active: _vm.isActive },attrs:{"id":"buzzer"},on:{"click":function($event){return _vm.$emit('activeSection', 'buzzer')}}},[_vm._m(0),(_vm.shopInfo && _vm.shopInfo.buzzerOrTag)?_c('b-col',{staticClass:"option-content col-xs-12"},[_c('b-form',{staticClass:"col-xs-12",on:{"submit":_vm.saveDetails}},[_c('b-row',[_c('b-col',{staticClass:"pt-6",attrs:{"sm":"12","md":"6"}},[_c('b-form-group',{attrs:{"label":"Buzzer Input's Platforms:","description":"This option enables the option to enter a buzzer number on the checkout page of the selected platforms"}},[_c('b-dropdown',{attrs:{"block":"","variant":"primary","toggle-class":"text-capitalize","text":_vm.shopInfo.buzzerOrTag.platforms &&
                _vm.shopInfo.buzzerOrTag.platforms.length > 0
                  ? _vm.shopInfo.buzzerOrTag.platforms.join(' - ')
                  : 'Select Platform Options'}},[_c('b-dropdown-item',{attrs:{"active":_vm.shopInfo.buzzerOrTag.platforms &&
                  _vm.shopInfo.buzzerOrTag.platforms.includes('pos')},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.selectBuzzerPlatforms('pos')}}},[_vm._v("POS")]),_c('b-dropdown-item',{attrs:{"active":_vm.shopInfo.buzzerOrTag.platforms &&
                  _vm.shopInfo.buzzerOrTag.platforms.includes('embed')},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.selectBuzzerPlatforms('embed')}}},[_vm._v("Online Ordering")]),_c('b-dropdown-item',{attrs:{"active":_vm.shopInfo.buzzerOrTag.platforms &&
                  _vm.shopInfo.buzzerOrTag.platforms.includes('kiosk')},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.selectBuzzerPlatforms('kiosk')}}},[_vm._v("Kiosk")])],1)],1)],1),_c('b-col',{staticClass:"pt-6",attrs:{"sm":"12","md":"6"}},[_c('b-form-group',{attrs:{"label":"Buzzer Input's Order Types:","description":"This option enables the option to enter a buzzer number on the checkout page for specific order types on the selected platforms"}},[_c('b-dropdown',{attrs:{"block":"","variant":"primary","toggle-class":"text-capitalize","text":_vm.shopInfo.buzzerOrTag.orderTypes &&
                _vm.shopInfo.buzzerOrTag.orderTypes.length > 0
                  ? _vm.shopInfo.buzzerOrTag.orderTypes.join(' - ')
                  : 'Select Order Type Options'}},[_c('b-dropdown-item',{attrs:{"active":_vm.shopInfo.buzzerOrTag.orderTypes &&
                  _vm.shopInfo.buzzerOrTag.orderTypes.includes('pickup')},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.selectBuzzerOrderTypes('pickup')}}},[_vm._v("Pick Up")]),_c('b-dropdown-item',{attrs:{"active":_vm.shopInfo.buzzerOrTag.orderTypes &&
                  _vm.shopInfo.buzzerOrTag.orderTypes.includes('dine-in')},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.selectBuzzerOrderTypes('dine-in')}}},[_vm._v("Dine In")]),_c('b-dropdown-item',{attrs:{"active":_vm.shopInfo.buzzerOrTag.orderTypes &&
                  _vm.shopInfo.buzzerOrTag.orderTypes.includes('delivery')},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.selectBuzzerOrderTypes('delivery')}}},[_vm._v("Delivery")])],1)],1)],1)],1)],1),_c('b-row',[_c('b-col',[_c('b-button',{staticClass:"float-right mb-1 ml-2",attrs:{"variant":"primary","type":"submit","disabled":_vm.loading,"pill":""},on:{"click":_vm.saveDetails}},[(_vm.loading)?_c('b-spinner'):_c('span',[_vm._v("Save")])],1),_c('b-button',{staticClass:"float-right mb-1",attrs:{"variant":_vm.shopInfo.buzzerOrTag.enabled
              ? 'outline-danger'
              : 'outline-primary',"disabled":_vm.loading,"pill":""},on:{"click":_vm.toggleBuzzer}},[(_vm.loading)?_c('b-spinner'):_c('span',[_vm._v(_vm._s(_vm.shopInfo.buzzerOrTag.enabled ? "Disable" : "Enable")+" Buzzer System")])],1)],1)],1)],1):_vm._e()],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"header"},[_c('i',{staticClass:"fas fa-wifi section-icon"}),_c('h5',{staticClass:"section-title dark-blue-color"},[_vm._v(" Buzzer Settings "),_c('br'),_c('small',[_vm._v("Enable and set your buzzer system settings")])])])
}]

export { render, staticRenderFns }