<template>
  <div style="height: 100%">
    <b-card>
      <b-col>
        <h5 class="section-title orange-color text">
          <span class="text-capitalize"
            >{{ user.name.given }} {{ user.name.family }}</span
          >
          <br />
          <small class="dark-blue-color">
            {{ user.profile.email }} -
            {{ user.profile.phone || "no contact phone available" }}
          </small>
        </h5>
      </b-col>
    </b-card>
    <b-alert :show="userInfo && userInfo.roles.includes('admin')">
      Information about "Order History" and "Loyalty" is related to the current
      shop selected.
    </b-alert>
    <b-tabs style="height: 100%" card fill pills :vertical="!isMobile">
      <template #tabs-end>
        <b-button pill class="float-left mt-3" onclick="history.back()">
          Back
        </b-button>
      </template>
      <contact-details
        :user-info="user"
        v-if="user && user.name && userInfo.roles.includes('admin')"
      />
    </b-tabs>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import ContactDetails from "../components/my-account/contact-details";

export default {
  name: "customer-details",
  components: { ContactDetails },
  props: ["customerId"],
  computed: {
    ...mapGetters(["userInfo"]),
    isMobile() {
      return document.getElementById("app").offsetWidth < 600;
    },
  },
  data: () => ({
    user: null,
  }),
  async mounted() {
    let user = await this.$store.dispatch("customer/GET_USER", {
      userId: this.$route.params.userId,
    });
    this.user = user;
  },
};
</script>

<style scoped></style>
