<template>
  <b-col>
    <b-card>
      <h4>Product Container</h4>
      <b-row>
        <b-col sm="12" md="6">
          <b-form-group label="Background Color">
            <b-input-group>
              <b-form-select
                :options="enumerations"
                v-model="pageLayout.containerColor"
              />
              <b-input-group-append>
                <theme-helper
                  :theme="theme"
                  :value="pageLayout.containerColor"
                  type="color"
                />
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>
      </b-row>
      <hr />
      <h4>Product Name</h4>
      <b-row>
        <b-col sm="12" md="6">
          <b-form-group label="Text Color">
            <b-input-group>
              <b-form-select
                :options="enumerations"
                v-model="pageLayout.productNameTextColor"
              />
              <b-input-group-append>
                <theme-helper
                  :theme="theme"
                  :value="pageLayout.productNameTextColor"
                  type="color"
                />
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col sm="12" md="6">
          <b-form-group label="Font">
            <b-form-select
              :options="enumerations"
              v-model="pageLayout.productNameFont"
            />
          </b-form-group>
        </b-col>
        <b-col sm="12" md="6">
          <b-form-group label="Text Size">
            <b-form-select
              :options="textSizeOptions"
              v-model="pageLayout.productNameTextSize"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <h4>Product Description</h4>
      <b-row>
        <b-col sm="12" md="6">
          <b-form-group label="Text Color">
            <b-input-group>
              <b-form-select
                :options="enumerations"
                v-model="pageLayout.productDescriptionTextColor"
              />
              <b-input-group-append>
                <theme-helper
                  :theme="theme"
                  :value="pageLayout.productDescriptionTextColor"
                  type="color"
                />
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col sm="12" md="6">
          <b-form-group label="Font">
            <b-form-select
              :options="enumerations"
              v-model="pageLayout.productDescriptionFont"
            />
          </b-form-group>
        </b-col>
        <b-col sm="12" md="6">
          <b-form-group label="Text Size">
            <b-form-select
              :options="textSizeOptions"
              v-model="pageLayout.productDescriptionTextSize"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <hr />
      <h4>Option Name</h4>
      <b-row>
        <b-col sm="12" md="6">
          <b-form-group label="Text Color">
            <b-input-group>
              <b-form-select
                :options="enumerations"
                v-model="pageLayout.optionNameTextColor"
              />
              <b-input-group-append>
                <theme-helper
                  :theme="theme"
                  :value="pageLayout.optionNameTextColor"
                  type="color"
                />
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col sm="12" md="6">
          <b-form-group label="Font">
            <b-form-select
              :options="enumerations"
              v-model="pageLayout.optionNameFont"
            />
          </b-form-group>
        </b-col>
        <b-col sm="12" md="6">
          <b-form-group label="Text Size">
            <b-form-select
              :options="textSizeOptions"
              v-model="pageLayout.optionNameTextSize"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <h4>Option Button</h4>
      <b-row>
        <b-col sm="12" md="6">
          <b-form-group label="Font">
            <b-form-select
              :options="enumerations"
              v-model="pageLayout.optionButtonFont"
            />
          </b-form-group>
        </b-col>
        <b-col sm="12" md="6">
          <b-form-group label="Text Size">
            <b-form-select
              :options="textSizeOptions"
              v-model="pageLayout.optionButtonTextSize"
            />
          </b-form-group>
        </b-col>
        <b-col sm="12" md="6">
          <b-form-group label="Button Color">
            <b-input-group>
              <b-form-select
                :options="enumerations"
                v-model="pageLayout.optionButtonSelectedColor"
              />
              <b-input-group-append>
                <theme-helper
                  :theme="theme"
                  :value="pageLayout.optionButtonSelectedColor"
                  type="color"
                />
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col sm="12" md="6">
          <b-form-group label="Shape">
            <b-form-select
              :options="shapeStyles"
              v-model="pageLayout.optionButtonShape"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <hr />
      <h4>Notes</h4>
      <b-row>
        <b-col sm="12" md="6">
          <b-form-group label="Text Color">
            <b-input-group>
              <b-form-select
                :options="enumerations"
                v-model="pageLayout.notesTextColor"
              />
              <b-input-group-append>
                <theme-helper
                  :theme="theme"
                  :value="pageLayout.notesTextColor"
                  type="color"
                />
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col sm="12" md="6">
          <b-form-group label="Font">
            <b-form-select
              :options="enumerations"
              v-model="pageLayout.notesFont"
            />
          </b-form-group>
        </b-col>
        <b-col sm="12" md="6">
          <b-form-group label="Text Size">
            <b-form-select
              :options="textSizeOptions"
              v-model="pageLayout.notesTextSize"
            />
          </b-form-group>
        </b-col>
        <b-col sm="12" md="6">
          <b-form-group label="Background Color">
            <b-input-group>
              <b-form-select
                :options="enumerations"
                v-model="pageLayout.notesBackgroundColor"
              />
              <b-input-group-append>
                <theme-helper
                  :theme="theme"
                  :value="pageLayout.notesBackgroundColor"
                  type="color"
                />
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>
      </b-row>
      <b-button
        variant="primary"
        @click="$emit('saved', { key: 'product', config: { ...pageLayout } })"
        >Save</b-button
      >
    </b-card>
  </b-col>
</template>

<script>
import { enumerations, shapeStyles, textSizeOptions } from "@/utils/constants";
import ThemeHelper from "@/components/layout-editor/themeHelper";

export default {
  name: "productTab",
  components: { ThemeHelper },
  props: ["pageLayout", "theme"],
  data: () => ({
    show: false,
    textSizeOptions,
    enumerations,
    shapeStyles,
  }),
};
</script>

<style scoped></style>
