<template>
  <div class="mobile-top-nav">
    <b-row>
      <b-col cols="3" @click="$emit('open')">
        <i class="fas fa-bars"></i>
      </b-col>
      <b-col cols="6">
        <img
          src="../assets/host_logo_Landscape_green.png"
          style="height: 30px"
        />
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  name: "mobile-nav",
};
</script>

<style scoped>
.mobile-top-nav {
  width: 100vw;
  z-index: 20;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  padding: 5px;
  background-color: #14253e;
  color: white;
  font-size: 30px;
  text-align: center;
}

@media only screen and (min-width: 600px) {
  .mobile-top-nav {
    display: none;
  }
}
</style>
