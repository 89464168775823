<template>
  <div
    id="report-container"
    class="col-xs-12 npsds"
    style="padding-top: 0px; height: 100%"
  >
    <b-card>
      <b-row align-h="start">
        <b-col cols="12">
          <h5 class="section-title orange-color">
            Reports
            <br />
            <small class="dark-blue-color">
              Generate your venue's sales, products stock and payout reports
            </small>
          </h5>
        </b-col>

        <b-col cols="12">
          <b-row>
            <b-col sm="12" md="3" class="p-2">
              <b-input-group>
                <template #prepend>
                  <i
                    class="far fa-calendar-alt"
                    style="margin: 0px 12px 0px 5px; line-height: 2.2"
                  />
                </template>
                <date-range-picker
                  ref="picker"
                  class="full-width-selector"
                  v-model="dateRange"
                  :ranges="dateRanges"
                  opens="right"
                  :showRangeLabel="true"
                  :append-to-body="true"
                >
                  <template v-slot:input="picker" style="min-width: 100%">
                    <strong class="report-title"
                      >{{ picker.startDate | moment("Do MMM") }} -
                      {{ picker.endDate | moment("Do MMM") }}</strong
                    >
                  </template>
                </date-range-picker>
              </b-input-group>
            </b-col>

            <b-col sm="12" md="3" class="p-2">
              <b-input-group>
                <template #prepend>
                  <i
                    class="fas fa-chart-bar"
                    style="margin: 0px 5px; line-height: 2.2"
                  ></i
                  >&nbsp;
                </template>
                <b-form-select
                  :options="reportTypes"
                  v-model="reportType"
                  value-field="value"
                ></b-form-select>
              </b-input-group>
            </b-col>

            <b-col sm="12" md="3" class="p-2">
              <b-input-group v-if="reportType === 'sales'">
                <template #prepend>
                  <i
                    class="fas fa-info-circle"
                    style="margin: 0px 5px; line-height: 2.2"
                  ></i
                  >&nbsp;
                </template>
                <b-form-select :options="orderStatuses" v-model="orderStatus">
                </b-form-select>
              </b-input-group>
            </b-col>

            <b-col sm="12" md="3" class="p-2">
              <b-dropdown
                variant="outline-secondary"
                text="Export as"
                class="float-right"
              >
                <b-dropdown-item @click="getReportCSV">CSV</b-dropdown-item>
              </b-dropdown>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-card>
    <b-col class="mt-3">
      <div v-if="loading" style="width: 100%" class="text-center">
        <b-spinner />
      </div>
      <orders
        ref="orderReport"
        :report="report"
        v-if="
          !loading &&
          report &&
          report.data &&
          report.data.length > 0 &&
          reportType === 'sales'
        "
      />
      <sales-by-sales
        ref="salesBySalesReport"
        :report="report"
        v-if="
          !loading &&
          report &&
          report.data &&
          report.data.length > 0 &&
          reportType === 'salesBySales'
        "
      />
      <payouts
        ref="payoutReport"
        :report="report"
        v-if="
          !loading &&
          report &&
          report.data &&
          report.data.length > 0 &&
          reportType === 'payouts'
        "
      />
      <stock
        ref="stockReport"
        :report="report"
        v-if="
          !loading &&
          report &&
          report.data &&
          report.data.length > 0 &&
          reportType === 'stock'
        "
      />
      <categories
        ref="categoriesReport"
        :report="report"
        v-if="
          !loading &&
          report &&
          report.data &&
          report.data.length > 0 &&
          reportType === 'categories'
        "
      />
      <h4
        class="orange-color text-center"
        v-if="!report || !report.data || report.data.length < 1"
      >
        No records found for selected report type
      </h4>
    </b-col>
    <b-row> </b-row>
  </div>
</template>

<script>
import DateRangePicker from "vue2-daterange-picker/src/components/DateRangePicker";
import moment from "moment-timezone";
import { mapGetters } from "vuex";
import { beforeRouteEnter } from "../router";
import Stock from "@/components/reports/stock";
import Payouts from "@/components/reports/payouts";
import Orders from "@/components/reports/orders";
import Categories from "@/components/reports/categories";
import SalesBySales from "@/components/reports/sales-by-sales.vue";

export default {
  name: "reports",
  components: {
    SalesBySales,
    Categories,
    Orders,
    Payouts,
    Stock,
    DateRangePicker,
  },
  data: () => ({
    isReportTypeOpen: false,
    orderStatus: "all",
    isOrderStatusOpen: false,
    isExportAsOpen: false,
    exportType: null,
    exportTypes: ["CSV"],
    reportType: null,
    reportTypes: [
      {
        text: "Orders",
        value: "sales",
      },
      { text: "Sales By Sales", value: "salesBySales" },
      {
        text: "Products Stock Report",
        value: "stock",
      },
      {
        text: "Payouts",
        value: "payouts",
      },
      {
        text: "Categories",
        value: "categories",
      },
    ],
    orderStatuses: [
      {
        text: "All",
        value: "all",
      },
      {
        text: "Pending",
        value: "pending",
      },
      {
        text: "Accepted",
        value: "accepted",
      },
      {
        text: "Rejected",
        value: "rejected",
      },
      {
        text: "Completed",
        value: "completed",
      },
    ],
    dateRange: {
      startDate: moment().toDate(),
      endDate: moment().endOf("day").toDate(),
    },
    dateRanges: {
      Yesterday: [
        moment().subtract(1, "days").startOf("day").toDate(),
        moment().subtract(1, "days").endOf("day").toDate(),
      ],
      "This Week": [
        moment().startOf("isoWeek").toDate(),
        moment().endOf("isoWeek").toDate(),
      ],
      "Previous Week": [
        moment().subtract(1, "weeks").startOf("isoWeek").toDate(),
        moment().subtract(1, "weeks").endOf("isoWeek").toDate(),
      ],
      "This Quarter": [
        moment().subtract(1, "weeks").startOf("isoWeek").toDate(),
        moment().endOf("isoWeek").toDate(),
      ],
      "Previous Quarter": [
        moment().subtract(3, "weeks").startOf("isoWeek").toDate(),
        moment().subtract(2, "weeks").endOf("isoWeek").toDate(),
      ],
      "This Month": [
        moment().startOf("month").toDate(),
        moment().endOf("month").toDate(),
      ],
      "Previous Month": [
        moment().subtract(1, "month").startOf("month").toDate(),
        moment().subtract(1, "month").endOf("month").toDate(),
      ],
    },
  }),
  computed: {
    ...mapGetters("reports", ["report", "loading"]),
    ...mapGetters("shop", ["shop"]),
  },
  methods: {
    moment,
    getReportCSV() {
      let csvContent = "data:text/csv;charset=utf-8,\n";
      let { report, reportType } = this;
      switch (reportType) {
        case "sales":
          csvContent += this.$refs.orderReport.getCSVData();
          break;
        case "stock":
          csvContent += this.$refs.stockReport.getCSVData();
          break;
        case "payouts":
          csvContent += this.$refs.payoutReport.getCSVData();
          break;
        case "categories":
          csvContent += this.$refs.categoriesReport.getCSVData();
          break;
        case "salesBySales":
          csvContent += this.$refs.salesBySalesReport.getCSVData();
          break;
      }

      console.log(csvContent);
      var encodedUri = encodeURI(csvContent);
      var link = document.createElement("a");
      link.setAttribute("href", encodedUri);
      link.setAttribute(
        "download",
        `${this.shop.name.normal}_${reportType}_${report.startDate}_to_${report.endDate}.csv`
      );
      document.body.appendChild(link); // Required for FF

      link.click();
    },
    loadReport() {
      let start = moment(this.dateRange.startDate)
        .startOf("day")
        .utc()
        .toDate();
      let end = moment(this.dateRange.endDate).endOf("day").utc().toDate();

      switch (this.reportType) {
        case "categories":
        case "salesBySales":
        case "sales":
          this.$store.dispatch("reports/GET_MERCHANT_WEEKLY", {
            shopId: this.shop._id,
            start,
            end,
            status: this.orderStatus === "all" ? undefined : this.orderStatus,
          });
          break;
        case "stock":
          this.$store.dispatch("reports/GET_PRODUCTS_STOCK", {
            shopId: this.shop._id,
            start,
            end,
            status: this.orderStatus === "all" ? undefined : this.orderStatus,
          });
          break;
        case "payouts":
          this.$store.dispatch("reports/GET_MERCHANT_PAYOUTS", {
            shopId: this.shop._id,
            start,
            end,
          });
          break;
      }
    },
  },
  mounted() {
    this.reportType = this.reportTypes[0].value;
    this.orderStatus = this.orderStatuses[0].value;
  },
  watch: {
    exportType(newVal) {
      if (!newVal) return;
      this.getReportCSV();
      this.exportType = null;
    },
    dateRange(newVal) {
      if (newVal && this.shop) {
        this.loadReport();
      }
    },
    reportType(newVal) {
      if (newVal && this.shop) this.loadReport();
    },
    shop(newVal) {
      if (newVal) {
        this.loadReport();
      }
    },
    report(newVal) {
      if (!newVal) return;
      console.log(newVal);
    },
  },
  beforeRouteEnter,
};
</script>

<style scoped>
.full-width-selector {
  width: calc(100% - 34px);
  display: inline-block;
}
#sales-data::v-deep tfoot,
#sales-data::v-deep tfoot th,
#sales-data::v-deep tfoot td {
  position: -webkit-sticky;
  position: sticky;
  bottom: 0;
  z-index: 4;
}
</style>
