<template>
  <div
    id="opening-hours"
    class="shop-section col-xs-12"
    v-bind:class="{ active: isActive }"
    @click="$emit('activeSection', 'opening-hours')"
  >
    <div class="header">
      <i class="fas fa-business-time section-icon" />

      <h5 class="section-title dark-blue-color">
        Opening Hours
        <br />
        <small>Store's trading hours</small>
      </h5>
    </div>
    <b-col
      class="option-content col-xs-12"
      v-if="shopInfo && shopInfo.openingHours"
    >
      <b-form class="form-horizontal col-xs-12" @submit="saveDetails">
        <div class="form-group col-xs-12 npsds">
          <div class="checkbox">
            <label>
              <input
                type="checkbox"
                v-model="shopInfo.openingHours.twentyFourSeven"
              />
              Is this shop open 24/7?
            </label>
          </div>
        </div>
        <b-row v-if="!shopInfo.openingHours.twentyFourSeven">
          <b-col
            md="12"
            lg="6"
            class="mt-2"
            v-bind:key="day"
            v-for="day of serviceDays"
          >
            <div class="checkbox">
              <label class="text-capitalize">
                <input
                  type="checkbox"
                  :checked="shopInfo.openingHours.openingDays.includes(day)"
                  @click="toggleOpeningDay(day)"
                />
                open on {{ day !== "holiday" ? day : "Public " + day }}?</label
              >
            </div>
            <div class="col-xs-12 npsds">
              <div style="width: 40px; display: inline-block" class="m-1">
                <select
                  class="form-control hide-arrow"
                  v-model="shopInfo.openingHours[`${day}From`].hour"
                  :disabled="!shopInfo.openingHours.openingDays.includes(day)"
                >
                  <option :key="index" v-for="index in 24" :value="index - 1">
                    <span v-if="index - 1 < 10">0</span>{{ index - 1 }}
                  </option>
                </select>
              </div>
              :
              <div style="width: 40px; display: inline-block" class="m-1">
                <select
                  class="form-control hide-arrow"
                  v-model="shopInfo.openingHours[`${day}From`].min"
                  :disabled="!shopInfo.openingHours.openingDays.includes(day)"
                >
                  <option :key="index" v-for="index in 60" :value="index - 1">
                    <span v-if="index - 1 < 10">0</span>{{ index - 1 }}
                  </option>
                </select>
              </div>
              To
              <div style="width: 40px; display: inline-block" class="m-1">
                <select
                  class="form-control hide-arrow"
                  v-model="shopInfo.openingHours[`${day}To`].hour"
                  :disabled="!shopInfo.openingHours.openingDays.includes(day)"
                >
                  <option :key="index" v-for="index in 24" :value="index - 1">
                    <span v-if="index - 1 < 10">0</span>{{ index - 1 }}
                  </option>
                </select>
              </div>
              :
              <div style="width: 40px; display: inline-block" class="m-1">
                <select
                  class="form-control hide-arrow"
                  v-model="shopInfo.openingHours[`${day}To`].min"
                  :disabled="!shopInfo.openingHours.openingDays.includes(day)"
                >
                  <option :key="index" v-for="index in 60" :value="index - 1">
                    <span v-if="index - 1 < 10">0</span>{{ index - 1 }}
                  </option>
                </select>
              </div>
              <div class="checkbox overnight">
                <label>
                  <input
                    type="checkbox"
                    v-model="shopInfo.openingHours[`${day}To`].isOvernight"
                  />
                  venue closes overnight?
                </label>
              </div>
            </div>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-button
              variant="primary"
              type="submit"
              class="float-right mb-1"
              :disabled="loading"
              pill
            >
              <b-spinner v-if="loading" />
              <span v-else>Save</span>
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </b-col>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "opening-hours",
  props: ["isActive"],
  data: () => ({
    serviceDays: [
      "monday",
      "tuesday",
      "wednesday",
      "thursday",
      "friday",
      "saturday",
      "sunday",
      "holiday",
    ],
    shopInfo: null,
  }),
  computed: {
    ...mapGetters("shop", ["shop", "loading"]),
  },
  methods: {
    toggleOpeningDay(day) {
      if (this.shopInfo.openingHours.openingDays.includes(day)) {
        this.shopInfo.openingHours.openingDays =
          this.shopInfo.openingHours.openingDays.filter((d) => d !== day);
      } else {
        this.shopInfo.openingHours.openingDays.push(day);
      }
    },
    async saveDetails(e) {
      e.preventDefault();
      const { openingHours } = this.shopInfo;
      this.$store.dispatch("shop/UPDATE_SHOP", {
        shopId: this.shop._id,
        shopInfo: { openingHours },
      });
    },
  },
  mounted() {
    this.shopInfo = JSON.parse(JSON.stringify(this.shop));
  },
};
</script>

<style scoped>
.hide-arrow {
  -webkit-appearance: none;
  -moz-appearance: none;
  text-align: center;
  padding: 5px;
  text-indent: 1px;
  text-overflow: "";
}
.overnight {
  margin-left: 15px;
  margin-top: 10px;
}
</style>
