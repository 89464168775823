import actions from "./actions";
import getters from "./getters";
import mutations from "./mutations";

const state = {
  shop: null,
  stripeAccount: null,
  accountLink: null,
  shops: [],
  shopsCount: 0,
  status: null,
  error: null,
  icons: [],
  loading: false,
  promoCodes: [],
  embedKey: null,
  loyaltyCard: null,
  showOnboarding: false,
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
