<template>
  <div id="app">
    <onboarding v-if="userToken" />
    <navbar v-if="userToken" :open="isMobileNavOpen"></navbar>
    <mobile-nav @open="setOpen" />
    <div
      class="view-container"
      v-bind:class="{
        'signed-in': userToken,
        'full-width': !showNavBar,
      }"
      @click="isMobileNavOpen = false"
    >
      <!--      <ShopWarnings v-if="!$route.path.includes('/admin/')" />-->
      <transition name="slide-left">
        <router-view></router-view>
      </transition>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import navbar from "./components/navbar";
//import ShopWarnings from "./components/shop-warnings";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
import Onboarding from "./components/onboarding";
import MobileNav from "@/components/mobile-nav";

export default {
  name: "App",
  components: {
    MobileNav,
    Onboarding,
    navbar,
    //ShopWarnings,
  },
  data: () => ({
    showNavBar: false,
    isMobileNavOpen: false,
  }),
  computed: {
    ...mapGetters(["userToken"]),
    ...mapGetters("shop", ["shop"]),
  },
  mounted() {
    if (this.userToken) {
      if (!this.$route.path.includes("/admin/"))
        this.$store.dispatch("shop/GET_SHOPS_BY_USER", {
          options: {
            limit: 1000,
            sort: "name.normal",
          },
        });
      this.$store.dispatch("GET_USER_INFO", {});
      this.$store.dispatch("GET_PLATFORM_PLANS", {});
    }
    this.showNavBar = ![
      "/login",
      "/",
      "/signup",
      "/password-reset",
      "/set-new-password",
    ].includes(this.$route.path);
    if (typeof window.Chart !== "undefined") {
      window.Chart.defaults.LineWithLine = window.Chart.defaults.line;
      window.Chart.controllers.LineWithLine =
        window.Chart.controllers.line.extend({
          draw: function (ease) {
            window.Chart.controllers.line.prototype.draw.call(this, ease);

            if (
              this.chart.tooltip._active &&
              this.chart.tooltip._active.length
            ) {
              var activePoint = this.chart.tooltip._active[0],
                ctx = this.chart.ctx,
                x = activePoint.tooltipPosition().x,
                topY = this.chart.legend.bottom,
                bottomY = this.chart.chartArea.bottom;

              // draw line
              ctx.save();
              ctx.beginPath();
              ctx.moveTo(x, topY);
              ctx.lineTo(x, bottomY);
              ctx.lineWidth = 2;
              ctx.strokeStyle = "#07C";
              ctx.stroke();
              ctx.restore();
            }
          },
        });
    }
  },
  methods: {
    setOpen() {
      this.isMobileNavOpen = !this.isMobileNavOpen;
    },
  },
  watch: {
    $route() {
      this.showNavBar = ![
        "/login",
        "/",
        "/signup",
        "/password-reset",
        "/set-new-password",
      ].includes(this.$route.path);
    },
  },
};
</script>

<style>
#app {
  height: 100%;
}

.view-container {
  position: absolute;
  top: 0px;
  right: 0px;
  height: 100%;
  overflow: scroll;
  background: #f9f4e9;
  -webkit-transition: all ease-in-out 500ms;
  -moz-transition: all ease-in-out 500ms;
  -ms-transition: all ease-in-out 500ms;
  -o-transition: all ease-in-out 500ms;
  transition: all ease-in-out 500ms;
}

@media only screen and (max-width: 600px) {
  .view-container {
    width: 100%;
    padding-top: 45px;
  }
}

.view-container.full-width {
  width: 100%;
}

.view-container.menu-shrinked {
  width: calc(100% - 55px);
  left: 55px;
}

@media only screen and (min-width: 601px) {
  .view-container {
    width: calc(100% - 220px);
  }
  .signed-in {
    width: calc(100% - 220px);
    position: absolute;
    right: 0;
  }
}

.orange-color {
  color: #ff6947 !important;
}
.orange-color:hover {
  text-decoration: none;
  color: #ff6969 !important;
}

.dark-blue-color {
  color: #2e4369;
  text-decoration: none;
}

#top-nav {
  width: 100%;
  height: 60px;
  background: white;
  border-bottom: 1px solid #e2e6e8;
  padding: 15px;
}

#top-nav h1,
#top-nav h2,
#top-nav h3,
#top-nav h4,
#top-nav h5,
#top-nav h6 {
  margin: 0;
}
</style>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
