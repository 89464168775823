<template>
  <b-col>
    <b-card>
      <h4>Container</h4>
      <b-row>
        <b-col sm="12" md="6">
          <b-form-group label="Background Color">
            <b-input-group>
              <b-form-select
                :options="enumerations"
                v-model="pageLayout.containerColor"
              />
              <b-input-group-append>
                <theme-helper
                  :theme="theme"
                  :value="pageLayout.containerColor"
                  type="color"
                />
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>
      </b-row>
      <hr />
      <h4>Title</h4>
      <b-row>
        <b-col sm="12" md="6">
          <b-form-group label="Color">
            <b-input-group>
              <b-form-select
                :options="enumerations"
                v-model="pageLayout.titleTextColor"
              />
              <b-input-group-append>
                <theme-helper
                  :theme="theme"
                  :value="pageLayout.titleTextColor"
                  type="color"
                />
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col sm="12" md="6">
          <b-form-group label="Font">
            <b-form-select
              :options="enumerations"
              v-model="pageLayout.titleFont"
            />
          </b-form-group>
        </b-col>
        <b-col sm="12" md="6">
          <b-form-group label="Size">
            <b-form-select
              :options="textSizeOptions"
              v-model="pageLayout.titleTextSize"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <hr />
      <h4>Subtitle</h4>
      <b-row>
        <b-col sm="12" md="6">
          <b-form-group label="Color">
            <b-input-group>
              <b-form-select
                :options="enumerations"
                v-model="pageLayout.subtitleTextColor"
              />
              <b-input-group-append>
                <theme-helper
                  :theme="theme"
                  :value="pageLayout.subtitleTextColor"
                  type="color"
                />
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col sm="12" md="6">
          <b-form-group label="Font">
            <b-form-select
              :options="enumerations"
              v-model="pageLayout.subtitleFont"
            />
          </b-form-group>
        </b-col>
        <b-col sm="12" md="6">
          <b-form-group label="Size">
            <b-form-select
              :options="textSizeOptions"
              v-model="pageLayout.subtitleTextSize"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <hr />
      <h4>Text</h4>
      <b-row>
        <b-col sm="12" md="6">
          <b-form-group label="Color">
            <b-input-group>
              <b-form-select
                :options="enumerations"
                v-model="pageLayout.textColor"
              />
              <b-input-group-append>
                <theme-helper
                  :theme="theme"
                  :value="pageLayout.textColor"
                  type="color"
                />
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col sm="12" md="6">
          <b-form-group label="Font">
            <b-form-select :options="enumerations" v-model="pageLayout.font" />
          </b-form-group>
        </b-col>
        <b-col sm="12" md="6">
          <b-form-group label="Size">
            <b-form-select
              :options="textSizeOptions"
              v-model="pageLayout.textSize"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <hr />
      <h4>Increase Buttons</h4>
      <b-row>
        <b-col sm="12" md="6">
          <b-form-group label="Background Color">
            <b-input-group>
              <b-form-select
                :options="enumerations"
                v-model="pageLayout.increaseButtonsBackgroundColor"
              />
              <b-input-group-append>
                <theme-helper
                  :theme="theme"
                  :value="pageLayout.increaseButtonsBackgroundColor"
                  type="color"
                />
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col sm="12" md="6">
          <b-form-group label="Icons Color">
            <b-input-group>
              <b-form-select
                :options="enumerations"
                v-model="pageLayout.increaseButtonsIconsColor"
              />
              <b-input-group-append>
                <theme-helper
                  :theme="theme"
                  :value="pageLayout.increaseButtonsIconsColor"
                  type="color"
                />
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col sm="12" md="6">
          <b-form-group label="Border Color">
            <b-input-group>
              <b-form-select
                :options="enumerations"
                v-model="pageLayout.increaseButtonsBorderColor"
              />
              <b-input-group-append>
                <theme-helper
                  :theme="theme"
                  :value="pageLayout.increaseButtonsBorderColor"
                  type="color"
                />
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>
      </b-row>
      <hr />
      <h4>Primary Button</h4>
      <b-row>
        <b-col sm="12" md="6">
          <b-form-group label="Text Color">
            <b-input-group>
              <b-form-select
                :options="enumerations"
                v-model="pageLayout.primaryButtonTextColor"
              />
              <b-input-group-append>
                <theme-helper
                  :theme="theme"
                  :value="pageLayout.primaryButtonTextColor"
                  type="color"
                />
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col sm="12" md="6">
          <b-form-group label="Font">
            <b-form-select
              :options="enumerations"
              v-model="pageLayout.primaryButtonFont"
            />
          </b-form-group>
        </b-col>
        <b-col sm="12" md="6">
          <b-form-group label="Text Size">
            <b-form-select
              :options="textSizeOptions"
              v-model="pageLayout.primaryButtonTextSize"
            />
          </b-form-group>
        </b-col>
        <b-col sm="12" md="6">
          <b-form-group label="Background Color">
            <b-input-group>
              <b-form-select
                :options="enumerations"
                v-model="pageLayout.primaryButtonBackgroundColor"
              />
              <b-input-group-append>
                <theme-helper
                  :theme="theme"
                  :value="pageLayout.primaryButtonBackgroundColor"
                  type="color"
                />
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col sm="12" md="6">
          <b-form-group label="Border Color">
            <b-input-group>
              <b-form-select
                :options="enumerations"
                v-model="pageLayout.primaryButtonBorderColor"
              />
              <b-input-group-append>
                <theme-helper
                  :theme="theme"
                  :value="pageLayout.primaryButtonBorderColor"
                  type="color"
                />
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col sm="12" md="6">
          <b-form-group label="Shape">
            <b-form-select
              :options="shapeStyles"
              v-model="pageLayout.primaryButtonShape"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <hr />
      <h4>Secondary Button</h4>
      <b-row>
        <b-col sm="12" md="6">
          <b-form-group label="Text Color">
            <b-input-group>
              <b-form-select
                :options="enumerations"
                v-model="pageLayout.secondaryButtonTextColor"
              />
              <b-input-group-append>
                <theme-helper
                  :theme="theme"
                  :value="pageLayout.secondaryButtonTextColor"
                  type="color"
                />
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col sm="12" md="6">
          <b-form-group label="Font">
            <b-form-select
              :options="enumerations"
              v-model="pageLayout.secondaryButtonFont"
            />
          </b-form-group>
        </b-col>
        <b-col sm="12" md="6">
          <b-form-group label="Text Size">
            <b-form-select
              :options="textSizeOptions"
              v-model="pageLayout.secondaryButtonTextSize"
            />
          </b-form-group>
        </b-col>
        <b-col sm="12" md="6">
          <b-form-group label="Background Color">
            <b-input-group>
              <b-form-select
                :options="enumerations"
                v-model="pageLayout.secondaryButtonBackgroundColor"
              />
              <b-input-group-append>
                <theme-helper
                  :theme="theme"
                  :value="pageLayout.secondaryButtonBackgroundColor"
                  type="color"
                />
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col sm="12" md="6">
          <b-form-group label="Border Color">
            <b-input-group>
              <b-form-select
                :options="enumerations"
                v-model="pageLayout.secondaryButtonBorderColor"
              />
              <b-input-group-append>
                <theme-helper
                  :theme="theme"
                  :value="pageLayout.secondaryButtonBorderColor"
                  type="color"
                />
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col sm="12" md="6">
          <b-form-group label="Shape">
            <b-form-select
              :options="shapeStyles"
              v-model="pageLayout.secondaryButtonShape"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-button
        variant="primary"
        @click="$emit('saved', { key: 'actionbar', config: { ...pageLayout } })"
        >Save</b-button
      >
    </b-card>
  </b-col>
</template>

<script>
import { enumerations, shapeStyles, textSizeOptions } from "@/utils/constants";
import ThemeHelper from "@/components/layout-editor/themeHelper";

export default {
  name: "actionbarTab",
  components: { ThemeHelper },
  props: ["pageLayout", "theme"],
  data: () => ({
    show: false,
    textSizeOptions,
    enumerations,
    shapeStyles,
  }),
};
</script>

<style scoped></style>
