<template>
  <div
    id="presentation"
    class="shop-section col-xs-12"
    v-bind:class="{ active: isActive }"
    @click="$emit('activeSection', 'presentation')"
  >
    <div class="header">
      <i class="fas fa-images section-icon" />

      <h5 class="section-title dark-blue-color">
        Shop Profile image
        <br />
        <small>Store's presentation settings</small>
      </h5>
    </div>
    <b-col class="option-content">
      <b-form id="presentation-form" @submit="saveDetails" v-if="shop">
        <b-row>
          <b-col sm="12" md="6">
            <b-form-group
              label="Hero Image"
              description="Click the image above to select the cover picture for your shop in our market place and your embed store"
            >
              <b-button variant="light" v-b-modal.image-editor-modal>
                <b-spinner v-if="loading" />
                <img v-else :src="shopInfo.images.hero" style="width: 100px" />
                <span
                  v-if="
                    !shopInfo.images ||
                    !shopInfo.images.hero ||
                    !shopInfo.images.hero === ''
                  "
                >
                  Click to Upload
                </span>
              </b-button>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col sm="12" md="6">
            <b-button
              variant="primary"
              type="submit"
              class="float-right mb-1"
              :disabled="loading"
              pill
            >
              <b-spinner v-if="loading" />
              <span v-else>Save</span>
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </b-col>
    <imageEditor
      :image-info="{
        type: 'shop-hero',
        image: shopInfo.images.hero,
      }"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import ImageEditor from "../imageEditor";

export default {
  name: "shop-presentation",
  props: ["isActive"],
  components: {
    ImageEditor,
  },
  data: () => ({
    shopInfo: { images: {} },
  }),
  computed: {
    ...mapGetters("shop", ["shop", "loading"]),
  },
  methods: {
    saveDetails(e) {
      e.preventDefault();
      let shopInfo = {
        images: { ...this.shopInfo.images },
      };
      this.$emit("saveChanges", shopInfo);
    },
  },
  mounted() {
    this.shopInfo = {
      images: {},
      ...JSON.parse(JSON.stringify(this.shop)),
    };
  },
  watch: {
    shop(newVal) {
      if (newVal) {
        this.shopInfo = {
          images: {},
          ...JSON.parse(JSON.stringify(newVal)),
        };
      }
    },
  },
};
</script>

<style scoped></style>
