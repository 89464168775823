import axios from "axios";
import Vue from "vue";
import moment from "moment";

export default {
  GET_MERCHANT_WEEKLY: async (
    { commit, rootState },
    { shopId, start, end, status, options = { sort: "-createdAt" } }
  ) => {
    commit("setLoading", true);
    try {
      const { data } = await axios({
        url: `${rootState.apiAddress}/api/shops/${shopId}/reports`,
        params: {
          start,
          end,
          status,
          options: JSON.stringify(options),
        },
        headers: {
          Authorization: `Bearer ${rootState.userToken}`,
        },
      });
      commit("setReport", data);
    } catch (err) {
      const message =
        err.response && err.response.data && err.response.data.message
          ? err.response.data.message
          : rootState.defaultError;
      Vue.toasted.global
        .showServerError({
          message,
        })
        .goAway(4000);
    }
    commit("setLoading", false);
  },
  GET_PRODUCTS_STOCK: async (
    { commit, rootState },
    { shopId, start, end, status, options }
  ) => {
    commit("setLoading", true);
    try {
      const { data } = await axios({
        url: `${rootState.apiAddress}/api/shops/${shopId}/products-stock-report`,
        params: {
          start,
          end,
          status,
          options: JSON.stringify(options),
        },
        headers: {
          Authorization: `Bearer ${rootState.userToken}`,
        },
      });
      commit("setReport", data);
    } catch (err) {
      const message =
        err.response && err.response.data && err.response.data.message
          ? err.response.data.message
          : rootState.defaultError;
      Vue.toasted.global
        .showServerError({
          message,
        })
        .goAway(4000);
    }
    commit("setLoading", false);
  },
  GET_COVID19: async (
    { commit, rootState },
    { shopId, start, end, options }
  ) => {
    commit("setLoading", true);
    try {
      const { data } = await axios({
        url: `${rootState.apiAddress}/api/shops/${shopId}/covid19-report`,
        params: {
          start,
          end,
          options: JSON.stringify(options),
        },
        headers: {
          Authorization: `Bearer ${rootState.userToken}`,
        },
      });
      commit("setReport", data);
    } catch (err) {
      const message =
        err.response && err.response.data && err.response.data.message
          ? err.response.data.message
          : rootState.defaultError;
      Vue.toasted.global
        .showServerError({
          message,
        })
        .goAway(4000);
    }
    commit("setLoading", false);
  },
  GET_MERCHANT_PAYOUTS: async (
    { commit, rootState },
    { shopId, start, end, options }
  ) => {
    commit("setLoading", true);
    try {
      const { data } = await axios({
        url: `${rootState.apiAddress}/api/shops/${shopId}/payouts-report`,
        params: {
          start,
          end,
          options: JSON.stringify(options),
        },
        headers: {
          Authorization: `Bearer ${rootState.userToken}`,
        },
      });
      commit("setReport", data.payouts);
    } catch (err) {
      const message =
        err.response && err.response.data && err.response.data.message
          ? err.response.data.message
          : rootState.defaultError;
      Vue.toasted.global
        .showServerError({
          message,
        })
        .goAway(4000);
    }
    commit("setLoading", false);
  },
  GET_TODAY_SALES_PER_HOUR: async (
    { commit, dispatch },
    { shopId, options }
  ) => {
    let start = moment().startOf("day").toDate();
    let end = moment().endOf("day").toDate();
    let todaySalesPerHour = await dispatch("GET_SALES_PER_HOUR", {
      shopId,
      start,
      end,
      options,
    });
    commit("setTodaySalesPerHour", todaySalesPerHour.result);
  },
  GET_PERIOD_OVERVIEW_SALES: async (
    { commit, dispatch },
    { shopId, start, end, options }
  ) => {
    let overViewSalesReport = await dispatch("GET_SALES_PER_HOUR", {
      shopId,
      start,
      end,
      options,
    });
    commit("setOverViewSalesReport", overViewSalesReport.result);
  },
  GET_PERIOD_TODAY_VS_YESTERDAY_OVERVIEW_SALES: async (
    { commit, dispatch },
    { shopId, start, end, options }
  ) => {
    let todayVSYesterdayOverViewSalesReport = await dispatch(
      "GET_SALES_PER_HOUR",
      {
        shopId,
        start,
        end,
        options,
      }
    );
    commit(
      "setTodayVSYesterdayOverViewSalesReport",
      todayVSYesterdayOverViewSalesReport.result
    );
    console.log(start, end);
    console.log(todayVSYesterdayOverViewSalesReport);
  },
  GET_SALES_PER_HOUR: async (
    { rootState, commit },
    { shopId, start, end, options }
  ) => {
    commit("setLoading", true);
    try {
      const { data } = await axios({
        url: `${rootState.apiAddress}/api/shops/${shopId}/sales-per-hour-report`,
        params: {
          start,
          end,
          options: JSON.stringify(options),
        },
        headers: {
          Authorization: `Bearer ${rootState.userToken}`,
        },
      });
      return data;
    } catch (err) {
      const message =
        err.response && err.response.data && err.response.data.message
          ? err.response.data.message
          : rootState.defaultError;
      Vue.toasted.global
        .showServerError({
          message,
        })
        .goAway(4000);
    }
    commit("setLoading", false);
  },
  GET_TODAY_BALANCE: async ({ commit, rootState }, { shopId, options }) => {
    commit("setLoading", true);
    try {
      const {
        data: { balance },
      } = await axios({
        url: `${rootState.apiAddress}/api/shops/${shopId}/balance-report`,
        params: {
          options: JSON.stringify(options),
        },
        headers: {
          Authorization: `Bearer ${rootState.userToken}`,
        },
      });
      commit("setTodayBalance", balance);
    } catch (err) {
      const message =
        err.response && err.response.data && err.response.data.message
          ? err.response.data.message
          : rootState.defaultError;
      Vue.toasted.global
        .showServerError({
          message,
        })
        .goAway(4000);
    }
    commit("setLoading", false);
  },
  GET_TOMORROW_PAYOUT: async ({ commit, rootState }, { shopId, options }) => {
    commit("setLoading", true);
    try {
      const {
        data: { payouts },
      } = await axios({
        url: `${rootState.apiAddress}/api/shops/${shopId}/tomorrow-payout-report`,
        params: {
          options: JSON.stringify(options),
        },
        headers: {
          Authorization: `Bearer ${rootState.userToken}`,
        },
      });
      commit("setTomorrowPayout", payouts);
    } catch (err) {
      const message =
        err.response && err.response.data && err.response.data.message
          ? err.response.data.message
          : rootState.defaultError;
      Vue.toasted.global
        .showServerError({
          message,
        })
        .goAway(4000);
    }
    commit("setLoading", false);
  },
  GET_MOST_SOLD_PRODUCTS: async (
    { commit, rootState },
    { shopId, start, end, options }
  ) => {
    commit("setLoading", true);
    try {
      const {
        data: { data },
      } = await axios({
        url: `${rootState.apiAddress}/api/shops/${shopId}/most-sold-products-report`,
        params: {
          start,
          end,
          options: JSON.stringify(options),
        },
        headers: {
          Authorization: `Bearer ${rootState.userToken}`,
        },
      });
      commit("setMostSoldProducts", data);
    } catch (err) {
      const message =
        err.response && err.response.data && err.response.data.message
          ? err.response.data.message
          : rootState.defaultError;
      Vue.toasted.global
        .showServerError({
          message,
        })
        .goAway(4000);
    }
    commit("setLoading", false);
  },
  GET_LOYALTY_PRODUCTS: async (
    { commit, rootState },
    { shopId, start, end, options }
  ) => {
    commit("setLoading", true);
    try {
      const {
        data: { orders },
      } = await axios({
        url: `${rootState.apiAddress}/api/shops/${shopId}/loyalty-report`,
        params: {
          start,
          end,
          options: JSON.stringify(options),
        },
        headers: {
          Authorization: `Bearer ${rootState.userToken}`,
        },
      });
      commit("setLoyaltyProducts", orders);
    } catch (err) {
      const message =
        err.response && err.response.data && err.response.data.message
          ? err.response.data.message
          : rootState.defaultError;
      Vue.toasted.global
        .showServerError({
          message,
        })
        .goAway(4000);
    }
    commit("setLoading", false);
  },
  GET_EMBED_VS_APP: async (
    { commit, rootState },
    { shopId, start, end, options }
  ) => {
    commit("setLoading", true);
    try {
      const { data } = await axios({
        url: `${rootState.apiAddress}/api/shops/${shopId}/embed-vs-app-report`,
        params: {
          start,
          end,
          options: JSON.stringify(options),
        },
        headers: {
          Authorization: `Bearer ${rootState.userToken}`,
        },
      });
      commit("setEmbedVSApp", data);
    } catch (err) {
      const message =
        err.response && err.response.data && err.response.data.message
          ? err.response.data.message
          : rootState.defaultError;
      Vue.toasted.global
        .showServerError({
          message,
        })
        .goAway(4000);
    }
    commit("setLoading", false);
  },
};
