<template>
  <div class="main">
    <div class="right-panel">
      <div class="form-container">
        <form id="resetPassword-form" @submit="resetPassword($event)">
          <img
            src="../assets/host_logo_Landscape_green.png"
            class="logo"
            style="width: 260px"
          />
          <p style="color: white">
            Enter your new password and confirm in order to update it
          </p>
          <password
            v-model="newPassword"
            :toggle="true"
            defaultClass="form-control"
            placeholder="New Password"
            :secureLength="6"
            :disabled="loading"
          />
          <password
            v-model="confirmPassword"
            :toggle="true"
            defaultClass="form-control"
            placeholder="Confirm Password"
            :secureLength="6"
            :disabled="loading"
          />

          <b-button :disabled="loading" type="submit" class="login-submit">
            <span v-if="!loading">Set New Password</span>
            <b-spinner v-else tag="div" style="color: white" />
          </b-button>
          <b-card
            v-if="success"
            class="mt-3"
            bg-variant="info"
            text-variant="white"
          >
            <p class="text-center">
              Your new password is ready! <br />You can leave this page now.
            </p>
          </b-card>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Password from "vue-password-strength-meter";
import { beforeRouteEnter } from "../router";

export default {
  name: "set-new-password",
  components: {
    Password,
  },
  data: () => ({
    newPassword: "",
    confirmPassword: "",
    success: false,
  }),
  metaInfo: {
    title: "Set Your New Password",
  },
  methods: {
    async resetPassword(e) {
      e.preventDefault();
      const { newPassword, confirmPassword } = this;
      let successful = await this.$store.dispatch("UPDATE_PASSWORD", {
        newPassword,
        confirmPassword,
        resetToken: this.$route.query.code,
        skipReload: true,
      });
      if (successful) this.success = true;
    },
  },
  computed: {
    ...mapGetters(["loading"]),
  },
  mounted() {
    if (!this.$route.query || !this.$route.query.code)
      return (window.location.href = "https://www.onthegoapp.co.nz");
  },
  beforeRouteEnter,
};
</script>

<style scoped>
.main {
  height: 100%;
  width: 100%;
  display: table;
}

.logo {
  width: 300px;
  margin: 10px auto;
  display: block;
}

#resetPassword-form {
  margin: auto auto;
}

.left-panel {
  display: table-cell;
  height: 100%;
  width: 40%;
  position: relative;
  background: url(../assets/hmfdzw6iy8qd35sxublc.jpg) center center/cover
    no-repeat;
}

.right-panel {
  display: table-cell;
  height: 100%;
  position: relative;
  width: 60%;
  background-color: #55b8b8;
}

#resetPassword-form {
  margin: auto auto;
}

.form-control {
  margin: 10px 0px;
}

.right-panel h3 {
  color: #f8f8f8;
  font-family: averta, avenir w02, avenir, Helvetica, Arial, sans-serif;
  font-style: normal;
  text-align: center;
  text-transform: uppercase;
}

.login-submit {
  color: ##14253e;
}

.login-submit {
  background: ##14253e;
  padding: 10px;
  display: block;
  border: none;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  width: 100%;
}

.login-submit span {
  font-family: Helvetica Neue;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  /* identical to box height */
  width: 100%;
  display: inline-block;
  align-items: center;
  text-align: center;
  letter-spacing: 2.30769px;
  text-transform: uppercase;
  color: white;
}

.account-question {
  font-family: Helvetica Neue;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  /* identical to box height */

  display: inline-block;
  align-items: center;
  letter-spacing: 2.30769px;
  text-transform: uppercase;

  color: white;
}

.account-question a {
  color: ##14253e;
  font-weight: bolder;
  text-decoration: none;
}

.form-container {
  width: 100%;
  display: flex;
  height: 100%;
}
</style>
