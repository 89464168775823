import axios from "axios";
import Vue from "vue";

export default {
  GET_CUSTOMERS: async ({ commit, rootState }, { shopId, options }) => {
    commit("setLoading", true);
    try {
      const {
        data: { users },
      } = await axios({
        url: `${
          rootState.apiAddress
        }/api/shops/${shopId}/customers?options=${JSON.stringify(options)}`,
        headers: {
          Authorization: `Bearer ${rootState.userToken}`,
        },
      });
      commit("setCustomers", users);
    } catch (err) {
      const message =
        err.response && err.response.data && err.response.data.message
          ? err.response.data.message
          : rootState.defaultError;
      Vue.toasted.global
        .showServerError({
          message,
        })
        .goAway(4000);
    }
    commit("setLoading", false);
  },
  GET_ALL_CUSTOMERS: async (
    { commit, rootState },
    { options, search, role }
  ) => {
    commit("setLoading", true);
    try {
      const {
        data: { users, count },
      } = await axios({
        url: `${rootState.apiAddress}/api/users?options=${JSON.stringify(
          options
        )}&search=${search}`,
        headers: {
          Authorization: `Bearer ${rootState.userToken}`,
        },
        data: {
          search: search,
          role: role,
          options: JSON.stringify(options),
        },
      });
      commit("setCustomers", users);
      commit("setCustomersCount", count);
    } catch (err) {
      const message =
        err.response && err.response.data && err.response.data.message
          ? err.response.data.message
          : rootState.defaultError;
      Vue.toasted.global
        .showServerError({
          message,
        })
        .goAway(4000);
    }
    commit("setLoading", false);
  },
  GET_USER: async ({ commit, rootState }, { userId, options }) => {
    commit("setLoading", true);
    try {
      const {
        data: { user },
      } = await axios({
        url: `${
          rootState.apiAddress
        }/api/users/${userId}?options=${JSON.stringify(options)}`,
        headers: {
          Authorization: `Bearer ${rootState.userToken}`,
        },
      });
      commit("setLoading", false);
      return user;
    } catch (err) {
      const message =
        err.response && err.response.data && err.response.data.message
          ? err.response.data.message
          : rootState.defaultError;
      Vue.toasted.global
        .showServerError({
          message,
        })
        .goAway(4000);
    }
    commit("setLoading", false);
  },
  CLEAR_CUSTOMER_CART: async ({ commit, rootState }, { userId }) => {
    commit("setLoading", true);
    try {
      await axios({
        url: `${rootState.apiAddress}/api/users/${userId}/clear-cart`,
        method: "PUT",
        headers: {
          Authorization: `Bearer ${rootState.userToken}`,
        },
      });
      Vue.toasted
        .success(`Customer's cart cleared`, { className: "app-font" })
        .goAway(4000);
    } catch (err) {
      const message =
        err.response && err.response.data && err.response.data.message
          ? err.response.data.message
          : rootState.defaultError;
      Vue.toasted.global
        .showServerError({
          message,
        })
        .goAway(4000);
    }
    commit("setLoading", false);
  },
};
