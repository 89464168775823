<template>
  <div style="height: 100%">
    <b-card>
      <b-row>
        <b-col>
          <h5 class="section-title orange-color">
            <span class="text-capitalize">My Account</span>
            <br />
            <small class="dark-blue-color">
              Set your contact details, update your password and set your
              billing's preferences
            </small>
          </h5>
        </b-col>
      </b-row>
    </b-card>
    <b-tabs style="height: 100%" card fill pills :vertical="!isMobile">
      <contact-details :user-info="userInfo" />
      <password-reset />
    </b-tabs>
  </div>
</template>

<script>
import ContactDetails from "../components/my-account/contact-details";
import PasswordReset from "../components/my-account/password-reset";
import { mapGetters } from "vuex";

export default {
  name: "my-account",
  components: { ContactDetails, PasswordReset },
  computed: {
    ...mapGetters(["userInfo"]),
    isMobile() {
      return document.getElementById("app").offsetWidth < 600;
    },
  },
};
</script>

<style scoped></style>
