<template>
  <b-tab>
    <template #title> <i class="fas fa-crown mr-1"></i> Loyalty </template>
    <b-row v-if="!shop.loyalty || !shop.loyalty.enabled">
      <b-col>
        <b-alert show>
          This venue is not subscribed to our loyalty system. To subscribe click
          the button below
          <br />
          <b-button href="/shop/settings?section=loyalty"
            >Loyalty Settings</b-button
          >
        </b-alert>
      </b-col>
    </b-row>
    <b-row v-else>
      <b-col>
        <b-alert :show="!loyaltyCard">
          <i class="fas fa-info-circle" /> Currently there is no an existing
          loyalty card for this customer. Do you wish to setup one now?
          <br />
          <b-button pill variant="primary" size="lg" @click="setLoyaltyCard"
            >Yes, create loyalty card</b-button
          >
        </b-alert>
        <b-card v-if="loyaltyCard">
          <b-card-title class="orange-color">
            <span class="text-capitalize">{{ customer.name.given }}</span
            >'s loyalty card ({{ loyaltyCard.rewardNumber }} points)
          </b-card-title>
          <span
            >Click in the stamps to assign new loyalty points or claim your
            customer's points to exchange their reward</span
          >
          <b-row>
            <b-col
              v-for="index in shop.loyalty.limit"
              :key="index"
              class="mt-2"
              sm="6"
              md="2"
            >
              <b-button
                variant="light"
                pill
                style="width: 80px; height: 80px"
                :pressed="index <= loyaltyCard.rewardNumber"
                @click="assignLoyaltyPoint(index)"
              >
                <b-img style="width: 50px" :src="shop.loyalty.icon.icon" />
              </b-button>
            </b-col>
          </b-row>
          <b-button
            pill
            variant="primary"
            class="mt-3 float-right"
            @click="claimLoyaltyPoints"
          >
            Claim Loyalty Points
          </b-button>
        </b-card>
      </b-col>
    </b-row>
  </b-tab>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "loyalty",
  props: ["customer", "shop"],
  computed: {
    ...mapGetters("shop", ["loyaltyCard"]),
  },
  methods: {
    async setLoyaltyCard() {
      console.log(this.customer);
      await this.$store.dispatch("shop/SET_CUSTOMER_LOYALTY_CARD", {
        userId: this.customer._id,
        shopId: this.shop._id,
      });
    },
    async claimLoyaltyPoints() {
      console.log(this.customer);
      await this.$store.dispatch("shop/CLAIM_CUSTOMER_LOYALTY_CARD_POINTS", {
        userId: this.customer._id,
        shopId: this.shop._id,
      });
    },
    async assignLoyaltyPoint(index) {
      if (index <= this.loyaltyCard.rewardNumber) return;
      console.log(this.customer);
      await this.$store.dispatch("shop/ASSIGN_CUSTOMER_LOYALTY_CARD_POINT", {
        userId: this.customer._id,
        shopId: this.shop._id,
      });
    },
  },
  async mounted() {
    console.log(this.customer);
    if (!this.shop.loyalty || !this.shop.loyalty.enabled) return;
    await this.$store.dispatch("shop/GET_CUSTOMER_LOYALTY_CARD", {
      userId: this.customer._id,
      shopId: this.shop._id,
    });
  },
};
</script>

<style scoped></style>
