export const getOrigin = (order) => {
  let type = "web";
  switch (order.origin) {
    case "kiosk":
      type = order.isPOSOrder ? "POS" : "Kiosk";
      break;
    case "web":
      type = "Online Ordering";
      break;
    case "mobile-app":
      type = "On the Go App";
      break;
  }
  return type;
};

export const getOrderType = (order) => {
  let type;
  switch (order.orderType) {
    case "pickup":
      type = "Takeaway";
      break;
    default:
      type = order.orderType;
      break;
  }
  return type;
};

export const getOrderPaymentMethod = (order) => {
  let type = "EFTPOS";
  if (order && order.stripeChargeId) type = "Card(Online)";
  if (
    order &&
    order.transactionResolution &&
    order.transactionResolution.isCashOrder
  )
    type = "CASH";
  if (
    order &&
    order.transactionResolution &&
    order.transactionResolution.method
  )
    type = order.transactionResolution.method;
  return type;
};

export function getCartTotal(cart) {
  let total = 0;
  for (let item of cart) {
    total += getProductTotalCost(item) * item.quantity;
  }
  console.log(total);

  return total;
}

export function getOptionValue(option, config) {
  console.log(option, config);
  let index = config.findIndex((c) => c.option === option._id);
  return config[index];
}

export const getProductTotalCost = (item) => {
  if (item.reward) return 0;
  let total = 0;
  total += item.product.price;
  for (let option of item.config) {
    if (option.value) {
      if (option.value.constructor !== Array) {
        if (option.value.price) total += parseInt(option.value.price);
      } else {
        for (let op of option.value) {
          if (op.price) total += parseInt(op.price);
        }
      }
    }
  }
  return total;
};
