var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-tab',{attrs:{"title":"QR Code"}},[_c('b-card',[_c('b-form-group',[_c('h5',{staticClass:"orange-color"},[_vm._v("Site Sharing")]),_c('label',[_vm._v("Select any two options to generate a site url QR Code to share with your customers")]),_c('b-row',[_c('b-col',[_c('b-form-radio',{attrs:{"name":"siteShareDisplay","value":"plain"},model:{value:(_vm.siteShareDisplay),callback:function ($$v) {_vm.siteShareDisplay=$$v},expression:"siteShareDisplay"}},[_vm._v(" Plain Site QR code (you can incorporate this QR in your own banner designs) ")])],1),_c('b-col',[_c('b-form-radio',{attrs:{"name":"siteShareDisplay","value":"custom"},model:{value:(_vm.siteShareDisplay),callback:function ($$v) {_vm.siteShareDisplay=$$v},expression:"siteShareDisplay"}},[_vm._v(" On the Go customised QR code (Get a branded PDF with your venues logo and QR Code) ")])],1)],1),(_vm.siteShareDisplay === 'plain')?_c('b-row',[_c('b-col',{staticClass:"text-center mt-3",attrs:{"sm":"12","md":"6"}},[_c('qrcode-vue',{attrs:{"value":_vm.getSiteQRValue(),"size":"200"}}),_c('strong',[_vm._v("Right click over this image and press \"Save Image As...\" to download this QR picture")])],1)],1):_c('b-row',[_c('b-col',[_c('vue-html2pdf',{ref:"html2Pdf",attrs:{"show-layout":false,"float-layout":true,"enable-download":false,"preview-modal":true,"paginate-elements-by-height":500,"filename":"hee hee","pdf-quality":2,"manual-pagination":false,"pdf-format":"a4","pdf-orientation":"portrait","pdf-content-width":"100%","html-to-pdf-options":{
              enableLinks: true,
              html2canvas: {
                scale: 1,
                useCORS: true,
              },
            }}},[_c('section',{attrs:{"slot":"pdf-content"},slot:"pdf-content"},[_c('b-row',[_c('b-col',{staticClass:"text-center",attrs:{"sm":"12","md":"6"}},[(
                      _vm.embedKey.layoutConfig.banner &&
                      _vm.embedKey.layoutConfig.banner.logo
                    )?_c('img',{staticStyle:{"width":"300px","position":"relative"},attrs:{"src":_vm.embedKey.layoutConfig.banner.logo.url}}):_vm._e(),_c('p',[_vm._v("Scan below QR Code to start your order")]),_c('qrcode-vue',{attrs:{"value":_vm.getSiteQRValue(),"size":"200"}}),_c('small',[_vm._v("Powered by "),_c('strong',[_vm._v("On the Go")]),_vm._v(" and secured by "),_c('strong',[_vm._v("Stripe")])])],1)],1)],1)]),_c('b-button',{staticClass:"mt-3",attrs:{"pill":"","variant":"primary"},on:{"click":_vm.generatePDF}},[_vm._v("Download Branded PDF site sharing QR code ")])],1)],1)],1),_c('b-alert',{attrs:{"show":""}},[_c('p',[_vm._v(" You can also generate a branded site sharing QR code for specific tables on your venue. Click "),_c('a',{attrs:{"href":"/shop/settings?section=table-ordering"}},[_vm._v("here")]),_vm._v(" and select the "),_c('strong',[_vm._v("Manage Tables")]),_vm._v(" button to print their assigned QR code ")])])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }