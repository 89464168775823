<template>
  <div>
    <b-card>
      <b-col>
        <h5 class="section-title orange-color">
          Customers
          <br />
          <small class="dark-blue-color">
            View your customer's details, promo-codes offered and their loyalty
            to your venue
          </small>
        </h5>
      </b-col>
    </b-card>
    <b-container>
      <b-button class="my-2 float-right" variant="primary" pill @click="csvExport">Export</b-button>
      <b-input-group class="mt-3">
        <b-form-input
          v-model="textSearch"
          placeholder="Type name or email address"
        />
        <b-input-group-append>
          <b-input-group-text>
            <i class="fas fa-search" />
          </b-input-group-text>
        </b-input-group-append>
      </b-input-group>
      <b-row>
        <b-col
          sm="12"
          md="3"
          class="mt-3"
          v-for="customer of customersSet"
          :key="customer._id"
        >
          <router-link :to="`/shop/customers/${customer._id}`" tag="div">
            <b-card class="pointer" style="min-height: 278px">
              <b-card-title class="text-center text-capitalize">
                <img
                  class="img img-circle mb-2"
                  style="width: 50%"
                  :src="getProfilePicture(customer)"
                />
                <br />
                {{ customer.name.given }} {{ customer.name.family }}
                <br />
              </b-card-title>
              <b-row>
                <b-col cols="1">
                  <i class="fas fa-envelope mr-2" />
                </b-col>
                <b-col cols="10">
                  <small>{{ customer.profile.email }}</small>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="1">
                  <i class="fas fa-phone mr-2"/>
                </b-col>
                <b-col>
                  <small>{{
                    customer.profile.mobile || customer.profile.mobile || "none"
                  }}</small>

                </b-col>
              </b-row>
            </b-card>
          </router-link>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "customers",
  data: () => ({
    textSearch: "",
  }),
  computed: {
    ...mapGetters("customer", ["customers"]),
    ...mapGetters("shop", ["shop"]),
    customersSet() {
      return this.customers.filter(
        (c) =>
          `${c.name.given} ${c.name.family}`
            .toLowerCase()
            .indexOf(this.textSearch.toLowerCase()) > -1 ||
          c.profile.email.toLowerCase().indexOf(this.textSearch.toLowerCase()) >
            -1
      );
    },
  },
  methods: {
    getProfilePicture(customer) {
      if (customer && customer.pictures && customer.pictures.profile.length > 0)
        return customer.pictures.profile;
      return require("../assets/Userpic.png");
    },
    csvExport() {
      let csvContent = "data:text/csv;charset=utf-8,\n";
      csvContent += "name,email,phone,venue\n";
      for (let customer of this.customersSet) {
        csvContent += `${customer.name.given} ${customer.name.family},${customer.profile.email},${customer.profile.phone || customer.profile.mobile},${this.shop.name.normal}\n`
      }

      console.log(csvContent);
      var encodedUri = encodeURI(csvContent);
      var link = document.createElement("a");
      link.setAttribute("href", encodedUri);
      link.setAttribute(
          "download",
          `${this.shop.name.normal}_customers.csv`
      );
      document.body.appendChild(link); // Required for FF

      link.click();
    }
  },
  watch: {
    shop(newVal) {
      if (newVal)
        this.$store.dispatch("customer/GET_CUSTOMERS", {
          shopId: newVal._id,
          options: { limit: 1000, sort: "name.given" },
        });
    },
  },
  mounted() {
    if (this.shop)
      this.$store.dispatch("customer/GET_CUSTOMERS", {
        shopId: this.shop._id,
        options: { limit: 1000, sort: "name.given" },
      });
  },
};
</script>

<style scoped>
.pointer {
  cursor: pointer;
}
</style>
