<template>
  <div style="height: 100%; overflow: scroll">
    <b-card>
      <b-row class="m-0">
        <b-col cols="9">
          <h5 class="section-title orange-color">
            Orders
            <br />
            <small class="dark-blue-color">
              Manage your customer's orders like in our Tablet merchant app from
              your browser
            </small>
          </h5>
        </b-col>
        <b-col cols="3">
          <b-dropdown
            no-caret
            class="mr-2"
            :variant="shop.status === 'accepting orders' ? 'success' : 'danger'"
            :text="`Status: ${
              shop.status === 'accepting orders'
                ? 'Accepting Orders'
                : 'Stop New orders'
            }`"
          >
            <b-dropdown-item
              @click="toggleAcceptingOrders"
              v-if="shop.status !== 'accepting orders'"
            >
              Resume New Orders</b-dropdown-item
            >
            <b-dropdown-item
              @click="toggleAcceptingOrders"
              v-if="shop.status === 'accepting orders'"
            >
              Stop New Orders</b-dropdown-item
            >
          </b-dropdown>
          <b-dropdown :text="`${dateFilter.label}'s orders`">
            <b-dropdown-item
              v-for="option of dateFilterOptions"
              :key="option.value"
              @click="getOrders(option)"
            >
              {{ `${option.label}' orders` }}
            </b-dropdown-item>
          </b-dropdown>
        </b-col>
      </b-row>
    </b-card>
    <b-row style="height: calc(100% - 98px); overflow: scroll" class="m-0">
      <b-col cols="4" style="height: 100%" class="p-0">
        <b-card no-body class="p-2 m-2" style="height: 100%; overflow: scroll">
          <b-tabs
            pills
            fill
            style="height: 100%; overflow: scroll"
            v-model="tabIndex"
          >
            <order-by-status
              status="pending"
              @orderSelected="onOrderSelected"
            />
            <order-by-status
              status="completed"
              @orderSelected="onOrderSelected"
            />
          </b-tabs>
        </b-card>
      </b-col>
      <order-details
        :selectedOrder="selectedOrder"
        v-on:orderStatusUpdate="onOrderStatusUpdate"
      />
    </b-row>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import OrderByStatus from "../components/orders/order-by-status";
import OrderDetails from "../components/orders/order-details";

export default {
  name: "orders",
  components: { OrderDetails, OrderByStatus },
  data: () => ({
    selectedOrder: null,
    tabIndex: 0,
    dateFilter: {
      label: "Today",
      value: "day",
    },
    dateFilterOptions: [
      {
        label: "Today",
        value: "day",
      },
      {
        label: "This Week",
        value: "week",
      },
      {
        label: "This Month",
        value: "month",
      },
      {
        label: "This Year",
        value: "year",
      },
    ],
  }),
  computed: {
    ...mapGetters("shop", ["shop"]),
    ...mapGetters("order", ["orders"]),
  },
  async mounted() {
    if (this.shop) await this.getOrders();
    setInterval(() => {
      if (this.shop) this.getOrders();
    }, 60000);
  },
  methods: {
    async getOrders(option) {
      if (option) this.dateFilter = option;
      await this.$store.dispatch("order/GET_SHOP_ORDERS", {
        shopId: this.shop._id,
        dateRange: this.dateFilter.value,
        options: {
          sort: "pickupTime",
          limit: 1000,
          skip: 0,
          populate: { promoCode: true, owner: { select: "name pictures" } },
        },
      });
    },
    onOrderSelected(order) {
      this.selectedOrder = { ...JSON.parse(JSON.stringify(order)), seen: true };
    },
    async onOrderStatusUpdate(status) {
      await this.$store.dispatch("order/UPDATE_ORDER_STATUS", {
        status,
        orderId: this.selectedOrder._id,
      });
      await this.$store.dispatch("order/GET_SHOP_ORDERS", {
        shopId: this.shop._id,
        dateRange: "year",
        options: {
          sort: "pickupTime",
          limit: 9999,
          skip: 0,
          populate: { promoCode: true, owner: { select: "name pictures" } },
        },
      });
      if (status !== "accepted") this.selectedOrder = null;
    },
    async toggleAcceptingOrders() {
      let status = this.shop.status !== "accepting orders";
      await this.$store.dispatch("shop/UPDATE_SHOP_STATUS", {
        shopId: this.shop._id,
        status,
      });
    },
  },
  watch: {
    async shop(newVal) {
      if (newVal) await this.getOrders();
    },
    async orders(newVal) {
      if (newVal && this.selectedOrder)
        this.selectedOrder = this.orders.find(
          (o) => this.selectedOrder._id === o._id
        );
    },
  },
};
</script>

<style scoped>
.orders-list {
  height: 100%;
  overflow: scroll;
  position: absolute;
}

.order-details {
  height: 100%;
  overflow: scroll;
  position: absolute;
}
</style>
