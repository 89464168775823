import actions from "./actions";
import getters from "./getters";
import mutations from "./mutations";

const state = {
  loading: false,
  kiosks: [],
  status: null,
  error: null,
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
