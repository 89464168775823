<template>
  <div v-if="type === 'color'" :style="cubeStyle"></div>
</template>

<script>
export default {
  name: "themeHelper",
  props: ["theme", "value", "type"],
  computed: {
    cubeStyle() {
      return `background-color: ${
        this.theme.colors[this.value]
      }; width: 38px;height: 38px;border:1px solid #ced4da;`;
    },
  },
};
</script>

<style scoped></style>
