<template>
  <b-modal
    title="Product Options Sync"
    id="product-sync-modal"
    okTitle="Sync Products Options"
    @ok="syncProducts"
    :ok-disabled="loading"
  >
    <p>
      Use this option if your current products available options doesn't match
      with the available options configured in this menu
    </p>
  </b-modal>
</template>

<script>
export default {
  name: "product-sync-modal",
  data: () => ({
    drag: false,
    productsList: [],
    loading: false,
  }),
  props: ["category"],
  methods: {
    async syncProducts() {
      this.loading = true;

      await this.$store.dispatch("category/SYNC_PRODUCTS", {
        shopId: this.category.shop,
        categoryId: this.category._id,
      });
      this.loading = false;
    },
  },
};
</script>

<style scoped></style>
