export default {
  setLoading: (state, loading) => (state.loading = loading),
  setEmbedId: (state, embedId) => (state.embedId = embedId),
  setShopId: (state, shopId) => (state.shopId = shopId),
  setGuestExchangeToken: (state, exchangeToken) =>
    (state.guestExchangeToken = exchangeToken),
  setApiAddress: (state, apiAddress) => (state.apiAddress = apiAddress),
  setAppInfo: (state, data) => {
    state.guestExchangeToken = data.guestToken;
    state.shopId = data.shopId;
    state.layoutConfig = data.layoutConfig;
    state.apiAddress = data.apiAddress;
  },
  setUserInfo: (state, { token, userId }) => {
    state.userToken = token;
    state.userId = userId;
    localStorage.setItem(`userToken`, token);
    localStorage.setItem(`userId`, userId);
    console.log("user info settled");
  },
  setGuestInfo(state, { token, guestId }) {
    this.state.guestToken = token;
    this.state.guestId = guestId;
    localStorage.setItem(`guestToken${state.shopId}`, token);
    localStorage.setItem(`guestId${state.shopId}`, guestId);
  },
  setCart: (state, cart) => {
    state.cart = cart;
  },
  setDeliveryInfo: (state, deliveryInfo) => {
    state.deliveryInfo = deliveryInfo;
  },
  setUserDetails: (state, userInfo) => {
    state.userInfo = userInfo;
  },
  setGuestDetails: (state, guestInfo) => {
    state.guestInfo = guestInfo;
  },
  setPaymentSources: (state, paymentSources) => {
    state.userInfo = {
      ...state.userInfo,
      paymentSources,
    };
  },
  setShowLockingLoading: (state, status) => {
    state.showLockingLoading = status;
  },
  setLockingLoadingTitle: (state, title) => {
    state.lockingLoadingTitle = title;
  },
  setLockingLoadingDescription: (state, description) => {
    state.lockingLoadingDescription = description;
  },
  setOrderType: (state, type) => (state.orderType = type),
  setPromoCode: (state, code) => (state.promoCode = code),
  setLayoutConfig: (state, layoutConfig) => (state.layoutConfig = layoutConfig),
  setOrderCompleted: (state, status) => (state.orderCompleted = status),
  logout: () => {
    localStorage.clear();
    window.location.href = `/`;
  },
  setPlatformPlans: (state, platformPlans) =>
    (state.platformPlans = platformPlans),
};
