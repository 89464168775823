<template>
  <div
    id="subscription"
    class="shop-section col-xs-12"
    v-bind:class="{active: isActive}"
    @click="$emit('activeSection', 'subscription')">
    <div class="header">
      
      <i class="fas fa-handshake section-icon"/>
      
      <h5 class="section-title dark-blue-color">
        Subscription
        <br/>
        <small>Manage your shop's current subscription</small>
      </h5>
    </div>
    <b-col class="option-content col-xs-12" v-if="shopInfo">
      <b-col class="col-lg-12">
        <b-alert :show="!shop.subscription || shop.subscription.legacy">
          You currently have a Legacy Plan allocated to your shop, which includes:
          <ul>
            <li>All enterprise features</li>
            <li>{{shop.orderingPreferences.feePercent}}% flat commission fee (Stripe fees included)</li>
            <li>$0 Monthly or Year subscription fee</li>
          </ul>
          <strong>You can change whenever you desire into any of our new plans, but you can't go back into this
            plan.</strong>
        </b-alert>
        <b-form-group
          label="Preferred plan period"
        >
          <b-form-select
            v-model="tierPeriod"
            class="text-capitalize"
            :options="['month', 'year']"
          />
        </b-form-group>
        
        <b-card-group deck
        >
          <b-card
            v-for="subscription of platformPlans"
            :key="subscription.monthPriceId"
            :border-variant="shop.subscription && shop.subscription.monthPriceId === subscription.monthPriceId
              && tierPeriod === shop.subscription.tier
              ? 'primary'
              : 'light'"
          >
            <b-card-title>
              {{subscription.title}} {{(subscription.fee * (tierPeriod === 'month' ? 1 : 12))/100 | numeral('$0,0.00')}}
              <br/>
              <small>{{subscription.percent}}% commission</small>
            </b-card-title>
            <ul>
              <li
                v-for="(value, index) of subscription.description"
                :key="index"
              >{{value}}
              </li>
            </ul>
            <template #footer>
              <b-button
                @click="openTierCheckout(subscription[`${tierPeriod}PriceId`])"
                pill
                size="lg"
                :variant="shop.subscription && shop.subscription.monthPriceId === subscription.monthPriceId && tierPeriod === shop.subscription.tier ? 'success' : 'primary'"
              >
                {{shop.subscription && shop.subscription.monthPriceId === subscription.monthPriceId && tierPeriod === shop.subscription.tier ? 'Update Subscription' :
                'Select'}}
              </b-button>
            </template>
          </b-card>
        </b-card-group>
        <b-alert show class="mt-3">
          <p>Additional Stripe payment fees apply. Standard rates:
            ‍
          <ul>
            <li>
              Order amounts less that $15, 3.5%+5c
            </li>
            <li>
              Order amounts more than $15, 2.9%+30c
            </li>
          </ul>
          </p>
        </b-alert>
      </b-col>
    </b-col>
  </div>
</template>

<script>
  import {mapGetters} from "vuex";
  import {loadStripe} from '@stripe/stripe-js';

  export default {
    name: "shop-subscriptions",
    components: {},
    props: ['isActive', 'shop'],
    data: () => ({
      shopInfo: {
        loyalty: {
          mechanism: 'otg'
        }
      },
      stripePromise: null,
      tierPeriod: 'month'
    }),
    computed: {
      ...mapGetters('category', ['categories']),
      ...mapGetters('shop', ['icons', 'loading']),
      ...mapGetters(['stripeKey', 'userInfo', 'localAddress', 'platformPlans'])
    },
    methods: {
      async openTierCheckout(tierId) {
        console.log(tierId)
        const stripe = await this.stripePromise;
        const {error} = await stripe.redirectToCheckout({
          lineItems: [
            // Replace with the ID of your price
            {price: tierId, quantity: 1}
          ],
          mode: 'subscription',
          clientReferenceId: this.shop._id,
          customerEmail: this.userInfo.profile.email,
          successUrl: `${this.localAddress}/shop/settings?section=subscription`,
          cancelUrl: `${this.localAddress}/shop/settings?section=subscription`,
        });
        console.log(error)
      }
    },
    mounted() {
      if (this.stripeKey) this.stripePromise = loadStripe(this.stripeKey);
      this.shopInfo = JSON.parse(JSON.stringify(this.shop))
      console.log(this.shopInfo)
    },
    watch: {
      stripeKey(newVal) {
        if (!newVal) return;
        this.stripePromise = loadStripe(newVal);
      },
      shop(newVal) {
        if (!newVal) return;
        this.shopInfo = {
          ...newVal
        }
      }
    }
  }
</script>

<style scoped>
</style>
