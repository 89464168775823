<template>
  <b-tab title="QR Code">
    <b-card>
      <b-form-group>
        <h5 class="orange-color">Site Sharing</h5>
        <label
          >Select any two options to generate a site url QR Code to share with
          your customers</label
        >
        <b-row>
          <b-col>
            <b-form-radio
              v-model="siteShareDisplay"
              name="siteShareDisplay"
              value="plain"
            >
              Plain Site QR code (you can incorporate this QR in your own banner
              designs)
            </b-form-radio>
          </b-col>
          <b-col>
            <b-form-radio
              v-model="siteShareDisplay"
              name="siteShareDisplay"
              value="custom"
            >
              On the Go customised QR code (Get a branded PDF with your venues
              logo and QR Code)
            </b-form-radio>
          </b-col>
        </b-row>
        <b-row v-if="siteShareDisplay === 'plain'">
          <b-col sm="12" md="6" class="text-center mt-3">
            <qrcode-vue :value="getSiteQRValue()" size="200" />
            <strong
              >Right click over this image and press "Save Image As..." to
              download this QR picture</strong
            >
          </b-col>
        </b-row>
        <b-row v-else>
          <b-col>
            <vue-html2pdf
              :show-layout="false"
              :float-layout="true"
              :enable-download="false"
              :preview-modal="true"
              :paginate-elements-by-height="500"
              filename="hee hee"
              :pdf-quality="2"
              :manual-pagination="false"
              pdf-format="a4"
              pdf-orientation="portrait"
              pdf-content-width="100%"
              :html-to-pdf-options="{
                enableLinks: true,
                html2canvas: {
                  scale: 1,
                  useCORS: true,
                },
              }"
              ref="html2Pdf"
            >
              <section slot="pdf-content">
                <b-row>
                  <b-col sm="12" md="6" class="text-center">
                    <img
                      v-if="
                        embedKey.layoutConfig.banner &&
                        embedKey.layoutConfig.banner.logo
                      "
                      :src="embedKey.layoutConfig.banner.logo.url"
                      style="width: 300px; position: relative"
                    />
                    <p>Scan below QR Code to start your order</p>
                    <qrcode-vue :value="getSiteQRValue()" size="200" />
                    <small
                      >Powered by <strong>On the Go</strong> and secured by
                      <strong>Stripe</strong></small
                    >
                  </b-col>
                </b-row>
              </section>
            </vue-html2pdf>
            <b-button pill class="mt-3" variant="primary" @click="generatePDF"
              >Download Branded PDF site sharing QR code
            </b-button>
          </b-col>
        </b-row>
      </b-form-group>
      <b-alert show>
        <p>
          You can also generate a branded site sharing QR code for specific
          tables on your venue. Click
          <a href="/shop/settings?section=table-ordering">here</a> and select
          the <strong>Manage Tables</strong> button to print their assigned QR
          code
        </p>
      </b-alert>
    </b-card>
  </b-tab>
</template>

<script>
import QrcodeVue from "qrcode.vue";
import VueHtml2pdf from "vue-html2pdf";
import { mapGetters } from "vuex";

export default {
  name: "qr",
  components: {
    QrcodeVue,
    VueHtml2pdf,
  },
  props: ["embedKey"],
  data: () => ({
    siteShareDisplay: "plain",
  }),
  computed: {
    ...mapGetters("shop", ["shop"]),
  },
  methods: {
    getSiteQRValue() {
      return `https://${
        this.embedKey.origins[this.embedKey.origins.length - 1]
      }`;
    },
    generatePDF() {
      this.$refs.html2Pdf.generatePdf();
    },
  },
};
</script>

<style scoped></style>
