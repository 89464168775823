<template>
  <b-tab>
    <template #title> <i class="fas fa-list-alt mr-1"></i> Options </template>
    <b-container>
      <b-row>
        <b-col>
          <b-button
            class="float-right mb-3"
            pill
            variant="primary"
            size="lg"
            v-b-modal.create-option-modal
          >
            Add Option
          </b-button>
          <b-button
            class="float-right mb-3 mr-2"
            pill
            variant="secondary"
            size="lg"
            v-b-modal.option-sort-modal
          >
            Sort Options
          </b-button>
        </b-col>
      </b-row>
      <b-row>
        <b-col
          md="12"
          lg="4"
          v-for="option of category.options"
          :key="option._id"
        >
          <b-card
            class="text-capitalize mt-3"
            text-variant="dark"
            style="min-height: 330px"
          >
            <template #header>
              <h5>{{ option.name }}</h5>
              <small>{{ option.type }}</small>
            </template>
            <ul class="pl-0">
              <li
                v-for="value of option.values"
                :key="value.value"
                style="
                  overflow: hidden;
                  -ms-text-overflow: ellipsis;
                  text-overflow: ellipsis;
                  white-space: nowrap;
                "
              >
                - {{ value.name }} (${{ value.price / 100 }})
              </li>
            </ul>
            <br />

            <template #footer>
              <b-button
                pill
                size="lg"
                variant="primary"
                :to="`/shop/menu/${category._id}/options/${option._id}`"
              >
                <i class="fas fa-edit" />
              </b-button>
              <b-button
                size="lg"
                pill
                variant="danger"
                class="float-right"
                v-b-modal.delete-option-modal
                @click="selectedOption = option"
              >
                <i class="fas fa-trash" />
              </b-button>
            </template>
          </b-card>
        </b-col>
      </b-row>
    </b-container>
    <create-option-modal :category="category" />
    <delete-option-modal :option="selectedOption" />
    <option-sort-modal :category="category" />
  </b-tab>
</template>

<script>
import CreateOptionModal from "./add-option-modal";
import DeleteOptionModal from "./delete-option-modal";
import OptionSortModal from "./option-sort-modal";
export default {
  name: "options",
  components: { OptionSortModal, DeleteOptionModal, CreateOptionModal },
  data: () => ({
    selectedOption: null,
  }),
  props: ["category"],
};
</script>

<style scoped></style>
