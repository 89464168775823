<template>
  <div class="main">
    <div class="left-panel"></div>
    <div class="right-panel">
      <div class="form-container">
        <form id="login-form" @submit="onSubmit">
          <img
            src="../assets/host_logo_Landscape_green.png"
            class="logo"
            style="width: 260px"
          />
          <b-form-input placeholder="Email" type="email" v-model="email" />
          <b-button :disabled="loading" type="submit" class="login-submit">
            <span>Send request</span>
          </b-button>
          <router-link to="/login" class="go-to-login">
            back to login
          </router-link>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { beforeRouteEnter } from "../router";

export default {
  name: "Password Reset",
  data: () => ({
    email: null,
    password: null,
    loading: false,
  }),
  metaInfo: {
    title: "Request Password Reset",
  },
  computed: {
    ...mapGetters(["embedId"]),
  },
  methods: {
    async onSubmit(e) {
      this.loading = true;
      e.preventDefault();
      await this.passwordReset({
        email: this.email,
      });
      this.loading = false;
    },
    ...mapActions({
      passwordReset: "PASSWORD_RESET",
    }),
  },
  beforeRouteEnter,
};
</script>

<style scoped>
.main {
  height: 100%;
  width: 100%;
  display: table;
}
.logo {
  width: 300px;
  margin: 10px auto;
  display: block;
}

.left-panel {
  display: table-cell;
  height: 100%;
  width: 40%;
  position: relative;
  background: url(../assets/hmfdzw6iy8qd35sxublc.jpg) center center/cover
    no-repeat;
}
.right-panel {
  display: table-cell;
  height: 100%;
  position: relative;
  width: 60%;
  background-color: #5b7fbd;
}

#login-form {
  margin: auto auto;
}

.right-panel h3 {
  color: ##14253e;
  font-family: averta, avenir w02, avenir, Helvetica, Arial, sans-serif;
  font-style: normal;
  text-align: center;
  text-transform: uppercase;
}

.form-container {
  width: 100%;
  display: flex;
  height: 100%;
}

.form-control {
  font-family: averta, avenir w02, avenir, Helvetica, Arial, sans-serif;
  margin: 10px 0px;
}

.login-submit {
  background: ##14253e;
  padding: 10px;
  display: block;
  border: none;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  width: 100%;
  margin: 10px 0px;
}

.login-submit span {
  font-family: Helvetica Neue;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  /* identical to box height */
  width: 100%;
  display: inline-block;
  align-items: center;
  text-align: center;
  letter-spacing: 2.30769px;
  text-transform: uppercase;
  color: white;
}

.go-to-login {
  color: white;
  text-transform: uppercase;
  font-size: 20px;
  text-align: center;
  width: 100%;
  margin: 10px 0px;
  display: block;
  text-decoration: none;
}

.account-question a {
  color: ##14253e;
  font-weight: bolder;
  text-decoration: none;
}

@media (max-width: 750px) {
  .right-panel {
    width: 100%;
  }

  .left-panel {
    display: none;
  }
}
</style>
