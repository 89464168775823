<template>
  <div
    id="loyalty"
    class="shop-section col-xs-12"
    v-bind:class="{ active: isActive }"
    @click="$emit('activeSection', 'loyalty')"
  >
    <div class="header">
      <i class="fas fa-crown section-icon" />

      <h5 class="section-title dark-blue-color">
        Loyalty Settings
        <br />
        <small>Store's loyalty system settings</small>
      </h5>
    </div>
    <b-col class="option-content col-xs-12" v-if="shopInfo">
      <b-form class="col-lg-12" @submit="saveDetails">
        <b-form-row>
          <b-col cols="12">
            <label>
              <input type="checkbox" v-model="shopInfo.loyalty.enabled" />
              Enable shop loyalty system
            </label>
          </b-col>
        </b-form-row>

        <b-form-group v-if="shopInfo && shopInfo.loyalty.enabled">
          <!--<b-form-row>-->
          <!--<b-col cols="12">-->
          <!--<h5 class="orange-color">-->
          <!--Loyalty Mechanism-->
          <!--</h5>-->
          <!--</b-col>-->
          <!--<b-col cols="12">-->
          <!--<div class="radio">-->
          <!--<label>-->
          <!--<input type="radio" id="otg" v-model="shopInfo.loyalty.mechanism" value="otg"/>-->
          <!--Use On the Go built-in loyalty mechanism-->
          <!--</label>-->
          <!--</div>-->
          <!--</b-col>-->
          <!--<b-col cols="12">-->
          <!--<div class="radio col-xs-12 npsds">-->
          <!--<label>-->
          <!--<input type="radio" id="custom" v-model="shopInfo.loyalty.mechanism" value="custom"/>-->
          <!--Use a custom loyalty mechanism based on collection of reward number per order-->
          <!--</label>-->
          <!--</div>-->
          <!--</b-col>-->
          <!--</b-form-row>-->

          <b-form-row v-if="shopInfo.loyalty.mechanism === 'otg'">
            <b-row>
              <b-col cols="12">
                <div class="divider" />
                <h5 class="orange-color">Points Collection</h5>
              </b-col>

              <b-col sm="12" md="6">
                <label>Points assignation</label>
                <select
                  class="form-control text-capitalize"
                  v-model="shopInfo.loyalty.type"
                >
                  <option>order</option>
                  <option>item</option>
                  <option>item in category</option>
                </select>
                <small
                  >Points will be earned either 1 per order, 1 per any item, or
                  1 per specified items within your selected category
                </small>
              </b-col>

              <b-col sm="12" md="6">
                <label>Total Points for reward</label>
                <input
                  class="form-control"
                  type="number"
                  v-model="shopInfo.loyalty.limit"
                />
                <small
                  >Number of points needed before the free reward is earned i.e.
                  after 9 points, the customers 10th will be their reward item.
                </small>
              </b-col>

              <b-col
                sm="12"
                md="6"
                v-b-tooltip.hover
                :title="`If you wish to select multiple categories use the &quot;shift&quot; or &quot;ctrl&quot; buttons of your keyboard along the selection click`"
                v-if="shopInfo.loyalty.type === 'item in category'"
              >
                <label> Points assignation item category </label>
                <select
                  multiple
                  class="form-control text-capitalize"
                  v-model="shopInfo.loyalty.itemCategories"
                >
                  <option
                    v-for="category of categories"
                    :key="category._id"
                    :value="category._id"
                  >
                    {{ category.name }}
                  </option>
                </select>
                <small
                  >Customers will earn points on the highlighted categories
                  above</small
                >
              </b-col>
            </b-row>
            <div class="divider" />
            <b-row>
              <b-col cols="12">
                <h5 class="orange-color">Reward</h5>
              </b-col>

              <b-col sm="12" md="6">
                <label>Free item within a category</label>
                <select
                  class="form-control text-capitalize"
                  v-model="shopInfo.loyalty.reward.type"
                >
                  <option>free item</option>
                  <option>free item on category</option>
                  <!--<option>discount %</option>-->
                  <!--<option>discount in $</option>-->
                </select>
                <small
                  >This specifies the free item within a category that your
                  customers will receive when they fill their loyalty card. It
                  can be a specific product, 1x any product under a category,
                  discount in $ or discount % in their next purchase.
                </small>
              </b-col>

              <b-col
                sm="12"
                md="6"
                v-if="shopInfo.loyalty.reward.type === 'free item'"
              >
                <label>Reward Item</label>
                <select
                  class="form-control text-capitalize"
                  v-model="shopInfo.loyalty.reward.item"
                >
                  <optgroup
                    v-for="category of categories"
                    :key="category._id"
                    :label="category.name"
                  >
                    <option
                      v-for="product of category.products"
                      :key="product._id"
                      :value="product._id"
                    >
                      {{ product.name }}
                    </option>
                  </optgroup>
                </select>
                <small
                  >You can specify the item that your customers can exchange for
                  the reward points. Can be a muffin or a completely custom
                  product to cover multiple types of products, but with similar
                  price
                </small>
              </b-col>

              <b-col
                sm="12"
                md="6"
                v-b-tooltip.hover
                :title="`If you wish to select multiple categories use the &quot;shift&quot; or &quot;ctrl&quot; buttons of your keyboard along the selection click`"
                v-if="shopInfo.loyalty.reward.type === 'free item on category'"
              >
                <label>Reward Categories</label>
                <select
                  multiple
                  class="form-control text-capitalize"
                  v-model="shopInfo.loyalty.reward.categories"
                >
                  <option
                    v-for="category of categories"
                    :key="category._id"
                    :value="category._id"
                  >
                    {{ category.name }}
                  </option>
                </select>
                <small
                  >You can specify 1 or multiple categories from where 1 item
                  will be for free in your next customer's purchase
                </small>
              </b-col>

              <b-col
                sm="12"
                md="6"
                v-if="shopInfo.loyalty.reward.type === 'discount %'"
              >
                <label>Discount Percentage</label>
                <input
                  class="form-control"
                  v-model="shopInfo.loyalty.reward.discountPercentage"
                />
                <small
                  >Indicates the percentage of discount that your customer will
                  receive as reward on their next purchase
                </small>
              </b-col>

              <b-col
                sm="12"
                md="6"
                v-if="shopInfo.loyalty.reward.type === 'discount in $'"
              >
                <label>Discount Amount</label>
                <input
                  class="form-control"
                  v-model="shopInfo.loyalty.reward.discountAmount"
                />
                <small
                  >Indicates the discount amount in $ that your customer will
                  receive as reward on their next purchase
                </small>
              </b-col>
            </b-row>
            <div class="divider" />
            <b-row>
              <b-col cols="12">
                <h5 class="orange-color">Presentation</h5>
              </b-col>

              <b-col cols="12">
                <label>Stamp Icon</label>
                <v-select
                  :options="icons"
                  label="name"
                  v-model="shopInfo.loyalty.icon"
                >
                  <template v-slot:option="defaultIcon">
                    <img
                      :src="defaultIcon.icon"
                      style="width: 32px; height: 32px; margin-right: 5px"
                      class=""
                    />
                    <small class="">{{ defaultIcon.name }}</small>
                  </template>
                  <template v-slot:selected-option="defaultIcon">
                    <img
                      :src="defaultIcon.icon"
                      style="width: 32px; height: 32px; margin-right: 5px"
                      class=""
                    />
                    <small class="">{{ defaultIcon.name }}</small>
                  </template>
                </v-select>
                <small>Loyalty card display icon stamp</small>
              </b-col>
            </b-row>
            <div class="divider" />
          </b-form-row>
          <b-form-row>
            <b-row class="mt-3">
              <b-col cols="12">
                <h5 class="orange-color">QR Code</h5>
              </b-col>

              <b-col sm="12" md="6">
                <div class="checkbox">
                  <label>
                    <input
                      type="checkbox"
                      v-model="shopInfo.loyalty.qrCode.enabled"
                    />
                    Enable QR Code scan loyalty programme
                  </label>
                </div>

                <small
                  >Allow customers to earn loyalty points scanning QR codes
                  provided in you venue</small
                >
              </b-col>

              <b-col
                sm="12"
                md="6"
                v-if="
                  shopInfo.loyalty.qrCode && shopInfo.loyalty.qrCode.enabled
                "
              >
                <label>Total points earned per scanned code</label>
                <input
                  class="form-control"
                  type="number"
                  v-model="shopInfo.loyalty.qrCode.pointsPerScan"
                />
                <small
                  >This set how many points are earned each time a customer
                  scans your Loyalty QR Code</small
                >
              </b-col>

              <b-col
                sm="12"
                md="6"
                v-if="
                  shopInfo.loyalty.qrCode && shopInfo.loyalty.qrCode.enabled
                "
              >
                <label>Scan Daily limit</label>
                <input
                  class="form-control"
                  type="number"
                  v-model="shopInfo.loyalty.qrCode.scanDailyLimit"
                />
                <small
                  >This sets how many times a day a user can scan your Loyalty
                  QR Code and earn loyalty points
                </small>
              </b-col>

              <b-col
                cols="12"
                v-if="
                  shopInfo.loyalty.qrCode && shopInfo.loyalty.qrCode.enabled
                "
              >
                <h4>This is your Loyalty QR Code</h4>
                <qrcode-vue :value="shop._id" size="200"></qrcode-vue>
                <small
                  >Do right-click over this code and download it in your
                  preferred image format</small
                >
              </b-col>
            </b-row>
          </b-form-row>
        </b-form-group>
        <b-row>
          <b-col>
            <b-button
              variant="primary"
              type="submit"
              class="float-right mb-1"
              :disabled="loading"
              pill
            >
              <b-spinner v-if="loading" />
              <span v-else>Save</span>
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </b-col>
  </div>
</template>

<script>
import QrcodeVue from "qrcode.vue";
import { mapGetters } from "vuex";

export default {
  name: "shop-loyalty",
  components: {
    QrcodeVue,
  },
  props: ["isActive", "shop"],
  data: () => ({
    shopInfo: {
      loyalty: {
        mechanism: "otg",
      },
    },
  }),
  computed: {
    ...mapGetters("category", ["categories"]),
    ...mapGetters("shop", ["icons", "loading"]),
  },
  methods: {
    saveDetails(e) {
      e.preventDefault();
      let shopInfo = {
        loyalty: {
          ...this.shopInfo.loyalty,
        },
      };
      this.$emit("saveChanges", shopInfo);
    },
  },
  mounted() {
    this.shopInfo = JSON.parse(JSON.stringify(this.shop));
    console.log(this.shopInfo);
  },
  watch: {
    shop(newVal) {
      if (!newVal) return;
      this.shopInfo = {
        ...newVal,
      };
    },
  },
};
</script>

<style scoped>
.divider {
  border-bottom: 1px solid rgba(60, 60, 60, 0.26);
  width: 100%;
  margin: 10px 0px;
}
</style>
