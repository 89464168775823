<template>
  <div class="col-xs-12 npsds">
    <b-col cols="12" class="p-0">
      <div>
        {{ moment(start).format(periodUnit === "days" ? "Do MMM" : "HH:mm") }}
        -
        {{ moment(end).format(periodUnit === "days" ? "Do MMM" : "HH:mm") }}
      </div>
    </b-col>
    <div class="canvas-loyalty-products col-xs-12 npsds">
      <canvas id="embed-vs-app-chart"></canvas>
    </div>
  </div>
</template>

<script>
import moment from "moment";
export default {
  name: "embed-vs-app",
  props: ["reportData", "start", "end", "periodUnit"],
  methods: {
    moment,
    fillGraph(newVal) {
      let { chart } = this;
      if (chart) chart.destroy();

      let data = {
        labels: [],
        datasets: [],
      };

      data.datasets.push({
        data: [
          newVal.appCount,
          newVal.embedCount,
          newVal.kioskCount,
          newVal.posCashCount,
          newVal.posEFTPOSCount,
        ],
        backgroundColor: [
          "rgba(255, 99, 132, 0.2)",
          "rgba(255, 159, 64, 0.2)",
          "rgba(255, 205, 86, 0.2)",
          "rgba(75, 192, 192, 0.2)",
          "rgba(54, 162, 235, 0.2)",
          "rgba(153, 102, 255, 0.2)",
          "rgba(201, 203, 207, 0.2)",
        ],
      });

      data.labels = [
        "App",
        "Online Order",
        "Kiosk",
        "POS(cash)",
        "POS(EFTPOS)",
      ];

      chart = new window.Chart(
        document.getElementById(`embed-vs-app-chart`).getContext("2d"),
        {
          type: "doughnut",
          data,
        }
      );
      this.chart = chart;
    },
  },
  watch: {
    reportData(newVal) {
      if (!newVal) return;
      this.fillGraph(newVal);
    },
  },
};
</script>

<style scoped>
.report-title {
  color: #5b7fbd;
}
</style>
