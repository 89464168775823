<template>
  <div
    id="delivery"
    class="shop-section col-xs-12"
    v-bind:class="{ active: isActive }"
    @click="$emit('activeSection', 'delivery')"
  >
    <div class="header">
      <i class="fas fa-truck section-icon" />

      <h5 class="section-title dark-blue-color">
        Delivery Settings
        <br />
        <small>Define your store delivery's settings & pricing</small>
      </h5>
    </div>
    <b-col
      class="option-content col-xs-12"
      v-if="shopInfo && shopInfo.delivery"
    >
      <b-form class="col-xs-12" @submit="saveDetails">
        <b-row>
          <b-col sm="12" md="6">
            <label>Delivery Type</label>
            <select
              class="form-control"
              v-model="shopInfo.delivery.deliveryType"
            >
              <option value="self-delivery">Self Delivery</option>
              <option value="retail">Retail</option>
            </select>
            <small>Define the type of delivery service</small>
          </b-col>
          <b-col
            sm="12"
            md="6"
            v-if="shopInfo.delivery.deliveryType === 'self-delivery'"
          >
            <label>Availability Radius</label>
            <b-input-group class="input-group" append="KMS">
              <input
                class="form-control"
                type="number"
                placeholder="Enter here the amount in kilometers"
                v-model="shopInfo.delivery.radius"
              />
            </b-input-group>
            <small
              >Define the amount of kilometers radius where your shop will
              provide delivery</small
            >
          </b-col>
          <b-col
            sm="12"
            md="6"
            v-if="shopInfo.delivery.deliveryType === 'self-delivery'"
          >
            <label>Order Minimum</label>
            <b-input-group prepend="$">
              <input
                class="form-control"
                type="number"
                placeholder="Enter here the order minimum amount"
                v-model="shopInfo.delivery.orderMinimum"
              />
            </b-input-group>
            <small
              >Define the minimum total amount of an order to enable
              delivery</small
            >
          </b-col>
          <b-col sm="12" md="6">
            <label>Fee Type</label>
            <select class="form-control" v-model="shopInfo.delivery.feeType">
              <option value="fixed">Fixed</option>
              <option
                v-if="shopInfo.delivery.deliveryType === 'self-delivery'"
                value="cost-per-kilometers"
              >
                Cost Per Kilometers
              </option>
            </select>
            <small>
              Define the delivery fee strategy, whenever it will charge a fixed
              cost anywhere in the shop radius or if will charge a small amount
              for every X kilometers of distance
            </small>
          </b-col>
          <b-col
            sm="12"
            md="6"
            v-if="shopInfo.delivery.feeType === 'cost-per-kilometers'"
          >
            <label>Cost Per Kilometers</label>
            <b-input-group prepend="$">
              <input
                class="form-control"
                type="number"
                placeholder="Enter here the amount in kilometers"
                v-model="shopInfo.delivery.costPerKilometers"
              />
            </b-input-group>
            <small
              >Define the amount of kilometers radius where your shop will
              provide delivery<br
            /></small>
          </b-col>

          <b-col v-else sm="12" md="6">
            <label>Delivery Fixed Fee</label>
            <b-input-group prepend="$">
              <input
                class="form-control"
                type="number"
                placeholder="Enter here the fixed amount"
                v-model="shopInfo.delivery.fixedCost"
              />
            </b-input-group>
            <small>Define the fixed cost delivery fee</small>
          </b-col>
          <b-col sm="12" md="6">
            <label>Free Delivery</label>
            <b-input-group prepend="$">
              <input
                class="form-control"
                type="number"
                placeholder="Enter here the total order amount"
                v-model="shopInfo.delivery.freeDeliveryMinSpend"
              />
            </b-input-group>
            <small
              >Define the total order amount which will entitle the user with a
              free delivery. Enter 0 if no free-delivery is offered
            </small>
          </b-col>
        </b-row>
        <b-row>
          <b-col class="pt-6" sm="12" md="6">
            <b-form-group
              v-if="user.roles.includes('admin')"
              label="Display Pickup on:"
              description="This option hide takeaway options and other UI elements for dine in bar orders"
            >
              <b-dropdown
                block
                variant="primary"
                toggle-class="text-capitalize"
                :text="
                  shopInfo.delivery.displayPlatforms &&
                  shopInfo.delivery.displayPlatforms.length > 0
                    ? shopInfo.delivery.displayPlatforms.join(' - ')
                    : 'Select Platform Options'
                "
              >
                <b-dropdown-item
                  :active="
                    shopInfo.delivery.displayPlatforms &&
                    shopInfo.delivery.displayPlatforms.includes('marketplace')
                  "
                  @click.stop.prevent="
                    selectDeliveryDisplayPlatforms('marketplace')
                  "
                  >On the Go Marketplace</b-dropdown-item
                >
                <b-dropdown-item
                  :active="
                    shopInfo.delivery.displayPlatforms &&
                    shopInfo.delivery.displayPlatforms.includes('embed')
                  "
                  @click.stop.prevent="selectDeliveryDisplayPlatforms('embed')"
                  >Embed Store</b-dropdown-item
                >
                <b-dropdown-item
                  :active="
                    shopInfo.delivery.displayPlatforms &&
                    shopInfo.delivery.displayPlatforms.includes('kiosk')
                  "
                  @click.stop.prevent="selectDeliveryDisplayPlatforms('kiosk')"
                  >Kiosk</b-dropdown-item
                >
              </b-dropdown>
            </b-form-group>
          </b-col>
        </b-row>
      </b-form>
      <b-row>
        <b-col>
          <b-button
            variant="primary"
            type="submit"
            class="float-right mb-1 ml-2"
            :disabled="loading"
            pill
            @click="saveDetails"
          >
            <b-spinner v-if="loading" />
            <span v-else>Save</span>
          </b-button>
          <b-button
            :variant="
              shopInfo.delivery.enabled ? 'outline-danger' : 'outline-primary'
            "
            @click="toggleDelivery"
            class="float-right mb-1"
            :disabled="loading"
            pill
          >
            <b-spinner v-if="loading" />
            <span v-else
              >{{
                shopInfo.delivery.enabled ? "Disable" : "Enable"
              }}
              Self-Delivery</span
            >
          </b-button>
        </b-col>
      </b-row>
    </b-col>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "shop-delivery",
  props: ["isActive"],
  data: () => ({
    shopInfo: null,
  }),
  computed: {
    ...mapGetters("shop", ["loading", "shop"]),
    ...mapGetters({ user: "userInfo" }),
  },
  methods: {
    saveDetails(e) {
      e.preventDefault();
      let shopInfo = {
        delivery: this.shopInfo.delivery,
      };
      this.$emit("saveChanges", shopInfo);
    },
    toggleDelivery(e) {
      this.shopInfo.delivery.enabled = !this.shopInfo.delivery.enabled;
      this.saveDetails(e);
    },
    selectDeliveryDisplayPlatforms(platform) {
      let platforms = [];
      if (this.shopInfo.delivery.displayPlatforms)
        platforms = this.shopInfo.delivery.displayPlatforms;
      if (platforms.includes(platform))
        platforms = platforms.filter((p) => p !== platform);
      else platforms.push(platform);
      this.shopInfo.delivery.displayPlatforms = platforms;
      this.$forceUpdate();
    },
  },
  mounted() {
    this.shopInfo = JSON.parse(JSON.stringify(this.shop));
  },
  watch: {
    shop(newVal) {
      if (!newVal) return;
      this.shopInfo = {
        ...newVal,
      };
    },
  },
};
</script>

<style scoped>
.pt-6 {
  padding-top: 1.5rem;
}
</style>
