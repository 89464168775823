export default {
  loading: (state) => state.loading,
  report: (state) => state.report,
  todaySalesPerHour: (state) => state.todaySalesPerHour,
  todayBalance: (state) => state.todayBalance,
  tomorrowPayout: (state) => state.tomorrowPayout,
  overViewSalesReport: (state) => state.overViewSalesReport,
  todayVSYesterdayOverViewSalesReport: (state) =>
    state.todayVSYesterdayOverViewSalesReport,
  mostSoldProducts: (state) => state.mostSoldProducts,
  loyaltyProducts: (state) => state.loyaltyProducts,
  embedVSApp: (state) => state.embedVSApp,
};
