<template>
  <b-tab style="height: 100%">
    <template #title>
      <i class="fas fa-file-invoice-dollar mr-1"></i> Order History
    </template>
    <b-card style="height: 80%">
      <b-row>
        <b-col>
          <h4 class="orange-color">
            Customer's Orders({{ totalCustomerOrders }})
          </h4>
        </b-col>
        <b-col>
          <b-pagination
            class="mt-3 float-right"
            v-model="page"
            :total-rows="totalCustomerOrders"
            :per-page="perPage"
            @page-click="pageUpdate"
          />
        </b-col>
      </b-row>
      <b-table
        sticky-header
        head-row-variant="primary"
        :items="customerOrders"
        :fields="fields"
        style="min-height: 600px"
      >
        <template #cell(total)="data">
          {{ (data.item.total / 100) | numeral("$0,0.00") }}
        </template>

        <template #cell(pickupTime)="data">
          {{ moment(data.item.pickupTime).format("Do MMM YYYY - HH:mm") }}
        </template>

        <template #cell(details)="row">
          <b-button size="sm" @click="row.toggleDetails" pill variant="light">
            <i class="fas fa-info-circle" />
          </b-button>
        </template>
        <template #row-details="row">
          <b-card
            v-for="(lineItem, index) of row.item.lineItems"
            :key="`${row.item._id}-order-line-item-${index}`"
            class="mt-2"
          >
            <b-row>
              <b-col>
                <h3>
                  <b-img
                    class="mr-2"
                    :src="lineItem.product.icon.icon"
                    style="width: 50px"
                  />{{ lineItem.product.name }}
                </h3>
              </b-col>
              <b-col>
                <b-form-group label="Quantity">
                  {{ lineItem.quantity }}
                </b-form-group>
              </b-col>
              <b-col>
                <b-form-group label="Details">
                  <ul>
                    <li
                      v-for="(config, configIndex) of lineItem.config"
                      :key="`${row.item._id}-order-line-item-${index}-config-${configIndex}`"
                    >
                      <strong>{{ config.option.name }}:</strong>
                      {{
                        config && config.value
                          ? config.value.name
                          : config.value
                      }}
                    </li>
                  </ul>
                </b-form-group>
              </b-col>
              <b-col>
                <b-form-group label="Notes">
                  <small>{{ lineItem.notes }}</small>
                </b-form-group>
              </b-col>
              <b-col>
                <b-form-group label="Total">
                  {{
                    (getLineItemTotalCost(lineItem) / 100) | numeral("$0,0.00")
                  }}
                </b-form-group>
              </b-col>
            </b-row>
          </b-card>
        </template>
      </b-table>
    </b-card>
  </b-tab>
</template>

<script>
import { mapGetters } from "vuex";
import * as moment from "moment";

export default {
  name: "orders",
  props: ["customer", "shop"],
  data: () => ({
    page: 1,
    perPage: 10,
    fields: [
      {
        key: "_id",
        label: "ID",
      },
      {
        key: "status",
        label: "Status",
      },
      {
        key: "total",
        label: "Total",
      },
      {
        key: "pickupTime",
        label: "Pick-up time",
      },
      {
        key: "origin",
        label: "Origin",
      },
      {
        key: "details",
        label: "Details",
      },
    ],
  }),
  computed: {
    ...mapGetters("order", ["customerOrders", "totalCustomerOrders"]),
  },
  methods: {
    moment,
    async pageUpdate(e, num) {
      await this.$store.dispatch("order/GET_CUSTOMER_ORDERS", {
        userId: this.customer._id,
        shopId: this.shop._id,
        options: {
          limit: this.perPage,
          skip: (num - 1) * this.perPage,
          sort: "-createdAt",
        },
      });
      this.page = num;
    },
    getLineItemTotalCost(item) {
      let total = 0;
      total += item.product.price;
      for (let option of item.config) {
        console.log(option.value);
        if (option.value) {
          if (option.value.constructor !== Array) {
            if (option.value.price) total += parseInt(option.value.price);
          } else {
            for (let op of option.value) {
              if (op.price) total += parseInt(op.price);
            }
          }
        }
      }
      return total;
    },
  },
  async mounted() {
    console.log(this.customer);
    await this.$store.dispatch("order/GET_CUSTOMER_ORDERS", {
      userId: this.customer._id,
      shopId: this.shop._id,
      options: {
        limit: this.perPage,
        skip: (this.page - 1) * this.perPage,
        sort: "-createdAt",
      },
    });
  },
  watch: {
    async customer(newVal) {
      alert();
      if (!newVal) return;
      await this.$store.dispatch("order/GET_CUSTOMER_ORDERS", {
        userId: newVal._id,
        shopId: this.shop._id,
        options: {
          limit: this.perPage,
          skip: (this.page - 1) * this.perPage,
          sort: "-createdAt",
        },
      });
    },
  },
};
</script>

<style scoped></style>
