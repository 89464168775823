<template>
  <b-col style="padding-top: 0px; height: 100%; overflow: scroll" v-if="shop">
    <b-card style="margin-top: 20px">
      <h4 class="col-xs-7">Here's your store performance</h4>
      <b-card>
        <b-row>
          <b-col sm="12" md="6"> </b-col>
          <b-col sm="12" md="6">
            <h5 class="text-right report-title">Today</h5>
          </b-col>
        </b-row>
        <hr class="mt-0" />
        <b-row class="mt-3">
          <b-col sm="12" md="12">
            <bar-period-sales
              :percentageDeduction="0"
              :reportData="todaySalesPerHour"
              :start="moment().startOf('day')"
              :end="moment().endOf('day')"
              :periodUnit="'hours'"
              :chartCanvasId="'today-sales-chart'"
              :graph-type="bar"
            />
          </b-col>
        </b-row>
        <b-row class="py-3">
          <b-col sm="12" md="4">
            <today-badge-metric
              :data="todayVSYesterdayOverViewSalesReport"
              type="total-sales"
            />
          </b-col>
          <b-col sm="12" md="4">
            <today-badge-metric
              :data="todayVSYesterdayOverViewSalesReport"
              type="total-orders"
            />
          </b-col>
          <b-col sm="12" md="4">
            <today-badge-metric
              :data="todayVSYesterdayOverViewSalesReport"
              type="average-orders"
            />
          </b-col>
        </b-row>
      </b-card>
      <b-card class="mt-2">
        <b-row>
          <b-col sm="12" md="6"> </b-col>
          <b-col sm="12" md="6">
            <div
              id="reportrange"
              style="
                background: #fff;
                cursor: pointer;
                padding: 5px 0px 5px 10px;
              "
              class="float-right"
            >
              <date-range-picker
                style="width: 100%"
                ref="picker"
                v-model="dateRange"
                :ranges="dateRanges"
                opens="left"
                :showRangeLabel="true"
              >
                <template v-slot:input="picker" style="min-width: 100%">
                  <i class="far fa-calendar-alt"></i>&nbsp;
                  <strong class="report-title"
                    >{{ picker.startDate | moment("DD/MM/YYYY") }} -
                    {{ picker.endDate | moment("DD/MM/YYYY") }}</strong
                  >
                  <i
                    class="fa fa-caret-down float-right"
                    style="line-height: 1.8"
                  ></i>
                </template>
              </date-range-picker>
            </div>
          </b-col>
        </b-row>
        <hr class="mt-0" />
        <b-row class="mt-3">
          <b-col sm="12" md="6">
            <most-sold-products :reportData="mostSoldProducts" />
          </b-col>
          <b-col sm="12" md="6">
            <h5 class="report-title">Order breakdown</h5>
            <embed-vs-app
              :reportData="embedVSApp"
              :start="dateRange.startDate"
              :end="dateRange.endDate"
              :periodUnit="'days'"
            />
          </b-col>
        </b-row>

        <b-row class="mt-3">
          <b-col sm="12" md="6">
            <h5 class="report-title">Gross Volume</h5>
            <period-sales
              :percentageDeduction="0"
              :reportData="overViewSalesReport"
              :start="dateRange.startDate"
              :end="dateRange.endDate"
              :periodUnit="'days'"
              :chartCanvasId="'period-sales-chart'"
            />
          </b-col>
          <b-col sm="12" md="6">
            <h5 class="report-title">Net Volume</h5>
            <period-sales
              :percentageDeduction="
                shop.subscription && shop.subscription.id
                  ? shop.subscription.percent
                  : shop.orderingPreferences.feePercent
              "
              :reportData="overViewSalesReport"
              :start="dateRange.startDate"
              :end="dateRange.endDate"
              :periodUnit="'days'"
              :chartCanvasId="'period-net-sales-chart'"
            />
          </b-col>
        </b-row>
      </b-card>
    </b-card>
  </b-col>
</template>

<script>
import * as moment from "moment";
import { mapGetters } from "vuex";
import PeriodSales from "../components/dashboard/period-sales";
import BarPeriodSales from "../components/dashboard/bar-period-sales";
import MostSoldProducts from "../components/dashboard/most-sold-products";
import DateRangePicker from "vue2-daterange-picker/src/components/DateRangePicker";

import { beforeRouteEnter } from "../router";
import EmbedVsApp from "../components/dashboard/embed-vs-app";
import TodayBadgeMetric from "@/components/dashboard/today-badge-metric.vue";

export default {
  name: "home",
  components: {
    TodayBadgeMetric,
    EmbedVsApp,
    PeriodSales,
    MostSoldProducts,
    DateRangePicker,
    BarPeriodSales,
  },
  data: () => ({
    dateRange: {
      startDate: moment().startOf("isoWeek").toDate(),
      endDate: moment().endOf("isoWeek").toDate(),
    },
    dateRanges: {
      Yesterday: [
        moment().subtract(1, "days").startOf("day").toDate(),
        moment().subtract(1, "days").endOf("day").toDate(),
      ],
      "This Week": [
        moment().startOf("isoWeek").toDate(),
        moment().endOf("isoWeek").toDate(),
      ],
      "Previous Week": [
        moment().subtract(1, "weeks").startOf("isoWeek").toDate(),
        moment().subtract(1, "weeks").endOf("isoWeek").toDate(),
      ],
      "This Quarter": [
        moment().subtract(1, "weeks").startOf("isoWeek").toDate(),
        moment().endOf("isoWeek").toDate(),
      ],
      "Previous Quarter": [
        moment().subtract(3, "weeks").startOf("isoWeek").toDate(),
        moment().subtract(2, "weeks").endOf("isoWeek").toDate(),
      ],
      "This Month": [
        moment().startOf("month").toDate(),
        moment().endOf("month").toDate(),
      ],
      "Previous Month": [
        moment().subtract(1, "month").startOf("month").toDate(),
        moment().subtract(1, "month").endOf("month").toDate(),
      ],
    },
  }),
  methods: {
    moment,
  },
  computed: {
    ...mapGetters("shop", ["shop"]),
    ...mapGetters("reports", [
      "todaySalesPerHour",
      "todayBalance",
      "tomorrowPayout",
      "overViewSalesReport",
      "todayVSYesterdayOverViewSalesReport",
      "mostSoldProducts",
      "loyaltyProducts",
      "embedVSApp",
    ]),
  },
  mounted() {
    if (!this.shop) return;
    this.$store.dispatch("reports/GET_TODAY_SALES_PER_HOUR", {
      shopId: this.shop._id,
    });
    this.$store.dispatch("reports/GET_PERIOD_OVERVIEW_SALES", {
      shopId: this.shop._id,
      start: this.dateRange.startDate.toUTCString(),
      end: this.dateRange.endDate.toUTCString(),
    });
    this.$store.dispatch(
      "reports/GET_PERIOD_TODAY_VS_YESTERDAY_OVERVIEW_SALES",
      {
        shopId: this.shop._id,
        start: moment()
          .subtract(1, "days")
          .startOf("day")
          .toDate()
          .toUTCString(),
        end: moment().endOf("day").toDate().toUTCString(),
      }
    );
    this.$store.dispatch("reports/GET_MOST_SOLD_PRODUCTS", {
      shopId: this.shop._id,
      start: this.dateRange.startDate.toUTCString(),
      end: this.dateRange.endDate.toUTCString(),
    });
    this.$store.dispatch("reports/GET_LOYALTY_PRODUCTS", {
      shopId: this.shop._id,
      start: this.dateRange.startDate.toUTCString(),
      end: this.dateRange.endDate.toUTCString(),
    });
    this.$store.dispatch("reports/GET_EMBED_VS_APP", {
      shopId: this.shop._id,
      start: this.dateRange.startDate.toUTCString(),
      end: this.dateRange.endDate.toUTCString(),
    });
  },
  watch: {
    shop(newVal) {
      if (!newVal) return;
      this.$store.dispatch("reports/GET_TODAY_SALES_PER_HOUR", {
        shopId: newVal._id,
      });
      this.$store.dispatch("reports/GET_TODAY_BALANCE", { shopId: newVal._id });
      this.$store.dispatch("reports/GET_TOMORROW_PAYOUT", {
        shopId: newVal._id,
      });
      this.$store.dispatch("reports/GET_PERIOD_OVERVIEW_SALES", {
        shopId: newVal._id,
        start: this.dateRange.startDate.toUTCString(),
        end: this.dateRange.endDate.toUTCString(),
      });
      this.$store.dispatch(
        "reports/GET_PERIOD_TODAY_VS_YESTERDAY_OVERVIEW_SALES",
        {
          shopId: newVal._id,
          start: moment()
            .subtract(1, "days")
            .startOf("day")
            .toDate()
            .toUTCString(),
          end: moment().endOf("day").toDate().toUTCString(),
        }
      );
      this.$store.dispatch("reports/GET_MOST_SOLD_PRODUCTS", {
        shopId: newVal._id,
        start: this.dateRange.startDate.toUTCString(),
        end: this.dateRange.endDate.toUTCString(),
      });
      this.$store.dispatch("reports/GET_LOYALTY_PRODUCTS", {
        shopId: newVal._id,
        start: this.dateRange.startDate.toUTCString(),
        end: this.dateRange.endDate.toUTCString(),
      });
      this.$store.dispatch("reports/GET_EMBED_VS_APP", {
        shopId: newVal._id,
        start: this.dateRange.startDate.toUTCString(),
        end: this.dateRange.endDate.toUTCString(),
      });
    },
    dateRange(newVal) {
      if (!newVal) return;
      this.$store.dispatch("reports/GET_PERIOD_OVERVIEW_SALES", {
        shopId: this.shop._id,
        start: this.dateRange.startDate.toUTCString(),
        end: this.dateRange.endDate.toUTCString(),
      });
      this.$store.dispatch("reports/GET_MOST_SOLD_PRODUCTS", {
        shopId: this.shop._id,
        start: this.dateRange.startDate.toUTCString(),
        end: this.dateRange.endDate.toUTCString(),
      });
      this.$store.dispatch("reports/GET_LOYALTY_PRODUCTS", {
        shopId: this.shop._id,
        start: this.dateRange.startDate.toUTCString(),
        end: this.dateRange.endDate.toUTCString(),
      });
      this.$store.dispatch("reports/GET_EMBED_VS_APP", {
        shopId: this.shop._id,
        start: this.dateRange.startDate.toUTCString(),
        end: this.dateRange.endDate.toUTCString(),
      });
    },
  },
  beforeRouteEnter,
};
</script>

<style scoped>
.report-title {
  color: #5b7fbd;
}

#reportrange::v-deep .daterangepicker {
  top: 40px;
}

#reportrange {
  width: 100%;
}

@media only screen and (min-width: 601px) {
  #reportrange {
    width: 247px;
  }
}
</style>
