<template>
  <b-modal
    id="create-option-modal"
    title="Add Option"
    okTitle="Create"
    @ok="addOption"
  >
    <b-form-group label="Name" description="Option's display name">
      <b-form-input
        v-model="name"
        required
        placeholder="i.e: Size, sides, etc"
      />
    </b-form-group>
  </b-modal>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "create-option-modal",
  props: ["category"],
  data: () => ({
    name: "",
  }),
  computed: {
    ...mapGetters("shop", ["shop"]),
    ...mapGetters("option", ["loading"]),
  },
  methods: {
    async addOption() {
      await this.$store.dispatch("option/CREATE_OPTION", {
        optionInfo: {
          shop: this.shop._id,
          name: this.name,
          category: this.category._id,
        },
        shopId: this.shop._id,
      });

      this.$bvModal.hide("create-option-modal");
    },
  },
};
</script>

<style scoped></style>
