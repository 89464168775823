import Vue from "vue";
import Vuex from "vuex";
import shop from "./modules/shop";
import reports from "./modules/reports";
import promocode from "./modules/promocode";
import category from "./modules/category";
import customer from "./modules/customer";
import product from "./modules/product";
import option from "./modules/option";
import order from "./modules/order";
import kiosk from "./modules/kiosk";
import mutations from "./mutations";
import getters from "./getters";
import actions from "./actions";

Vue.use(Vuex);
export default new Vuex.Store({
  modules: {
    shop,
    category,
    reports,
    promocode,
    customer,
    product,
    option,
    order,
    kiosk,
  },
  state: {
    loading: false,
    shopId: null,
    apiAddress:
      process.env.VUE_APP_API_ADDRESS ||
      "https://api.onthegoapp.co.nz",
    localAddress: process.env.VUE_APP_LOCAL_ADDRESS || "http://localhost:8080",
    stripeKey:
      process.env.VUE_APP_STRIPE_KEY || "pk_test_K6rKkRwI2Wt0wWC5FMVdWmQD",
    userToken: localStorage.getItem("userToken"),
    userId: localStorage.getItem("userId"),
    userInfo: null,
    showLockingLoading: false,
    lockingLoadingTitle: "",
    lockingLoadingDescription: "",
    defaultError: `Something odd just happened on our end, sorry about that. If you experience further problems, please refresh your browser`,
    platformPlans: [],
  },
  actions,
  mutations,
  getters,
});
