<template>
  <div class="navbar-holder" v-bind:class="{ open: open }">
    <div class="navbar-head text-left">
      <h5 class="" style="color: white">
        <img
          src="../assets/host_logo_Landscape_green.png"
          style="width: 130px; margin-left: 25px"
        />
      </h5>
    </div>
    <b-button
      v-b-modal.shop-selection-modal
      class="m-2"
      variant="outline-light"
      style="width: 90%"
    >
      <i
        class="fas fa-store"
        style="display: inline-block; width: 30px; font-size: 20px"
      ></i>
      <div class="user-name" v-if="shop">
        {{ shop.name.normal.substring(0, 15) }}
        <i class="fas fa-caret-down float-right" style="font-size: 20px" />
      </div>
    </b-button>
    <div class="navbar-body" @click="$emit('open')">
      <ul v-if="shop">
        <router-link
          to="/dashboard"
          tag="li"
          v-bind:class="{ active: $route.path.includes('/dashboard') }"
        >
          <i class="fas fa-tachometer-alt"></i>
          <span class="navbar-item-span"> Dashboard </span>
        </router-link>

        <router-link
          to="/shop/customers"
          tag="li"
          v-bind:class="{ active: $route.path.includes('/shop/customers') }"
        >
          <i class="fas fa-users"></i>
          <span class="navbar-item-span"> Customers </span>
        </router-link>

        <router-link
          to="/shop/menu"
          tag="li"
          v-bind:class="{ active: $route.path.includes('/shop/menu') }"
        >
          <i class="fas fa-list-alt"></i>
          <span class="navbar-item-span"> Menu </span>
        </router-link>

        <router-link
          to="/shop/embed-store"
          tag="li"
          v-bind:class="{ active: $route.path.includes('/shop/embed-store') }"
        >
          <i class="fas fa-window-restore"></i>
          <span class="navbar-item-span"> Embed Store </span>
        </router-link>

        <router-link
          to="/shop/kiosks"
          tag="li"
          v-bind:class="{ active: $route.path.includes('/shop/kiosks') }"
        >
          <i class="fas fa-tablet-alt"></i>
          <span class="navbar-item-span"> Kiosks </span>
        </router-link>

        <router-link
          to="/shop/orders"
          tag="li"
          v-bind:class="{ active: $route.path.includes('/shop/orders') }"
        >
          <i class="fas fa-file-invoice-dollar"></i>
          <span class="navbar-item-span"> Orders </span>
        </router-link>

        <router-link
          to="/shop/promo-codes"
          tag="li"
          v-bind:class="{ active: $route.path.includes('/shop/promo-codes') }"
        >
          <i class="fas fa-barcode"></i>
          <span class="navbar-item-span"> Voucher Manager </span>
        </router-link>

        <router-link
          to="/shop/reports"
          tag="li"
          v-bind:class="{ active: $route.path.includes('/shop/reports') }"
        >
          <i class="fas fa-chart-bar"></i>
          <span class="navbar-item-span"> Reports </span>
        </router-link>

        <router-link
          to="/shop/integrations"
          tag="li"
          v-bind:class="{ active: $route.path.includes('/shop/integrations') }"
        >
          <i class="fas fa-plug"></i>
          <span class="navbar-item-span"> Integrations </span>
        </router-link>
        <router-link
          to="/shop/settings"
          tag="li"
          v-bind:class="{ active: $route.path.includes('/shop/settings') }"
        >
          <i class="fas fa-cogs"></i>
          <span class="navbar-item-span"> Settings </span>
        </router-link>
      </ul>
      <b-dropdown
        variant="link"
        dropright
        v-if="userInfo"
        style="width: 95%; color: white"
      >
        <template #button-content>
          <img src="../assets/Userpic.png" class="user-image" />
          <div class="user-name" style="color: white">
            {{ userInfo.name.given }} {{ `${userInfo.name.family}`.charAt(0) }}.
          </div>
        </template>
        <b-dropdown-item to="/my-account">My Account</b-dropdown-item>
        <b-dropdown-item
          to="/admin/users"
          v-if="userInfo.roles.includes('admin')"
        >
          All Users
        </b-dropdown-item>
        <b-dropdown-item
          to="/admin/shops"
          v-if="userInfo.roles.includes('admin')"
        >
          All Shops
        </b-dropdown-item>
        <b-dropdown-divider></b-dropdown-divider>
        <b-dropdown-item @click="logout">Logout</b-dropdown-item>
      </b-dropdown>
    </div>
    <shop-selection-modal />
    <create-shop-modal />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import shopSelectionModal from "./shop-selection-modal";
import CreateShopModal from "./create-shop-modal";
export default {
  name: "navbar",
  components: { CreateShopModal, shopSelectionModal },
  props: ["open"],
  data: () => ({
    menuOpen: false,
    shopMenuOpen: false,
    newShop: {
      name: { normal: "" },
      images: {
        hero: "",
      },
    },
  }),
  methods: {
    logout() {
      localStorage.clear();
      window.location = "/";
    },
    customLabel(value) {
      return value ? value.name.normal : "";
    },
    selectShop(shop) {
      if (shop._id !== 0) {
        localStorage.setItem("shopId", shop._id);
        this.$store.dispatch("shop/GET_SHOP", {
          shopId: shop._id,
          options: { includeStripeAccount: true },
        });
      } else {
        this.$modal.show("newShopModal");
      }
    },
    async createNewShop(e) {
      e.preventDefault();
      await this.$store.dispatch("shop/CREATE_SHOP", {
        shopInfo: {
          ...this.newShop,
          contact: {
            email: this.userInfo.profile.email,
          },
        },
      });
      this.$modal.hide("newShopModal");
    },
  },
  computed: {
    ...mapGetters("shop", [
      "shops",
      "shop",
      "isTrialExpired",
      "showOnboarding",
    ]),
    ...mapGetters(["userInfo", "shopId"]),
  },
};
</script>

<style scoped lang="scss">
.navbar-holder {
  width: 220px;
  position: absolute;
  top: 0px;
  height: 100%;
  background: #14253e;
  -webkit-transition: all ease-in-out 500ms;
  -moz-transition: all ease-in-out 500ms;
  -ms-transition: all ease-in-out 500ms;
  -o-transition: all ease-in-out 500ms;
  transition: all ease-in-out 500ms;
  z-index: 21;
}
.navbar-holder::v-deep .dropright .dropdown-toggle::after {
  color: white;
  font-size: 20px;
}
.stores-container {
  width: calc(100% - 16px);
  display: inline-block;
  padding-left: 10px;
}

.no-border {
  border: 1px solid transparent;
}

.user-image {
  width: 30px;
  display: inline-block;
}

.user-name {
  width: calc(100% - 30px);
  display: inline-block;
  padding-left: 10px;
  font-size: 15px;
  text-transform: capitalize;
  vertical-align: middle;
  text-align: left;
}

.user-name i {
  font-size: 25px;
}

.dropdown-toggle {
  border: none;
}

.dropdown-menu {
  width: 220px;
  left: 215px;
  margin-bottom: -40px;
  max-height: 500px;
  overflow: scroll;
}

.shrink .dropdown-menu {
  left: 50px;
}

.shrink .user-name {
  display: none;
}

.navbar-holder.shrink {
  width: 55px;
}

.navbar-holder.shrink .navbar-head span {
  display: none;
}

.navbar-holder.shrink .navbar-item-span {
  display: none;
}

.navbar-holder .navbar-head {
  text-align: left;
  padding: 10px 15px;
}

.navbar-holder .navbar-body {
  height: calc(100% - 188px);
}

.navbar-holder .navbar-body > ul {
  list-style: none;
  margin: 0px;
  padding: 0px;
  height: calc(100% - 50px);
  display: block;
}

.navbar-holder .navbar-body > ul li {
  cursor: pointer;
  min-height: 32px;
  color: #f2f3f6;
  padding: 5px 25px;
  -webkit-transition: 200ms all;
  -moz-transition: 200ms all;
  -ms-transition: 200ms all;
  -o-transition: 200ms all;
  transition: 200ms all;
  font-size: 15px;
}

.navbar-holder .navbar-body > ul li .fas {
  margin-right: 10px;
  width: 19px;
}

.navbar-holder .navbar-body > ul li:hover {
  min-height: 32px;
  color: #00a4df;
  background: #2e4369;
}

.navbar-holder .navbar-body > ul li.active {
  min-height: 32px;
  border-left: 2px solid #00c6ff;
  color: #00c6ff;
  background: #2e4369;
}

.option-container {
  width: 100%;
  padding: 10px;
}

.option-container::v-deep .multiselect {
  .multiselect__tags {
    border: none;
    color: white;
    -webkit-border-radius: 0px;
    -moz-border-radius: 0px;
    border-radius: 0px;
    border-bottom: 1px solid #bcc1cb;
    padding: 5px 0px;
    font-family: var(--main-font);
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 26px;
    cursor: pointer;
    background: transparent;
    text-transform: capitalize !important;
  }

  .multiselect__input,
  .multiselect__single {
    text-transform: capitalize;
    background: var(--background);
    color: var(--text-color);
  }

  .multiselect__input::placeholder {
    text-transform: capitalize;
    color: #adadad;
  }

  .multiselect__tag {
    text-transform: capitalize;
    color: var(--primary-button-text-color);
    background-color: var(--primary-button-background);
  }

  .multiselect__tag-icon,
  .multiselect__tag-icon:after,
  .multiselect__tag-icon:focus,
  .multiselect__tag-icon:hover {
    text-transform: capitalize;
    color: var(--primary-button-text-color);
    background-color: var(--primary-button-background);
  }

  .multiselect__content-wrapper {
    text-transform: capitalize;
    font-family: var(--main-font);
    font-style: normal;
  }

  .multiselect__option {
    text-transform: capitalize;
  }

  .multiselect__option.multiselect__option--highlight,
  .multiselect__option.multiselect__option--highlight:after {
    text-transform: capitalize;
    background: var(--background);
    color: var(--text-color);
    opacity: 0.7;
  }

  .multiselect__option.multiselect__option--highlight:after {
    text-transform: capitalize;
    display: none;
  }

  .multiselect__option.multiselect__option--selected,
  .multiselect__option.multiselect__option--selected:after {
    text-transform: capitalize;
    background-color: transparent;
    color: black;
  }

  .multiselect__option.multiselect__option--selected:after {
    text-transform: capitalize;
    display: none;
  }

  .multiselect__option.multiselect__option--highlight.multiselect__option--selected,
  .multiselect__option.multiselect__option--highlight.multiselect__option--selected:after {
    text-transform: capitalize;
    background: var(--background);
    color: var(--text-color);
  }

  .multiselect__option.multiselect__option--highlight.multiselect__option--selected:after {
    display: none;
  }
}

@media only screen and (max-width: 600px) {
  .navbar-holder {
    transform: translateX(-100%);
  }

  .navbar-holder.open {
    transform: translateX(0);
  }
}
</style>
