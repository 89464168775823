<template>
  <b-col cols="8" class="p-0" v-if="selectedOrder">
    <b-card no-body class="p-2 m-2">
      <h4 class="text-capitalize text-center" v-if="selectedOrder.owner">
        {{ selectedOrder.owner.name.given }}
        {{ selectedOrder.owner.name.family }}
      </h4>
      <h4 class="text-capitalize text-center" v-else>
        {{ selectedOrder.guestOwner.name.given }}
        {{ selectedOrder.guestOwner.name.family }}
      </h4>

      <b-row v-if="selectedOrder.contactTracing">
        <b-col class="text-center">
          <a :href="`mailto:${selectedOrder.contactTracing.email}`">{{
            selectedOrder.contactTracing.email
          }}</a>
          <a :href="`phone:${selectedOrder.contactTracing.phone}`">{{
            selectedOrder.contactTracing.phone
          }}</a>
        </b-col>
      </b-row>
      <h5 class="text-capitalize text-center orange-color">
        {{ selectedOrder.lineItems.length }} Item{{
          selectedOrder.lineItems.length > 1 ? "s" : ""
        }}
      </h5>
      <b-button variant="danger" v-if="selectedOrder.stripeChargeId"
        >Refund</b-button
      >
    </b-card>
    <b-alert :show="selectedOrder.promoCode" variant="info" class="text-center">
      Promo code "{{ selectedOrder.promoCode }}" applied
    </b-alert>
    <b-card
      v-if="selectedOrder.orderType === 'delivery'"
      title="Delivery Info"
      bg-variant="info"
      text-variant="white"
    >
      <p>{{ selectedOrder.deliveryInfo.address.formatted_address }}</p>
      <p>{{ selectedOrder.deliveryInfo.deliveryNotes }}</p>
    </b-card>
    <b-card
      v-for="(lineItem, index) of selectedOrder.lineItems"
      :key="`order-details-${selectedOrder._id}-item-${index}`"
      class="p-2 m-2"
    >
      <b-row>
        <b-col>
          <h4 class="text-center text-capitalize">
            {{ lineItem.product.name }} (${{
              (getProductTotalCost(lineItem) / 100).toFixed(2)
            }})
          </h4>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="2" class="text-center">
          <b-img
            v-if="lineItem.product && lineItem.product.icon"
            rounder="circle"
            :src="lineItem.product.icon.icon"
            width="80"
          />
          <div class="quantity">
            {{ lineItem.quantity }}
          </div>
        </b-col>
        <b-col cols="8">
          <b-row>
            <b-col
              cols="6"
              class="text-capitalize text-center p-0"
              v-for="config of lineItem.config"
              :key="`line-item-config-option-${config.option._id}`"
            >
              <b-row>
                <b-col cols="6" class="p-0"> {{ config.option.name }}: </b-col>
                <b-col cols="6" class="p-0">
                  <ul v-if="config.value.constructor === Array">
                    <li
                      v-for="(v, i) of config.value"
                      :key="`config-${i}-${config.option._id}`"
                    >
                      {{ v.name }}
                    </li>
                  </ul>
                  <div v-else>
                    {{ config.value.name }}
                  </div>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </b-col>
        <b-col cols="2">
          <i
            @click="updateLineItemStatus(index)"
            class="fas fa-check-circle fa-3x pointer"
            v-bind:class="{
              'text-secondary': lineItem.status === 'pending',
              'text-info': lineItem.status !== 'pending',
            }"
          />
        </b-col>
      </b-row>
      <b-alert :show="lineItem.notes && lineItem.notes.length > 0">
        <h5>Notes</h5>
        <p>{{ lineItem.notes }}</p>
      </b-alert>
    </b-card>
    <b-row class="m-0">
      <b-col>
        <b-button
          variant="info"
          pill
          block
          class="mt-3"
          @click="
            updateOrderStatus(
              selectedOrder.status === 'pending' ? 'accepted' : 'completed'
            )
          "
        >
          <strong class="text-uppercase">
            {{ selectedOrder.status === "pending" ? "Accept" : "Complete" }}
            Order (${{ (selectedOrder.total / 100).toFixed(2) }})
          </strong>
        </b-button>
      </b-col>
    </b-row>
    <b-row class="m-0" v-if="selectedOrder.status === 'pending'">
      <b-col>
        <b-button
          variant="danger"
          pill
          block
          class="mt-3"
          @click="updateOrderStatus('rejected')"
        >
          <strong class="text-uppercase"> Reject Order </strong>
        </b-button>
      </b-col>
    </b-row>
  </b-col>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "order-details",
  props: ["selectedOrder"],
  computed: {
    ...mapGetters("shop", ["shop"]),
  },
  methods: {
    getProductTotalCost(item) {
      let total = 0;
      total += item.product.price;
      for (let option of item.config) {
        console.log(option.value);
        if (option.value) {
          if (option.value.constructor !== Array) {
            if (option.value.price) total += parseInt(option.value.price);
          } else {
            for (let op of option.value) {
              if (op.price) total += parseInt(op.price);
            }
          }
        }
      }
      return total;
    },
    updateLineItemStatus(index) {
      if (this.selectedOrder.status !== "accepted")
        return this.$toasted.global
          .showServerError({
            message: "Please accept order to allow item status update",
          })
          .goAway(4000);
      this.selectedOrder.lineItems[index].status = "completed";
    },
    async updateOrderStatus(status) {
      this.$emit("orderStatusUpdate", status);
    },
  },
};
</script>

<style scoped>
.pointer {
  cursor: pointer;
}
.quantity {
  width: 40px;
  height: 40px;
  background-color: red;
  color: white;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  font-weight: bolder;
  font-size: 30px;
  position: absolute;
  top: 0;
  right: 20%;
}
</style>
