<template>
  <b-modal
    id="shop-selection-modal"
    okTitle="Select Shop"
    title="Shop Selection"
    hide-footer
  >
    <b-input-group class="mb-3">
      <b-form-input placeholder="Venues search by name" v-model="searchText" />
      <b-input-group-text>
        <i class="fas fa-search"></i>
      </b-input-group-text>
    </b-input-group>
    <b-list-group style="max-height: 300px; overflow: scroll" v-if="shops">
      <b-list-group-item
        v-for="s of filteredShops"
        :key="s._id"
        v-bind:class="{ active: shop && s._id === shop._id }"
        @click="selectShop(s)"
        class="text-capitalize"
      >
        <span v-if="s && s.name">{{ s.name.normal }}</span>
      </b-list-group-item>
    </b-list-group>
    <b-button
      v-if="!hideNewShopButton"
      class="mt-2 float-left"
      variant="primary"
      pill
      size="sm"
      v-b-modal.create-shop-modal
    >
      + Add New Shop
    </b-button>
    <b-button
      @click="$bvModal.hide('shop-selection-modal')"
      class="mt-2 float-right"
      variant="secondary"
      pill
      size="sm"
    >
      Close
    </b-button>
  </b-modal>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "shop-selection-modal",
  props: ["hideNewShopButton", "returnSelectedShop"],
  data: () => ({
    searchText: "",
  }),
  computed: {
    ...mapGetters("shop", ["shops", "shop"]),
    filteredShops() {
      return this.shops.filter(
        (s) =>
          s.name &&
          s.name.normal &&
          s.name.normal.toLowerCase().indexOf(this.searchText) > -1
      );
    },
  },
  methods: {
    selectShop(shop) {
      localStorage.setItem("shopId", shop._id);
      this.$store.dispatch("shop/GET_SHOP", {
        shopId: shop._id,
        options: { includeStripeAccount: true },
      });
      this.$bvModal.hide("shop-selection-modal");
    },
  },
};
</script>

<style scoped></style>
