<template>
  <b-modal id="table-management-modal" size="xl" title="Table Management">
    <h5 class="text-center" v-if="!dineIn.tables || dineIn.tables.length < 1">
      No tables found, click the + button to add a new table
    </h5>
    <b-form-group
      :id="`table-qr-${selectedTable._id}`"
      style="height: 0px; width: 0px; overflow: hidden"
      v-if="selectedTable"
    >
      <vue-html2pdf
        :show-layout="false"
        :float-layout="true"
        :enable-download="false"
        :preview-modal="true"
        :paginate-elements-by-height="500"
        filename="hee hee"
        :pdf-quality="2"
        :manual-pagination="false"
        pdf-format="a4"
        pdf-orientation="portrait"
        pdf-content-width="100%"
        :html-to-pdf-options="{
          enableLinks: true,
        }"
        ref="html2Pdf"
      >
        <section slot="pdf-content">
          <div class="w-100" :style="`color:${themeColors.font}`">
            <b-row
              :style="`margin: auto;
                width: 400px;
                background: ${themeColors.background};
                padding: 20px;`"
            >
              <b-col class="text-center">
                <h3>{{ shop.name.normal }}</h3>
                <p>Scan to Order & Pay</p>
                <qrcode-vue
                  :foreground="themeColors.font"
                  :background="themeColors.background"
                  :value="getQRValue(selectedTable)"
                  size="200"
                />
                <h4>Table {{ selectedTable.number }}</h4>
                <div class="w-100 text-center">
                  <img :src="themeColors.hostLogo" style="width: 50px" />
                </div>
              </b-col>
            </b-row>
          </div>
        </section>
      </vue-html2pdf>
    </b-form-group>
    <b-row>
      <b-col v-for="table of dineIn.tables" :key="table._id" md="12" lg="4">
        <b-card class="mt-2">
          <b-form-group>
            <label> Table number </label>
            <i
              class="fas fa-info-circle float-right"
              v-b-tooltip.hover
              title="Enter a short number or text reference for the table number to differentiate from other tables"
            />
            <input class="form-control" v-model="table.number" />
            <!--<small>Enter a short number or text reference for the table number to differentiate from other tables</small>-->
          </b-form-group>
          <b-form-group>
            <label> Capacity </label>
            <i
              class="fas fa-info-circle float-right"
              v-b-tooltip.hover
              title="Enter the maximum amount of people allowed in this table"
            />
            <input
              class="form-control"
              type="number"
              v-model="table.capacity"
            />
            <!--<small>Enter the maximum amount of people allowed in this table</small>-->
          </b-form-group>
          <b-row>
            <b-col>
              <b-button
                block
                class="mt-1"
                variant="warning"
                v-b-tooltip.hover
                title="Downloads this table's QR Code"
                @click="getTableQRCode(table)"
              >
                <i class="fas fa-qrcode"></i> Download QR Code
              </b-button>

              <b-button
                block
                class="mt-1"
                variant="success"
                v-b-tooltip.hover
                title="Visit your embed store as you were scanning the QR code"
                @click="goToTableOrdering(table)"
              >
                <i class="fas fa-eye"></i> Preview
              </b-button>

              <b-button
                block
                class="mt-1"
                variant="primary"
                v-b-tooltip.hover
                title="Saves the changes on this table"
                @click="saveTable(table)"
              >
                <i class="fas fa-save"></i> Save
              </b-button>

              <b-button
                block
                class="mt-1"
                variant="danger"
                v-b-tooltip.hover
                title="Deletes this table from the venue"
                @click="deleteTable(table)"
              >
                <i class="fas fa-trash"></i> Delete
              </b-button>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
    <a id="link" style="display: none"></a>
    <template #modal-footer>
      <div style="width: 100%">
        <b-row>
          <b-col>
            QR Theme
            <b-select
              v-model="qrTheme"
              :options="['dark', 'platinum', 'bronze', 'gold']"
            />
          </b-col>
          <b-col></b-col>
          <b-col>
            <b-button variant="primary" @click="addNew" pill class="w-100 mb-2">
              Add New
            </b-button>
            <b-button
              pill
              class="w-100"
              variant="outline-secondary"
              @click="$bvModal.hide('table-management-modal')"
            >
              Cancel
            </b-button>
          </b-col>
        </b-row>
      </div>
    </template>
  </b-modal>
</template>

<script>
import { mapGetters } from "vuex";
import QrcodeVue from "qrcode.vue";
import VueHtml2pdf from "vue-html2pdf";
import darkSvg from "@/assets/host.svg";
import lightSvg from "@/assets/host-white.svg";
export default {
  name: "table-management",
  components: {
    QrcodeVue,
    VueHtml2pdf,
  },
  data: () => ({
    dineIn: {
      enabled: false,
      tables: [],
      preparationTime: 15,
    },
    selectedTable: null,
    qrTheme: "dark",
  }),
  computed: {
    ...mapGetters("shop", ["shop", "loading", "embedKey"]),
    themeColors() {
      let colors;
      switch (this.qrTheme) {
        case "dark":
          colors = {
            font: "#ffffff",
            background: "#231F20",
            hostLogo: lightSvg,
          };
          break;
        case "platinum":
          colors = {
            font: "#231F20",
            background: "#B8B9B9",
            hostLogo: darkSvg,
          };
          break;
        case "bronze":
          colors = {
            font: "#231F20",
            background: "#C79458",
            hostLogo: darkSvg,
          };
          break;
        case "gold":
          colors = {
            font: "#231F20",
            background: "#E1A034",
            hostLogo: darkSvg,
          };
          break;
      }
      return colors;
    },
  },
  methods: {
    goToTableOrdering(table) {
      window
        .open(
          `https://${
            this.embedKey.origins[this.embedKey.origins.length - 1]
          }?order-type=dine-in&table=${table._id}`,
          "_blank"
        )
        .focus();
    },
    getTableQRCode(selectedTable) {
      this.selectedTable = selectedTable;
      setTimeout(() => this.$refs.html2Pdf.generatePdf(), 500);
    },
    async saveTable() {
      await this.$store.dispatch("shop/UPDATE_SHOP", {
        shopId: this.shop._id,
        shopInfo: { dineIn: this.dineIn },
      });
    },
    async addNew() {
      const number = prompt("Enter table number");
      const capacity = prompt("Enter table maximum persons capacity");

      await this.$store.dispatch("shop/UPDATE_SHOP", {
        shopId: this.shop._id,
        shopInfo: {
          $push: {
            "dineIn.tables": {
              number,
              capacity,
            },
          },
        },
      });
    },
    async deleteTable(table) {
      let answer = confirm(
        `Are you sure you want to remove the table "${table.number}"?`
      );
      if (!answer) return;
      await this.$store.dispatch("shop/UPDATE_SHOP", {
        shopId: this.shop._id,
        shopInfo: {
          $pull: {
            "dineIn.tables": { _id: table._id },
          },
        },
      });
    },
    getQRValue(table) {
      return `https://${
        this.embedKey.origins[this.embedKey.origins.length - 1]
      }?order-type=dine-in&table=${table._id}`;
    },
  },
  mounted() {
    if (this.shop) this.dineIn = JSON.parse(JSON.stringify(this.shop.dineIn));
    if (this.shop)
      this.$store.dispatch("shop/GET_EMBED_KEY", {
        shopId: this.shop._id,
        ignoreException: true,
      });
  },
  watch: {
    shop(newVal) {
      if (!newVal) return;
      this.dineIn = JSON.parse(JSON.stringify(newVal.dineIn));
      if (this.shop)
        this.$store.dispatch("shop/GET_EMBED_KEY", {
          shopId: this.shop._id,
          ignoreException: true,
        });
    },
  },
};
</script>

<style scoped>
.hover-button {
  position: absolute;
  right: 10px;
  bottom: 40px;
}
</style>
