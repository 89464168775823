<template>
  <b-modal
    id="shop-delete-modal"
    title="Shop Delete"
    :ok-disabled="deleteConfirmText.trim().length < 1"
    @ok="deleteShop"
    ok-variant="danger"
    ok-title="Delete"
  >
    <b-alert show variant="danger">
      This action can't be undone. Please type "confirm delete" to proceed
    </b-alert>
    <b-input v-model="deleteConfirmText" placeholder="confirm delete" />
  </b-modal>
</template>

<script>
export default {
  name: "shop-delete-modal",
  props: ["shop"],
  data: () => ({
    deleteConfirmText: "",
  }),
  methods: {
    async deleteShop(e) {
      e.preventDefault();
      if (this.deleteConfirmText !== "confirm delete") return;
      await this.$store.dispatch("shop/DELETE_SHOP", { shopId: this.shop._id });
      this.$bvModal.hide("shop-delete-modal");
      this.deleteConfirmText = "";
      this.$emit("shop-deleted", {});
    },
  },
};
</script>

<style scoped></style>
