import Vue from "vue";
import vueNumeralFilterInstaller from "vue-numeral-filter";
import { BootstrapVue, BootstrapVueIcons } from "bootstrap-vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

//import './loadFAIcons';
import Multiselect from "vue-multiselect";
import Switches from "vue-switches";
import Toasted from "vue-toasted";
import VueProgressBar from "vue-progressbar";
import Popover from "vue-js-popover";
// import LogRocket from 'logrocket';
import VueMeta from "vue-meta";
import vmodal from "vue-js-modal";
import VueMoment from "vue-moment";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";

vSelect.props.components.default = () => ({
  OpenIndicator: {
    render: (createElement) =>
      createElement("i", { class: "fa fa-caret-down pull-right" }),
  },
});

// LogRocket.init('3ncsnb/otg-web-ordering');

// register globally
Vue.component("multiselect", Multiselect);
Vue.component("switches", Switches);

Vue.use(VueProgressBar, {
  color: "rgb(143, 255, 199)",
  failedColor: "red",
  location: "bottom",
  thickness: "5px",
  position: "absolute",
});
Vue.use(vueNumeralFilterInstaller, { locale: "en-au" });
Vue.use(BootstrapVue);
Vue.use(BootstrapVueIcons);
Vue.use(Toasted);
Vue.use(Popover);
Vue.use(VueMeta);
Vue.use(vmodal);
Vue.use(VueMoment);
Vue.component("v-select", vSelect);

Vue.toasted.register(
  "showServerError",
  (payload) => {
    if (!payload.message) return "Oh...something is wrong, please try again";
    return payload.message;
  },
  {
    type: "error",
    className: "app-font",
  }
);

Vue.config.productionTip = false;

new Vue({
  render: (h) => h(App),
  router,
  store,
}).$mount("#app");
