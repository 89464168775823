<template>
  <div
    id="table-ordering"
    class="shop-section col-xs-12"
    v-bind:class="{ active: isActive }"
    @click="$emit('activeSection', 'table-ordering')"
  >
    <div class="header">
      <i class="fas fa-utensils section-icon" />

      <h5 class="section-title dark-blue-color">
        Table Ordering Settings
        <br />
        <small>Define your store table ordering settings</small>
      </h5>
    </div>
    <b-col class="option-content col-xs-12" v-if="shopInfo && shopInfo.dineIn">
      <b-form class="col-xs-12" @submit="saveDetails">
        <b-form-row>
          <b-col sm="12" md="6">
            <b-form-group
              label="Preparation time"
              description="Amount of minutes the food preparation takes for dine in orders
              Usually food preparations take between 10 to 30 minutes."
            >
              <b-form-input
                type="number"
                v-model="shopInfo.dineIn.preparationTime"
                placeholder="Default time is 5 minutes"
              />
            </b-form-group>
          </b-col>
          <b-col sm="12" md="6">
            <b-form-group
              label="Preparation Display Platforms"
              description="This option allows you to choose if you desire to show the preparation time and in which parts of the On the Go ecosystem"
            >
              <b-dropdown
                block
                variant="primary"
                toggle-class="text-capitalize"
                :text="
                  shopInfo.dineIn.preparationDisplayPlatforms &&
                  shopInfo.dineIn.preparationDisplayPlatforms.length > 0
                    ? shopInfo.dineIn.preparationDisplayPlatforms.join(' - ')
                    : 'Select Platform Options'
                "
              >
                <b-dropdown-item
                  :active="
                    shopInfo.dineIn.preparationDisplayPlatforms &&
                    shopInfo.dineIn.preparationDisplayPlatforms.includes(
                      'marketplace'
                    )
                  "
                  @click.stop.prevent="
                    selectPreparationDisplayPlatforms('marketplace')
                  "
                  >On the Go Marketplace</b-dropdown-item
                >
                <b-dropdown-item
                  :active="
                    shopInfo.dineIn.preparationDisplayPlatforms &&
                    shopInfo.dineIn.preparationDisplayPlatforms.includes(
                      'embed'
                    )
                  "
                  @click.stop.prevent="
                    selectPreparationDisplayPlatforms('embed')
                  "
                  >Embed Store</b-dropdown-item
                >
                <b-dropdown-item
                  :active="
                    shopInfo.dineIn.preparationDisplayPlatforms &&
                    shopInfo.dineIn.preparationDisplayPlatforms.includes(
                      'kiosk'
                    )
                  "
                  @click.stop.prevent="
                    selectPreparationDisplayPlatforms('kiosk')
                  "
                  >Kiosk</b-dropdown-item
                >
              </b-dropdown>
            </b-form-group>
          </b-col>
        </b-form-row>
        <b-form-row>
          <b-col sm="12" md="6">
            <b-form-group
              description="This option enable customers to select in which order they want their food to be brought"
            >
              <b-form-checkbox v-model="shopInfo.dineIn.servingOrderEnabled"
                >Enable serving order</b-form-checkbox
              >
            </b-form-group>
          </b-col>
          <b-col sm="12" md="6">
            <b-form-group
              label="Serving Identifier"
              description="This option allow which identifier will be used for bringing the food to the customer"
            >
              <b-dropdown
                toggle-class="text-capitalize"
                :text="
                  `${
                    shopInfo.dineIn.servingIdentifier
                      ? shopInfo.dineIn.servingIdentifier.replace('-', ' ')
                      : ''
                  }` || 'Select an identifier'
                "
                block
                variant="primary"
              >
                <b-dropdown-item
                  @click="selectServingIdentifier('table-number')"
                >
                  <b-row>
                    <b-col sm="12" md="6">
                      <i class="fas fa-dinning"></i>
                    </b-col>
                    <b-col sm="12" md="6">
                      <strong>Table Number</strong>
                      <p>
                        This option is commonly used in large restaurants with
                        numbered <br />
                        tables or based on table booking
                      </p>
                    </b-col>
                  </b-row>
                </b-dropdown-item>
                <b-dropdown-item @click="selectServingIdentifier('pin-number')">
                  <strong>Pin Number</strong>
                  <p>
                    This option is usually used by Cafes, allowing customer to
                    <br />
                    pick a pin/pole number or get one assigned by the staff,
                    <br />
                    which needs to be displayed in the customer's table
                  </p>
                </b-dropdown-item>
                <b-dropdown-item @click="selectServingIdentifier('name')">
                  <strong>Name</strong>
                  <p>
                    This option is usually in venues were customers have
                    <br />full freedom to choose where to seat and staff will
                    mention <br />their name in order to bring the order to the
                    table
                  </p>
                </b-dropdown-item>
              </b-dropdown>
            </b-form-group>
          </b-col>
        </b-form-row>
        <b-form-row>
          <b-col sm="12" md="6">
            <b-form-group
              label="Table Selection Text"
              description="Set a specific selection message instruction on the dine in table selection. Embed and kiosk 2.0 only feature"
            >
              <b-textarea v-model="shopInfo.dineIn.tableSelectionText" />
            </b-form-group>
          </b-col>
          <b-col sm="12" md="6">
            <b-form-group
              v-if="user.roles.includes('admin')"
              label="Display dine in on:"
              description="Select the platforms of the On the Go ecosystem where you want to show the table-ordering option"
            >
              <b-dropdown
                block
                variant="primary"
                toggle-class="text-capitalize"
                :text="
                  shopInfo.dineIn.displayPlatforms &&
                  shopInfo.dineIn.displayPlatforms.length > 0
                    ? shopInfo.dineIn.displayPlatforms.join(' - ')
                    : 'Select Platform Options'
                "
              >
                <b-dropdown-item
                  :active="
                    shopInfo.dineIn.displayPlatforms &&
                    shopInfo.dineIn.displayPlatforms.includes('marketplace')
                  "
                  @click.stop.prevent="
                    selectDineInDisplayPlatforms('marketplace')
                  "
                  >On the Go Marketplace</b-dropdown-item
                >
                <b-dropdown-item
                  :active="
                    shopInfo.dineIn.displayPlatforms &&
                    shopInfo.dineIn.displayPlatforms.includes('embed')
                  "
                  @click.stop.prevent="selectDineInDisplayPlatforms('embed')"
                  >Embed Store</b-dropdown-item
                >
                <b-dropdown-item
                  :active="
                    shopInfo.dineIn.displayPlatforms &&
                    shopInfo.dineIn.displayPlatforms.includes('kiosk')
                  "
                  @click.stop.prevent="selectDineInDisplayPlatforms('kiosk')"
                  >Kiosk</b-dropdown-item
                >
              </b-dropdown>
            </b-form-group>
          </b-col>
        </b-form-row>
        <b-row>
          <b-col sm="12" md="6">
            <b-button
              variant="primary"
              type="submit"
              class="float-right mb-1 ml-2"
              :disabled="loading"
              pill
            >
              <b-spinner v-if="loading" />
              <span v-else>Save</span>
            </b-button>
            <b-button
              :variant="
                shopInfo.dineIn.enabled ? 'outline-danger' : 'outline-primary'
              "
              @click="togglePickup"
              class="float-right mb-1"
              :disabled="loading"
              pill
            >
              <b-spinner v-if="loading" />
              <span v-else
                >{{ shopInfo.dineIn.enabled ? "Disable" : "Enable" }} Table
                Ordering</span
              >
            </b-button>
            <b-button
              class="float-right mb-1 mr-2"
              pill
              variant="info"
              :disabled="
                !shopInfo.dineIn ||
                !shopInfo.dineIn.servingIdentifier ||
                shopInfo.dineIn.servingIdentifier !== 'table-number'
              "
              v-b-modal:table-management-modal
            >
              Manage Tables
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </b-col>
    <table-management />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import TableManagement from "@/components/shop-settings/table-ordering/table-management";

export default {
  name: "shop-table-ordering",
  components: { TableManagement },
  props: ["isActive"],
  data: () => ({
    shopInfo: null,
  }),
  computed: {
    ...mapGetters("shop", ["shop", "loading"]),
    ...mapGetters({ user: "userInfo" }),
  },
  methods: {
    saveDetails(e) {
      e.preventDefault();
      let shopInfo = {
        dineIn: this.shopInfo.dineIn,
      };
      this.$emit("saveChanges", shopInfo);
    },
    togglePickup(e) {
      this.shopInfo.dineIn.enabled = !this.shopInfo.dineIn.enabled;
      this.saveDetails(e);
    },
    selectServingIdentifier(identifier) {
      this.shopInfo.dineIn.servingIdentifier = identifier;
      this.$forceUpdate();
    },
    selectPreparationDisplayPlatforms(platform) {
      let platforms = [];
      if (this.shopInfo.dineIn.preparationDisplayPlatforms)
        platforms = this.shopInfo.dineIn.preparationDisplayPlatforms;
      if (platforms.includes(platform))
        platforms = platforms.filter((p) => p !== platform);
      else platforms.push(platform);
      this.shopInfo.dineIn.preparationDisplayPlatforms = platforms;
      this.$forceUpdate();
    },
    selectDineInDisplayPlatforms(platform) {
      let platforms = [];
      if (this.shopInfo.dineIn.displayPlatforms)
        platforms = this.shopInfo.dineIn.displayPlatforms;
      if (platforms.includes(platform))
        platforms = platforms.filter((p) => p !== platform);
      else platforms.push(platform);
      this.shopInfo.dineIn.displayPlatforms = platforms;
      this.$forceUpdate();
    },
  },
  mounted() {
    this.shopInfo = JSON.parse(JSON.stringify(this.shop));
  },
  watch: {
    shop(newVal) {
      if (!newVal) return;
      this.shopInfo = {
        ...newVal,
      };
    },
  },
};
</script>

<style scoped></style>
