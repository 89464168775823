<template>
  <b-modal
    id="image-editor-modal"
    hide-header
    @ok="uploadImage"
    okTitle="Save Image"
    @show="onShow"
  >
    <input
      type="file"
      ref="file"
      style="display: none"
      @change="loadImage"
      accept="image/*"
    />

    <div class="buttons-container">
      <b-button
        @click="$refs.file.click()"
        pill
        class="ml-1"
        variant="link"
        style="color: white"
      >
        <i class="fas fa-upload" />
      </b-button>
      <b-button
        @click="$refs.cropper.flip(true, false)"
        pill
        class="ml-1"
        variant="link"
        style="color: white"
      >
        <img src="/img/flip-horizontal.svg" />
      </b-button>
      <b-button
        @click="$refs.cropper.flip(false, true)"
        pill
        class="ml-1"
        variant="link"
        style="color: white"
      >
        <img src="/img/flip-vertical.svg" />
      </b-button>
      <b-button
        @click="rotate(15)"
        pill
        class="ml-1"
        variant="link"
        style="color: white"
      >
        <img src="/img/rotate-clockwis.svg" />
      </b-button>
      <b-button
        @click="rotate(-15)"
        pill
        class="ml-1"
        variant="link"
        style="color: white"
      >
        <img src="/img/rotate-counter-clockwise.svg" />
      </b-button>
      <br />
    </div>
    <div class="cropper-container">
      <cropper
        v-if="image"
        :src="image"
        :stencil-props="stencilProps"
        :auto-zoom="false"
        ref="cropper"
        image-restriction="stencil"
        foreground-class="white-background"
        background-class="white-background"
      />
    </div>
  </b-modal>
</template>

<script>
import { Cropper } from "vue-advanced-cropper";
import { mapGetters } from "vuex";

export default {
  name: "imageEditor",
  components: {
    Cropper,
  },
  props: ["imageInfo"],
  data: () => ({
    stencilProps: {
      scalable: false,
    },
    image: null,
  }),
  computed: {
    ...mapGetters("shop", ["shop"]),
  },
  methods: {
    rotate(angle) {
      this.$refs.cropper.rotate(angle);
    },
    onShow() {
      if (!this.imageInfo || !this.imageInfo.image)
        return setTimeout(() => this.$refs.file.click(), 1000);
      this.image = this.imageInfo.image;
      if (this.imageInfo.aspectRatio)
        this.stencilProps.aspectRatio = this.imageInfo.aspectRatio;
      if (this.imageInfo.minAspectRatio)
        this.stencilProps.minAspectRatio = this.imageInfo.minAspectRatio;
      if (this.imageInfo.maxAspectRatio)
        this.stencilProps.maxAspectRatio = this.imageInfo.maxAspectRatio;
      if (this.imageInfo.scalable)
        this.stencilProps.scalable = this.imageInfo.scalable;
    },
    loadImage(event) {
      // Reference to the DOM input element
      var input = event.target;
      // Ensure that you have a file before attempting to read it
      if (input.files && input.files[0]) {
        // create a new FileReader to read this image and convert to base64 format
        var reader = new FileReader();
        // Define a callback function to run, when FileReader finishes its job
        reader.onload = (e) => {
          this.image = null;
          // Note: arrow function used here, so that "this.imageData" refers to the imageData of Vue component
          // Read image as base64 and set to imageData
          this.image = e.target.result;
        };
        // Start the reader job - read file as a data url (base64 format)
        reader.readAsDataURL(input.files[0]);
      }
    },
    async uploadImage() {
      const { canvas } = this.$refs.cropper.getResult();
      if (canvas) {
        switch (this.imageInfo.type) {
          case "product-hero":
            this.$store.dispatch("product/UPDATE_PRODUCT_IMAGE", {
              productId: this.imageInfo.productId,
              shopId: this.shop._id,
              type: "hero",
              imageData: canvas.toDataURL("image/jpeg"),
            });
            break;
          case "shop-hero":
            this.$store.dispatch("shop/UPDATE_SHOP_IMAGE", {
              shopId: this.shop._id,
              type: "hero",
              imageData: canvas.toDataURL("image/jpeg"),
            });

            break;
          case "kiosk-upload":
            this.$emit(
              "upload-success",
              await this.$store.dispatch("kiosk/UPLOAD_KIOSK_IMAGE", {
                imageData: canvas.toDataURL("image/png"),
              })
            );
            break;
        }
      }
    },
  },
};
</script>

<style scoped>
.vue-advanced-cropper__foreground {
  background-color: white;
}
.buttons-container {
  width: 50px;
  display: inline-block;
  z-index: 5000;
  height: 100%;
  position: absolute;
}
.cropper-container {
  background-color: #212529;
  padding-left: 50px;
  width: 100%;
  display: inline-block;
  height: 100%;
  min-height: 200px;
}
</style>
