export const textSizeOptions = [
  "xs",
  "sm",
  "base",
  "lg",
  "xl",
  "2xl",
  "3xl",
  "4xl",
  "5xl",
  "6xl",
  "7xl",
  "8xl",
  "9xl",
];

export const enumerations = [
  "first",
  "second",
  "third",
  "fourth",
  "fifth",
  "sixth",
  "seventh",
  "eighth",
  "ninth",
  "tenth",
];

export const borderStyles = ["bottom-only", "full-bordered"];

export const shapeStyles = ["square", "rounded", "full-rounded"];

export const twoPointOTheme = {
  landing: {
    textColor: "first",
    logo: "https://res.cloudinary.com/on-the-go/image/upload/v1698138737/dxjdpxpvdsqc8s3limfo.png",
    font: "first",
    subtitleTextSize: "3xl",
    startOrderTextSize: "6xl",
    backgroundImage:
      "https://res.cloudinary.com/on-the-go/image/upload/v1699974015/oxburxpiwehkm6tyrdya.png",
  },
  menu: {
    productDescriptionFont: "first",
    productDescriptionTextColor: "sixth",
    productPriceTextSize: "2xl",
    productPriceTextColor: "third",
    productPriceFont: "first",
    categoryContainerColor: "first",
    categoryButtonTextColor: "third",
    categoryButtonFont: "first",
    categoryButtonTextSize: "2xl",
    categoryButtonBorderColor: "third",
    categoryHeaderTextColor: "first",
    categoryHeaderFont: "first",
    categoryHeaderTextSize: "3xl",
    containerColor: "first",
    productNameTextColor: "third",
    productNameFont: "first",
    productNameTextSize: "xl",
    productDescriptionTextSize: "base",
    categoriesContainerColor: "third",
    pageBackgroundColor: "second",
    productsColumns: 2,
  },
  product: {
    containerColor: "first",
    productNameTextColor: "third",
    productNameFont: "first",
    productNameTextSize: "4xl",
    productDescriptionTextColor: "sixth",
    productDescriptionFont: "first",
    productDescriptionTextSize: "2xl",
    notesTextColor: "third",
    notesFont: "first",
    notesTextSize: "xl",
    notesBackgroundColor: "fourth",
    optionButtonTextColor: "third",
    optionButtonFont: "first",
    optionButtonTextSize: "lg",
    optionNameTextColor: "third",
    optionNameFont: "first",
    optionNameTextSize: "xl",
    optionButtonSelectedColor: "third",
    optionButtonShape: "square",
  },
  cart: {
    containerColor: "first",
    productNameTextColor: "third",
    productNameFont: "first",
    productNameTextSize: "xl",
    productDescriptionTextColor: "third",
    productDescriptionFont: "first",
    productDescriptionTextSize: "base",
    productPriceTextColor: "third",
    productPriceFont: "first",
    productPriceTextSize: "xl",
    increaseButtonsBackgroundColor: "first",
    increaseButtonsIconsColor: "third",
    increaseButtonsBorderColor: "third",
    editButtonBackgroundColor: "fourth",
    editButtonIconColor: "third",
    removeButtonBackgroundColor: "fifth",
    removeButtonIconColor: "sixth",
  },
  checkout: {
    buttonsTextColor: "first",
    buttonsFont: "first",
    buttonsTextSize: "2xl",
    buttonsSelectedColor: "third",
    inputsShape: "square",
    inputsBorderStyle: "bottom-only",
    inputsBorderColor: "third",
    inputsTextSize: "2xl",
    inputsFont: "first",
    inputsTextColor: "third",
    inputsBackgroundColor: "first",
    containerColor: "second",
    buttonsShape: "square",
    summaryTextColor: "third",
    summaryFont: "first",
    summaryTextSize: "4xl",
    infoShape: "square",
    infoTextSize: "lg",
    infoFont: "first",
    infoTextColor: "first",
    infoBackgroundColor: "third",
  },
  theme: {
    colors: {
      first: "#ffffff",
      second: "#abbbd3",
      third: "#5B7FBD",
      fourth: "#dbe2ef",
      fifth: "#fecaca",
      sixth: "#14253e",
      seventh: "#e6fe75",
      eighth: "#000000",
      ninth: "#000000",
      tenth: "",
    },
    fonts: {
      first: "Poppins ",
      second: "matterregular",
      third: "",
      fourth: "",
      fifth: "",
      sixth: "",
      seventh: "",
      eighth: "",
      ninth: "",
      tenth: "",
    },
    importFonts:
      "@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');",
    pageBackgroundColor: "first",
  },
  actionbar: {
    secondaryButtonBackgroundColor: "first",
    containerColor: "first",
    primaryButtonBackgroundColor: "third",
    primaryButtonFont: "first",
    primaryButtonTextColor: "first",
    primaryButtonTextSize: "2xl",
    primaryButtonBorderColor: "third",
    primaryButtonShape: "square",
    secondaryButtonTextColor: "third",
    secondaryButtonFont: "first",
    secondaryButtonTextSize: "2xl",
    secondaryButtonBorderColor: "third",
    secondaryButtonShape: "square",
    increaseButtonsBackgroundColor: "third",
    increaseButtonsIconsColor: "first",
    increaseButtonsBorderColor: "first",
    textColor: "third",
    font: "first",
    textSize: "xl",
    titleTextColor: "third",
    titleFont: "first",
    titleTextSize: "xl",
    subtitleTextColor: "third",
    subtitleFont: "first",
    subtitleTextSize: "xl",
  },
  navbar: {
    backgroundColor: "#314299",
    textColor: "third",
    logo: "https://res.cloudinary.com/on-the-go/image/upload/v1698137679/kdhcohnfhj6qzdedftxe.png",
    textSize: "2xl",
    font: "first",
    containerColor: "first",
  },
  buttons: {
    primary: {
      fillingStyle: "primary",
      displayStyle: "rounded",
      backgroundColor: "#ff3300",
      textColor: "#ffffff",
      font: "Courier New",
      textSize: "34",
    },
    secondary: {
      fillingStyle: "outline-secondary",
      displayStyle: "square",
      backgroundColor: "#fe3200",
      textColor: "#314299",
      font: "Courier New",
      textSize: "34",
    },
    link: { textColor: "black" },
  },
  infoBlock: { backgroundColor: "#d0ecf1", textColor: "#0a545f" },
  fonts: "",
  general: {
    backgroundColor: "#ffffff",
    secondaryBackgroundColor: "#ffffff",
    textColor: "#000000",
    logo: "https://res.cloudinary.com/on-the-go/image/upload/v1696913097/yfnwkq6qday5tcodyydr.png",
    mainFont: "Courier New",
    titlesFont: "Courier New",
  },
};

export const twoPointOEmbed = {
  general: {
    titlesFont: "Courier new",
    mainFont: "Courier new",
    logo: "https://res.cloudinary.com/on-the-go/image/upload/v1689719820/x60mgjlwi2g6yi6calca.png",
    textColor: "#000000",
    secondaryBackgroundColor: "#ffffff",
    backgroundColor: "#ffffff",
  },
  fonts: "",
  infoBlock: {
    textColor: "",
    backgroundColor: "",
  },
  buttons: {
    link: {
      textColor: "black",
    },
    secondary: {
      backgroundColor: "#ffffff",
      textColor: "#314299",
      font: "Courier new",
      displayStyle: "rounded",
      fillingStyle: "outline-secondary",
    },
    primary: {
      textColor: "#ffffff",
      backgroundColor: "#304299",
      font: "Courier new",
      displayStyle: "rounded",
      fillingStyle: "primary",
    },
  },
  navbar: {
    containerColor: "first",
    font: "first",
    textSize: "lg",
    logo: "https://res.cloudinary.com/on-the-go/image/upload/v1708331250/bycqasjic6huxwbopwnm.png",
    textColor: "third",
    backgroundColor: "#314299",
  },
  actionbar: {
    textSize: "lg",
    font: "first",
    textColor: "third",
    increaseButtonsBorderColor: "first",
    increaseButtonsIconsColor: "first",
    increaseButtonsBackgroundColor: "third",
    secondaryButtonShape: "square",
    secondaryButtonBorderColor: "third",
    secondaryButtonTextSize: "base",
    secondaryButtonFont: "first",
    secondaryButtonTextColor: "third",
    primaryButtonShape: "square",
    primaryButtonBorderColor: "third",
    primaryButtonTextSize: "base",
    primaryButtonTextColor: "first",
    primaryButtonFont: "first",
    primaryButtonBackgroundColor: "third",
    containerColor: "first",
    secondaryButtonBackgroundColor: "first",
  },
  theme: {
    pageBackgroundColor: "first",
    importFonts:
      "@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');",
    fonts: {
      tenth: "",
      ninth: "",
      eighth: "",
      seventh: "",
      sixth: "",
      fifth: "",
      fourth: "",
      third: "",
      second: "matterregular",
      first: "Poppins ",
    },
    colors: {
      tenth: "",
      ninth: "#000000",
      eighth: "#000000",
      seventh: "#e6fe75",
      sixth: "#14253e",
      fifth: "#fecaca",
      fourth: "#dbe2ef",
      third: "#5B7FBD",
      second: "#abbbd3",
      first: "#ffffff",
    },
  },
  checkout: {
    infoBackgroundColor: "third",
    infoTextColor: "first",
    infoFont: "first",
    infoTextSize: "sm",
    infoShape: "square",
    summaryTextSize: "xl",
    summaryFont: "first",
    summaryTextColor: "third",
    buttonsShape: "square",
    containerColor: "second",
    inputsBackgroundColor: "first",
    inputsTextColor: "third",
    inputsFont: "first",
    inputsTextSize: "sm",
    inputsBorderColor: "third",
    inputsBorderStyle: "bottom-only",
    inputsShape: "square",
    buttonsSelectedColor: "third",
    buttonsTextSize: "sm",
    buttonsFont: "first",
    buttonsTextColor: "first",
  },
  cart: {
    cartTitleFont: "first",
    cartTitleTextSize: "2xl",
    cartTitleTextColor: "third",
    removeButtonIconColor: "sixth",
    removeButtonBackgroundColor: "fifth",
    editButtonIconColor: "third",
    editButtonBackgroundColor: "fourth",
    increaseButtonsBorderColor: "third",
    increaseButtonsIconsColor: "third",
    increaseButtonsBackgroundColor: "first",
    productPriceTextSize: "xl",
    productPriceFont: "first",
    productPriceTextColor: "third",
    productDescriptionTextSize: "base",
    productDescriptionFont: "first",
    productDescriptionTextColor: "third",
    productNameTextSize: "xl",
    productNameFont: "first",
    productNameTextColor: "third",
    containerColor: "first",
  },
  product: {
    optionButtonShape: "square",
    optionButtonSelectedColor: "third",
    optionNameTextSize: "lg",
    optionNameFont: "first",
    optionNameTextColor: "third",
    optionButtonTextSize: "base",
    optionButtonFont: "first",
    optionButtonTextColor: "third",
    notesBackgroundColor: "fourth",
    notesTextSize: "base",
    notesFont: "first",
    notesTextColor: "third",
    productDescriptionTextSize: "lg",
    productDescriptionFont: "first",
    productDescriptionTextColor: "sixth",
    productNameTextSize: "2xl",
    productNameFont: "first",
    productNameTextColor: "third",
    containerColor: "first",
  },
  menu: {
    productsColumns: 4,
    pageBackgroundColor: "second",
    categoriesContainerColor: "third",
    productDescriptionTextSize: "base",
    productNameTextSize: "lg",
    productNameFont: "first",
    productNameTextColor: "third",
    containerColor: "first",
    categoryHeaderTextSize: "lg",
    categoryHeaderFont: "first",
    categoryHeaderTextColor: "first",
    categoryButtonBorderColor: "third",
    categoryButtonTextSize: "lg",
    categoryButtonFont: "first",
    categoryButtonTextColor: "third",
    categoryContainerColor: "first",
    productPriceFont: "first",
    productPriceTextColor: "third",
    productPriceTextSize: "lg",
    productDescriptionTextColor: "sixth",
    productDescriptionFont: "first",
  },
  landing: {
    backgroundImage:
      "https://res.cloudinary.com/on-the-go/image/upload/v1699974015/oxburxpiwehkm6tyrdya.png",
    startOrderTextSize: "6xl",
    subtitleTextSize: "3xl",
    font: "first",
    logo: "https://res.cloudinary.com/on-the-go/image/upload/v1698138737/dxjdpxpvdsqc8s3limfo.png",
    textColor: "first",
  },
};
