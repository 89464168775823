<template>
  <table
      class="table b-table table-hover table-borderless table-sm"
  >
    <tbody>
    <tr>
      <th class="table-primary b-table-sticky-column position-relative">Products</th>
      <th v-for="day of stockDates" :key="day" class="table-primary b-table-sticky-column position-relative">
        {{day}}
      </th>
    </tr>
    <tr v-for="stockProduct of stockProducts" :key="stockProduct">
      <td class="table-b-table-default b-table-sticky-column">
        {{stockProduct}}
      </td>
      <td v-for="day of stockDates" :key="day" class="table-b-table-default b-table-sticky-column">
        {{getStockCell(day, stockProduct).count}}
      </td>
    </tr>
    </tbody>
  </table>
</template>

<script>
export default {
  name: "stock",
  props: ['report'],
  computed: {
    stockDates() {
      if (!this.report || !this.report.data || this.report.data.length < 1) return []
      return Array.from(new Set(this.report.data.map(r => `${r._id.day}/${r._id.month}/${r._id.year}`))).sort()
    },
    stockProducts() {
      if (!this.report || !this.report.data || this.report.data.length < 1) return []
      return Array.from(new Set(this.report.data.map(r => r._id.product)))
    },
  },
  methods: {
    getStockCell(day, product) {
      let dates = day.split('/');
      let cell = this.report.data.filter(r => r._id.product == product && r._id.day == parseInt(dates[0]) && r._id.month == parseInt(dates[1]) && r._id.year == parseInt(dates[2]))
      return cell && cell.length > 0 ? cell[0] : {count: 0}
    },
    getStockRow(product) {
      let filteredCells = this.report.data.filter(r => r._id.product == product);
      return filteredCells.reduce((sum, r) => sum + r.count, 0);
    },
    getCSVData() {
      let csvContent = ',' + this.stockDates.join(',') + ', Total\r\n';
      for  (let stockProduct of this.stockProducts) {
        csvContent +=  `"${stockProduct.replace('#', 'N')}",`
        for (let day of this.stockDates) {
          csvContent += this.getStockCell(day, stockProduct).count + ','
        }
        csvContent += this.getStockRow(stockProduct) + '\r\n'
      }
      return csvContent
    }
  }
}
</script>

<style scoped>

</style>
