<template>
  <div
    id="location"
    class="shop-section col-xs-12"
    v-bind:class="{ active: isActive }"
    @click="$emit('activeSection', 'location')"
  >
    <div class="header">
      <i class="fas fa-map-marker-alt section-icon" />

      <h5 class="section-title dark-blue-color">
        Location
        <br />
        <small>Store's Location</small>
      </h5>
    </div>
    <b-col class="option-content" v-if="shopInfo">
      <b-form id="location-form" @submit="saveDetails">
        <b-row>
          <b-col sm="12" md="6">
            <b-form-group>
              <label>Type</label>
              <select
                class="form-control text-capitalize"
                v-model="shopInfo.type"
              >
                <option>fixed</option>
                <option>dynamic</option>
              </select>
              <small>Type of address location</small>
              <b-alert variant="warning" :show="shopInfo.type === 'dynamic'">
                <p>
                  <i class="fas fa-info-circle"></i> Dynamic store's location on
                  map will follow the device geolocation where the account is
                  logged in and ready to receive orders. This is suitable for
                  coffee vans or food trucks.
                </p>
              </b-alert>
            </b-form-group>
            <b-form-group>
              <b-checkbox v-model="shopInfo.hideFromMap">
                Hide from map
              </b-checkbox>
              <small
                >Use this option to not display the venue on the map view of our
                mobile app directory. Shop only will appear in text search or if
                name is typed manually</small
              >
            </b-form-group>
          </b-col>
          <b-col sm="12" md="6">
            <b-form-group v-if="shopInfo.type === 'fixed'">
              <label>Address</label>
              <vue-google-autocomplete
                id="map"
                classname="form-control"
                placeholder="Enter your address"
                country="nz"
                required
                v-model="shopInfo.address.formatted_address"
                :types="'geocode'"
                v-on:placechanged="setAddress"
              >
              </vue-google-autocomplete>
              <small>Shop physical address</small>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col sm="12" md="6">
            <b-button
              variant="primary"
              type="submit"
              class="float-right mb-1"
              :disabled="loading"
              pill
            >
              <b-spinner v-if="loading" />
              <span v-else>Save</span>
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </b-col>
  </div>
</template>

<script>
import VueGoogleAutocomplete from "vue-google-autocomplete";
import { mapGetters } from "vuex";
export default {
  name: "shop-location",
  components: {
    VueGoogleAutocomplete,
  },
  props: ["isActive", "shop"],
  data: () => ({
    shopInfo: null,
  }),
  methods: {
    setAddress(geocodedAddress, place) {
      this.shopInfo.address = place;
      console.log(this.shopInfo.address);
    },
    saveDetails(e) {
      e.preventDefault();
      let shopInfo = {
        type: this.shopInfo.type,
        address: this.shopInfo.address,
        hideFromMap: this.shopInfo.hideFromMap,
      };
      this.$emit("saveChanges", shopInfo);
    },
  },
  computed: {
    ...mapGetters("shop", ["loading"]),
  },
  mounted() {
    this.shopInfo = JSON.parse(
      JSON.stringify({
        ...this.shop,
        address: this.shop.address || {},
      })
    );
  },
  watch: {
    shop(newVal) {
      if (!newVal) return;
      this.shopInfo = {
        ...newVal,
        address: newVal.address || {},
      };
    },
  },
};
</script>

<style scoped></style>
