var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-tab',{attrs:{"id":"presentation-tab"},scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('i',{staticClass:"fas fa-image"}),_vm._v(" Presentation ")]},proxy:true}])},[_c('b-form',{on:{"submit":_vm.saveProduct}},[_c('b-row',[_c('b-col',[_c('b-card',[_c('b-row',[_c('b-col',{attrs:{"lg":"6","sm":"12"}},[_c('b-form-group',{attrs:{"label":"Icon","description":"Product's identification icon, used in the market place and as the default product image"}},[_c('b-dropdown',{attrs:{"boundary":"","menu-class":"icons-container","variant":"light"},scopedSlots:_vm._u([{key:"button-content",fn:function(){return [(_vm.productInfo && _vm.productInfo.icon)?_c('span',[_c('img',{staticStyle:{"height":"30px","margin-right":"5px"},attrs:{"src":_vm.productInfo.icon.icon}}),_vm._v(_vm._s(_vm.productInfo.icon.name)+" ")]):_c('span',[_vm._v(" Select an Icon ")])]},proxy:true}])},_vm._l((_vm.icons),function(icon){return _c('b-dropdown-item',{key:icon._id,on:{"click":function($event){_vm.productInfo.icon = icon}}},[_c('img',{staticStyle:{"height":"30px"},attrs:{"src":icon.icon}}),_vm._v(" "+_vm._s(icon.name)+" ")])}),1)],1)],1),_c('b-col',{attrs:{"lg":"6","sm":"12"}},[(_vm.productInfo && _vm.productInfo.images)?_c('b-form-group',{attrs:{"label":"Hero Image","description":"Add hero image for this product. Note maximum size is 9mb"}},[_c('b-button',{directives:[{name:"b-modal",rawName:"v-b-modal.image-editor-modal",modifiers:{"image-editor-modal":true}}],attrs:{"variant":"light"}},[(_vm.loading)?_c('b-spinner'):_c('img',{staticStyle:{"width":"100px"},attrs:{"src":_vm.productInfo.images.hero}}),(
                      !_vm.productInfo.images ||
                      !_vm.productInfo.images.hero ||
                      !_vm.productInfo.images.hero === ''
                    )?_c('span',[_vm._v(" Click to Upload ")]):_vm._e()],1)],1):_vm._e()],1)],1)],1),_c('b-button',{staticClass:"float-right mt-3",attrs:{"type":"submit","pill":"","variant":"primary","size":"lg","disabled":_vm.loading}},[(_vm.loading)?_c('b-spinner'):_c('span',[_vm._v("Save")])],1)],1)],1)],1),(_vm.productInfo.images)?_c('imageEditor',{attrs:{"image-info":{
      productId: this.product._id,
      type: 'product-hero',
      image: _vm.productInfo.images.hero,
    }}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }