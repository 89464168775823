import axios from "axios";
import Vue from "vue";

export default {
  CREATE_PRODUCT: async (
    { rootState, commit, dispatch },
    { productInfo, shopId, options }
  ) => {
    commit("setLoading", true);
    try {
      const {
        data: { product },
      } = await axios({
        url: `${rootState.apiAddress}/api/products/`,
        method: "POST",
        data: {
          productInfo: JSON.stringify(productInfo),
          options: JSON.stringify(options),
        },
        headers: {
          Authorization: `Bearer ${rootState.userToken}`,
        },
      });
      await dispatch("category/GET_CATEGORIES", { shopId }, { root: true });
      Vue.toasted.success("Product creation successful", {
        duration: 2000,
      });
      commit("setLoading", false);
      return product;
    } catch (err) {
      console.log(err);
      const message =
        err.response && err.response.data && err.response.data.message
          ? err.response.data.message
          : rootState.defaultError;
      Vue.toasted.global
        .showServerError({
          message,
        })
        .goAway(4000);
    }
    commit("setLoading", false);
  },
  UPDATE_PRODUCT: async (
    { rootState, commit, dispatch },
    { productId, productInfo, shopId, skipReload = false, options }
  ) => {
    try {
      commit("setLoading", true);
      const {
        data: { product },
      } = await axios({
        url: `${rootState.apiAddress}/api/products/${productId}`,
        method: "PUT",
        data: {
          productInfo: JSON.stringify(productInfo),
          options: JSON.stringify(options),
        },
        headers: {
          Authorization: `Bearer ${rootState.userToken}`,
        },
      });

      if (!skipReload) {
        Vue.toasted.success("Product update successful", {
          duration: 2000,
        });
        await dispatch("category/GET_CATEGORIES", { shopId }, { root: true });
      }
      commit("setLoading", false);
      return product;
    } catch (err) {
      const message =
        err.response && err.response.data && err.response.data.message
          ? err.response.data.message
          : rootState.defaultError;
      Vue.toasted.global
        .showServerError({
          message,
        })
        .goAway(4000);
    }
    commit("setLoading", false);
  },
  DELETE_PRODUCT: async (
    { rootState, dispatch, commit },
    { productId, shopId }
  ) => {
    try {
      commit("setLoading", true);
      const {
        data: { product },
      } = await axios({
        url: `${rootState.apiAddress}/api/products/${productId}`,
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${rootState.userToken}`,
        },
      });

      await dispatch("category/GET_CATEGORIES", { shopId }, { root: true });
      Vue.toasted.success("Product delete successful", {
        duration: 2000,
      });
      commit("setLoading", false);
      return product;
    } catch (err) {
      const message =
        err.response && err.response.data && err.response.data.message
          ? err.response.data.message
          : rootState.defaultError;
      Vue.toasted.global
        .showServerError({
          message,
        })
        .goAway(4000);
    }
    commit("setLoading", false);
  },
  UPDATE_PRODUCT_IMAGE: async (
    { rootState, commit, dispatch },
    { productId, imageData, shopId }
  ) => {
    try {
      commit("setLoading", true);
      let {
        data: { secure_url },
      } = await axios({
        method: "POST",
        url: "https://api.cloudinary.com/v1_1/on-the-go/image/upload",
        data: {
          file: imageData,
          upload_preset: "hr8ken0o",
        },
        timeout: 60 * 1000,
      });
      commit("setLoading", false);
      await dispatch("UPDATE_PRODUCT", {
        productId,
        productInfo: {
          "images.hero": secure_url,
        },
        shopId,
      });
    } catch (err) {
      console.log(err);
      const message =
        err.response && err.response.data && err.response.data.message
          ? err.response.data.message
          : rootState.defaultError;
      Vue.toasted.global
        .showServerError({
          message,
        })
        .goAway(4000);
    }
    commit("setLoading", false);
  },
};
