<template>
  <div>
    <b-card>
      <b-col>
        <h5 class="section-title orange-color">
          Users Database
          <br />
          <small class="dark-blue-color">
            Manage all system's users and more
          </small>
        </h5>
      </b-col>
    </b-card>
    <b-container>
      <b-input-group class="my-3">
        <b-form-input
          v-model="textSearch"
          placeholder="Type name or email address"
          @keypress="search"
        />
        <b-input-group-append>
          <b-input-group-text>
            <i class="fas fa-search" />
          </b-input-group-text>
        </b-input-group-append>
      </b-input-group>
      <b-card>
        <b-table striped hover :items="customers" :fields="fields">
          <template #cell(_id)="data">
            <b-form-checkbox :id="data.item._id" />
          </template>
          <template #cell(name)="data">
            <router-link
              :to="`/admin/users/${data.item._id}`"
              tag="div"
              class="pointer"
            >
              <strong>{{ data.item.name.given }}</strong>
              <strong>{{ data.item.name.family }}</strong>
              <br />
              <small>{{ data.item.profile.email }}</small>
              <br />
              <small
                ><strong>{{ data.item.profile.phone }}</strong></small
              >
            </router-link>
          </template>

          <template #cell(roles)="data">
            <ul class="m-0 p-0" style="list-style-type: none">
              <li
                v-for="role of data.item.roles"
                :key="`${data.item._id}-${role}`"
              >
                {{ role }}
              </li>
            </ul>
          </template>

          <template #cell(cart)="data">
            <span>{{ data.item.cart.length }} items</span>
          </template>

          <template #cell(actions)="data">
            <b-dropdown no-caret variant="light">
              <template #button-content>
                <i class="fas fa-ellipsis-v"></i>
              </template>
              <b-dropdown-item @click="sendPasswordReset(data.item)">
                <i class="fas fa-envelope" />
                Send Password Reset
              </b-dropdown-item>
              <b-dropdown-item @click="clearUserCart(data.item)">
                <i class="fas fa-cart-arrow-down"></i>
                Clear Cart
              </b-dropdown-item>
              <b-dropdown-item
                v-b-modal.shop-assign-modal
                @click="selectedUser = data.item"
              >
                <i class="fas fa-store"></i>
                Assign Shop
              </b-dropdown-item>
            </b-dropdown>
          </template>
        </b-table>
        <b-pagination
          class="float-right"
          v-model="currentPage"
          :total-rows="customersCount"
          :per-page="usersLimit"
          aria-controls="my-table"
          @change="pageChange"
        ></b-pagination>
      </b-card>
    </b-container>
    <shop-assign-modal v-on:shop-selected="shopSelected" />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import ShopAssignModal from "../components/shop-assign-modal";
const usersLimit = 15;
export default {
  name: "admin-users",
  components: { ShopAssignModal },
  data: () => ({
    currentPage: 1,
    textSearch: "",
    fields: [
      {
        key: "_id",
        label: "",
        stickyColumn: true,
      },
      {
        key: "name",
        sortable: true,
        label: "Name",
        stickyColumn: true,
      },
      {
        key: "roles",
        label: "Roles",
      },
      {
        key: "cart",
        label: "Cart",
      },
      {
        key: "actions",
        label: "",
      },
    ],
    selectedUser: null,
  }),
  computed: {
    usersLimit() {
      return usersLimit;
    },
    ...mapGetters("customer", ["customers", "customersCount"]),
  },
  methods: {
    search(immediate = false) {
      if (this.timeOut) clearTimeout(this.timeOut);
      this.timeOut = setTimeout(
        () => {
          this.$store.dispatch("customer/GET_ALL_CUSTOMERS", {
            options: {
              limit: usersLimit,
              skip: usersLimit * (this.currentPage - 1),
            },
            search: this.textSearch,
          });
        },
        immediate ? 0 : 700
      );
    },
    async clearUserCart(user) {
      await this.$store.dispatch("customer/CLEAR_CUSTOMER_CART", {
        userId: user._id,
      });
      await this.search();
    },
    sendPasswordReset({ profile: { email } }) {
      this.$store.dispatch("PASSWORD_RESET", { email });
    },
    pageChange() {
      this.search(true);
    },
    shopSelected(shop) {
      let answer = confirm(
        `Are you sure you wish to transfer the venue "${shop.name.normal}" to the user "${this.selectedUser.name.given} ${this.selectedUser.name.family}(${this.selectedUser.profile.email})"?`
      );
      if (!answer) return;
      this.$store.dispatch("shop/TRANSFER_SHOP", {
        shopId: shop._id,
        userId: this.selectedUser._id,
      });
    },
  },
  mounted() {
    this.$store.dispatch("customer/GET_ALL_CUSTOMERS", {
      options: { limit: usersLimit },
      search: "",
    });
  },
};
</script>

<style scoped>
.pointer {
  cursor: pointer;
}
</style>
