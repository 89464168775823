<template>
  <b-tab>
    <template #title> <i class="fas fa-key" /> Password Management </template>
    <b-form @submit.prevent="savePassword">
      <b-card>
        <b-row>
          <b-col lg="6" sm="12">
            <b-form-group
              label="Current Password"
              description="To validate your password update, please enter your current password"
            >
              <b-form-input
                type="password"
                required
                v-model="currentPassword"
                placeholder="Enter at least 6 characters"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col lg="6" sm="12">
            <b-form-group
              label="New Password"
              description="Please enter your new password"
            >
              <b-form-input
                type="password"
                required
                v-model="newPassword"
                placeholder="Enter at least 6 characters"
              />
            </b-form-group>
          </b-col>
          <b-col lg="6" sm="12">
            <b-form-group
              label="Password confirmation"
              description="Confirm your new password"
            >
              <b-form-input
                type="password"
                required
                v-model="confirmPassword"
                placeholder="Enter at least 6 characters"
              />
            </b-form-group>
          </b-col>
        </b-row>
      </b-card>
      <b-button
        type="submit"
        pill
        variant="primary"
        class="float-right mt-3"
        size="lg"
        :disabled="loading"
      >
        <b-spinner v-if="loading" />
        <span v-else>Save</span>
      </b-button>
    </b-form>
  </b-tab>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "password-reset",
  data: () => ({
    currentPassword: "",
    newPassword: "",
    confirmPassword: "",
  }),
  computed: {
    ...mapGetters(["loading"]),
  },
  methods: {
    async savePassword() {
      const { currentPassword, newPassword, confirmPassword } = this;
      await this.$store.dispatch("UPDATE_PASSWORD", {
        currentPassword,
        newPassword,
        confirmPassword,
      });
    },
  },
};
</script>

<style scoped></style>
