<script>
import { mapGetters } from "vuex";

export default {
  name: "buzzer-settings",
  props: ["isActive"],
  data: () => ({
    shopInfo: null,
  }),
  computed: {
    ...mapGetters("shop", ["loading", "shop"]),
    ...mapGetters({ user: "userInfo" }),
  },
  methods: {
    saveDetails(e) {
      e.preventDefault();
      let shopInfo = {
        buzzerOrTag: this.shopInfo.buzzerOrTag,
      };
      this.$emit("saveChanges", shopInfo);
    },
    toggleBuzzer(e) {
      this.shopInfo.buzzerOrTag.enabled = !this.shopInfo.buzzerOrTag.enabled;
      this.saveDetails(e);
    },
    selectBuzzerPlatforms(platform) {
      let platforms = [];
      if (this.shopInfo.buzzerOrTag.platforms)
        platforms = this.shopInfo.buzzerOrTag.platforms;
      if (platforms.includes(platform))
        platforms = platforms.filter((p) => p !== platform);
      else platforms.push(platform);
      this.shopInfo.buzzerOrTag.platforms = platforms;
      this.$forceUpdate();
    },
    selectBuzzerOrderTypes(type) {
      let types = [];
      if (this.shopInfo.buzzerOrTag.orderTypes)
        types = this.shopInfo.buzzerOrTag.orderTypes;
      if (types.includes(type)) types = types.filter((p) => p !== type);
      else types.push(type);
      this.shopInfo.buzzerOrTag.orderTypes = types;
      this.$forceUpdate();
    },
  },
  mounted() {
    this.shopInfo = JSON.parse(JSON.stringify(this.shop));
  },
  watch: {
    shop(newVal) {
      if (!newVal) return;
      this.shopInfo = {
        ...newVal,
      };
    },
  },
};
</script>

<template>
  <div
    id="buzzer"
    class="shop-section col-xs-12"
    v-bind:class="{ active: isActive }"
    @click="$emit('activeSection', 'buzzer')"
  >
    <div class="header">
      <i class="fas fa-wifi section-icon" />

      <h5 class="section-title dark-blue-color">
        Buzzer Settings
        <br />
        <small>Enable and set your buzzer system settings</small>
      </h5>
    </div>
    <b-col
      class="option-content col-xs-12"
      v-if="shopInfo && shopInfo.buzzerOrTag"
    >
      <b-form class="col-xs-12" @submit="saveDetails">
        <b-row>
          <b-col class="pt-6" sm="12" md="6">
            <b-form-group
              label="Buzzer Input's Platforms:"
              description="This option enables the option to enter a buzzer number on the checkout page of the selected platforms"
            >
              <b-dropdown
                block
                variant="primary"
                toggle-class="text-capitalize"
                :text="
                  shopInfo.buzzerOrTag.platforms &&
                  shopInfo.buzzerOrTag.platforms.length > 0
                    ? shopInfo.buzzerOrTag.platforms.join(' - ')
                    : 'Select Platform Options'
                "
              >
                <b-dropdown-item
                  :active="
                    shopInfo.buzzerOrTag.platforms &&
                    shopInfo.buzzerOrTag.platforms.includes('pos')
                  "
                  @click.stop.prevent="selectBuzzerPlatforms('pos')"
                  >POS</b-dropdown-item
                >
                <b-dropdown-item
                  :active="
                    shopInfo.buzzerOrTag.platforms &&
                    shopInfo.buzzerOrTag.platforms.includes('embed')
                  "
                  @click.stop.prevent="selectBuzzerPlatforms('embed')"
                  >Online Ordering</b-dropdown-item
                >
                <b-dropdown-item
                  :active="
                    shopInfo.buzzerOrTag.platforms &&
                    shopInfo.buzzerOrTag.platforms.includes('kiosk')
                  "
                  @click.stop.prevent="selectBuzzerPlatforms('kiosk')"
                  >Kiosk</b-dropdown-item
                >
              </b-dropdown>
            </b-form-group>
          </b-col>
          <b-col class="pt-6" sm="12" md="6">
            <b-form-group
              label="Buzzer Input's Order Types:"
              description="This option enables the option to enter a buzzer number on the checkout page for specific order types on the selected platforms"
            >
              <b-dropdown
                block
                variant="primary"
                toggle-class="text-capitalize"
                :text="
                  shopInfo.buzzerOrTag.orderTypes &&
                  shopInfo.buzzerOrTag.orderTypes.length > 0
                    ? shopInfo.buzzerOrTag.orderTypes.join(' - ')
                    : 'Select Order Type Options'
                "
              >
                <b-dropdown-item
                  :active="
                    shopInfo.buzzerOrTag.orderTypes &&
                    shopInfo.buzzerOrTag.orderTypes.includes('pickup')
                  "
                  @click.stop.prevent="selectBuzzerOrderTypes('pickup')"
                  >Pick Up</b-dropdown-item
                >
                <b-dropdown-item
                  :active="
                    shopInfo.buzzerOrTag.orderTypes &&
                    shopInfo.buzzerOrTag.orderTypes.includes('dine-in')
                  "
                  @click.stop.prevent="selectBuzzerOrderTypes('dine-in')"
                  >Dine In</b-dropdown-item
                >
                <b-dropdown-item
                  :active="
                    shopInfo.buzzerOrTag.orderTypes &&
                    shopInfo.buzzerOrTag.orderTypes.includes('delivery')
                  "
                  @click.stop.prevent="selectBuzzerOrderTypes('delivery')"
                  >Delivery</b-dropdown-item
                >
              </b-dropdown>
            </b-form-group>
          </b-col>
        </b-row>
      </b-form>
      <b-row>
        <b-col>
          <b-button
            variant="primary"
            type="submit"
            class="float-right mb-1 ml-2"
            :disabled="loading"
            pill
            @click="saveDetails"
          >
            <b-spinner v-if="loading" />
            <span v-else>Save</span>
          </b-button>
          <b-button
            :variant="
              shopInfo.buzzerOrTag.enabled
                ? 'outline-danger'
                : 'outline-primary'
            "
            @click="toggleBuzzer"
            class="float-right mb-1"
            :disabled="loading"
            pill
          >
            <b-spinner v-if="loading" />
            <span v-else
              >{{ shopInfo.buzzerOrTag.enabled ? "Disable" : "Enable" }} Buzzer
              System</span
            >
          </b-button>
        </b-col>
      </b-row>
    </b-col>
  </div>
</template>

<style scoped></style>
