export default {
  setLoading: (state, loading) => (state.loading = loading),
  setReport: (state, report) => (state.report = report),
  setTodaySalesPerHour: (state, todaySalesPerHour) =>
    (state.todaySalesPerHour = todaySalesPerHour),
  setTodayBalance: (state, todayBalance) => (state.todayBalance = todayBalance),
  setTomorrowPayout: (state, tomorrowPayout) =>
    (state.tomorrowPayout = tomorrowPayout),
  setOverViewSalesReport: (state, overViewSalesReport) =>
    (state.overViewSalesReport = overViewSalesReport),
  setTodayVSYesterdayOverViewSalesReport: (
    state,
    todayVSYesterdayOverViewSalesReport
  ) =>
    (state.todayVSYesterdayOverViewSalesReport =
      todayVSYesterdayOverViewSalesReport),
  setMostSoldProducts: (state, mostSoldProducts) =>
    (state.mostSoldProducts = mostSoldProducts),
  setLoyaltyProducts: (state, loyaltyProducts) =>
    (state.loyaltyProducts = loyaltyProducts),
  setEmbedVSApp: (state, embedVSApp) => (state.embedVSApp = embedVSApp),
};
