<template>
  <b-modal
    id="promo-code-delete-modal"
    title="Delete Confirmation"
    okVariant="danger"
    okTitle="Delete"
    centered
    @ok="deleteCode"
  >
    <p class="my-4" v-if="promoCode">
      Are you sure you want to proceed with the deletion of the promo code "{{
        promoCode.code
      }}"? This action can't be reversed. Also the removal of a promo code will
      not remove any applied discount to orders which had already claimed this
      code.
    </p>
  </b-modal>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "deleteCodeModal",
  props: ["promoCode"],
  computed: {
    ...mapGetters("shop", ["shop"]),
  },
  methods: {
    deleteCode() {
      this.$store.dispatch("promocode/DELETE_PROMO_CODE", {
        code: this.promoCode,
        shopId: this.shop._id,
      });
    },
  },
};
</script>

<style scoped></style>
