<template>
  <b-modal
    id="create-shop-modal"
    title="New Shop"
    @ok="createShop"
    okTitle="Create"
  >
    <b-form-group
      label="Shop Name"
      description="The shop display name in our marketplace or your embed store"
    >
      <b-form-input v-model="shopInfo.name.normal" required ref="nameInput" />
    </b-form-group>
  </b-modal>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "create-shop-modal",
  data: () => ({
    shopInfo: {
      name: {
        normal: "",
      },
    },
  }),
  computed: {
    ...mapGetters(["userInfo", "shopId"]),
  },
  methods: {
    async createShop(e) {
      e.preventDefault();
      if (
        !this.shopInfo.name ||
        !this.shopInfo.name.normal ||
        this.shopInfo.name.normal.trim().length < 1
      )
        return this.$refs.nameInput.focus();
      await this.$store.dispatch("shop/CREATE_SHOP", {
        shopInfo: {
          name: this.shopInfo.name,
          contact: {
            email: this.userInfo.profile.email,
          },
        },
      });
      this.$bvModal.hide("create-shop-modal");
      this.$bvModal.hide("shop-selection-modal");
    },
  },
};
</script>

<style scoped></style>
