<template>
  <b-row>
    <b-col>
      <b-form-group
        label="Target"
        description="Pick the target of the reward, either choose all your customers or a selected group of them"
      >
        <b-form-radio
          v-model="promoCode.targetUsersConfig.isStoreWide"
          :value="true"
        >
          All Customers
        </b-form-radio>
        <b-form-radio
          v-model="promoCode.targetUsersConfig.isStoreWide"
          :value="false"
        >
          Selected Customer(s)
        </b-form-radio>
      </b-form-group>
      <b-form-group v-if="!promoCode.targetUsersConfig.isStoreWide">
        <b-input-group class="mt-2 mb-2">
          <b-form-input
            v-model="textSearch"
            placeholder="Enter customer's name or email"
          />
          <b-input-group-append>
            <b-input-group-text>
              <i class="fas fa-search"></i>
            </b-input-group-text>
          </b-input-group-append>
        </b-input-group>
        <b-list-group class="users-list">
          <b-list-group-item
            v-for="user of filteredCustomers"
            :key="user._id"
            :active="promoCode.targetUsersConfig.users.includes(user._id)"
            @click="toggleUser(user)"
          >
            {{ user.name.given }} {{ user.name.family }}
            <span class="float-right">({{ user.profile.email }})</span>
          </b-list-group-item>
          <b-list-group-item v-if="filteredCustomers.length < 1">
            <h4>No users found under specified search</h4>
          </b-list-group-item>
        </b-list-group>
      </b-form-group>
      <b-form-group
        label="Usage Limit"
        description="Maximum number of time this code can be used (every user can use it just once). Enter 0
          to make it unlimited"
      >
        <b-form-input
          v-model="promoCode.targetUsersConfig.usageLimit"
          type="number"
          placeholder="i.e. 200, so can be used by 200 different users once"
        />
      </b-form-group>
      <!--<div class="form-group col-xs-12">-->
      <!--<label>Target</label>-->
      <!--<div class="radio">-->
      <!--<label>-->
      <!--<input type="radio" value="{{true}}" name="{{codeInfo.targetUsersConfig.isStoreWide}}"/>-->
      <!--Everybody-->
      <!--</label>-->
      <!--<label>-->
      <!--<input type="radio" value="{{false}}"-->
      <!--name="{{codeInfo.targetUsersConfig.isStoreWide}}"/>-->
      <!--Selected Users-->
      <!--</label>-->
      <!--{{#if !codeInfo.targetUsersConfig.isStoreWide}}-->
      <!--<div class="col-xs-12 input-group" style="margin-bottom: 15px;">-->
      <!--<input class="form-control" value="{{userSearch}}" placeholder="type a name or email"/>-->
      <!--<div class="input-group-addon">-->
      <!--<i class="fa fa-search"/>-->
      <!--</div>-->
      <!--</div>-->
      <!--<div class="col-xs-12 npsds" style="max-height: 500px;overflow: scroll;">-->
      <!--{{#each customers}}-->
      <!--{{#if isUserInSearch(this)}}-->
      <!--<div class="col-xs-6" style="padding-left: 0px;">-->
      <!--<div-->
      <!--on-click="['toggleCustomer', this._id]"-->
      <!--class="well well-sm"-->
      <!--style="min-height: 70px; {{codeInfo.targetUsersConfig.users.includes(this._id) ? 'border:2px solid black' : ''}}">-->
      <!--{{this.name.given}} {{this.name.family}} ({{this.profile.email}})-->
      <!--</div>-->
      <!--</div>-->
      <!--{{/if}}-->
      <!--{{/each}}-->
      <!--</div>-->
      <!--{{/if}}-->
      <!--</div>-->
      <!--<small>Pick the target of the reward, either choose all your customers or a selected group of-->
      <!--people-->
      <!--</small>-->
      <!--</div>-->
      <!--<div class="form-group col-xs-12">-->
      <!--<label>Usage Limit</label>-->
      <!--<input class="form-control" type="number" value="{{codeInfo.targetUsersConfig.usageLimit}}"-->
      <!--/>-->
      <!--<small>Maximum number of time this code can be used (every user can use it just once). Enter 0-->
      <!--to make it unlimited-->
      <!--</small>-->
      <!--</div>-->
    </b-col>
  </b-row>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "step1",
  props: ["promoCode"],
  data: () => ({
    textSearch: "",
  }),
  computed: {
    ...mapGetters("customer", ["customers"]),
    filteredCustomers() {
      return this.customers.filter(
        (c) =>
          c.name.given.toLowerCase().includes(this.textSearch.toLowerCase()) ||
          c.name.family.toLowerCase().includes(this.textSearch.toLowerCase()) ||
          c.profile.email.toLowerCase().includes(this.textSearch.toLowerCase())
      );
    },
  },
  methods: {
    toggleUser(user) {
      if (this.promoCode.targetUsersConfig.users.includes(user._id))
        this.promoCode.targetUsersConfig.users =
          this.promoCode.targetUsersConfig.users.filter((u) => u !== user._id);
      else this.promoCode.targetUsersConfig.users.push(user._id);
    },
  },
};
</script>

<style scoped>
.users-list {
  max-height: 400px;
  overflow: scroll;
}
</style>
