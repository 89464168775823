<template>
  <b-tab>
    <template #title>
      <span class="text-uppercase">{{ status }}</span>
    </template>
    <div style="height: 100%; overflow: scroll">
      <b-row
        @click="selectOrder(order)"
        class="mt-3 pointer m-0 p-2"
        v-bind:class="{
          blink:
            order.status !== 'completed' &&
            order.orderType === 'pickup' &&
            moment(order.pickupTime).diff(moment(), 'minutes') < 3,
        }"
        v-for="order of filteredOrders"
        :key="order._id"
      >
        <b-col cols="3" class="p-0">
          <b-img width="70" :src="getOrderPicture(order)" rounded="circle" />
        </b-col>
        <b-col cols="7">
          <b-row>
            <b-col>
              <div v-if="order.type === 'delivery'">
                <h4>
                  Delivery
                  {{
                    order.deliveryInfo.arrival.time
                      | moment("hh:mm A DD/MM/YYYY")
                  }}
                </h4>
              </div>
              <div v-else>
                <span v-if="order.pickupType === 'dynamic'">
                  Pick Up {{ order.pickupTime | moment("hh:mm A") }}
                  <br />
                  <small>{{ order.pickupTime | moment("DD/MM/YYYY") }}</small>
                </span>
                <span v-else> Scheduled Pick Up {{ order.seen }} </span>
              </div>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <div v-if="order.owner">
                <h5 class="text-capitalize">
                  {{ order.owner.name.given }} {{ order.owner.name.family }}
                </h5>
              </div>
              <div v-else>
                <strong class="text-capitalize">
                  {{ order.guestOwner.name.given }}
                  {{ order.guestOwner.name.family }}
                </strong>
              </div>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <div
                v-for="(lineItem, index) of order.lineItems"
                :key="`order-${order._id}-${index}`"
              >
                <b-img
                  v-if="lineItem.product && lineItem.product.icon"
                  :src="lineItem.product.icon.icon"
                  width="20"
                />
              </div>
            </b-col>
          </b-row>
        </b-col>
        <b-col cols="1">
          <i
            class="fas fa-circle text-primary"
            v-if="!order.seen && order.status === 'pending'"
          />
          <i
            class="fas fa-spinner text-info"
            v-if="order.status === 'accepted'"
          />
          <i
            class="fas fa-check-circle text-success"
            v-if="order.status === 'completed'"
          />
        </b-col>
      </b-row>
    </div>
  </b-tab>
</template>

<script>
import { mapGetters } from "vuex";
import * as moment from "moment";

export default {
  name: "order-by-status",
  props: ["status"],
  data: () => ({
    statuses: {
      pending: ["pending", "accepted"],
      completed: ["completed"],
    },
    moment,
  }),
  computed: {
    ...mapGetters("order", ["orders"]),
    filteredOrders() {
      return this.orders.filter((o) =>
        this.statuses[this.status].includes(o.status)
      );
    },
  },
  methods: {
    selectOrder(order) {
      this.$emit("orderSelected", order);
      let orders = JSON.parse(JSON.stringify(this.orders));
      let index = orders.findIndex((o) => o._id === order._id);
      orders[index].seen = true;
      console.log(orders[index]);
      this.$store.commit("order/setOrders", orders);
      this.$forceUpdate();
    },
    getOrderPicture(order) {
      if (
        !order.owner ||
        !order.owner.pictures ||
        !order.owner.pictures.profile
      )
        return require("../../assets/Userpic.png");
      return order.owner.pictures.profile;
    },
  },
};
</script>

<style scoped>
.pointer {
  cursor: pointer;
}

.pointer:hover {
  background: lightgrey;
}

@keyframes blink {
  0% {
    color: red;
  }
  100% {
    color: black;
  }
}
@-webkit-keyframes blink {
  0% {
    color: red;
  }
  100% {
    color: black;
  }
}
.blink {
  -webkit-animation: blink 1s linear infinite;
  -moz-animation: blink 1s linear infinite;
  animation: blink 1s linear infinite;
}
</style>
