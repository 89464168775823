<template>
  <b-tab>
    <template #title> <i class="fas fa-list-alt"></i> Options </template>
    <b-row>
      <b-col
        lg="6"
        sm="12"
        v-for="(option, optionIndex) of options"
        :key="option._id"
      >
        <b-card class="mt-3">
          <template #header>
            {{ option.name }}
          </template>
          <b-row v-for="(value, valueIndex) of option.values" :key="value._id">
            <b-col>
              {{ value.name }}
              <br />
              <small>{{ (value.price / 100) | numeral("$0,0.00") }}</small>
            </b-col>
            <b-col>
              <b-form-checkbox
                :checked="value.default"
                @change="setDefaultValue(optionIndex, valueIndex)"
              >
                Default
              </b-form-checkbox>
            </b-col>
            <b-col>
              <b-form-checkbox v-model="value.selected">
                Show this option
              </b-form-checkbox>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
    <b-button
      @click="saveProduct"
      pill
      variant="primary"
      class="float-right mt-3"
      size="lg"
      :disabled="loading"
    >
      <b-spinner v-if="loading" />
      <span v-else>Save</span>
    </b-button>
  </b-tab>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "options",
  props: ["category", "product"],
  data: () => ({
    options: [],
  }),
  computed: {
    ...mapGetters("product", ["loading"]),
  },
  methods: {
    saveProduct(e) {
      e.preventDefault();
      let options = this.options.filter((o) => {
        let selectedValues = o.values.filter((v) => v.selected);
        return selectedValues.length > 0;
      });
      options = options.map((o) => {
        let selectedValues = o.values.filter((v) => v.selected);
        return {
          ...o,
          values: selectedValues,
        };
      });

      this.$store.dispatch("product/UPDATE_PRODUCT", {
        productId: this.product._id,
        productInfo: {
          options,
        },
        shopId: this.product.shop,
      });
    },
    setDefaultValue(optionIndex, valueIndex) {
      this.options[optionIndex].values = this.options[optionIndex].values.map(
        (v) => ({
          ...v,
          default: false,
        })
      );
      this.options[optionIndex].values[valueIndex].default = true;
    },
  },
  mounted() {
    this.options = JSON.parse(JSON.stringify(this.category.options));
    for (let [optionIndex, option] of this.options.entries()) {
      let storeOption = this.product.options.find((o) => o._id === option._id);
      if (!storeOption) continue;
      valuesLoop: for (let [valueIndex, value] of option.values.entries()) {
        let storedValue = storeOption.values.find((v) => v._id === value._id);
        if (!storedValue) continue valuesLoop;
        this.options[optionIndex].values[valueIndex].selected =
          storedValue.selected;
        this.options[optionIndex].values[valueIndex].price = storedValue.price;
        this.options[optionIndex].values[valueIndex].default =
          storedValue.default;
      }
    }
  },
  watch: {
    category(newVal) {
      if (newVal) {
        this.options = JSON.parse(JSON.stringify(newVal.options));
        for (let [optionIndex, option] of this.options.entries()) {
          let storeOption = this.product.options.find(
            (o) => o._id === option._id
          );
          if (!storeOption) continue;
          valuesLoop: for (let [valueIndex, value] of option.values.entries()) {
            let storedValue = storeOption.values.find(
              (v) => v._id === value._id
            );
            if (!storedValue) continue valuesLoop;
            this.options[optionIndex].values[valueIndex].selected =
              storedValue.selected;
            this.options[optionIndex].values[valueIndex].price =
              storedValue.price;
            this.options[optionIndex].values[valueIndex].default =
              storedValue.default;
          }
        }
      }
    },
  },
};
</script>

<style scoped></style>
