<template>
  <b-modal
    id="shop-create-location-modal"
    title="Shop Stripe Location"
    ok-title="Set Location"
    @ok="setLocation"
  >
    <b-form-group label="Line 1">
      <b-form-input required v-model="line1" />
    </b-form-group>
    <b-form-group label="Line 2">
      <b-form-input required v-model="line2" />
    </b-form-group>
    <b-form-group label="city">
      <b-form-input required v-model="city" />
    </b-form-group>
    <b-form-group label="country">
      <b-form-input required v-model="country" />
    </b-form-group>
    <b-form-group label="Post Code">
      <b-form-input required v-model="postCode" />
    </b-form-group>
  </b-modal>
</template>

<script>
export default {
  name: "shop-create-location-modal",
  props: ["shop"],
  data: () => ({
    line1: "",
    line2: "",
    city: "",
    country: "NZ",
    postCode: "",
  }),
  methods: {
    async setLocation() {
      const { line1, line2, city, country, postCode } = this;
      await this.$store.dispatch("shop/SET_STRIPE_LOCATION", {
        shopId: this.shop._id,
        location: {
          line1,
          line2,
          city,
          country,
          postCode,
        },
      });
    },
  },
};
</script>

<style scoped></style>
