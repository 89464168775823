import axios from "axios";
import Vue from "vue";

export default {
  GET_CUSTOMER_ORDERS: async (
    { rootState, commit },
    { userId, shopId, options }
  ) => {
    try {
      commit("setLoading", true);
      const {
        data: { orders, count },
      } = await axios({
        url: `${
          rootState.apiAddress
        }/api/shops/${shopId}/customers/${userId}/orders?options=${JSON.stringify(
          options
        )}`,
        method: "GET",
        headers: {
          Authorization: `Bearer ${rootState.userToken}`,
        },
      });

      commit("setCustomerOrders", orders);
      commit("setTotalCustomerOrders", count);
    } catch (err) {
      const message =
        err.response && err.response.data && err.response.data.message
          ? err.response.data.message
          : rootState.defaultError;
      Vue.toasted.global
        .showServerError({
          message,
        })
        .goAway(4000);
    }
    commit("setLoading", false);
  },
  GET_SHOP_ORDERS: async (
    { rootState, commit },
    { shopId, status = null, dateRange = "day", options }
  ) => {
    try {
      commit("setLoading", true);
      const {
        data: { orders, count },
      } = await axios({
        url: `${
          rootState.apiAddress
        }/api/shops/${shopId}/orders?status=${status}&dateRange=${dateRange}&options=${JSON.stringify(
          options
        )}`,
        method: "GET",
        headers: {
          Authorization: `Bearer ${rootState.userToken}`,
        },
      });

      commit("setOrders", orders);
      commit("setTotalOrders", count);
    } catch (err) {
      const message =
        err.response && err.response.data && err.response.data.message
          ? err.response.data.message
          : rootState.defaultError;
      Vue.toasted.global
        .showServerError({
          message,
        })
        .goAway(4000);
    }
    commit("setLoading", false);
  },
  UPDATE_ORDER_STATUS: async ({ rootState, commit }, { orderId, status }) => {
    try {
      commit("setLoading", true);
      await axios({
        url: `${rootState.apiAddress}/api/orders/${orderId}/${status}`,
        method: "PUT",
        headers: {
          Authorization: `Bearer ${rootState.userToken}`,
        },
      });
    } catch (err) {
      const message =
        err.response && err.response.data && err.response.data.message
          ? err.response.data.message
          : rootState.defaultError;
      Vue.toasted.global
        .showServerError({
          message,
        })
        .goAway(4000);
    }
    commit("setLoading", false);
  },
  SEND_ORDER_RECEIPT: async ({ rootState, commit }, { orderId, email }) => {
    try {
      commit("setLoading", true);
      const {
        data: { ok },
      } = await axios({
        url: `${rootState.apiAddress}/api/orders/${orderId}/resend-receipt`,
        method: "POST",
        data: {
          email,
        },
        headers: {
          Authorization: `Bearer ${rootState.userToken}`,
        },
      });
      if (ok) Vue.toasted.success("Receipt send successfully to " + email);
    } catch (err) {
      const message =
        err.response && err.response.data && err.response.data.message
          ? err.response.data.message
          : rootState.defaultError;
      Vue.toasted.global
        .showServerError({
          message,
        })
        .goAway(4000);
    }
    commit("setLoading", false);
  },
};
