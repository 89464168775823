export default {
  localAddress: (state) => state.localAddress,
  loading: (state) => state.loading,
  shopId: (state) => state.shopId,
  embedId: (state) => state.embedId,
  userToken: (state) => state.userToken,
  userId: (state) => state.userId,
  userInfo: (state) => state.userInfo,
  guestToken: (state) => state.guestToken,
  guestId: (state) => state.guestId,
  guestInfo: (state) => state.guestInfo,
  guestExchangeToken: (state) => state.guestExchangeToken,
  deliveryInfo: (state) => state.deliveryInfo,
  stripeKey: (state) => state.stripeKey,
  cart: (state) => state.cart,
  placingOrder: (state) => state.placingOrder,
  lockingLoadingTitle: (state) => state.lockingLoadingTitle,
  lockingLoadingDescription: (state) => state.lockingLoadingDescription,
  showLockingLoading: (state) => state.showLockingLoading,
  orderType: (state) => state.orderType,
  promoCode: (state) => state.promoCode,
  orderCompleted: (state) => state.orderCompleted,
  layoutConfig: (state) => state.layoutConfig,
  platformPlans: (state) => state.platformPlans,
};
