<template>
  <div style="height: 100%; overflow: scroll">
    <b-card>
      <h5 class="section-title orange-color">
        Table Ordering
        <br />
        <small class="dark-blue-color">
          Set your store options for dine in customers and allow ordering from
          their tables
        </small>
      </h5>
    </b-card>
    <div v-if="shop" style="height: 100%">
      <b-tabs style="height: 100%" card fill pills>
        <settings />
        <tables />
      </b-tabs>
    </div>
  </div>
</template>

<script>
import Settings from "../components/table-ordering/settings";
import Tables from "../components/table-ordering/tables";
import { mapGetters } from "vuex";

export default {
  name: "table-ordering",
  components: { Tables, Settings },
  computed: {
    ...mapGetters("shop", ["shop"]),
  },
  mounted() {
    if (this.shop)
      this.$store.dispatch("shop/GET_EMBED_KEY", {
        shopId: this.shop._id,
        ignoreException: true,
      });
  },
  watch: {
    shop(newVal) {
      this.$store.dispatch("shop/GET_EMBED_KEY", {
        shopId: newVal._id,
        ignoreException: true,
      });
    },
  },
};
</script>

<style scoped></style>
