<template>
  <div>
    <b-card>
      <b-col>
        <h5 class="section-title orange-color">
          Kiosk Settings
          <br />
          <small class="dark-blue-color">
            Update kiosk details and display settings
          </small>
        </h5>
      </b-col>
    </b-card>
    <b-tabs fill pills style="height: 100%" card>
      <Details :kiosk="kiosk" v-if="kiosk" />
      <b-tab title="Layout">
        <LayoutEditorTwo :kiosk="kiosk" v-if="kiosk" />
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import Details from "../components/kiosk-details/details";
import LayoutEditorTwo from "../components/layout-editor/layoutEditorTwo";
import { mapGetters } from "vuex";

export default {
  name: "kiosk-details",
  props: ["kioskId"],
  components: { LayoutEditorTwo, Details },
  computed: {
    ...mapGetters("kiosk", ["kiosks"]),
    ...mapGetters("shop", ["shop"]),
    kiosk() {
      return this.kiosks.find((k) => k._id === this.kioskId);
    },
  },
  async mounted() {
    if (this.shop)
      await this.$store.dispatch("kiosk/GET_KIOSKS", { shopId: this.shop._id });
  },
  watch: {
    async shop(newVal) {
      if (!newVal) return;
      await this.$store.dispatch("kiosk/GET_KIOSKS", { shopId: newVal._id });
    },
  },
};
</script>

<style scoped></style>
