<script>
import moment from "moment/moment";

export default {
  name: "today-badge-metric",
  props: ["data", "type"],
  computed: {
    todayValues() {
      if (!this.data) return [];
      const today = moment().dayOfYear();
      return this.data.filter((d) => {
        let { hour, day, month, year } = d._id;
        let fecha = moment.utc([year, month - 1, day, hour, 0]);
        fecha.local();
        return fecha.dayOfYear() === today;
      });
    },
    totalAmountToday() {
      return this.todayValues.reduce((acc, val) => {
        return acc + val.totalAmount;
      }, 0);
    },
    totalCountToday() {
      return this.todayValues.reduce((acc, val) => {
        return acc + val.count;
      }, 0);
    },
    totalAverageToday() {
      if (isNaN(this.totalAmountToday) || isNaN(this.totalCountToday)) return 0;
      return this.totalAmountToday / this.totalCountToday;
    },
    yesterdayValues() {
      if (!this.data) return [];
      const today = moment().dayOfYear();
      return this.data.filter((d) => {
        let { hour, day, month, year } = d._id;
        let fecha = moment.utc([year, month - 1, day, hour, 0]);
        fecha.local();
        return fecha.dayOfYear() !== today;
      });
    },
    totalAmountYesterday() {
      return this.yesterdayValues.reduce((acc, val) => {
        return acc + val.totalAmount;
      }, 0);
    },
    totalCountYesterday() {
      return this.yesterdayValues.reduce((acc, val) => {
        return acc + val.count;
      }, 0);
    },
    totalAverageYesterday() {
      if (isNaN(this.totalAmountYesterday) || isNaN(this.totalCountYesterday))
        return 0;
      return this.totalAmountYesterday / this.totalCountYesterday;
    },
  },
  methods: {
    relDiff(a, b) {
      return 100 * Math.abs((a - b) / ((a + b) / 2));
    },
  },
};
</script>

<template>
  <b-card>
    <b-row v-if="type === 'total-sales'" class="text-center">
      <b-col>
        <h6>Total Sales Today</h6>
        <h2 class="report-title">${{ (totalAmountToday / 100).toFixed(2) }}</h2>
        <span
          v-bind:class="{
            'text-success': totalAmountToday - totalAmountYesterday >= 0.0,
            'text-danger': totalAmountToday - totalAmountYesterday <= 0.0,
          }"
        >
          <i
            class="fas"
            v-bind:class="{
              'fa-arrow-up': totalAmountToday - totalAmountYesterday >= 0.0,
              'fa-arrow-down': totalAmountToday - totalAmountYesterday <= 0.0,
            }"
          ></i
          >{{ ((totalAmountToday - totalAmountYesterday) / 100).toFixed(2) }}
        </span>
        <br />
        <small
          >VS yesterday(${{ (totalAmountYesterday / 100).toFixed(2) }})</small
        >
      </b-col>
    </b-row>
    <b-row v-if="type === 'total-orders'">
      <b-col class="text-center">
        <h6>Total Orders Today</h6>
        <h2 class="report-title">{{ totalCountToday }}</h2>
        <span
          v-bind:class="{
            'text-success': totalCountToday - totalCountYesterday >= 0.0,
            'text-danger': totalCountToday - totalCountYesterday <= 0.0,
          }"
        >
          <i
            class="fas"
            v-bind:class="{
              'fa-arrow-up': totalCountToday - totalCountYesterday >= 0.0,
              'fa-arrow-down': totalCountToday - totalCountYesterday <= 0.0,
            }"
          ></i
          >{{ totalCountToday - totalCountYesterday }}
        </span>
        <br />
        <small>VS yesterday({{ totalCountYesterday }})</small>
      </b-col>
    </b-row>
    <b-row v-if="type === 'average-orders'">
      <b-col class="text-center">
        <h6>Average order size</h6>
        <h2 class="report-title">
          ${{ (totalAverageToday / 100).toFixed(2) }}
        </h2>
        <span
          v-bind:class="{
            'text-success': totalAverageToday - totalAverageYesterday >= 0.0,
            'text-danger': totalAverageToday - totalAverageYesterday <= 0.0,
          }"
        >
          <i
            class="fas"
            v-bind:class="{
              'fa-arrow-up': totalAverageToday - totalAverageYesterday >= 0.0,
              'fa-arrow-down': totalAverageToday - totalAverageYesterday <= 0.0,
            }"
          ></i>
          {{ ((totalAverageToday - totalAverageYesterday) / 100).toFixed(2) }}
        </span>
        <br />
        <small
          >VS yesterday(${{ (totalAverageYesterday / 100).toFixed(2) }})</small
        >
      </b-col>
    </b-row>
  </b-card>
</template>

<style scoped>
.report-title {
  color: #5b7fbd;
}
</style>
