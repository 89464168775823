<template>
  <b-tab title="Details">
    <template #title> <i class="fab fa-wpforms" /> Details </template>
    <b-form @submit="saveProduct">
      <b-row>
        <b-col>
          <b-card>
            <b-row>
              <b-col lg="6" sm="12">
                <b-form-group
                  label="Title"
                  description="Product's display title"
                >
                  <b-form-input
                    v-model="productInfo.name"
                    placeholder="i.e: Coffee, Burger, Bacon & Egg Pie, etc."
                    required
                  />
                </b-form-group>
              </b-col>
              <b-col lg="6" sm="12">
                <b-form-group
                  label="Description"
                  description="Product's description"
                >
                  <b-form-textarea
                    placeholder="i.e: Delicious whole grain toast served with egg(scrambled, poached, fried), bacon & avocado"
                    v-model="productInfo.description"
                    required
                  />
                </b-form-group>
              </b-col>
              <b-col lg="6" sm="12">
                <b-form-group
                  label="Stock"
                  description="Product's available stock. Enter -1 for unlimited stock"
                >
                  <b-form-input
                    type="number"
                    placeholder="i.e: 10"
                    v-model="productInfo.stock"
                    required
                  />
                </b-form-group>
              </b-col>
              <b-col lg="6" sm="12">
                <b-form-group
                  label="SKU"
                  description="SKU: Stock Keeping Unit. A unique identifier for the product"
                >
                  <b-form-input
                    v-model="productInfo.SKU"
                    placeholder="XYZ12345"
                  />
                </b-form-group>
              </b-col>
              <b-col lg="6" sm="12">
                <b-form-group
                  label="Visibility"
                  description="Use this option to show or hide the product to your customers"
                >
                  <b-form-checkbox v-model="productInfo.enabled">
                    Is this product accessible to customers?
                  </b-form-checkbox>
                </b-form-group>
              </b-col>
              <b-col lg="6" sm="12">
                <b-form-group
                  label="Price"
                  description="Product's base price. Additional fees or discounts can be added under the Options tab"
                >
                  <b-input-group prepend="$">
                    <b-form-input
                      v-model="productInfo.price"
                      type="number"
                      required
                      step="0.01"
                    />
                  </b-input-group>
                </b-form-group>
              </b-col>
            </b-row>
          </b-card>
          <b-button
            type="submit"
            pill
            variant="primary"
            class="float-right mt-3"
            size="lg"
            :disabled="loading"
          >
            <b-spinner v-if="loading" />
            <span v-else>Save</span>
          </b-button>
        </b-col>
      </b-row>
    </b-form>
  </b-tab>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "details-tab",
  props: ["product"],
  data: () => ({
    productInfo: {},
  }),
  computed: {
    ...mapGetters("product", ["loading"]),
    ...mapGetters("shop", ["shop"]),
    ...mapGetters("categories", ["category"]),
  },
  methods: {
    async saveProduct(e) {
      e.preventDefault();
      let productInfo = JSON.parse(JSON.stringify(this.productInfo));
      delete productInfo._id;
      let product = await this.$store.dispatch("product/UPDATE_PRODUCT", {
        productId: this.product._id,
        productInfo: {
          ...productInfo,
          price: productInfo.price * 100,
        },
        shopId: this.shop._id,
      });
      this.productInfo = {
        name: product.name,
        price: (product.price / 100).toFixed(2),
        description: product.description,
        stock: product.stock,
        enabled: product.enabled,
      };
    },
  },
  mounted() {
    if (this.product) {
      this.productInfo = {
        name: this.product.name,
        price: (this.product.price / 100).toFixed(2),
        description: this.product.description,
        stock: this.product.stock,
        enabled: this.product.enabled,
        SKU: this.product.SKU,
      };
    }
  },
};
</script>

<style scoped></style>
