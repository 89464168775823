import Vue from "vue";
import Router from "vue-router";
import Login from "./screens/login";
import PasswordReset from "./screens/password-reset";
import Home from "./screens/home";
import Settings from "./screens/settings";
import Reports from "./screens/reports";
import Customers from "./screens/customers";
import CustomerDetails from "./screens/customer-details";
import Orders from "./screens/orders";
import Menu from "./screens/menu-builder";
import CategoryDetails from "./screens/category-details";
import ProductDetails from "./screens/product-details";
import OptionDetails from "./screens/option-details";
import PromoCodes from "./screens/promo-codes";
import EmbedStore from "./screens/embed-store";
import MyAccount from "./screens/my-account";
import Kiosks from "./screens/kiosks";
import KioskDetails from "./screens/kiosk-details";
import TableOrdering from "./screens/table-ordering";
import AdminUsers from "./screens/admin-users";
import AdminShops from "./screens/admin-shops";
import UserDetails from "./screens/user-details";
import SetNewPassword from "./screens/set-new-password";
import Integrations from "@/screens/integrations";
import PageNotFound from "./screens/page-not-found";

Vue.use(Router);
const router = new Router({
  routes: [
    {
      path: "/dashboard",
      props: true,
      component: Home,
    },
    {
      path: "/login",
      props: true,
      component: Login,
    },
    {
      path: "/set-new-password",
      props: true,
      component: SetNewPassword,
    },
    {
      path: "/password-reset",
      props: true,
      component: PasswordReset,
    },
    {
      path: "/shop/settings",
      props: true,
      component: Settings,
    },
    {
      path: "/shop/reports",
      props: true,
      component: Reports,
    },
    {
      path: "/shop/customers",
      props: true,
      component: Customers,
    },
    {
      path: "/shop/customers/:customerId",
      props: true,
      component: CustomerDetails,
    },
    {
      path: "/shop/orders",
      props: true,
      component: Orders,
    },
    {
      path: "/shop/menu",
      props: true,
      component: Menu,
    },
    {
      path: "/shop/menu/:categoryId",
      props: true,
      component: CategoryDetails,
    },
    {
      path: "/shop/menu/:categoryId/products/:productId",
      props: true,
      component: ProductDetails,
    },
    {
      path: "/shop/menu/:categoryId/options/:optionId",
      props: true,
      component: OptionDetails,
    },
    {
      path: "/shop/promo-codes",
      props: true,
      component: PromoCodes,
    },
    {
      path: "/shop/embed-store",
      props: true,
      component: EmbedStore,
    },
    {
      path: "/shop/kiosks",
      props: true,
      component: Kiosks,
    },
    {
      path: "/shop/kiosks/:kioskId",
      props: true,
      component: KioskDetails,
    },
    {
      path: "/shop/table-ordering",
      props: true,
      component: TableOrdering,
    },
    {
      path: "/shop/integrations",
      props: true,
      component: Integrations,
    },
    {
      path: "/my-account",
      props: true,
      component: MyAccount,
    },
    {
      path: "/admin/users",
      props: true,
      component: AdminUsers,
    },
    {
      path: "/admin/shops",
      props: true,
      component: AdminShops,
    },
    {
      path: "/admin/users/:userId",
      props: true,
      component: UserDetails,
    },
    {
      path: "*",
      component: PageNotFound,
    },
    {
      path: "",
      component: PageNotFound,
    },
  ],
  mode: "history",
});

function beforeRouteEnter(to, from, next) {
  let outerRoutes = [
    "/login",
    "/password-reset",
    "/signup",
    "/set-new-password",
  ];
  if (outerRoutes.includes(to.path) && localStorage.getItem("userToken"))
    return next("/dashboard");
  if (!outerRoutes.includes(to.path) && !localStorage.getItem("userToken"))
    return next("/login");
  return next();
}

export default router;
export { beforeRouteEnter };
