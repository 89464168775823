export default {
  loading: (state) => state.loading,
  categories: (state) => state.categories,
  categoryFilter: (state) => state.categoryFilter,
  categoryFilterName: (state) => {
    let categoryName = "All Products";
    for (let c of state.categories) {
      if (c._id === state.categoryFilter) categoryName = c.name;
    }
    return categoryName;
  },
};
