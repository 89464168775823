<template>
  <b-modal
    title="Category Sorting"
    id="category-sort-modal"
    okTitle="Confirm"
    @ok="saveOrder"
    @show="onOpen"
    :ok-disabled="loading"
  >
    <p>Drag and drop to change the display order in the venues menu</p>
    <draggable
      v-model="categoriesList"
      group="people"
      @start="drag = true"
      @end="drag = false"
    >
      <b-list-group-item v-for="element in categoriesList" :key="element._id">
        {{ element.name }}
      </b-list-group-item>
    </draggable>
  </b-modal>
</template>

<script>
import draggable from "vuedraggable";
import { mapGetters } from "vuex";

export default {
  name: "category-sort-modal",
  components: {
    draggable,
  },
  data: () => ({
    drag: false,
    categoriesList: [],
    loading: false,
  }),
  computed: {
    ...mapGetters("category", ["categories"]),
  },
  methods: {
    async saveOrder() {
      this.loading = true;
      for (let [index, category] of this.categoriesList.entries()) {
        await this.$store.dispatch("category/UPDATE_CATEGORY", {
          categoryId: category._id,
          categoryInfo: { displayOrder: index },
          shopId: category.shop,
          skipReload: true,
        });
      }

      await this.$store.dispatch("category/GET_CATEGORIES", {
        shopId: this.categoriesList[0].shop,
      });
      this.loading = false;
    },
    onOpen() {
      this.categoriesList = this.categories;
    },
  },
};
</script>

<style scoped></style>
