import { render, staticRenderFns } from "./checkoutTab.vue?vue&type=template&id=35679cf2&scoped=true&"
import script from "./checkoutTab.vue?vue&type=script&lang=js&"
export * from "./checkoutTab.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "35679cf2",
  null
  
)

export default component.exports